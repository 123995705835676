import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";

//import NumberFormat from 'react-number-format';

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import $ from "jquery";
import "./style.css";

class SettingPpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      termsOfService: "",
    };
  }

  componentDidMount() {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/settings/termsofservice",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      let finishedTXT = res.data.content.replace(/\n/g, "<br>");
      vm.setState({ termsOfService: finishedTXT });
    });
  }

  // changetoLogin() {
  //   this.props.toggle('signin');
  //   this.props.toggle('login');
  // }

  // changeToVerificationPhone = () => {
  //   this.props.toggle('tosmodal');
  //   this.props.toggle('verificationPhoneModal');
  // }

  render() {
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("settingstosmodal")}
      >
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="signup-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("settingstosmodal")}
          size="lg"
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#FFCD46" }}
          >
            Terms Of Service{" "}
          </ModalHeader>
          <ModalBody style={{ maxHeight: 600, overflowY: "auto" }}>
            <Form>
              <FormGroup style={{ padding: 15 }} row>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.termsOfService,
                  }}
                ></div>
                {/* <p>
                    Lorem ipsum, or lipsum as it is sometimes known, is dummy
                    text used in laying out print, graphic or web designs. The
                    passage is attributed to an unknown typesetter in the 15th
                    century who is thought to have scrambled parts of Cicero's
                    De Finibus Bonorum et Malorum for use in a type specimen
                    book.
                  </p> */}
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

SettingPpModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default SettingPpModal;
