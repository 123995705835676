import $ from "jquery";

export function extendRental(id, data) {
  return $.ajax({
    method: "POST",
    dataType: "json",
    traditional: true,
    data: JSON.stringify(data),
    xhrFields: {
      withCredentials: true
    },
    url: `${process.env.REACT_APP_API}/rentals/${id}/extend`,

    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(res => res)
    .catch(res => res);
}
