import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "./post.css";
class Post extends React.Component {
  getMeta = (url) => {
    var img = new Image();
    // img.addEventListener("load", function(){
    //     // alert( this.naturalWidth +' '+ this.naturalHeight );
    // });
    img.src = url;
  };

  render() {
    const currencyFormatter = new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
    });
    let Rating = () => {
      let stars = [];
      if (this.props.postRating === 0) {
        return <span style={{ color: "rgba(0,0,0,0.4)", marginBottom: "12px" }}>Not yet rated</span>;
      } else {
        for (let i = 0; i < this.props.postRating; i++) {
          stars.push(
            <FontAwesomeIcon icon={faStar} className="checked fa-star" />
          );
        }
        return stars;
      }
    };

    return (
      <Link
        to={{
          pathname: `/post/${this.props.postId}`,
          state: {
            postUserId: this.props.postUserId,
            postId: this.props.postId,
          },
        }}
      >
        <Col
        // sm="12" md="3" lg="3"
        >
          <Card sm="12" className="dashboard-card" style={{ padding: "10px" }}>
            <CardHeader className="card-img-header p-0 mb-0">
              <div className="card-img" style={{ position: "relative" }}>
                <img
                  width="100%"
                  height="220px"
                  src={this.props.postImageUrl? this.props.postImageUrl : require("./field-bale.jpg")}
                  // src={require("./field-bale.jpg")}
                  alt="item img"
                  style={{ objectFit: "cover", borderRadius: "30px" }}
                />
              </div>
            </CardHeader>
            <CardBody
              className="text-muted dashboard-card-body p-0 mb-0"
              // style={{ height: "136px" }}
            >
              <CardTitle className="dashboard-card-title pt-2 d-flex justify-content-between align-items-center">
                <span>{this.props.postName}</span>
                {(window.location.pathname === "/myprofile" ||
                  window.location.pathname === "/myprofile/") && (
                  <>
                    {this.props.postIsActive ? (
                      <span
                        class="badge"
                        style={{
                          // position: "absolute",
                          // top: 60,
                          // right: 10,
                          backgroundColor: "#FFCD46",
                        }}
                      >
                        Active
                      </span>
                    ) : (
                      <span
                        style={{
                          // position: "absolute",
                          // top: 60,
                          // right: 10,

                          backgroundColor: "#55575F",
                        }}
                        class="badge  text-white"
                      >
                        Inactive
                      </span>
                    )}
                  </>
                )}
              </CardTitle>
              <div className="dashboard-card-text">
                <div
                  className="d-flex mb-0"
                  style={{ textTransform: "capitalize", fontWeight: "500" }}
                >
                  <span className="dash-username">{this.props.postUser}</span>
                  {/* {this.props.postUserId}  */}
                </div>
                <div className="green-txt">
                  <Rating />
                </div>
                <div
                  style={{ width: 40, padding: 1, backgroundColor: "#E5E7EB" }}
                  className="mb-3"
                ></div>
                <p>
                  <span
                    style={{ fontWeight: 600, fontSize: 18 }}
                    className=" text-dark text-bold"
                  >
                    {currencyFormatter.format(this.props.amount)}{" "}
                  </span>
                  / bale
                </p>
                <p>
                  <span
                    style={{ fontWeight: 600, fontSize: 18 }}
                    className=" text-dark text-bold"
                  >
                    {this.props.postType == "Selling" ? "Quantity available" : "Quantity wanted"}{": "}
                  </span>
                  {this.props.postQuantity}
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Link>
    );
  }
}

Post.propTypes = {
  postImageUrl: PropTypes.string,
  postName: PropTypes.string,
  postUser: PropTypes.string,
  postRating: PropTypes.number,
  postId: PropTypes.string,
  postUserId: PropTypes.string,
  amount: PropTypes.number,
};

export default Post;
