import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardHeader,
  CardImg,
  CardBody,
  CardSubtitle,
  CardText,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import $ from "jquery";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

// import SimpleMap from '../post-map';
import Map from "../GoogleAutocomplete";

// import ReactCustomGoogleAutocomplete from '../GoogleAutocomplete';

import Switch from "react-switch";

import Timeslider from "../Timeslider";

import RentalPdfModal from "../../../../Common/Modals/RentalPdfModal";
import PaymentSuccessModal from "../../../../Common/Modals/PaymentSuccessModal";

import RentalHistory from "./RentalHistory";
import "react-input-range/lib/css/index.css";

import "../GoogleAutocomplete/map.css";
import "./style.css";
import _ from "lodash";

import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import "react-toastify/dist/ReactToastify.css";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import { getEquipmentsSingle } from "../../../../App/Api/equipmentSingle.js";
import UserRating from "../UserRating";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

toast.configure();

const optionsTrucking = [
  { id: 0, label: "Pick up only, loading included" },
  { id: 1, label: "Pick up only, loading not included" },
  { id: 2, label: "Delivery to your location, unloading included" },
  { id: 3, label: "Delivery to your location, unloading not included" },
];

const optionsHay = [
  { id: 1, label: "Grass" },
  { id: 2, label: "Grass/Alfalfa" },
  { id: 3, label: "Alfalfa" },
  { id: 4, label: "Second Cut" },
  { id: 5, label: "Third Cut" },
  { id: 6, label: "Haylage" },
  { id: 7, label: "Greenfeed" },
  { id: 8, label: "Other Hay" },
];

const optionsStraw = [
  { id: 9, label: "Barley" },
  { id: 10, label: "Wheat" },
  { id: 11, label: "Oats" },
  { id: 12, label: "Peas" },
  { id: 13, label: "Other Straw" },
];

const optionsGrain = [
  { id: 14, label: "Barley" },
  { id: 15, label: "Wheat" },
  { id: 16, label: "Oats" },
  { id: 17, label: "Peas" },
  { id: 18, label: "Other Feed" },
];

export default class Example extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      selectedTrucking: null,
      checked: false,
      checked2: false,
      RentalPdfModalOpen: false,
      PaymentSuccessModalOpen: false,
      userDetails: {},
      itemDetails: [],
      addOnDetails: [],
      itemRating: 4,
      startDate: null,
      startTime: "00:00",
      endDate: null,
      endTime: "00:00",
      startDateAddOn: null,
      endDateAddOn: null,
      totalRentalAmount: 0,
      rentalAmount: 0,
      rentalDeliveryAmount: 0,
      rentalGst: 0,
      rentalPst: 0,
      rentalQst: 0,
      rentalHst: 0,
      itemDetailsId: null,
      isOpenEndedRental: true,
      deliveryLocationAddress: null,
      deliveryLocationLatitude: 0,
      deliveryLocationLongitude: 0,
      postId: null,
      postUserId: null,
      addOnIds: [],
      rentalDates: [],
      addOnTemp: [],
      dateErrors: false,
      disabledDateError: false,
      agreement: null,
      isPaymentButtonDisabled: true,
      isTermsButtonDisabled: true,
      paymentResponse: "",
      calculateTotalError: false,
      emptyAddressError: false,
      reviewsCount: [],
      messagable: false,
      showEndDate: true,
      quantity: "1",
    };

    this.modaltoggle = this.modaltoggle.bind(this);
  }

  handleTruckingCheckChange = (id) => {
    this.setState({ selectedTrucking: id }, () => this.calculateTotal());
  };

  modaltoggle(type) {
    if (type == "rentalpdf") {
      this.setState((prevState) => ({
        RentalPdfModalOpen: !prevState.RentalPdfModalOpen,
      }));
    }
    if (type == "paymentSuccess") {
      this.setState((prevState) => ({
        PaymentSuccessModalOpen: !prevState.PaymentSuccessModalOpen,
      }));
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  Rating = () => {
    let stars = [];
    for (let i = 0; i < this.state.itemDetails.score; i++) {
      stars.push(<FontAwesomeIcon icon={faStar} className="checked fa-star" />);
    }
    return stars;
  };

  componentWillMount() {
    this.setState({
      postId: this.props.postId,
      postUserId: this.props.postUserId,
      companyName: null,
      itemDetails: this.props.postInfo,
    });
  }

  componentDidMount() {
    const vm = this;

    // if (this.props.postInfo) {
    //   // let addOnObj = this.props.postInfo.addOns.map((addOnElement) => {
    //   //   vm.getAddOnsDates(addOnElement);
    //   // });

    //   let startTime = this.props.postInfo.startTime.split(":");
    //   let startHour = startTime[0];

    //   let startDate = moment
    //     .utc(this.props.postInfo.startDate)
    //     .add(startHour, "hours")
    //     .local()
    //     .format("ddd, MMM  DD, YYYY, hh:mm A");
    //   let startTimeString = startDate;

    //   let endTime = this.props.postInfo.endTime.split(":");
    //   let endHour = endTime[0];
    //   let endDate = moment
    //     .utc(this.props.postInfo.endDate)
    //     .add(endHour, "hours")
    //     .local()
    //     .format("ddd, MMM  DD, YYYY, hh:mm A");
    //   let endTimeString = endDate;

    //   this.setState(
    //     {
    //       reviewsCount: this.props.postInfo.ratings,
    //       userDetails: this.props.postInfo.user,
    //       itemDetails: this.props.postInfo,
    //       startTime: this.props.postInfo.startTime,
    //       endTime: this.props.postInfo.endTime,
    //       companyName: this.props.postInfo.user.companyName,
    //       // isOpenEndedRental: this.props.isOpenEndedRental,
    //       // endDate: endDate,
    //       // startDate: startDate,
    //       messagable: this.props.postInfo.isRentedOut,
    //       startTimeString: startTimeString,
    //       endTimeString: endTimeString,
    //       //addOnDetails: addOnObj,
    //       itemDetailsId: this.props.postInfo.id,
    //       // agreement: this.props.postInfo.user.agreement.filePath,
    //     },
    //     () => {
    //       localStorage.getItem("accessToken") && vm.getRentalDates();
    //     }
    //   );
    // }

    //vm.calculateTotal();
  }

  componentDidUpdate(prevProps, prevState) {
    const vm = this;
    if (prevState.quantity !== this.state.quantity) {
      vm.calculateTotal();
    }
  }

  getRentalDates = () => {
    const vm = this;
    let product = {
      equipmentId: vm.state.itemDetailsId,
    };
    $.ajax({
      method: "POST",
      dataType: "json",
      traditional: true,
      contentType: "application/json",
      data: JSON.stringify(product),
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/rentals/availability",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    })
      .then((res) => {
        let allDates = [];
        var today = moment();
        res.data.rentalDates.map((element) => {
          let obj = {
            after: moment(element.startDate).subtract("day", 1).toDate(),
            before: moment(element.endDate).add("day", 1).toDate(),
          };
          allDates.push(obj);
        });
        vm.setState((prevState) => ({
          rentalDates: allDates,
        }));
      })
      .catch(function (res) {
        console.log("get Rental Dates Error");
        console.log(res);
      });
  };

  process(data) {
    console.log(data);
  }

  getAddOnsDates = (addon) => {
    const vm = this;
    let product = {
      equipmentId: addon.id,
    };
    console.log(product);

    $.ajax({
      method: "POST",
      dataType: "json",
      processData: false,
      traditional: true,
      contentType: "application/json",
      data: JSON.stringify(product),
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/rentals/availability",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
      success: function (res) {
        vm.process(res);
      },
    }).then((res) => {
      let obj = {
        addOnObjImg: addon.equipmentMedias[0].filePath,
        addOnObjName: addon.name,
        addOnObjDaily: addon.dailyRate,
        addOnObjId: addon.id,
        addOnObjDate: res.data.rentalDates,
        selected: false,
        addOnminStartDate: addon.startDate,
        addOnmaxEndDate: addon.endDate,
      };
      console.log(obj);

      obj.addOnObjDate.forEach((element) => {
        vm.setState({
          startDateAddOn: element.startDate,
          endDateAddOn: element.endDate,
        });
      });

      vm.setState((prevState) => ({
        addOnDetails: [...prevState.addOnDetails, obj],
      }));
    });
  };

  setQuantity = (val) => {
    this.setState({ quantity: val });
  };

  ChangeDate = (e) => {
    console.log("da date");
    console.log(this.state.startDate);
    let startdate_utc = moment.utc(e._d).set("hour", 0).format();
    console.log(startdate_utc);
    this.setState(
      {
        startDate: startdate_utc,
      },
      () => {
        this.CheckDateLogic();
      }
    );
  };

  callbackFunction = (value, type) => {
    console.log(value, type);
    if (type == "startTime") {
      this.setState({ startTime: value });
    } else if (type == "endTime") {
      this.setState({ endTime: value });
    }
    // this.setState({ minTimeValue: childMin, maxTimeValue: childMax })
  };

  ChangeTime = (e) => {
    console.log("da time");
    console.log(e._d);
    let starttime_utc = moment.utc(e._d).format("HH:mm");
    console.log(starttime_utc);

    this.setState({
      startTime: String(starttime_utc),
    });
  };

  handleStartDayChange = (selectedDay, modifiers, dayPickerInput) => {
    const vm = this;
    const input = dayPickerInput.getInput();
    vm.setState(
      {
        startDate: selectedDay,
        addOnIds: [],
      },
      () => {
        vm.CheckDateLogic();
        if (
          vm.state.endDate !== null &&
          (vm.state.checked || vm.state.checked2) &&
          vm.state.dateErrors === false &&
          vm.state.disabledDateError === false
        ) {
          console.log("CALLLL");
          vm.calculateTotal();
        } else {
          console.log("NOCALL");
        }
      }
    );
  };

  handleEndDayChange = (selectedDay, modifiers, dayPickerInput) => {
    const vm = this;
    //const input = dayPickerInput.getInput();
    vm.setState(
      {
        endDate: selectedDay,
        addOnIds: [],
      },
      () => {
        vm.CheckDateLogic();
        console.log();
        if (
          vm.state.startDate !== null &&
          (vm.state.checked || vm.state.checked2) &&
          vm.state.dateErrors === false &&
          vm.state.disabledDateError === false
        ) {
          console.log("CALLLL");
          vm.calculateTotal();
        } else {
          console.log("NOCALL");
        }
      }
    );
  };

  getDates = (startDate, endDate) => {
    const dates = [];

    // Strip hours minutes seconds etc.
    let currentDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );

    while (currentDate <= endDate) {
      dates.push(currentDate);

      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1 // Will increase month if over range
      );
    }
    return dates;
  };

  findIfMatch = (haystack, arr) => {
    var i = 0; //array index
    var j = 0; //array index
    while (j < arr.length && i < haystack.length) {
      let cur_cal = Date.parse(haystack[i]);
      let cur_match = Date.parse(arr[j]);
      if (cur_cal > cur_match) {
        j++;
      } else if (cur_cal < cur_match) {
        i++;
      } else {
        console.log("TRUE");
        this.setState({
          disabledDateError: true,
          isTermsButtonDisabled: true,
          isPaymentButtonDisabled: true,
        });
        return true;
      }
    }
    console.log("FALSE");
    this.setState({ disabledDateError: false });
    return false;
  };

  CheckDateLogic() {
    const vm = this;
    let bannedDates = this.state.rentalDates;
    bannedDates.push(
      {
        after: moment(this.props.maxEndDate).toDate(),
        before: moment(this.props.minStartDate).toDate(),
      },
      { before: new Date() }
    );
    let availableDates = [];
    bannedDates.map((element) => {
      let dates = vm.getDates(
        moment(element.after).add("day", 1).toDate(),
        moment(element.before).subtract("day", 1).toDate()
      );
      dates.map((d) => {
        availableDates.push(d);
      });
    });

    if (this.state.endDate && this.state.startDate) {
      let start = new Date(this.state.startDate);
      let end = new Date(this.state.endDate);

      let b = vm.getDates(start, end);

      this.findIfMatch(availableDates, b);

      if (start <= end) {
        console.log("LOGIC TRUE");
        this.setState({ dateErrors: false, calculateTotalError: false });
      } else {
        // end date is earlier then start date
        this.setState({ dateErrors: true });
      }

      this.state.addOnDetails.map((addon, i) => {
        console.log("THIS IS THE START ", addon);

        if (addon.selected && addon.addOnObjDate.length > 0) {
          if (
            this.state.startDate <= addon.addOnObjDate[0].startDateAddOn ||
            this.state.endDate >= addon.addOnObjDate[0].endDateAddOn ||
            moment(addon.addOnminStartDate) <=
              moment(this.state.startDate).toDate() ||
            moment(addon.addOnmaxEndDate) >= moment(this.state.endDate).toDate()
          ) {
            this.addAddon(addon.id, i);
            // addon.selected = !addon.selected;
            this.setState({
              addOnIds: [],
            });
            console.log(addon, "THE FINISHED OBJ");
          }
        }
      });
    }
  }

  getHayType = () => {
    let vm = this;
    let foundHay = optionsHay.find((item) => {
      return item.label === vm.state.itemDetails.category.name;
      // return item.id === vm.state.orderDetails.equipments[0].category.hayType
    });
    let foundStraw = optionsStraw.find((item) => {
      return item.label === vm.state.itemDetails.category.name;
      // return item.id === vm.state.orderDetails.equipments[0].category.hayType
    });
    let foundFeed = optionsGrain.find((item) => {
      return item.label === vm.state.itemDetails.category.name;
      // return item.id === vm.state.orderDetails.equipments[0].category.hayType
    });

    if (foundHay) {
      return "Hay Bale";
    }
    if (foundStraw) {
      return "Straw Bale";
    }
    if (foundFeed) {
      return "Feed Grain";
    }
    return "";
  };

  ChangeTimeEnd = (e) => {
    console.log("da time");
    console.log(e._d);
    let endtime_utc = moment.utc(e._d).format("HH:mm");
    console.log(endtime_utc);

    this.setState({
      endTime: String(endtime_utc),
    });
  };

  getlatlong = (latLng) => {
    this.setState({
      pickupLocationLatitude: latLng.lat,
      pickupLocationLongitude: latLng.lng,
    });
  };

  getlatlongDeliver = (latLng) => {
    console.log("latlng", latLng);
    this.setState(
      {
        deliveryLocationLatitude: latLng.lat,
        deliveryLocationLongitude: latLng.lng,
      },
      () => {
        this.calculateTotal();
      }
    );
  };

  theaddressDeliver = (add) => {
    const vm = this;
    vm.setState({
      deliveryLocationAddress: add,
    });
  };

  // addAddon = (elementID, index) => {
  //   console.log(elementID);
  //   console.log("NEW CONSOLE", this.state.addOnDetails);

  //   let addons = this.state.addOnDetails;

  //   addons[index].selected = !addons[index].selected;
  //   if (this.state.addOnIds.length > 0) {
  //     if(this.state.selectedAddons.includes(elementID)){
  //         this.setState({
  //           addOnIds: this.state.addOnIds.filter(ele => ele !== elementID)
  //         });
  //       } else {
  //         this.setState(state => {
  //           const addOnIds = [...state.addOnIds, elementID];
  //           return addOnIds;
  //         });
  //       }
  //   } else {
  //     this.setState({ addOnIds: [elementID] });
  //   }
  // };

  addAddon = (elementID, index) => {
    console.log(elementID);
    console.log("addondetails", this.state.addOnDetails);
    let addons = this.state.addOnDetails;
    let selected = this.state.addOnIds;

    addons[index].selected = !addons[index].selected;
    if (this.state.addOnIds.length > 0) {
      console.log(selected.includes(elementID));
      if (this.state.addOnIds.includes(elementID)) {
        selected = selected.filter((ele) => ele !== elementID);
      } else {
        selected.push(elementID);
      }
    } else {
      selected.push(elementID);
    }
    console.log("Checking select", selected);
    this.setState({ addOnIds: selected });
  };

  isDelivery = () => {
    let vm = this;
    if (vm.state.selectedTrucking === 2 || vm.state.selectedTrucking === 3)
      return true;
    return false;
  };

  calculateTotal = () => {
    const vm = this;

    let add = this.state.deliveryLocationAddress;
    if (add === null && this.isDelivery()) {
      vm.setState({
        emptyAddressError: true,
      });
    } else {
      vm.setState({
        emptyAddressError: false,
      });
    }
    console.log(vm.state.startDate, vm.state.endDate);

    let startDate = moment.utc(vm.state.startDate).set("hour", 0).format();
    let endDate = moment.utc(vm.state.endDate).set("hour", 20).format();
    console.log(startDate, endDate);

    let myData = {
      equipmentId: vm.state.itemDetails.id,
      addOnIds: vm.state.addOnIds,
      isOpenEndedRental: vm.state.isOpenEndedRental,
      // startDate: startDate,
      // endDate: endDate,
      // startTime: vm.state.startTime,
      // endTime: vm.state.endTime,
      freightOptions: vm.state.selectedTrucking,
      quantity: vm.state.quantity == "" ? 1 : vm.state.quantity,
      deliveryLocationAddress: this.isDelivery()
        ? this.state.deliveryLocationAddress
        : null,
      deliveryLocationLatitude: this.isDelivery()
        ? this.state.deliveryLocationLatitude
        : 0,
      deliveryLocationLongitude: this.isDelivery()
        ? this.state.deliveryLocationLongitude
        : 0,
      // "deliveryLocationAddress": "2004 4 St SW, Calgary, AB T2S 1W3, Canada",
      // "deliveryLocationLatitude": 51.035156,
      // "deliveryLocationLongitude": -114.0715212
    };
    if (vm.state.isOpenEndedRental) {
      delete myData.endDate;
      delete myData.endTime;
    }
    console.log(myData);
    if (
      this.state.dateErrors ||
      this.state.disabledDateError ||
      this.state.calculateTotalError
    ) {
      vm.setState({
        calculateTotalError: true,
      });
    } else {
      vm.setState({
        calculateTotalError: false,
      });
      $.ajax({
        method: "POST",
        dataType: "json",

        traditional: true,
        data: JSON.stringify(myData),
        xhrFields: {
          withCredentials: true,
        },

        url: process.env.REACT_APP_API + "/rentals/calculate",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(function (res) {
          console.log("********* POST TEST **********");
          console.log({ resData: res.data });
          vm.setState({
            totalRentalAmount: res.data.total.toFixed(2),
            rentalAmount: res.data.rentalAmount.toFixed(2),
            rentalGst: res.data.gst.toFixed(2),
            rentalPst: res.data.pst.toFixed(2),
            rentalQst: res.data.qst.toFixed(2),
            rentalHst: res.data.hst.toFixed(2),
            rentalDeliveryAmount: res.data.deliveryAmount.toFixed(2),
            isTermsButtonDisabled: false,
          });
        })
        .catch(function (res) {
          console.log(res);
        });
    }
  };

  handleToken = (token) => {
    const vm = this;
    let utcStartTime = vm.state.startTime;
    let utcEndTime = vm.state.endTime;
    let startHour;
    let endHour;

    if (typeof vm.state.startTime == "string") {
      let startTime = vm.state.startTime.split(":");
      startHour = startTime[0];
      let endTime = vm.state.endTime.split(":");
      endHour = endTime[0];
    } else {
      startHour = vm.state.startTime;
      endHour = vm.state.endTime;
    }

    let startDate = moment(this.state.startDate)
      .set("hour", 0)
      // .add(startHour, "hours")
      .format();
    let endDate = moment(this.state.endDate)
      .set("hour", 20)
      // .add(endHour, "hours")
      .format();

    let finishedStartDate = startDate;
    let finishedEndDate = endDate;

    let product = {
      stripeToken: token.id,
      equipmentId: this.state.itemDetails.id,
      addOnIds: this.state.addOnIds,
      quantity: this.state.quantity == "" ? 1 : +this.state.quantity,
      amount: +this.state.totalRentalAmount,
      freightOptions: this.state.selectedTrucking,
      // startDate: finishedStartDate,
      // startTime: utcStartTime,
      // endDate: finishedEndDate,
      // endTime: utcEndTime,
      isOpenEndedRental: this.state.isOpenEndedRental,
      deliveryLocationAddress: this.state.deliveryLocationAddress,
      deliveryLocationLatitude: this.state.deliveryLocationLatitude,
      deliveryLocationLongitude: this.state.deliveryLocationLongitude,
    };

    if (this.state.isOpenEndedRental) {
      delete product.endDate;
      delete product.endTime;
    }
    console.log(product);

    $.ajax({
      method: "POST",
      dataType: "json",
      processData: false,
      traditional: true,
      contentType: "application/json",
      data: JSON.stringify(product),
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/rentals/purchase",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    })
      .then(function (res) {
        console.log(res);
        vm.setState({
          paymentResponse: res.code,
        });
        if (vm.state.paymentResponse === "SUCCESS") {
          vm.setState({
            PaymentSuccessModalOpen: true,
            isPaymentButtonDisabled: true,
          });
        } else {
          alert("Payment Unsuccessful");
        }
      })
      .catch(function (res) {
        console.log(res);
        alert("Payment Unsuccessful");
      });
  };

  filterTrucking = (id) => {
    let vm = this;
    if (
      id == 0 &&
      vm.state.itemDetails.pickUpLoadingIncluded &&
      vm.state.itemDetails.isPickupAvailable
    )
      return true;
    else if (
      id == 1 &&
      vm.state.itemDetails.pickUpLoadingNotIncluded &&
      vm.state.itemDetails.isPickupAvailable
    )
      return true;
    else if (
      id == 2 &&
      vm.state.itemDetails.deliveryUnloadingIncluded &&
      vm.state.itemDetails.isDeliveryAvailable
    )
      return true;
    else if (
      id == 3 &&
      vm.state.itemDetails.deliveryUnloadingNotIncluded &&
      vm.state.itemDetails.isDeliveryAvailable
    )
      return true;
  };

  acceptTerms = (accept) => {
    this.setState({ isPaymentButtonDisabled: accept });
  };

  render() {
    const { selectedDay, isDisabled, isEmpty } = this.state;
    console.log("checked", this.state.checked);
    let bannedDates = this.state.rentalDates;
    bannedDates.push(
      {
        after: moment(this.props.maxEndDate).toDate(),
        before: moment(this.props.minStartDate).toDate(),
      },
      { before: new Date() }
    );
    const currencyFormatter = new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
    });
    return (
      <div>
        {/* <p>
          {isEmpty && "Please type or pick a day"}
          {!isEmpty && !selectedDay}
          {selectedDay && isDisabled && "This day is disabled"}
          {selectedDay &&
            !isDisabled &&
            `You chose ${selectedDay.toLocaleDateString()}`}
        </p> */}
        {/* <RentalPdfModal
          acceptTerms={this.acceptTerms}
          agreement={this.state.agreement}
          toggle={this.modaltoggle}
          modalOpen={this.state.RentalPdfModalOpen}
        /> */}

        <PaymentSuccessModal
          toggle={this.modaltoggle}
          modalOpen={this.state.PaymentSuccessModalOpen}
        />
        {this.props.postInfo.user.id == localStorage.getItem("userId") ? (
          <Nav tabs className="single-post-navs no-wrap-flex">
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                {" "}
                INFORMATION{" "}
              </NavLink>
            </NavItem>
            {this.state.postUserId == localStorage.getItem("userId") ? (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  ORDER HISTORY
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
        ) : null}
        <TabContent className="p-0 m-0" activeTab={this.state.activeTab}>
          <TabPane className="p-0 m-0" tabId="1">
            <Row className="p-0 m-0">
              <Col sm="12" className="post-info-main-container m-0 p-0">
                <Row style={{ padding: "0 10px", marginBottom: 30 }}>
                  <div className=" p-0">
                    <h2 className="post-title mb-4">
                      {this.state.itemDetails.name}
                    </h2>
                    <h4
                      className="post-price mb-12"
                      style={{ marginBottom: "1.5rem" }}
                    >
                      {currencyFormatter.format(
                        this.state.itemDetails.dailyRate
                      )}{" "}
                      <span style={{ fontWeight: 400, fontSize: 18 }}>
                        / Bale
                      </span>
                    </h4>
                    <div className="d-flex align-items-center">
                      {this.state.userDetails.profileImage ? (
                        <img
                          className="profile-img mr-4"
                          src={this.state.userDetails.profileImage}
                          alt="search"
                        />
                      ) : (
                        <div className="profile-img mr-4 d-flex justify-content-center align-items-center p-8">
                          {this.props.postInfo.user.firstName.charAt(0)}
                        </div>
                      )}
                      {this.props.postInfo.user ? (
                        <p
                          className="post-title-username "
                          style={{ marginBottom: "0px" }}
                        >
                          {`${this.props.postInfo.user.firstName} ${this.props.postInfo.user.lastName}`}
                        </p>
                      ) : null}
                    </div>
                    {/* <div className="right-side"> */}
                    {/* <div className="right-side-one"> */}
                    {/* {localStorage.getItem("accessToken") && (
                          <p className="post-title-username">
                            {" "}
                            <Link
                              to={
                                this.props.postUserId ==
                                localStorage.getItem("userId")
                                  ? {
                                      pathname: `/myprofile`,
                                      state: { userId: this.props.postUserId },
                                    }
                                  : {
                                      pathname: `/profile/${this.props.postUserId}`,
                                      state: {
                                        userId: this.props.postUserId,
                                        messagable: this.state.messagable,
                                      },
                                    }
                              }
                            >
                              {" "}
                              {this.state.userDetails.firstName}{" "}
                              {this.state.userDetails.lastName}
                            </Link>
                          </p>
                        )} */}
                    {/* {this.state.itemDetails.allowsCancelling == true ? (
                          <p style={{ color: "#B1B1B1" }}>
                            {" "}
                            Allows Cancellations{" "}
                          </p>
                        ) : (
                          <p style={{ color: "#B1B1B1" }}>
                            Does Not Allow Cancellations{" "}
                          </p>
                        )} */}
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </Row>
                <hr />

                {/* <Row style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side">
                      <img className="left-side-icon" src={require('../../../../Common/images/icon_info.svg')} alt="search" />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Description  </h4>
                      <div className="post-title-username post-info-block" style={{ padding: '0 20px 10px 0' }}>
                        <div className="rates-container">
                          <p className="rateFreq">Make</p>
                          <p className="ratePrice"> {this.state.itemDetails.make} </p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Model</p>
                          <p className="ratePrice">  {this.state.itemDetails.model}  </p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Year</p>
                          <p className="ratePrice"> {this.state.itemDetails.year} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row> */}

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container m-0 p-0">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../../../Common/images/newicons/info_icon.svg")}
                        alt="info"
                        color="#E7B10A"
                      />
                    </div>
                    <div className="" style={{ fontSize: 18 }}>
                      <h4 className="post-subtitle">Description </h4>
                      <p className=""> {this.state.itemDetails.description} </p>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container m-0 p-0">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../../../Common/images/newicons/ruler-icon.svg")}
                        alt="info"
                        color="#E7B10A"
                      />
                    </div>
                    <div className="" style={{ fontSize: 18, width: "100%" }}>
                      <h4 className="post-subtitle">Specifications </h4>
                      {/* <p className=""> {this.state.itemDetails.description} </p> */}

                      <div
                        className=" post-info-block"
                        style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                      >
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p className="rateFreq">Crop Year</p>
                          <p className="ratePrice ml-auto">
                            {" "}
                            {this.state.itemDetails.year}{" "}
                          </p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Type</p>
                          <p className="ratePrice"> {this.getHayType()} </p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Category</p>
                          <p className="ratePrice">
                            {" "}
                            {this.state.itemDetails.category
                              ? this.state.itemDetails.category.name
                              : ""}{" "}
                          </p>
                        </div>
                        <div className="rates-container">
                          <p className="rateFreq">Weight per bale</p>
                          <p className="ratePrice">
                            {" "}
                            {this.state.itemDetails.weight} lbs
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container m-0 p-0">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../../../Common/images/newicons/quantity-ellipsis-stroke.svg")}
                        alt="info"
                        color="#E7B10A"
                      />
                    </div>
                    <div className="" style={{ fontSize: 18, width: "100%" }}>
                      <h4 className="post-subtitle">
                        {this.state.itemDetails.selling
                          ? "Quantity Available"
                          : "Quantity Wanted"}{" "}
                      </h4>

                      <div
                        className=" post-info-block"
                        style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                      >
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p className="rateFreq">
                            {this.state.itemDetails.selling
                              ? "Quantity Available"
                              : "Quantity Wanted"}
                          </p>
                          <p className="ratePrice ml-auto">
                            {" "}
                            {this.state.itemDetails.quantity}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container m-0 p-0">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../../../Common/images/newicons/comments-icon.svg")}
                        alt="info"
                        color="#E7B10A"
                      />
                    </div>
                    <div className="" style={{ fontSize: 18, width: "100%" }}>
                      <h4 className="post-subtitle">Comments </h4>

                      <div
                        className=" post-info-block"
                        style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                      >
                        <p className="ratePrice ml-auto">
                          {this.state.itemDetails.comments
                            ? this.state.itemDetails.comments
                            : "No comments"}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container m-0 p-0">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../../../Common/images/newicons/truck-icon.svg")}
                        alt="info"
                        color="#E7B10A"
                        height={30}
                      />
                    </div>
                    <div className="" style={{ fontSize: 18, width: "100%" }}>
                      <h4 className="post-subtitle">
                        Trucking/Freight Options{" "}
                      </h4>

                      <div
                        className=" post-info-block"
                        style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                      >
                        <form>
                          <Col className="p-0">
                            {optionsTrucking
                              .filter((item) => this.filterTrucking(item.id))
                              .map((option, idx) => {
                                if (
                                  this.state.selectedTrucking == null &&
                                  idx == 0
                                ) {
                                  this.handleTruckingCheckChange(option.id);
                                }
                                return (
                                  <div
                                    key={option.id}
                                    className="form-check p-0"
                                  >
                                    <label className="filter-radio-container">
                                      <input
                                        // On view disable this missing
                                        type="radio"
                                        name="options"
                                        value={option.id || ""}
                                        checked={
                                          this.state.selectedTrucking ===
                                          option.id
                                        }
                                        onChange={() =>
                                          this.handleTruckingCheckChange(
                                            option.id
                                          )
                                        }
                                        // onChange={() =>
                                        //   this.handleChange(option.id)
                                        // }
                                        className="form-check-input radio-custom"
                                      />
                                      <span className="option-label">
                                        {option.label}
                                      </span>
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                );
                              })}
                          </Col>
                        </form>
                      </div>
                    </div>
                  </div>
                </Row>

                <Row style={{ padding: 10 }}>
                  <div className="container post-info-container m-0 p-0">
                    <div className="left-side newicons">
                      <img
                        className="left-side-icon"
                        src={require("../../../../Common/images/newicons/money-bills-simple.svg")}
                        alt="info"
                        color="#E7B10A"
                      />
                    </div>
                    <div className="" style={{ fontSize: 18, width: "100%" }}>
                      <h4 className="post-subtitle">Delivery Rate</h4>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className=" post-info-block"
                          style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                        >
                          <div
                            className="rates-container"
                            style={{ width: "100%" }}
                          >
                            <p className="rateFreq">Flat Rate, first 10 km</p>

                            <p className="ratePrice ml-auto">
                              {currencyFormatter.format(
                                this.state.itemDetails.deliveryRateFirst10Km
                              )}{" "}
                            </p>
                          </div>
                        </div>
                        <div
                          className=" post-info-block"
                          style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                        >
                          <div
                            className="rates-container"
                            style={{ width: "100%" }}
                          >
                            <p className="rateFreq">Extra km</p>

                            <p className="ratePrice ml-auto">
                              {currencyFormatter.format(
                                this.state.itemDetails.deliveryRateExtraPerKm
                              )}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                {this.isDelivery() ? (
                  <Row style={{ padding: 10 }}>
                    <div className="container post-info-container">
                      <div className="left-side" />
                      <div className="right-side">
                        <div className="my-map-container">
                          <Map
                            places={[
                              {
                                type: "Pickup / Delivery",
                                geometry: {
                                  location: {
                                    lat: this.state.deliveryLocationLatitude,
                                    lng: this.state.deliveryLocationLongitude,
                                  },
                                },
                                address: this.state.deliveryLocationAddress,
                                returnAddress: "",
                              },
                            ]}
                            theaddress={this.theaddressDeliver}
                            getlatlong={this.getlatlongDeliver}
                            center={{ lat: 51.05011, lng: -114.08529 }}
                            calculateTotal={this.calculateTotal}
                          />
                        </div>
                      </div>
                    </div>
                  </Row>
                ) : null}

                {this.state.postUserId != localStorage.getItem("userId") && (
                  <Row
                    style={{
                      padding: 10,
                    }}
                  >
                    <div className="container post-info-container m-0 p-0">
                      <div className="left-side newicons">
                        <img
                          className="left-side-icon"
                          src={require("../../../../Common/images/newicons/payment-icon.svg")}
                          alt="info"
                          color="#E7B10A"
                          height={25}
                        />
                      </div>
                      <div className="" style={{ fontSize: 18, width: "100%" }}>
                        <h4 className="post-subtitle">Payment </h4>

                        <div
                          className=" post-info-block"
                          style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                        >
                          <p className="ratePrice ml-auto">Quantity </p>
                        </div>
                        <div className="d-flex justify-content-between mb-4">
                          <div
                            className="d-flex align-items-center"
                            style={{ flexGrow: 1 }}
                          >
                            <input
                              style={{ flexGrow: 1 }}
                              value={this.state.quantity || "1"}
                              onChange={(e) => {
                                if (
                                  e.target.value >
                                  this.state.itemDetails.quantity
                                ) {
                                  return this.setState({
                                    quantity: this.state.itemDetails.quantity,
                                  });
                                }
                                if (e.target.value < 1) {
                                  return this.setState({ quantity: 1 });
                                }
                                this.setQuantity(e.target.value);
                              }}
                              type="number"
                              step="1"
                              min={1}
                              max={this.state.itemDetails.quantity}
                              name="quantity"
                              id="quantity"
                              className="input-style"
                              // placeholder="Bales"
                              // disabled={selectedGrain}
                            />
                            <span className="pl-2">Bales</span>
                          </div>
                          {/* <div className="text-right">
                                For{" "}
                                <span className="post-price">
                                  {currencyFormatter.format(this.state.rentalAmount)}{" "}
                                </span>{" "}
                              </div> */}
                        </div>
                        <div
                          className=" post-info-block"
                          style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                        >
                          <p className="ratePrice ml-auto">Order </p>
                        </div>
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p className="rateFreq">Items</p>
                          <p className=" ml-auto"> {this.state.quantity} </p>
                        </div>
                        <hr />
                        <div
                          className=" post-info-block"
                          style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                        >
                          <p className="ratePrice ml-auto">Delivery </p>
                        </div>
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p className="rateFreq">Delivery</p>
                          <p className=" ml-auto">
                            {" "}
                            {currencyFormatter.format(
                              this.state.rentalDeliveryAmount
                            )}{" "}
                          </p>
                        </div>
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p className="rateFreq">GST</p>
                          <p className=" ml-auto">
                            {" "}
                            {currencyFormatter.format(
                              this.state.rentalGst
                            )}{" "}
                          </p>
                        </div>
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p className="rateFreq">PST</p>
                          <p className=" ml-auto">
                            {" "}
                            {currencyFormatter.format(
                              this.state.rentalPst
                            )}{" "}
                          </p>
                        </div>
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p className="rateFreq">HST</p>
                          <p className=" ml-auto">
                            {" "}
                            {currencyFormatter.format(
                              this.state.rentalHst
                            )}{" "}
                          </p>
                        </div>
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p className="rateFreq">QST</p>
                          <p className=" ml-auto">
                            {" "}
                            {currencyFormatter.format(
                              this.state.rentalQst
                            )}{" "}
                          </p>
                        </div>
                        <hr />
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p
                            className="rateFreq"
                            style={{ fontWeight: "bold" }}
                          >
                            Total
                          </p>
                          <p
                            className=" ml-auto"
                            style={{ fontWeight: "bold" }}
                          >
                            {" "}
                            {currencyFormatter.format(
                              this.state.totalRentalAmount
                            )}{" "}
                          </p>
                        </div>
                        {this.state.postUserId !=
                          localStorage.getItem("userId") && (
                          <div
                            style={{ paddingBottom: "20px" }}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <button
                              type="button"
                              onClick={() => {
                                // this._handlePostUpdate;
                                // localStorage.setItem(
                                //   "outsideChatUserId",
                                //   this.state.postUserId
                                // );
                                this.props.handler(this.state.postUserId);

                                // alert("boom");
                              }}
                              className="btn-white"
                              style={{
                                fontSize: 18,
                              }}
                            >
                              <img
                                src={require("../../../../Common/images/newicons/chat-icon.svg")}
                                alt="icon filter"
                                color="black"
                              />
                              {this.state.itemDetails.selling == true
                                ? "Contact Seller"
                                : this.state.itemDetails.lookingFor == true
                                ? "Contact Buyer"
                                : "Contact"}
                            </button>
                            {/* {!this.state.isPaymentButtonDisabled ? ( */}
                            {this.state.itemDetails.selling == true && (
                              <StripeCheckout
                                stripeKey={process.env.REACT_APP_STRIPE}
                                token={this.handleToken}
                                className="btn btn-success"
                                name="Make Payment"
                              >
                                <button
                                  disabled={
                                    !this.state.selectedTrucking ||
                                    this.state.quantity >
                                      this.state.itemDetails.quantity
                                  }
                                  block
                                  className="btn-hay-yellow"
                                >
                                  Buy Now
                                </button>
                              </StripeCheckout>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Row>
                )}

                {!this.state.isPaymentButtonDisabled ? (
                  <StripeCheckout
                    stripeKey={process.env.REACT_APP_STRIPE}
                    token={this.handleToken}
                    className="btn btn-success"
                    name="Make Payment"
                  >
                    <Button
                      disabled={this.state.isPaymentButtonDisabled}
                      block
                      className="post-page-continue postview-submitbutton"
                    >
                      Make Payment
                    </Button>
                  </StripeCheckout>
                ) : null}
              </Col>
            </Row>
          </TabPane>

          {this.state.postUserId == localStorage.getItem("userId") ? (
            <TabPane tabId="2" activeTab={this.state.activeTab}>
              <Row>
                <RentalHistory equipmentId={this.state.postId} />
              </Row>
            </TabPane>
          ) : null}
        </TabContent>
      </div>
    );
  }
}

Example.propTypes = {
  postImageUrl: PropTypes.string,
  postName: PropTypes.string,
  postUser: PropTypes.string,
  postRating: PropTypes.number,
  postId: PropTypes.string,
  postUserId: PropTypes.string,
};
