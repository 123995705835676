import React, { Component } from "react";
import closeIcon from "./../assets/close-icon.png";

class Header extends Component {
  render() {
    return (
      <div className="sc-header">
        <div
          style={{ width: 20, height: 20, marginLeft: 3 }}
          class="profile-img pr-1"
        >
          <img className="img-circle" src={this.props.imageUrl} alt="" />
        </div>

        <div className="sc-header--team-name"> {this.props.userName} </div>
        {/* <img className={'sc-delete-icon'} src={deleteIcon} onClick={this.props.onDelete} /> */}
        <div className="sc-header--close-button" onClick={this.props.onClose}>
          <img src={closeIcon} alt="" />
        </div>
      </div>
    );
  }
}

export default Header;
