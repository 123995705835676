import PropTypes from "prop-types";
import React, { Component } from "react";
import MessageList from "./MessageList";
import UserInput from "./UserInput";
import Header from "./Header";

class ChatWindow extends Component {
  onUserInputSubmit(message) {
    this.props.onUserInputSubmit(message);
  }

  render() {
    let messageList = this.props.messageList || [];
    let classList = ["sc-chat-window", this.props.isOpen ? "opened" : "closed"];
    return (
      <div className={classList.join(" ")}>
        <Header
          userName={this.props.userProfile}
          imageUrl={this.props.imageUrl}
          onClose={this.props.onClose}
          onDelete={this.props.onDelete}
        />
        <MessageList messages={messageList} imageUrl={this.props.imageUrl} />
        <UserInput onSubmit={this.onUserInputSubmit.bind(this)} />
      </div>
    );
  }
}

ChatWindow.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUserInputSubmit: PropTypes.func.isRequired
};

export default ChatWindow;
