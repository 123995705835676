import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import { Card, CardTitle, CardImg, CardBody } from "reactstrap";
import DateTime from "./DateTimePicker";
import moment from "moment";
import { getCategories } from "../../../App/Api/categories";
import { getStripeCheck } from "../../../App/Api/getStripeCheck";

import Switch from "react-switch";

import "./editPost.css";
import "../ViewPost/ViewPostTabs/style.css";

import $ from "jquery";

import PostSuccessModal from "../../../Common/Modals/PostSuccess";
import Posteditsuccessmodal from "../../../Common/Modals/PostEditSuccess";
import OnBoardModal from "../../../Common/Modals/OnBoradModal";

import Loading from "../../../Common/Loading";

import Map from "../EditPost/GoogleAutocomplete";
import "../EditPost/GoogleAutocomplete/map.css";
import Header from "../../../Common/Header";

import { Link } from "react-router-dom";
import Swal from "sweetalert2";
// const categories = [
//   { id: 0, name: "--- Hay Bales ---", optionType: true },
//   { id: 1, name: "Grass", optionType: false },
//   { id: 2, name: "Grass/Alfalfa", optionType: false },
//   { id: 3, name: "Alfalfa", optionType: false },
//   { id: 4, name: "Second Cut", optionType: false },
//   { id: 5, name: "Third Cut", optionType: false },
//   { id: 6, name: "Haylage", optionType: false },
//   { id: 7, name: "Greenfeed", optionType: false },
//   { id: 8, name: "Other Hay", optionType: false },
//   { id: 9, name: " --- Straw Bales ---", optionType: true },
//   { id: 10, name: "Barley", optionType: false },
//   { id: 11, name: "Wheat", optionType: false },
//   { id: 12, name: "Oats", optionType: false },
//   { id: 13, name: "Peas", optionType: false },
//   { id: 14, name: "Other Straw", optionType: false },
//   { id: 15, name: "--- Feed Grains ---", optionType: true },
//   { id: 16, name: "Barley", optionType: false },
//   { id: 17, name: "Wheat", optionType: false },
//   { id: 18, name: "Oats", optionType: false },
//   { id: 19, name: "Peas", optionType: false },
//   { id: 20, name: "Other Feed", optionType: false },
// ];
const categories = [
  {
    id: 0,
    name: "--- Hay Bales ---",
    optionType: true,
    haytype: 0,
    imagePath: "",
  },
  { id: 1, name: "Grass", optionType: false, haytype: 0, imagePath: "" },
  {
    id: 2,
    name: "Grass/Alfalfa",
    optionType: false,
    haytype: 0,
    imagePath: "",
  },
  { id: 3, name: "Alfalfa", optionType: false, haytype: 0, imagePath: "" },
  { id: 4, name: "Second Cut", optionType: false, haytype: 0, imagePath: "" },
  { id: 5, name: "Third Cut", optionType: false, haytype: 0, imagePath: "" },
  { id: 6, name: "Haylage", optionType: false, haytype: 0, imagePath: "" },
  { id: 7, name: "Greenfeed", optionType: false, haytype: 0, imagePath: "" },
  { id: 8, name: "Other Hay", optionType: false, haytype: 0, imagePath: "" },
  {
    id: 19,
    name: " --- Straw Bales ---",
    optionType: true,
    haytype: 0,
    imagePath: "",
  },
  { id: 9, name: "Barley", optionType: false, haytype: 1, imagePath: "" },
  { id: 10, name: "Wheat", optionType: false, haytype: 1, imagePath: "" },
  { id: 11, name: "Oats", optionType: false, haytype: 1, imagePath: "" },
  { id: 12, name: "Peas", optionType: false, haytype: 1, imagePath: "" },
  { id: 13, name: "Other Straw", optionType: false, haytype: 1, imagePath: "" },
  {
    id: 20,
    name: "--- Feed Grains ---",
    optionType: true,
    haytype: 2,
    imagePath: "",
  },
  { id: 14, name: "Barley", optionType: false, haytype: 2, imagePath: "" },
  { id: 15, name: "Wheat", optionType: false, haytype: 2, imagePath: "" },
  { id: 16, name: "Oats", optionType: false, haytype: 2, imagePath: "" },
  { id: 17, name: "Peas", optionType: false, haytype: 2, imagePath: "" },
  { id: 18, name: "Other Feed", optionType: false, haytype: 2, imagePath: "" },
];
const optionsTrucking = [
  { id: 1, label: "Pick up only, loading included" },
  { id: 2, label: "Pick up only, loading not included" },
  { id: 3, label: "Delivery to your location, unloading included" },
  { id: 4, label: "Delivery to your location, unloading not included" },
];
class EditPost extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.handleChange4 = this.handleChange4.bind(this);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    this.state = {
      PostSuccessModalOpen: false,
      PostEditSuccessModalOpen: false,
      checked: false,
      categorie: [],
      categoriesBe: [],
      checked3: true,
      checked2: false,
      categoryId: 0,
      equipmentIds: [],
      addOnIds: [],
      equipmentMediasList: [], //should be uploadedMedia
      isAddOn: false,
      canBeRentedAsStandAlone: false,
      name: null,
      make: null,
      model: null,
      type: null,
      description: null,
      axles: 0,
      weight: 0,
      length: 0,
      year: currentYear,
      sleeps: 0,
      dailyRate: 0,
      weeklyRate: 0,
      comments: null,
      startDate: null,

      startTime: null,

      endDate: null,

      endTime: null,

      isPickupAvailable: false,
      isOpenEndedRental: false,
      pickupLocationAddress: null,

      pickupLocationLatitude: 0,
      pickupLocationLongitude: 0,

      isDeliveryAvailable: false,
      deliveryLocationAddress: null,

      pickupLocationAddress_inc: null,
      pickupcity: null,
      pickuppostal: null,

      deliveryLocationLatitude: 0,
      deliveryLocationLongitude: 0,

      deliveryRateFirst10Km: 0,
      deliveryRateExtraPerKm: 0,
      imagefilepath: [],

      files: [],
      imagesPreviewUrls: [],

      videoFiles: [],
      videoPreviewUrls: [],

      uploadedMedia: [],

      uploadMediaErrors: "",

      are_any_images_uploaded: false,
      addOnDetails: [],

      selectedAddons: [],

      equipDetails: [],

      selectedEquips: [],
      fielderrors: false,
      dateErrors: false,
      apierrors: "",
      editingmyown: false,
      initialStartDateFromapi: null,
      initialEndDateFromapi: null,
      updatePickupAddress: false,
      updateDeliveryAddress: false,

      updateStartDateTime: false,
      updateEndDateTime: false,

      loading: false,
      rentalpdfname: "",
      stripeUrl: "",
      onboardModalOpen: false,
      selectedOption: "Selling",
      totalWeight: 0,
      totalBushels: 0,
    };

    this._handleImageChange = this._handleImageChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    getStripeCheck().then((res) => {
      console.log("RES FROM STRIPE CHECK", res);
      if (!res.isStripeConnectOnboarded) {
        this.setState({
          stripeUrl: res.stripeConnectOnboardLink,
          onboardModalOpen: true,
        });
      }
    });
    if (this.state.categoryId == 15) {
      this.setState({
        weight: 0.0218,
        make: "Feed Grain",
      });
    }
    if (this.state.categoryId == 16) {
      this.setState({
        weight: 0.0272,
        make: "Feed Grain",
      });
    }
    if (this.state.categoryId == 17) {
      this.setState({
        weight: 0.015,
        make: "Feed Grain",
      });
    }
    if (this.state.categoryId == 18) {
      this.setState({
        weight: 0.027,
        make: "Feed Grain",
      });
    }
    console.log({ data: this.props.data });

    let vm = this;
    getCategories().then((res) => {
      console.log("THE CATEGORIES", res);
      vm.setState({ categoriesBe: res.items });
    });
    vm.setState({ categorie: categories });
    if (this.props.postUserId) {
      console.log("EDIT ALREADY CREAATED POST");
      //TODO: get all states of fields
      //
      this.setState({ editingmyown: true });
      this.setState({ categoryId: null });
      //this.getPostInfo();

      let mediaImage = [];
      let mediaVideo = [];
      this.props.data.equipmentMedias.forEach(function (media) {
        if (media.type == 0) {
          mediaImage.push(media.filePath);
        } else if (media.type == 2) {
          mediaVideo.push(media.filePath);
        }
      });

      let addonsIds = [];
      this.props.data.addOns.map((addon) => {
        addonsIds.push(addon.id);
      });
      let equipmentIds = [];
      this.props.data.equipments.map((addon) => {
        equipmentIds.push(addon.id);
      });
      vm.props.availability(this.props.data.isActive);

      this.setState({
        // canBeRentedAsStandAlone: standalone,
        canBeRentedAsStandAlone: this.props.data.pickUpLoadingIncluded,
        isAddOn: this.props.data.isAddOn,
        selectedAddons: [],
        selectedEquips: [],
        categoryId: this.props.data.category.id.toString(),
        name: this.props.data.name,
        make: this.props.data.make,
        model: this.props.data.model,
        type: this.props.data.type,
        description: this.props.data.description,
        axles: this.props.data.axles,
        quantity: this.props.data.quantity,
        weight: this.props.data.weight,
        length: this.props.data.length,
        year: this.props.data.year,
        sleeps: this.props.data.sleeps,
        dailyRate: this.props.data.dailyRate,
        weeklyRate: this.props.data.weeklyRate,
        startDate: this.props.data.startDate,
        endDate: this.props.data.endDate,
        startTime: this.props.data.startTime,
        endTime: this.props.data.endTime,
        comments: this.props.data.comments,
        isPickupAvailable: this.props.data.isPickupAvailable,
        isDeliveryAvailable: this.props.data.isDeliveryAvailable,
        isOpenEndedRental: this.props.data.deliveryUnloadingIncluded,
        imagesPreviewUrls: mediaImage,
        videoPreviewUrls: mediaVideo,
        pickupLocationAddress: this.props.data.pickupLocationAddress,
        pickupLocationLatitude: this.props.data.pickupLocationLatitude,
        pickupLocationLongitude: this.props.data.pickupLocationLongitude,
        deliveryLocationAddress: this.props.data.deliveryLocationAddress,
        deliveryLocationLatitude: this.props.data.deliveryLocationLatitude,
        deliveryLocationLongitude: this.props.data.deliveryLocationLongitude,
        deliveryRateFirst10Km: this.props.data.deliveryRateFirst10Km,
        deliveryRateExtraPerKm: this.props.data.deliveryRateExtraPerKm,
        uploadedMedia: this.props.data.equipmentMedias,
        //rentalpdfname: this.props.data.user.agreement.name,
      });
    } else {
      console.log("CREATE POST");

      // if (this.props.location.state.isAddOn) {
      //   this.getEquipments();
      // } else {
      //   this.getAddons();
      // }
      // this.getRentalAgreement();
      // this.setState({ isAddOn: this.props.location.state.isAddOn });
    }
  }
  checkStripeOnboard = () => {
    setInterval(() => {
      console.log("TEST TIMEOUT CHECK");
      getStripeCheck().then((res) => {
        if (res.isStripeConnectOnboarded) {
          this.setState({
            onboardModalOpen: false,
          });
        }
      });
    }, 3000);
  };

  handleChange(isPickupAvailable) {
    this.setState({ isPickupAvailable });
  }

  handleChange2(isDeliveryAvailable) {
    this.setState({ isDeliveryAvailable });
  }

  handleChange3(canBeRentedAsStandAlone) {
    console.log({ canBeRentedAsStandAlone });
    this.setState({ canBeRentedAsStandAlone });
  }

  handleChange4(isOpenEndedRental) {
    this.setState({ isOpenEndedRental });
  }

  selectChangeid(e) {
    this.setState({
      categoryId: parseInt(e.target.value),
    });
    if (e.target.value >= 1 && e.target.value <= 8) {
      this.setState({
        make: "Hay Bales",
        weight: 0,
        axles: 0,
        totalWeight: 0,
        totalBushels: 0,
        dailyRate: 0,
        weeklyRate: 0,
      });
    } else if (e.target.value >= 9 && e.target.value <= 13) {
      this.setState({
        make: "Straw Bales",
        weight: 0,
        axles: 0,
        totalWeight: 0,
        totalBushels: 0,
        dailyRate: 0,
        weeklyRate: 0,
      });
    } else {
      this.setState({
        make: "Feed Grain",
        axles: 0,
        totalWeight: 0,
        totalBushels: 0,
        dailyRate: 0,
        weeklyRate: 0,
      });
      if (e.target.value == 14) {
        this.setState({
          weight: 0.0218,
          make: "Feed Grain",
          totalWeight: 0,
          totalBushels: 0,
          dailyRate: 0,
          weeklyRate: 0,
        });
      }
      if (e.target.value == 15) {
        this.setState({
          weight: 0.0272,
          make: "Feed Grain",
          totalWeight: 0,
          totalBushels: 0,
          dailyRate: 0,
          weeklyRate: 0,
        });
      }
      if (e.target.value == 16) {
        this.setState({
          weight: 0.015,
          make: "Feed Grain",
          totalWeight: 0,
          totalBushels: 0,
          dailyRate: 0,
          weeklyRate: 0,
        });
      }
      if (e.target.value == 17) {
        this.setState({
          weight: 0.027,
          make: "Feed Grain",
          totalWeight: 0,
          totalBushels: 0,
          dailyRate: 0,
          weeklyRate: 0,
        });
      }
    }
  }

  handleQuantityChange(e) {
    {
      this.state.categoryId == 18
        ? this.setState({
            axles: e.target.value,
          })
        : this.setState({
            axles: parseInt(e.target.value),
          });
    }
    if (this.state.categoryId == 14) {
      this.setState({
        totalWeight: (e.target.value * 0.0218).toFixed(2),
        totalBushels: (e.target.value * 45.93).toFixed(1),
      });
    }
    if (this.state.categoryId == 15) {
      this.setState({
        totalWeight: (e.target.value * 0.0272).toFixed(2),
        totalBushels: (e.target.value * 36.744).toFixed(1),
      });
    }
    if (this.state.categoryId == 16) {
      this.setState({
        totalWeight: (e.target.value * 0.015).toFixed(2),
        totalBushels: (e.target.value * 64.842).toFixed(1),
      });
    }
    if (this.state.categoryId == 17) {
      this.setState({
        totalWeight: (e.target.value * 0.027).toFixed(2),
        totalBushels: (e.target.value * 36.744).toFixed(2),
      });
    }
    if (e.target.value > 0 && this.state.dailyRate > 0) {
      this.setState({
        weeklyRate: parseFloat(e.target.value * this.state.dailyRate), // weeklyRate - total price
      });
    }
  }

  handlePriceChange(e) {
    this.state.axles > 0
      ? this.setState({
          dailyRate: parseFloat(e.target.value), // dailyRate - price per bale/ tonne
          weeklyRate: parseFloat(e.target.value * this.state.axles), // weeklyRate - total price
        })
      : this.setState({
          dailyRate: parseFloat(e.target.value),
        });
  }

  handleDeliveryRateChange(e) {
    this.setState({
      deliveryRateFirst10Km: parseFloat(e.target.value),
    });
  }

  // handleQuantityChange(e) {
  //   {
  //     this.state.categoryId == 19
  //       ? this.setState({
  //           axles: e.target.value,
  //         })
  //       : this.setState({
  //           axles: parseInt(e.target.value),
  //         });
  //   }
  //   if (this.state.categoryId == 15) {
  //     this.setState({
  //       totalWeight: (e.target.value * 0.0218).toFixed(2),
  //     });
  //   }
  //   if (this.state.categoryId == 16) {
  //     this.setState({
  //       totalWeight: (e.target.value * 0.0272).toFixed(2),
  //     });
  //   }
  //   if (this.state.categoryId == 17) {
  //     this.setState({
  //       totalWeight: (e.target.value * 0.015).toFixed(2),
  //     });
  //   }
  //   if (this.state.categoryId == 18) {
  //     this.setState({
  //       totalWeight: (e.target.value * 0.027).toFixed(2),
  //     });
  //   }
  // }

  checkWeight(e) {
    console.log("checkWeight", e.target.value, e.target.value == 17);
    // if (e.target.value == 17) {
    //   this.setState({
    //     weight: 0.015,
    //   });
    // }
  }
  toggle(type) {
    if (type == "postsuccessmodal") {
      this.setState((prevState) => ({
        PostSuccessModalOpen: !prevState.PostSuccessModalOpen,
      }));
    } else if (type == "posteditsuccessmodal") {
      this.setState((prevState) => ({
        PostEditSuccessModalOpen: !prevState.PostEditSuccessModalOpen,
      }));
    } else if (type == "checkstripeonboard") {
      this.setState((prevState) => ({
        onboardModalOpen: !prevState.onboardModalOpen,
      }));
    }
  }

  getRentalAgreement = () => {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: `${process.env.REACT_APP_API}/users/me`,

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      this.setState({ rentalpdfname: res.data.agreement.name });
    });
  };

  getPostInfo = () => {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: `${process.env.REACT_APP_API}/equipments/${this.props.postId}`,

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      let mediaImage = [];
      let mediaVideo = [];
      res.data.equipmentMedias.forEach(function (media) {
        if (media.type == 0) {
          mediaImage.push(media.filePath);
        } else if (media.type == 2) {
          mediaVideo.push(media.filePath);
        }
      });

      let addonsIds = [];
      res.data.addOns.map((addon) => {
        addonsIds.push(addon.id);
      });
      let equipmentIds = [];
      res.data.equipments.map((addon) => {
        equipmentIds.push(addon.id);
      });
      vm.props.availability(res.data.isActive);

      console.log("CAN BE RENTED AS STAND ALONE", res.data);

      vm.setState(
        {
          // canBeRentedAsStandAlone: standalone,
          canBeRentedAsStandAlone: res.data.pickUpLoadingIncluded,
          isAddOn: res.data.isAddOn,
          selectedAddons: addonsIds,
          selectedEquips: equipmentIds,
          categoryId: res.data.category.id.toString(),
          name: res.data.name,
          make: res.data.make,
          model: res.data.model,
          type: res.data.type,
          description: res.data.description,
          axles: res.data.axles,
          quantity: res.data.quantity,
          weight: res.data.weight,
          length: res.data.length,
          year: res.data.year,
          sleeps: res.data.sleeps,
          dailyRate: res.data.dailyRate,
          weeklyRate: res.data.weeklyRate,
          startDate: res.data.startDate,
          endDate: res.data.endDate,
          startTime: res.data.startTime,
          endTime: res.data.endTime,
          comments: res.data.comments,
          isPickupAvailable: res.data.isPickupAvailable,
          isDeliveryAvailable: res.data.isDeliveryAvailable,
          isOpenEndedRental: res.data.deliveryUnloadingIncluded,
          imagesPreviewUrls: mediaImage,
          videoPreviewUrls: mediaVideo,
          pickupLocationAddress: res.data.pickupLocationAddress,
          pickupLocationLatitude: res.data.pickupLocationLatitude,
          pickupLocationLongitude: res.data.pickupLocationLongitude,
          deliveryLocationAddress: res.data.deliveryLocationAddress,
          deliveryLocationLatitude: res.data.deliveryLocationLatitude,
          deliveryLocationLongitude: res.data.deliveryLocationLongitude,
          deliveryRateFirst10Km: res.data.deliveryRateFirst10Km,
          deliveryRateExtraPerKm: res.data.deliveryRateExtraPerKm,
          uploadedMedia: res.data.equipmentMedias,
          rentalpdfname: res.data.user.agreement.name,
        }
        // ,
        // () => {
        //   console.log(vm.state);
        //   if (res.data.isAddOn) {
        //     vm.getEquipments();
        //   } else {
        //     vm.getAddons();
        //   }
        // }
      );
    });
  };

  _handlePostUpdate = (e) => {
    e.preventDefault();
    const vm = this;
    console.log(vm.state.selectedAddons);
    // Time not needed anymore
    // let startTime;
    // let endTime;
    // let endDate;
    // let startDate;
    // if (vm.state.updateStartDateTime) {
    //   startTime = vm.getTimeStringFromDateObject(vm.state.startDate);
    //   startDate = vm.state.startDate;
    // } else {
    //   startDate = vm.state.startDate;
    // }
    // if (vm.state.updateEndDateTime) {
    //   endTime = vm.getTimeStringFromDateObject(vm.state.endDate);
    //   endDate = vm.adddateAndtime(vm.state.endDate, vm.state.endTime);
    // } else {
    //   endDate = vm.state.endDate;
    // }
    let updatedata = {
      categoryId: vm.state.categoryId,
      equipmentIds: vm.state.selectedEquips,
      addOnIds: vm.state.selectedAddons,
      equipmentMediasList: vm.state.uploadedMedia,
      isAddOn: vm.state.isAddOn,
      canBeRentedAsStandAlone: vm.state.canBeRentedAsStandAlone,
      name: vm.state.name,
      make: vm.state.make,
      //model: vm.state.model,
      type: vm.state.type,
      description: vm.state.description,
      specifications: null,
      axles: vm.state.axles,
      weight: vm.state.weight,
      length: vm.state.length,
      year: vm.state.year,
      sleeps: vm.state.sleeps,
      dailyRate: vm.state.dailyRate,
      weeklyRate: vm.state.weeklyRate,
      comments: vm.state.comments,
      // startDate: vm.state.startDate,
      // startTime: startTime,
      // endDate: vm.state.endDate,
      // endTime: endTime,
      isPickupAvailable: vm.state.isPickupAvailable,
      isOpenEndedRental: vm.state.isOpenEndedRental,
      pickupLocationAddress: vm.state.pickupLocationAddress,
      pickupLocationLatitude: vm.state.pickupLocationLatitude,
      pickupLocationLongitude: vm.state.pickupLocationLongitude,
      isDeliveryAvailable: vm.state.isDeliveryAvailable,
      deliveryLocationAddress: vm.state.pickupLocationAddress, //delivery same as pickup
      deliveryLocationLatitude: vm.state.pickupLocationLatitude, //delivery same as pickup
      deliveryLocationLongitude: vm.state.pickupLocationLongitude, //delivery same as pickup
      deliveryRateFirst10Km: vm.state.deliveryRateFirst10Km,
      deliveryRateExtraPerKm: vm.state.deliveryRateExtraPerKm,
      pickUpLoadingIncluded:
        vm.state.canBeRentedAsStandAlone && vm.state.isPickupAvailable,
      deliveryUnloadingIncluded:
        vm.state.isOpenEndedRental && vm.state.isDeliveryAvailable,
      pickUpLoadingNotIncluded:
        !vm.state.canBeRentedAsStandAlone && vm.state.isPickupAvailable,
      deliveryUnloadingNotIncluded:
        !vm.state.isOpenEndedRental && vm.state.isDeliveryAvailable,
      quantity: vm.state.axles ? vm.state.axles : 0,
      selling: vm.state.selectedOption == "Selling" ? true : false,
      lookingFor: vm.state.selectedOption == "Looking for" ? true : false,
    };

    if (this.verifyFields()) {
      $.ajax({
        method: "PUT",
        dataType: "json",
        data: JSON.stringify(updatedata),
        traditional: true,
        xhrFields: {
          withCredentials: false,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        url: `${process.env.REACT_APP_API}/equipments/${vm.props.postId}`,
      })
        .then(function (res) {
          console.log("******** response from backend after ************");
          console.log(res.data);

          vm.toggle("posteditsuccessmodal");
        })
        .catch(function (res) {
          console.log(res);
          if (res.responseJSON.code === "VALIDATION_ERROR") {
            vm.setState({
              apierrors: res.responseJSON.data.equipment[0],
            });
          } else if (res.responseJSON.code === "UNKNOWN_ERROR") {
            vm.setState({
              apierrors:
                "an unknown error occured, please log out and try again.",
            });
          } else {
            vm.setState({
              apierrors:
                "an unknown error occured, please log out and try again.",
            });
          }
        });
    }
  };

  getTimeStringFromDateObject(theDate) {
    if (!theDate || theDate == "") {
      return null;
    }
    let splitDate = theDate.split("T");
    let TimePart = splitDate[1];
    let TimePartClean = String(TimePart.substring(0, TimePart.length - 1));
    console.log("TimePartClean", TimePartClean);

    return TimePartClean;
  }
  verifyFields = () => {
    const vm = this;
    console.log();
    return (
      vm.state.name != null &&
      vm.state.make != null &&
      //vm.state.model != null &&
      vm.state.year != null &&
      vm.state.dailyRate != null &&
      vm.state.weeklyRate != null &&
      // vm.state.startDate != null &&
      // vm.state.endDate != null &&
      ((vm.state.isDeliveryAvailable &&
        vm.state.deliveryLocationAddress &&
        vm.state.deliveryLocationLatitude &&
        vm.state.deliveryLocationLongitude &&
        vm.state.deliveryRateExtraPerKm &&
        vm.state.deliveryRateFirst10Km) ||
        (vm.state.isPickupAvailable &&
          vm.state.deliveryLocationAddress &&
          vm.state.deliveryLocationLatitude &&
          vm.state.deliveryLocationLongitude)) &&
      // !vm.state.dateErrors &&"
      vm.state.categoryId !== 0 && vm.state.axles != "0"
    );
  };

  _handleSubmit(e) {
    const vm = this;
    e.preventDefault();
    // vm.setState({ loading: true });

    // Time not needed anymore
    // let startTime = vm.getTimeStringFromDateObject(vm.state.startDate);
    // let endTime = vm.getTimeStringFromDateObject(vm.state.endDate);

    // if (this.state.categoryId == 0) {
    // } else {
    // }

    let data = {
      categoryId: vm.state.categoryId,
      equipmentIds: vm.state.selectedEquips,
      addOnIds: vm.state.selectedAddons,
      equipmentMediasList: vm.state.uploadedMedia,
      isAddOn: vm.state.isAddOn,
      canBeRentedAsStandAlone: vm.state.canBeRentedAsStandAlone,
      name: vm.state.name,
      make: vm.state.make,
      selling: vm.state.selectedOption == "Selling" ? true : false,
      lookingFor: vm.state.selectedOption == "Looking for" ? true : false,
      //model: vm.state.model,
      type: null,
      description: vm.state.description,
      specifications: null,
      axles: vm.state.axles ? vm.state.axles : 0,
      weight: vm.state.weight ? +vm.state.weight : 0,
      length: vm.state.length ? vm.state.length : 0,
      year: vm.state.year,
      sleeps: vm.state.sleeps ? vm.state.sleeps : 0,
      dailyRate: vm.state.dailyRate,
      weeklyRate: vm.state.weeklyRate,
      comments: vm.state.comments,
      // startDate: vm.state.startDate,
      // startTime: startTime,
      // endDate: vm.state.endDate,
      // endTime: endTime,
      isPickupAvailable: vm.state.isPickupAvailable,
      isOpenEndedRental: vm.state.isOpenEndedRental,
      pickupLocationAddress: vm.state.pickupLocationAddress,
      pickupLocationLatitude: vm.state.pickupLocationLatitude,
      pickupLocationLongitude: vm.state.pickupLocationLongitude,
      isDeliveryAvailable: vm.state.isDeliveryAvailable,
      deliveryLocationAddress: vm.state.pickupLocationAddress, //delivery same as pickup
      deliveryLocationLatitude: vm.state.pickupLocationLatitude, //delivery same as pickup
      deliveryLocationLongitude: vm.state.pickupLocationLongitude, //delivery same as pickup
      deliveryRateFirst10Km: vm.state.deliveryRateFirst10Km,
      deliveryRateExtraPerKm: vm.state.deliveryRateExtraPerKm,
      pickUpLoadingIncluded:
        vm.state.canBeRentedAsStandAlone && vm.state.isPickupAvailable,
      deliveryUnloadingIncluded:
        vm.state.isOpenEndedRental && vm.state.isDeliveryAvailable,
      pickUpLoadingNotIncluded:
        !vm.state.canBeRentedAsStandAlone && vm.state.isPickupAvailable,
      deliveryUnloadingNotIncluded:
        !vm.state.isOpenEndedRental && vm.state.isDeliveryAvailable,
      quantity: vm.state.axles ? vm.state.axles : 0,
    };
    // console.log(JSON.stringify("data", data));
    console.log("data", data);

    console.log("THE STATE!!!!", vm.state);
    if (
      vm.state.are_any_images_uploaded === false &&
      vm.state.selectedOption == "Selling"
    ) {
      vm.setState({ loading: false });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You must upload at least one image before submitting",
        confirmButtonColor: "#ffcd46",
      });
    } else if (this.verifyFields()) {
      console.log("data before sending ");
      console.log(data);

      $.ajax({
        method: "POST",
        dataType: "json",
        data: JSON.stringify(data),
        traditional: true,
        xhrFields: {
          withCredentials: false,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API + "/equipments",
      })
        .then(function (res) {
          console.log("******** response from backend after ************");
          console.log(res.data);
          vm.setState({ loading: false });
          vm.toggle("postsuccessmodal");
        })
        .catch(function (res) {
          console.log(res);
          if (res.code === "VALIDATION_ERROR" || res.code === "UNKNOWN_ERROR") {
            vm.setState({
              loading: false,
              apierrors:
                "The info you provided could not be validtaed. Please refresh the page and try again ",
            });
          } else {
            vm.setState({
              loading: false,
              apierrors:
                "an unknown error occured, please log out and try again.",
            });
          }
        });
    } else {
      vm.setState({ fielderrors: true, loading: false });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You must upload at least one image before submitting",
        confirmButtonColor: "#ffcd46",
      });
    }
  }

  handleUpload = () => {
    let vm = this;
    vm.setState({ loading: true });

    if (vm.state.files.length === 0) {
      alert("You need to select at least one image!");
    } else {
      $.when(vm.uploadImages()).done(function () {
        vm.setState({ loading: false });
      });
    }
  };

  uploadImages = () => {
    const vm = this;

    let uploadedMedia = [];

    this.state.files.map((file, index) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("public", true);
      console.log(formData.get("image"));
      let data = {
        File: file,
        Public: true,
      };
      console.log("PASSING DATA");
      console.log(data);

      $.ajax({
        method: "POST",

        processData: false,

        contentType: false,
        data: formData,
        xhrFields: {
          withCredentials: true,
        },

        url: process.env.REACT_APP_API + "/uploads/uploadimage",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Accept: "application/json",
        },
      })
        .then(function (res) {
          console.log("LENGTH", vm.state.uploadedMedia.length);
          let obj = {};
          if (uploadedMedia.length == 0 && vm.state.uploadedMedia.length == 0) {
            obj = {
              filePath: res.data.filePath,
              default: true,
            };
          } else {
            obj = {
              filePath: res.data.filePath,
              default: false,
            };
          }
          vm.setState({
            uploadedMedia: [...vm.state.uploadedMedia, obj],
            are_any_images_uploaded: true,
          });
          uploadedMedia.push(obj);
        })
        .catch(function (res) {
          if (res.responseJSON.code === "VALIDATION_ERROR") {
            vm.setState({
              apierrors: res.responseJSON.data.equipment[0],
            });
          } else if (res.responseJSON.code === "UNKNOWN_ERROR") {
            vm.setState({
              apierrors:
                "an unknown error occured, please log out and try again.",
            });
          } else {
            vm.setState({
              apierrors:
                "an unknown error occured, please log out and try again.",
            });
          }
        });
    });
  };

  _handleImageChange(e) {
    e.preventDefault();
    let vm = this;
    let files = Array.from(e.target.files);
    let size = 6000000;
    let approvedfiles = [];
    let fileCount = 0;

    if (files.length > 9) {
      alert(
        "Only 10 images can be uploaded to your equipment, please try again"
      );
      files.value = null; // discard selected file
    } else {
      files.forEach((file) => {
        console.log(file.size);
        fileCount++;

        if (file.size > size) {
          alert("One of the Images is bigger than 6mb. It will be omited.");
        } else {
          approvedfiles.push(file);
          let reader = new FileReader();
          reader.onloadend = () => {
            this.setState(
              {
                files: [...this.state.files, file],
                imagesPreviewUrls: [
                  ...this.state.imagesPreviewUrls,
                  reader.result,
                ],
              },
              () => {
                if (fileCount == vm.state.files.length) {
                  vm.handleUpload();
                }
              }
            );
          };
          reader.readAsDataURL(file);
        }
      });
    }

    console.log("the final images");
    console.log(this.state.files);
    console.log("the final images");
  }

  clear = (e) => {
    e.preventDefault();
    this.fileInput.value = [];
    this.setState(
      {
        files: [],
        imagesPreviewUrls: [],
        uploadedMedia: [],
      },
      () => {
        console.log("CLEARING", this.state);
      }
    );
  };

  ChangeDate = (e) => {
    console.log("da date");
    let startdate_utc = moment.utc(e._d).format();
    console.log("startdate_utc", startdate_utc);
    this.setState(
      {
        startDate: startdate_utc,
      },
      () => {
        this.CheckDateLogic();
      }
    );
  };

  ChangeTime = (e) => {
    console.log("da time");
    console.log(e._d);
    let starttime_utc = moment.utc(e._d).format("HH:mm");
    console.log(starttime_utc);

    this.setState({
      startTime: String(starttime_utc),
    });
  };

  CheckDateLogic() {
    if (this.state.endDate && this.state.startDate) {
      let start = new Date(this.state.startDate);
      let end = new Date(this.state.endDate);
      if (start < end) {
        // start date is earlier then end date
        console.log("LOGIC TRUE");
        this.setState({ dateErrors: false });
      } else {
        // end date is earlier then start date
        this.setState({ dateErrors: true });
      }
    }
  }

  adddateAndtime(date, time) {
    console.log(time);
    let array = time.split(":");

    let hours = array[0];
    let min = array[1];
    let value = moment
      .utc(date)
      .add(hours, "hours")
      .add(min, "minutes")
      .format();

    console.log("finall-valuee", value);

    return value;
  }

  //ChangeDateEnd
  ChangeDateEnd = (e) => {
    console.log("da end date");
    let enddate_utc = moment.utc(e._d).format();
    console.log(enddate_utc);

    this.setState(
      {
        endDate: enddate_utc,
      },
      () => {
        this.CheckDateLogic();
      }
    );
  };

  ChangeTimeEnd = (e) => {
    console.log("da time");
    console.log(e);
    console.log(e._d);
    let endtime_utc = moment.utc(e._d).format("HH:mm");
    console.log(endtime_utc);

    this.setState({
      endTime: String(endtime_utc),
    });
  };

  getlatlong = (latLng) => {
    this.setState({
      pickupLocationLatitude: latLng.lat,
      pickupLocationLongitude: latLng.lng,
      deliveryLocationLatitude: latLng.lat,
      deliveryLocationLongitude: latLng.lng,
    });
  };

  getlatlongDeliver = (latLng) => {
    this.setState({
      pickupLocationLatitude: latLng.lat,
      pickupLocationLongitude: latLng.lng,
      deliveryLocationLatitude: latLng.lat,
      deliveryLocationLongitude: latLng.lng,
    });
  };

  theaddress = (add) => {
    this.setState({
      //CF: Added both address as now it is the same address for both
      pickupLocationAddress: add,
      deliveryLocationAddress: add,
    });
  };

  theaddressDeliver = (add) => {
    this.setState({
      //CF: Added both address as now it is the same address for both
      pickupLocationAddress: add,
      deliveryLocationAddress: add,
    });
  };

  getAddons = () => {
    const vm = this;
    let data = {};

    data.EquipmentType = 1;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: data,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/equipments/user",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        console.log("******** add on data ************");
        console.log(res.data);

        let addOnObj = res.data.items.map((addOnElement) => {
          let selected = false;
          console.log("EDITTING", vm.state.selectedAddons);
          if (vm.state.editingmyown) {
            vm.state.selectedAddons.map((selectedAddon) => {
              if (selectedAddon === addOnElement.id) {
                console.log("FINISH!!!!");
                selected = true;
              } else {
                selected = false;
              }
            });
          }
          console.log("SELECTED", selected);
          return {
            addOnObjImg: addOnElement.equipmentMedias[0].filePath,
            addOnObjName: addOnElement.name,
            addOnObjDaily: addOnElement.dailyRate,
            addAddonID: addOnElement.id,
            selected: selected,
          };
        });
        console.log("******** TEST ************");
        console.log(addOnObj);
        vm.setState({
          addOnDetails: addOnObj,
        });
      })
      .catch(function (res) {
        console.log("the addon response ");
        console.log(res);
      });
  };

  getEquipments = () => {
    const vm = this;
    let data = {};

    data.EquipmentType = 2;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: data,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/equipments/user",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        console.log("******** eq data ************");
        console.log(res.data);

        let addOnObj = res.data.items.map((addOnElement) => {
          let selected = false;
          if (vm.state.editingmyown) {
            console.log("selectedequipments--here", vm.state.selectedEquips);
            vm.state.selectedEquips.map((selectedEquips) => {
              if (selectedEquips === addOnElement.id) {
                console.log("FINISH!!!!");
                selected = true;
              } else {
                selected = false;
              }
            });
          }

          return {
            addOnObjImg: addOnElement.equipmentMedias[0].filePath,
            addOnObjName: addOnElement.name,
            addOnObjDaily: addOnElement.dailyRate,
            addAddonID: addOnElement.id,
            selected: selected,
          };
        });

        vm.setState({
          equipDetails: addOnObj,
        });
      })
      .catch(function (res) {
        console.log("the addon response if issue ");
        console.log(res);
      });
  };

  addAddon = (elementID, index) => {
    console.log(elementID);
    console.log("addondetails", this.state.addOnDetails);
    let addons = this.state.addOnDetails;
    let selected = this.state.selectedAddons;

    addons[index].selected = !addons[index].selected;
    if (this.state.selectedAddons.length > 0) {
      console.log(selected.includes(elementID));
      if (this.state.selectedAddons.includes(elementID)) {
        selected = selected.filter((ele) => ele !== elementID);
      } else {
        selected.push(elementID);
      }
    } else {
      selected.push(elementID);
    }
    console.log("Checking select", selected);
    this.setState({ selectedAddons: selected });
  };

  addEquip = (elementID, index) => {
    console.log(elementID);
    let addons = this.state.equipDetails;
    let selected = this.state.selectedEquips;

    addons[index].selected = !addons[index].selected;
    if (this.state.selectedEquips.length > 0) {
      console.log(selected.includes(elementID));
      if (this.state.selectedEquips.includes(elementID)) {
        selected = selected.filter((ele) => ele !== elementID);
      } else {
        selected.push(elementID);
      }
    } else {
      selected.push(elementID);
    }
    console.log("Checking select", selected);
    this.setState({ selectedEquips: selected });
  };

  updateThepickupAddress = () => {
    this.setState({ updatePickupAddress: true });
  };

  // updateTheDeliveryAddress = () => {
  //   this.setState({ updateDeliveryAddress: true });
  // };

  // updateTheStartDateTime = () => {
  //   this.setState({ updateStartDateTime: true });
  // };

  // updateTheEndDateTime = () => {
  //   this.setState({ updateEndDateTime: true });
  // };

  // MakeTimePretty = (thetime) => {
  //   let startTime = thetime.split(":");

  //   let startHour = startTime[0];
  //   let test0 = moment
  //     .utc(this.state.startDate)
  //     .add(startHour, "hours")
  //     .format();

  //   let thefinal = moment(test0).format("MMM Do YYYY ,  hh:mm a");

  //   console.log("time", test0);

  //   return thefinal;
  // };

  // MakeTimePretty2 = (thetime) => {
  //   let startTime = thetime.split(":");

  //   let startHour = startTime[0];
  //   let test0 = moment.utc(this.state.endDate).add(startHour, "hours").format();
  //   console.log("the start hr in end time", startHour);

  //   let thefinal = moment(test0).format("MMM Do YYYY ,  hh:mm a");
  //   let thefinal0 = moment(test0);

  //   console.log("time", test0);
  //   console.log("moment added", thefinal0);

  //   return thefinal;
  // };

  handleOptionChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  render() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const { selectedOption } = this.state;
    const currencyFormatter = new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
    });
    console.log("initial start time ", this.state.isOpenEndedRental);
    console.log("categoryId **** ", this.state.categoryId);

    console.log("weight **** ", this.state.weight);
    console.log("type **** ", this.state.make);
    console.log("selectedOption **** ", selectedOption);

    return (
      <>
        {/* <Header
          authenticated={true}
          bannerDisplay={false}
          userName={"this.props.userName"}
          userLocation={"this.props.userLocation"}
          userId={"this.props.userId"}
          profileImage={"this.props.profileImage"}
        /> */}
        <nav
          className="bg-grey-light rounded-md w-full text-sm d-flex align-items-baseline"
          style={{ marginTop: 125 }}
        >
          <ol className="mb-0 d-flex align-items-center col-sm-6">
            <Link
              to={`/dashboard`}
              style={{ color: "#000000", fontSize: "26px" }}
              className=""
            >
              Home screen
            </Link>
            <div>
              <span
                className="text-gray-500 mx-2 font-bold text-lg"
                style={{ fontWeight: 800, fontSize: 20 }}
              >
                {">"}
              </span>
            </div>
            <Link
              to={`#`}
              style={{ color: "#E7B10A", fontSize: "26px" }}
              className="font-semibold"
            >
              {this.state.editingmyown ? "Edit " : "New "} Post
            </Link>
          </ol>
          <div className="d-flex col-sm-6 px-4" style={{ marginRight: "auto" }}>
            <label className="container-custom-radio mr-4">
              <input
                type="radio"
                value="Selling"
                checked={selectedOption === "Selling"}
                onChange={this.handleOptionChange}
                name="radio"
                className=" radio-custom-new-post"
              />
              {/* <span className="option-label">Selling</span> */}
              <h4
                style={{
                  color: selectedOption === "Selling" ? "#454642" : "",
                  fontSize: "22px",
                }}
                // className="post-subtitle mt-0 mb-0"
              >
                Selling{" "}
              </h4>
              <span className="custom-checkmark"></span>
            </label>
            <label className="container-custom-radio ">
              <input
                type="radio"
                value="Looking for"
                checked={selectedOption === "Looking for"}
                onChange={this.handleOptionChange}
                name="radio"
                className=" radio-custom-new-post"
              />
              {/* <span className="option-label">Looking for</span> */}
              <h4
                style={{
                  color: selectedOption === "Looking for" ? "#454642" : "",
                  fontSize: "22px",
                }}
                // className="post-subtitle mt-0 mb-0"
              >
                Looking for{" "}
              </h4>
              <span className="custom-checkmark"></span>
            </label>
          </div>
        </nav>
        <Col>
          <PostSuccessModal
            toggle={this.toggle}
            modalOpen={this.state.PostSuccessModalOpen}
            backdrop="static"
          />
          <Posteditsuccessmodal
            toggle={this.toggle}
            modalOpen={this.state.PostEditSuccessModalOpen}
            backdrop="static"
          />
          {this.state.loading && <Loading modalOpen={this.state.loading} />}
          {this.state.onboardModalOpen && (
            <OnBoardModal
              startCheck={this.checkStripeOnboard}
              url={this.state.stripeUrl}
              modalOpen={this.state.onboardModalOpen}
              toggle={this.toggle}
            />
          )}
          <Row className="pt-5">
            <Col sm="6" className="image-upload-container">
              <form
                className="img-prev"
                style={{ width: "90%", margin: "0 auto", fontSize: 18 }}
                onSubmit={this._handleSubmit}
              >
                <input
                  ref={(fileInput) => (this.fileInput = fileInput)}
                  style={{ display: "none" }}
                  className="upload"
                  type="file"
                  accept="image/*"
                  onChange={this._handleImageChange}
                  multiple
                />
                <div
                  style={{ textAlign: "center", display: "flex" }}
                  className="mb-4"
                >
                  <Button
                    style={{ width: "80%" }}
                    onClick={() => this.fileInput.click()}
                    className="upload-btn upload upload-images-btn py-3 "
                    type="button"
                    disabled={selectedOption !== "Selling"}
                  >
                    Select Images of your post
                  </Button>
                  <Button
                    style={{
                      width: "20%",
                      marginLeft: 10,
                      borderRadius: "8px",
                    }}
                    onClick={(e) => this.clear(e)}
                    type="button"
                    className="upload-reset-btn upload upload-images-btn py-3"
                    disabled={selectedOption !== "Selling"}
                  >
                    Clear
                  </Button>
                </div>
                <div
                  className="image-prev-container"
                  style={{
                    textAlign: "center",
                    minHeight: "300px",
                    backgroundColor: "#ececec",
                    padding: "50px 0",
                    borderRadius: "8px",
                  }}
                >
                  {this.state.imagesPreviewUrls.length > 0 ? (
                    this.state.imagesPreviewUrls.map((imagePreviewUrl) => {
                      return (
                        <img
                          style={{ maxWidth: "300px", padding: "10px" }}
                          key={imagePreviewUrl}
                          alt="previewImg"
                          src={imagePreviewUrl}
                        />
                      );
                    })
                  ) : (
                    <div style={{ fontWeight: 500, marginTop: 35 }}>
                      {selectedOption === "Selling" && (
                        <>
                          <p>
                            Image files allowed:{" "}
                            <span
                              style={{
                                fontWeight: 700,
                                textTransform: "uppercase",
                              }}
                            >
                              png, jpg, jpeg
                            </span>{" "}
                          </p>{" "}
                          <p style={{ color: "#E7B10A" }}>
                            You must upload at least one image to create a
                            post*.
                          </p>
                          <p>Please do not upload more than 10 images.</p>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </form>

              <form
                className="img-prev"
                style={{ width: "80%", margin: "30px auto" }}
                onSubmit={this._handleSubmit}
              >
                <input
                  onChange={this.handleVideo}
                  ref={(fileInputvid) => (this.fileInputvid = fileInputvid)}
                  type="file"
                  name="file[]"
                  className="file_multi_video"
                  accept="video/*"
                  style={{ display: "none" }}
                  multiple
                />
              </form>

              <div
                className="upload-images-button-container"
                style={{ width: "100%", textAlign: "center" }}
              >
                {this.state.uploadMediaErrors.length > 0 ? (
                  <p
                    style={{
                      color: "#000",
                      fontWeight: "600",
                      padding: "10px",
                    }}
                    className="upload-media-errors"
                  >
                    {this.state.uploadMediaErrors}{" "}
                  </p>
                ) : null}
              </div>
            </Col>

            <Col
              sm="6"
              className="post-content-description"
              // style={{
              //   fontSize: 18,
              //   padding: "0 30px 30px",
              //   position: "absolute",
              //   right: "0%",
              //   overflowY: "auto",
              //   height: "450px",
              // }}
            >
              <Row>
                <Form className="edit-post px-4 pb-4">
                  <div
                    className="container m-0 p-0 mb-4"
                    style={{ borderBottom: "1px solid #efefef" }}
                  >
                    <div className="left-side newicons d-flex align-items-center">
                      <img
                        className="left-side-icon mr-2"
                        src={require("../../../Common/images/newicons/info_icon.svg")}
                        alt="info"
                        color="#E7B10A"
                      />
                      <h4 className="post-subtitle mt-0 mb-0">Description </h4>
                    </div>
                    <div className="py-2">
                      <label className="py-2">
                        <span className="post-label">Post Name</span>
                        <input
                          value={this.state.name || ""}
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                          type="text"
                          name="name"
                          id="itemName"
                          placeholder="Post Name"
                          className="input-style-post"
                        />
                      </label>
                      <label className="py-2">
                        <span className="post-label">Description</span>
                        <textarea
                          value={this.state.description || ""}
                          onChange={(e) =>
                            this.setState({ description: e.target.value })
                          }
                          type="textarea"
                          name="description"
                          id="itemDescription"
                          rows={3}
                          placeholder="Description"
                          className="input-style-post"
                        />
                      </label>
                    </div>
                  </div>
                  <div
                    className="container  m-0 p-0 mb-4"
                    style={{ borderBottom: "1px solid #efefef" }}
                  >
                    <div className="left-side newicons d-flex align-items-center">
                      <img
                        className="left-side-icon mr-2"
                        src={require("../../../Common/images/newicons/ruler-icon.svg")}
                        alt="info"
                        color="#E7B10A"
                      />
                      <h4 className="post-subtitle mt-0 mb-0">
                        Specifications{" "}
                      </h4>
                    </div>
                    <div className="py-2">
                      <label className="py-2">
                        <span className="post-label">Crop Year</span>
                        <input
                          value={this.state.year}
                          onChange={(e) =>
                            this.setState({ year: parseInt(e.target.value) })
                          }
                          type="number"
                          step="1"
                          name="year"
                          id="itemType"
                          placeholder={currentYear}
                          className="input-style-post"
                          min={1999}
                          max={currentYear}
                          maxLength="4"
                        />
                      </label>
                      <label className="py-2">
                        <span className="post-label">Category</span>
                        <select
                          defaultValue={this.state.categoryId}
                          className="input-style-post"
                          name="category"
                          id="itemCategory"
                          onChange={(e) => {
                            this.selectChangeid(e);
                            this.checkWeight(e);
                          }}
                        >
                          <option hidden value="0">
                            Select a category
                          </option>
                          {this.state.categorie.map((cat, i) => {
                            return (
                              <option
                                key={i}
                                selected={
                                  this.state.categoryId == cat.id
                                    ? "selected"
                                    : null
                                }
                                value={cat.id}
                                style={{ padding: "20px", marginBottom: 5 }}
                                disabled={cat.optionType}
                              >
                                {cat.name}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                      {this.state.categoryId > 0 && (
                        <label className="py-2">
                          <span className="post-label">Type</span>
                          <input
                            value={this.state.make}
                            onChange={(e) =>
                              this.setState({ make: e.target.value })
                            }
                            type="text"
                            name="type"
                            id="itemMake"
                            placeholder="Type"
                            className="input-style-post"
                            disabled
                          />
                          {/* <div className="input-style-post">
                            {this.state.make}
                          </div> */}
                        </label>
                      )}
                      {this.state.categoryId !== 18 && (
                        <label className="py-2">
                          <span className="post-label">
                            Weight per
                            {this.state.make === "Feed Grain"
                              ? " bushel (Metric Tonnes)"
                              : " bale (lbs)"}
                          </span>

                          <input
                            value={this.state.weight}
                            // onChange={(e) =>
                            //   this.setState({
                            //     weight: parseFloat(e.target.value),
                            //   })
                            // }
                            onChange={(e) =>
                              this.setState({
                                weight: e.target.value,
                              })
                            }
                            // onChange={(e) => this.handleWeightChange(e)}
                            type="number"
                            // step="0.1"
                            min={0}
                            name="weight"
                            id="itemWeight"
                            placeholder="weight"
                            className="input-style-post"
                            disabled={this.state.categoryId >= 13}
                          />
                        </label>
                      )}
                    </div>
                  </div>
                  <div
                    className="container  m-0 p-0 mb-4"
                    style={{ borderBottom: "1px solid #efefef" }}
                  >
                    <div className="left-side newicons d-flex align-items-center">
                      <img
                        className="left-side-icon mr-2"
                        src={require("../../../Common/images/newicons/quantity-ellipsis-stroke.svg")}
                        alt="info"
                        color="#E7B10A"
                      />
                      <h4 className="post-subtitle mt-0 mb-0">
                        Quantity Available{" "}
                      </h4>
                    </div>
                    <div className="py-2">
                      {/* <label className="py-2">
                        <span className="post-label">
                          Quantity Available{" "}
                          {this.state.make === "Feed Grain" &&
                          this.state.categoryId == 19
                            ? "(Metric Tonnes)"
                            : this.state.make === "Feed Grain" &&
                              this.state.categoryId !== 19
                            ? "(Bushels)"
                            : "(Bales)"}
                        </span>
                        <input
                          value={this.state.axles}
                          // onChange={(e) =>
                          //   this.setState({ axles: parseInt(e.target.value) })
                          // }
                          onChange={(e) => this.handleQuantityChange(e)}
                          type="number"
                          name="axles"
                          id="itemAxles"
                          placeholder="Quantity"
                          className="input-style-post"
                        />
                      </label> */}
                      <label className="py-2">
                        <span className="post-label">
                          Quantity Available{" "}
                          {this.state.make === "Feed Grain"
                            ? "(Metric Tonnes)"
                            : "(Bales)"}
                        </span>
                        <input
                          value={this.state.axles}
                          // onChange={(e) =>
                          //   this.setState({ axles: parseInt(e.target.value) })
                          // }
                          onChange={(e) => this.handleQuantityChange(e)}
                          type="number"
                          // step={this.state.make === "Feed Grain" ? "0.1" : "1"}
                          name="axles"
                          id="itemAxles"
                          placeholder="Quantity"
                          className="input-style-post"
                          min={0}
                        />
                      </label>
                    </div>
                    {this.state.make === "Feed Grain" &&
                      this.state.categoryId !== 18 && (
                        // <div className="py-2">
                        <label className="pb-2">
                          <span className="post-label">
                            Bushels{" "}
                            {/* {this.state.make === "Feed Grain"
                              ? "(Metric Tonnes)"
                              : "(Lbs?)"} */}
                          </span>
                          <input
                            value={this.state.totalBushels}
                            // onChange={(e) =>
                            //   this.setState({ axles: parseInt(e.target.value) })
                            // }
                            type="number"
                            name="axles"
                            id="itemAxles"
                            placeholder="Quantity"
                            className="input-style-post"
                            disabled
                            min={0}
                          />
                        </label>
                        // </div>
                      )}
                    {/* {this.state.make === "Feed Grain" &&
                      this.state.categoryId !== 19 && (
                        // <div className="py-2">
                        <label className="pb-2">
                          <span className="post-label">
                            Total Weight{" "}
                            {this.state.make === "Feed Grain"
                              ? "(Metric Tonnes)"
                              : "(Lbs?)"}
                          </span>
                          <input
                            value={this.state.totalWeight}
                            // onChange={(e) =>
                            //   this.setState({ axles: parseInt(e.target.value) })
                            // }
                            type="number"
                            name="axles"
                            id="itemAxles"
                            placeholder="Quantity"
                            className="input-style-post"
                            disabled
                          />
                        </label>
                        // </div>
                      )} */}
                  </div>
                  <div
                    className="container  m-0 p-0 mb-4"
                    style={{ borderBottom: "1px solid #efefef" }}
                  >
                    <div className="left-side newicons d-flex align-items-center">
                      <div
                        style={{
                          padding: "0 0.75rem 0 0 ",
                          fontSize: "2rem",
                          fontWeight: 600,
                          color: "#E7B10A",
                        }}
                      >
                        $
                      </div>
                      <h4 className="post-subtitle mt-0 mb-0">Price </h4>
                    </div>
                    <div className="py-2">
                      <label className="py-2">
                        <span className="post-label">
                          Price (CAD){" "}
                          {this.state.make === "Feed Grain"
                            ? "per metric tonne"
                            : "per bale"}
                          {/* {this.state.make === "Feed Grain" &&
                          this.state.categoryId == 19
                            ? "per metric tonne"
                            : this.state.make === "Feed Grain" &&
                              this.state.categoryId !== 19
                            ? "per bushel"
                            : "per bale"} */}
                        </span>
                        <input
                          value={this.state.dailyRate}
                          // onChange={(e) =>
                          //   this.setState({
                          //     dailyRate: parseFloat(e.target.value),
                          //   })
                          // }
                          onChange={(e) => this.handlePriceChange(e)}
                          min={0}
                          type="number"
                          step="1"
                          name="price"
                          id="price"
                          placeholder="Price"
                          className="input-style-post"
                        />
                      </label>
                    </div>
                    <div className="py-2">
                      <label className="py-2">
                        <span className="post-label">
                          Total price (CAD){" "}
                          {/* {this.state.make === "Feed Grain"
                            ? "per metric tonne"
                            : "per bale"} */}
                        </span>
                        <input
                          value={this.state.weeklyRate}
                          // onChange={(e) =>
                          //   this.setState({
                          //     weeklyRate: parseFloat(e.target.value),
                          //   })
                          // }
                          min={0}
                          type="number"
                          step="1"
                          name="price"
                          id="price"
                          placeholder="Total price"
                          className="input-style-post"
                          disabled
                        />
                      </label>
                    </div>
                  </div>
                  <div
                    className="container  m-0 p-0 mb-4"
                    style={{ borderBottom: "1px solid #efefef" }}
                  >
                    <div className="left-side newicons d-flex align-items-center">
                      <img
                        className="left-side-icon mr-2"
                        src={require("../../../Common/images/newicons/truck-icon.svg")}
                        alt="info"
                        color="#E7B10A"
                      />
                      <div>
                        <h4 className="post-subtitle mt-0 mb-0">
                          Trucking/Freight Options
                        </h4>
                        <small
                          style={{
                            color: "#a3a3a3",
                            fontWeight: "300",
                            fontSize: 16,
                          }}
                        >
                          Please select at least one option
                        </small>
                      </div>
                    </div>

                    <div
                      className="py-2"
                      style={{ borderBottom: "1px solid #efefef" }}
                    >
                      <label className="py-2 d-flex justify-content-between">
                        <span className="post-label">Pick up</span>
                        <div
                          style={{ marginLeft: "auto", marginRight: "1.5rem" }}
                        >
                          <Switch
                            onChange={this.handleChange}
                            checked={this.state.isPickupAvailable}
                            offColor="#E6E6E6"
                            onColor="#dabc69"
                            onHandleColor="#FFCD46"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                          />
                        </div>
                      </label>

                      {this.state.isPickupAvailable ? (
                        <div
                          className="p-4"
                          style={{
                            backgroundColor: "#fafafa",
                            borderRadius: 8,
                          }}
                        >
                          {/* {selectedOption === "Selling" && (
                          <div>
                            <FormGroup row>
                              <span
                                style={{
                                  paddingLeft: "15px",
                                  color: "#000",
                                  fontWeight: "300",
                                  fontSize: 16,
                                }}
                              >
                                <br />
                                Please Select your address from the suggested
                                autocomplete for accuracy.
                              </span>
                            </FormGroup>

                            {this.state.editingmyown &&
                            !this.state.updatePickupAddress ? (
                              <div className="the-outer-cont">
                                <div
                                  style={{
                                    display: "flex",
                                    padding: "30px 0",
                                  }}
                                >
                                  <div style={{ width: "50%" }}>
                                    {" "}
                                    <p>{this.state.pickupLocationAddress}</p>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      width: "50%",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    {" "}
                                    <p>
                                      <button
                                        type="button"
                                        className="change-button"
                                        onClick={this.updateThepickupAddress}
                                      >
                                        Change Pickup Address
                                      </button>
                                    </p>
                                  </div>
                                </div>
                                {this.state.updatePickupAddress ? (
                                  <div
                                    className="my-map-container"
                                    style={{ position: "relative" }}
                                  >
                                    <Map
                                      theaddress={this.theaddress}
                                      getlatlong={this.getlatlong}
                                      center={{
                                        lat: 51.05011,
                                        lng: -114.08529,
                                      }}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            ) : (
                              <div
                                className="my-map-container"
                                style={{ position: "relative" }}
                              >
                                <Map
                                  theaddress={this.theaddress}
                                  getlatlong={this.getlatlong}
                                  center={{ lat: 51.05011, lng: -114.08529 }}
                                />
                              </div>
                            )}
                          </div> */}

                          <label className="py-2 d-flex ">
                            <span className="post-label mr-4">{`Loading ${
                              selectedOption === "Selling"
                                ? "included"
                                : "needed"
                            } `}</span>
                            <div style={{ marginLeft: "auto" }}>
                              <Switch
                                onChange={this.handleChange3}
                                checked={this.state.canBeRentedAsStandAlone}
                                offColor="#E6E6E6"
                                onColor="#dabc69"
                                onHandleColor="#FFCD46"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                              />
                            </div>
                          </label>
                        </div>
                      ) : null}
                    </div>
                    <div className="py-2">
                      <label className="py-2 d-flex justify-content-between">
                        <span className="post-label">Delivery</span>
                        <div
                          style={{ marginLeft: "auto", marginRight: "1.5rem" }}
                        >
                          <Switch
                            onChange={this.handleChange2}
                            checked={this.state.isDeliveryAvailable}
                            offColor="#E6E6E6"
                            onColor="#dabc69"
                            onHandleColor="#FFCD46"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                          />
                        </div>
                      </label>

                      {this.state.isDeliveryAvailable ? (
                        <div
                          className="p-4"
                          style={{
                            backgroundColor: "#fafafa",
                            borderRadius: 8,
                          }}
                        >
                          {/* {selectedOption === "Looking for" && ( */}
                          {/* <div>
                            <FormGroup row>
                              <span
                                style={{
                                  paddingLeft: "15px",
                                  color: "#a3a3a3",
                                  fontWeight: "300",
                                  fontSize: 16,
                                }}
                              >
                                Please type your full Delivery address and
                                select it from the dropdown below.{" "}
                              </span>
                            </FormGroup>

                            {this.state.editingmyown &&
                            !this.state.updateDeliveryAddress ? (
                              <div className="the-outer-cont">
                                <div
                                  style={{
                                    display: "flex",
                                    padding: "30px 0",
                                  }}
                                >
                                  <div style={{ width: "50%" }}>
                                    {" "}
                                    <p>{this.state.deliveryLocationAddress}</p>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      width: "50%",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    {" "}
                                    <p>
                                      <button
                                        type="button"
                                        className="change-button"
                                        onClick={this.updateTheDeliveryAddress}
                                      >
                                        Change Delivery Address
                                      </button>
                                    </p>
                                  </div>
                                </div>
                                {this.state.updateDeliveryAddress ? (
                                  <div
                                    className="my-map-container"
                                    style={{ position: "relative" }}
                                  >
                                    <Map
                                      theaddress={this.theaddress}
                                      getlatlong={this.getlatlong}
                                      center={{
                                        lat: 51.05011,
                                        lng: -114.08529,
                                      }}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            ) : (
                              <div
                                className="my-map-container"
                                style={{ position: "relative" }}
                              >
                                <Map
                                  theaddress={this.theaddressDeliver}
                                  getlatlong={this.getlatlongDeliver}
                                  center={{ lat: 51.05011, lng: -114.08529 }}
                                />
                              </div>
                            )}
                          </div> */}

                          <label className="py-2 d-flex ">
                            <span className="post-label mr-4">{`Unloading ${
                              selectedOption === "Looking for"
                                ? "needed"
                                : "included"
                            } `}</span>
                            <div style={{ marginLeft: "auto" }}>
                              <Switch
                                onChange={this.handleChange4}
                                checked={this.state.isOpenEndedRental}
                                offColor="#E6E6E6"
                                onColor="#dabc69"
                                onHandleColor="#FFCD46"
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={48}
                              />
                            </div>
                          </label>
                        </div>
                      ) : null}
                      {/* {selectedOption === "Selling" && ( */}
                      <div>
                        <FormGroup row>
                          <span
                            style={{
                              paddingLeft: "15px",
                              color: "#000",
                              fontWeight: "300",
                              fontSize: 16,
                            }}
                          >
                            <br />
                            Please Select your address from the suggested
                            autocomplete for accuracy.
                          </span>
                        </FormGroup>

                        {this.state.editingmyown &&
                        !this.state.updatePickupAddress ? (
                          <div className="the-outer-cont">
                            <div
                              style={{
                                display: "flex",
                                padding: "30px 0",
                              }}
                            >
                              <div style={{ width: "50%" }}>
                                {" "}
                                <p>{this.state.pickupLocationAddress}</p>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  width: "50%",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {" "}
                                <p>
                                  <button
                                    type="button"
                                    className="change-button"
                                    onClick={this.updateThepickupAddress}
                                  >
                                    Change Pickup Address
                                  </button>
                                </p>
                              </div>
                            </div>
                            {this.state.updatePickupAddress ? (
                              <div
                                className="my-map-container"
                                style={{ position: "relative" }}
                              >
                                <Map
                                  theaddress={this.theaddress}
                                  getlatlong={this.getlatlong}
                                  center={{
                                    lat: 51.05011,
                                    lng: -114.08529,
                                  }}
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div
                            className="my-map-container"
                            style={{ position: "relative" }}
                          >
                            <Map
                              theaddress={this.theaddress}
                              getlatlong={this.getlatlong}
                              center={{ lat: 51.05011, lng: -114.08529 }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className="container  m-0 p-0 mb-4"
                    style={{ borderBottom: "1px solid #efefef" }}
                  >
                    <div className="left-side newicons d-flex align-items-center">
                      <img
                        className="left-side-icon mr-2"
                        src={require("../../../Common/images/newicons/money-bills-simple.svg")}
                        alt="info"
                        color="#E7B10A"
                      />
                      <h4 className="post-subtitle mt-0 mb-0">
                        Delivery Rate{" "}
                      </h4>
                    </div>
                    <div className="py-2">
                      <label className="py-2">
                        <span className="post-label">
                          Flat rate, first 10 km{" "}
                        </span>
                        <input
                          value={this.state.deliveryRateFirst10Km}
                          onChange={(e) =>
                            this.setState({
                              deliveryRateFirst10Km: e.target.value,
                            })
                          }
                          // onChange={(e) => this.handleDeliveryRateChange(e)}
                          min={0}
                          type="number"
                          step="1"
                          name="price"
                          id="price"
                          placeholder="Delivery Rate First 10 Km"
                          className="input-style-post"
                        />
                      </label>
                    </div>
                    <div className="py-2">
                      <label className="py-2">
                        <span className="post-label">Extra km </span>
                        <input
                          value={this.state.deliveryRateExtraPerKm}
                          onChange={(e) =>
                            this.setState({
                              deliveryRateExtraPerKm: parseInt(e.target.value),
                            })
                          }
                          // onChange={(e) => this.handleDeliveryRateChange(e)}
                          min={0}
                          type="number"
                          step="1"
                          name="deliveryExtra"
                          id="deliveryExtra"
                          placeholder="Delivery Rate Extra Per Km"
                          className="input-style-post"
                        />
                      </label>
                    </div>
                  </div>

                  {/* <FormGroup>
                    <div style={{ minHeight: "50px" }}
                    >
                      {this.state.fielderrors ? (
                        <p style={{ color: "red" }}>
                          You must fill out all the mandotory * feilds! Please
                          double check you didnt miss anything{" "}
                        </p>
                      ) : null}

                      {this.state.apierrors.length > 2 ? (
                        <p style={{ color: "red" }}>{this.state.apierrors} </p>
                      ) : null}
                    </div>
                  </FormGroup> */}

                  <div className="d-flex justify-content-end">
                    {/* <button
                      type="button"
                      // onClick={this._handlePostUpdate}
                      className="btn-white"
                      style={{
                        fontSize: 18,
                      }}
                    >
                      <img
                        src={require("../../../Common/images/newicons/chat-icon.svg")}
                        alt="icon filter"
                        color="black"
                      />
                      {selectedOption === "Selling"
                        ? "Contact Buyer"
                        : "Contact Seller"}
                    </button> */}
                    {this.state.editingmyown ? (
                      <button
                        type="button"
                        onClick={this._handlePostUpdate}
                        className="btn-hay-yellow ml-auto"
                      >
                        Update Post
                      </button>
                    ) : (
                      <button
                      disabled={!this.verifyFields()}
                        type="button"
                        onClick={this._handleSubmit}
                        className="btn-hay-yellow"
                        style={{
                          backgroundColor: this.verifyFields()? "#ffcd46": "lightgrey"
                        }}
                      >
                        <img
                          className="left-side-icon mr-2"
                          src={require("../../../Common/images/newicons/plus.svg")}
                          alt="info"
                          color="#E7B10A"
                        />{" "}
                        Post
                      </button>
                    )}
                  </div>
                </Form>
              </Row>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

EditPost.propTypes = {
  postImageUrl: PropTypes.string,
  postName: PropTypes.string,
  postUser: PropTypes.string,
  postRating: PropTypes.number,
  postId: PropTypes.string,
  postUserId: PropTypes.string,
};

export default EditPost;
