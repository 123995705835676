import React from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

class DatePicker extends React.Component {
  constructor(props, context) {
    super(props, context);

    const today = moment();

    this.state = {
      isOpen: true,
      value: moment.range(today.clone().add(7, "days"), today.clone()),
    };
  }

  onSelect = (value, states) => {
    this.setState({ value, states });
  };

  //   onToggle = () => {
  //     this.setState({ isOpen: !this.state.isOpen });
  //   };

  // Show selected dates
  renderSelectionValue = () => {
    return (
      <div>
        <div>Selection</div>
        {this.state.value.start.format("YYYY-MM-DD")}
        {" - "}
        {this.state.value.end.format("YYYY-MM-DD")}
      </div>
    );
  };
  submit = () => {
    // if(this.state.start &&  this.state.end){
    this.props.getValues(this.state.value.start, this.state.value.end);
    // }
  };

  render() {
    return (
      <div>
        {/* <div>
          <input
            type="button"
            value="Toggle date picker"
            onClick={this.onToggle}
          />
        </div> */}

        {this.state.isOpen && (
          <DateRangePicker
            value={this.state.value}
            onSelect={this.onSelect}
            singleDateRange={true}
            numberOfCalendars={2}
            minimumDate={new Date()}
            selectionType="range"
          />
        )}

        <button
          style={{
            backgroundColor: "#FFCD46",
            color: "#fff",
            fontSize: "14px",
            borderRadius: "0",
            padding: "5px 35px",
            marginTop: "20px",
          }}
          className="btn-apply"
          onClick={() => this.submit()}
        >
          Apply
        </button>
      </div>
    );
  }
}

export default DatePicker;
