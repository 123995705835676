import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  // FormText
} from "reactstrap";
import PropTypes from "prop-types";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import $ from "jquery";

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLock,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { postLogin } from "../../../App/Api/login.js";

import "./style.css";

class LoginModal extends React.Component {
  _isMounted = false; //set is mounted to false
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      password: "",
      username: "",
      clientId: "",
      errorCode: "",
      // clientId: "",
      userId: null,
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    this._isMounted = true; // when component is mounted set to true
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }
  }

  componentWillUnmount() {
    this._isMounted = false; // set mounted to false so async call doesnt try to set state
  }

  changetoLogin = () => {
    this.props.toggle("login");
    this.props.toggle("signin");
  };

  changetoReset = () => {
    this.props.toggle("login");
    this.props.toggle("resetpass");
  };

  loginUser = (e) => {
    e.preventDefault();
    const vm = this;
    postLogin(this.state.username, this.state.password).then(function (res) {
      // console.log(res);

      if (res.code === "INVALID_CREDENTIALS") {
        // console.log('wrong info');
        vm.setState({ errorCode: res.message });
      } else if (res.code === "SUCCESS") {
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        localStorage.setItem("expiaryDate", res.data.expires);
        localStorage.setItem("userId", res.data.user.id);

        // console.log(res.data.expires)
        window.location.reload();
      }
    });
    // let data = {
    //   username: this.state.username,
    //   password: this.state.password
    // };
  };

  render() {
    const closeBtn = (
      <button
        className="close"
        onClick={() => {
          if (this.props.forceLogin) {
            //window.location.href = "/dashboard";
          } else {
            this.props.toggle("login");
          }
        }}
      >
        &times;
      </button>
    );

    const responseGoogle = (response) => {
      // console.log(response);
      const vm = this;

      let data = {};
      data.accessToken = response.tokenId;

      $.ajax({
        method: "POST",
        dataType: "json",
        data: JSON.stringify(data),
        traditional: true,
        xhrFields: {
          withCredentials: false,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API + "/account/login/google",
      })
        .then(function (res) {
          // console.log(res);

          if (res.code === "INVALID_CREDENTIALS") {
            // console.log('wrong info');
            vm.setState({ errorCode: res.message });
          } else if (res.code === "SUCCESS") {
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("expiaryDate", res.data.expires);
            localStorage.setItem("userId", res.data.user.id);
            localStorage.setItem("firstName", res.data.user.firstName);
            localStorage.setItem("lastName", res.data.user.lastName);
            localStorage.setItem("userName", res.data.user.userName);
            localStorage.setItem("profileImage", res.data.user.profileImage);
            localStorage.setItem("socialMediaUser", true);

            // console.log(res.data.expires)
            window.location.reload();
          }
        })
        .catch(function (res) {
          // console.log('the gogole login issue ')
          // console.log(res)
        });
    };
    const responseFacebook = (response) => {
      console.log("thefb res", response);
      const vm = this;

      let data = {};
      data.accessToken = response.accessToken;

      $.ajax({
        method: "POST",
        dataType: "json",
        data: JSON.stringify(data),
        traditional: true,
        xhrFields: {
          withCredentials: false,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API + "/account/login/facebook",
      })
        .then(function (res) {
          console.log(res);

          if (res.code === "INVALID_CREDENTIALS") {
            console.log("wrong info");
            vm.setState({ errorCode: res.message });
          } else if (res.code === "SUCCESS") {
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("expiaryDate", res.data.expires);
            localStorage.setItem("userId", res.data.user.id);
            localStorage.setItem("firstName", res.data.user.firstName);
            localStorage.setItem("lastName", res.data.user.lastName);
            localStorage.setItem("userName", res.data.user.userName);
            localStorage.setItem("profileImage", res.data.user.profileImage);
            localStorage.setItem("socialMediaUser", true);

            console.log(res.data.expires);
            window.location.reload();
          }
        })
        .catch(function (res) {
          console.log("the gogole login issue ");
          console.log(res);
        });
    };
    return (
      <div>
        <Modal
          className="signup-modal"
          isOpen={this.props.modalOpen}
          toggle={() => {
            if (this.props.forceLogin) {
              //window.location.href = "/dashboard";
            } else {
              this.props.toggle("login");
            }
          }}
        >
          <ModalHeader
            toggle={this.toggle}
            close={!this.props.forceLogin && closeBtn}
            style={{ background: "#FFCD46" }}
          >
            Login
          </ModalHeader>
          <ModalBody style={{ paddingTop: 20 }}>
            <span
              style={{
                width: "100%",
                textAlign: "center",
                display: "block",
                color: "red",
                marginBottom: 20,
              }}
            >
              {this.state.errorCode}{" "}
            </span>
            <Form>
              <FormGroup row>
                <label for="exampleEmail" sm={2} size="lg" hidden>
                  Email
                </label>
                <Col sm={12}>
                  <input
                    onChange={(e) =>
                      this.setState({ username: e.target.value })
                    }
                    className="input-style"
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="Enter Email"
                    bsSize="lg"
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <label for="examplePassword" sm={2} size="lg" hidden>
                  Password
                </label>
                <Col sm={12}>
                  {/* <FontAwesomeIcon
                    icon={faLock}
                    size="lg"
                    className="pass-icon-modal"
                  />{" "} */}
                  <input
                    // onChange={(e) =>
                    //   this.setState({ password: e.target.value })
                    // }
                    // onChange={this.handleChange}
                    className="input-style"
                    type={this.state.hidden ? "password" : "text"}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    name="password"
                    id="examplePassword"
                    placeholder="Enter Password "
                    bsSize="lg"
                  />{" "}
                  <FontAwesomeIcon
                    icon={this.state.hidden ? faEye : faEyeSlash}
                    onClick={this.toggleShow}
                    size="lg"
                    className="eye-icon-modal"
                  />
                </Col>
              </FormGroup>
              <div className="d-flex justify-content-end my-4 pt-4">
                <button
                  className="btn-hay-yellow"
                  onClick={this.loginUser}
                  size="lg"
                  block
                  style={{ width: "100%" }}
                >
                  Login
                </button>
              </div>

              <FormGroup row>
                <a className="forgot-pass-login" onClick={this.changetoReset}>
                  Forgot your password?
                </a>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter style={{ matginTop: 20 }}>
            <FormGroup row>
              <p>
                <a
                  className="text-center cursor-pointer"
                  style={{ cursor: "pointer" }}
                  onClick={this.changetoLogin}
                >
                  {" "}
                  Sign Up
                </a>
              </p>
            </FormGroup>
            {/* <Button color="primary" onClick={this.props.toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button> */}
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
}

LoginModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default LoginModal;
