import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  // FormText
} from "reactstrap";
import PropTypes from "prop-types";
import $ from "jquery";

import ReactTooltip from "react-tooltip";

import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLock,
  faEyeSlash,
  faEye,
  faUser,
  faBuilding,
  // faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";

import "./style.css";

class SignUpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      hiddenConfirm: true,
      password: "",
      confirmedpassword: "",
      value: "1",
      firstName: "",
      lastName: "",
      email: "",
      sponsorID: "",
      companyName: "",
      passErrorMsg: "",
      confirmErrorMsg: "",
      id: "",
      // firstName: "",
      // lastName: "",
      profileImage: "",
      userName: "",
      errorCode: "",
      emailExistsError: false,
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.confirmedpassword = this.confirmedpassword.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value }, () => {
      if (this.state.password !== this.state.confirmedpassword) {
        this.setState({ confirmErrorMsg: "The 2 Passwords dont match" });
      } else {
        this.setState({ confirmErrorMsg: "" });
      }
    });
  }

  confirmedpassword(e) {
    // console.log(e.target.value);
    this.setState({ confirmedpassword: e.target.value }, () => {
      if (this.state.password !== this.state.confirmedpassword) {
        this.setState({ confirmErrorMsg: "The 2 Passwords dont match" });
      } else {
        this.setState({ confirmErrorMsg: "" });
      }
    });
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  toggleShowConfirm = () => {
    this.setState({ hiddenConfirm: !this.state.hiddenConfirm });
  };

  componentDidMount() {
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }
  }

  verifyEmail = (em, callback) => {
    const vm = this;
    let data = {
      email: em,
    };
    $.ajax({
      method: "POST",
      dataType: "json",
      data: JSON.stringify(data),
      traditional: true,
      xhrFields: {
        withCredentials: false,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API + "/account/register/verifyemail",
    })
      .then((response) => {
        this.setState({ emailExistsError: false }, () => {
          callback();
        });
        console.log("response res", response);
      })
      .catch((response) => {
        console.log("response err", response);
        console.log("responseJSON", response.responseJSON);
        if (response.responseJSON.code === "VALIDATION_ERROR") {
          vm.setState(
            {
              emailExistsError: true,
            },
            () => {
              callback();
            }
          );
        }
      });
  };

  changetoLogin = () => {
    this.props.toggle("signin");
    this.props.toggle("login");
  };

  changeToSignupProcess = (e) => {
    e.preventDefault();
    const vm = this;
    const regEx = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/;
    console.log(this.state);
    if (
      regEx.test(this.state.password) &&
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.email !== "" &&
      this.state.password === this.state.confirmedpassword
    ) {
      this.verifyEmail(this.state.email, () => {
        if (!this.state.emailExistsError) {
          console.log("True");
          let data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            sponsorID: this.state.sponsorID,
            companyName: this.state.companyName,
            email: this.state.email,
            passwordConfirmation: this.state.confirmedpassword,
          };
          console.log("data", data);
          this.props.setRegisterData(data);

          this.props.toggle("signin");
          this.props.toggle("tosmodal");
        }
      });
    } else if (!regEx.test(this.state.password)) {
      console.log("FAIL");
      vm.setState({
        passErrorMsg:
          "Password needs: 1 uppercase letter,1 lowercase letter, 1 special character, 1 digit and minimum 8 characters",
      });
    } else {
      vm.setState({
        passErrorMsg:
          "You need to fill all the info except for the optional one",
      });
    }
  };

  render() {
    // console.log("this.state.firstName", this.state.firstName);
    // console.log("this.state.lastName", this.state.lastName);
    // console.log("this.state.password", this.state.password);
    // console.log("this.state.confirmedpassword", this.state.confirmedpassword);
    // console.log("this.state.email", this.state.email);

    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("signin")}>
        &times;
      </button>
    );

    // const responseFacebook = (response) => {
    //   console.log("thefb res", response);
    //   const vm = this;

    //   let data = {};
    //   data.accessToken = response.accessToken;

    //   $.ajax({
    //     method: "POST",
    //     dataType: "json",
    //     data: JSON.stringify(data),
    //     traditional: true,
    //     xhrFields: {
    //       withCredentials: false,
    //     },
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     url: process.env.REACT_APP_API + "/account/login/facebook",
    //   })
    //     .then(function (res) {
    //       console.log(res);

    //       if (res.code === "INVALID_CREDENTIALS") {
    //         console.log("wrong info");
    //         vm.setState({ errorCode: res.message });
    //       } else if (res.code === "SUCCESS") {
    //         localStorage.setItem("accessToken", res.data.accessToken);
    //         localStorage.setItem("refreshToken", res.data.refreshToken);
    //         localStorage.setItem("expiaryDate", res.data.expires);
    //         localStorage.setItem("userId", res.data.user.id);
    //         localStorage.setItem("firstName", res.data.user.firstName);
    //         localStorage.setItem("lastName", res.data.user.lastName);
    //         localStorage.setItem("userName", res.data.user.userName);
    //         localStorage.setItem("profileImage", res.data.user.profileImage);
    //         localStorage.setItem("socialMediaUser", true);

    //         console.log(res.data.expires);
    //         window.location.reload();
    //       }
    //     })
    //     .catch(function (res) {
    //       console.log("the gogole login issue ");
    //       console.log(res);
    //     });
    // };

    // const responseGoogle = (response) => {
    //   // console.log(response);
    //   const vm = this;

    //   let data = {};
    //   data.accessToken = response.tokenId;

    //   $.ajax({
    //     method: "POST",
    //     dataType: "json",
    //     data: JSON.stringify(data),
    //     traditional: true,
    //     xhrFields: {
    //       withCredentials: false,
    //     },
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     url: process.env.REACT_APP_API + "/account/login/google",
    //   })
    //     .then(function (res) {
    //       // console.log(res);

    //       if (res.code === "INVALID_CREDENTIALS") {
    //         // console.log('wrong info');
    //         vm.setState({ errorCode: res.message });
    //       } else if (res.code === "SUCCESS") {
    //         localStorage.setItem("accessToken", res.data.accessToken);
    //         localStorage.setItem("refreshToken", res.data.refreshToken);
    //         localStorage.setItem("expiaryDate", res.data.expires);
    //         localStorage.setItem("userId", res.data.user.id);
    //         localStorage.setItem("firstName", res.data.user.firstName);
    //         localStorage.setItem("lastName", res.data.user.lastName);
    //         localStorage.setItem("userName", res.data.user.userName);
    //         localStorage.setItem("profileImage", res.data.user.profileImage);
    //         localStorage.setItem("socialMediaUser", true);

    //         // console.log(res.data.expires)
    //         window.location.reload();
    //       }
    //     })
    //     .catch(function (res) {
    //       // console.log('the gogole login issue ')
    //       // console.log(res)
    //     });
    // };

    return (
      <div>
        <Modal
          className="signup-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("signin")}
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#FFCD46", paddingLeft: "60px" }}
          >
            Sign Up
          </ModalHeader>
          <ModalBody>
            <span
              style={{
                width: "100%",
                textAlign: "center",
                display: "block",
                color: "red",
                marginBottom: 20,
              }}
            >
              {this.state.errorCode}{" "}
            </span>
            <span
              style={{
                width: "100%",
                textAlign: "center",
                display: "block",
                color: "red",
                marginBottom: 20,
              }}
            >
              {this.state.passErrorMsg}{" "}
            </span>
            <span
              style={{
                width: "100%",
                textAlign: "center",
                display: "block",
                color: "red",
                marginBottom: 20,
              }}
            >
              {this.state.confirmErrorMsg}{" "}
            </span>
            {this.state.emailExistsError ? (
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "block",
                  color: "red",
                  marginBottom: 20,
                }}
              >
                Email already exists, Please select a different email.
              </span>
            ) : null}

            <Form>
              <FormGroup row className="my-4">
                <label for="firstname" sm={2} size="lg" hidden>
                  First Name
                </label>
                <Col sm={12}>
                  <input
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    className="input-style"
                    type="text"
                    name="firstname"
                    id="firstname"
                    placeholder="First Name"
                    bsSize="lg"
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="my-4">
                <label for="lastname" sm={2} size="lg" hidden>
                  Last Name
                </label>
                <Col sm={12}>
                  <input
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    className="input-style"
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Last Name"
                    bsSize="lg"
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="my-4">
                <label for="emailsignup" sm={2} size="lg" hidden>
                  Email
                </label>
                <Col sm={12}>
                  <input
                    onChange={(e) => this.setState({ email: e.target.value })}
                    className="input-style"
                    type="email"
                    name="emailsignup"
                    id="emailsignup"
                    placeholder="Email Address"
                    bsSize="lg"
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup row className="my-4">
                <label for="companyName" sm={2} size="lg" hidden>
                  Company name (optional)
                </label>
                <Col sm={12}>
                  <input
                    onChange={(e) =>
                      this.setState({ companyName: e.target.value })
                    }
                    className="input-style"
                    type="text"
                    name="companyName"
                    id="companyName"
                    placeholder="Company Name"
                    bsSize="lg"
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup
                row
                className="my-4"
                // data-tip="Needs to be at least  1 uppercase letter,1 lowercase letter, 1 symbol, 1 digit and between 7-10 characters"
              >
                <label for="createpassword" sm={2} size="lg" hidden>
                  Create Password
                </label>
                <Col sm={12}>
                  <input
                    className="input-style"
                    type={this.state.hidden ? "password" : "text"}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    name="createpassword"
                    id="createpassword"
                    placeholder="Create Password"
                    bsSize="lg"
                    required
                  />{" "}
                  <FontAwesomeIcon
                    icon={this.state.hidden ? faEye : faEyeSlash}
                    onClick={this.toggleShow}
                    size="lg"
                    className="eye-icon-modal"
                  />
                </Col>
              </FormGroup>

              <FormGroup
                className="my-4"
                row
                // data-tip="Needs to be at least  1 uppercase letter,1 lowercase letter, 1 symbol, 1 digit and between 7-10 characters"
              >
                <label for="createpassword-confirm" sm={2} size="lg" hidden>
                  Confirm Password
                </label>
                <Col sm={12}>
                  <input
                    className="input-style"
                    type={this.state.hiddenConfirm ? "password" : "text"}
                    value={this.state.confirmedpassword}
                    onChange={this.confirmedpassword}
                    name="createpassword-confirm"
                    id="createpassword-confirm"
                    placeholder="Confirm Password"
                    bsSize="lg"
                    required
                  />{" "}
                  <FontAwesomeIcon
                    icon={this.state.hiddenConfirm ? faEye : faEyeSlash}
                    onClick={this.toggleShowConfirm}
                    size="lg"
                    className="eye-icon-modal"
                  />
                  <p className="pt-5 px-3">
                    Password requires, 1 uppercase letter, 1 lowercase letter, 1
                    special character and 1 digit and between 7-10 characters
                  </p>
                </Col>
              </FormGroup>
              <div className="d-flex justify-content-end mb-3">
                <button
                  className="btn-hay-yellow ml-auto"
                  onClick={this.changeToSignupProcess}
                  size="lg"
                  block
                  style={{ width: "100%" }}
                >
                  Sign Up
                </button>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter className="py=4">
            <div row className="text-center pt-2 pb-4">
              <p>
                <a
                  className=""
                  style={{ cursor: "pointer" }}
                  onClick={this.changetoLogin}
                >
                  Login
                </a>{" "}
              </p>
            </div>
            {/* <Button color="primary" onClick={this.props.toggle}>Do Something</Button>{' '}
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button> */}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

SignUpModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default SignUpModal;
