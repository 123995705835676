import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import Renting from "../Renting";

import EditProfileModal from "../../Common/Modals/EditProfileModal";
import { getloggedinUser } from "../../App/Api/loggedinuser.js";
// import { getUser } from "../../App/Api/singleuser.js";
import ChatWindow from "../../Common/Chats/components/ChatWindow";
import { getEquipmentsByUserId } from "../..//App/Api/equipmentByUserId";
import Post from "../../Common/Post";

import * as signalR from "@aspnet/signalr";
// import ChatWindow from "../Chats/components/ChatWindow";
import $ from "jquery";

import "./style.css";

class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      EditProfileModalOpen: false,
      userId: 1,

      profileImage: null,
      userName: null,
      userCity: 0,

      authUserId: 1,
      authUserpfImage: null,
      autheruserName: null,
      authuserLocation: null,
      chatHistory: [],
      isOpen: false,
      userDisplayName: "",
      roomId: "",
      accessToken: localStorage.getItem("accessToken"),
      items: [],
      messagable: false,
      sponsorID: null,
      companyName: null,
    };
  }

  componentWillMount() {
    this.setState({ userId: this.props.match.params.id });
  }

  componentWillReceiveProps(nextProps) {
    // console.log("HERE")
    // if(nextProps.locaton.state === "MyProfile"){
    this.setState({ userId: this.props.match.params.id });
    // }
  }

  //TODO: get a list of all items and set state to them
  componentDidMount() {
    const vm = this;
    console.log("PROFILE PROPS", this.props.location.state.messagable);
    vm.setState({ messagable: this.props.location.state.messagable });
    getEquipmentsByUserId(
      null,
      null,
      null,
      4,
      0,
      null,
      vm.state.userId,
      null,
      null
    )
      .then(function (res) {
        // this.setState({userId: res.id});
        console.log({resEquipments: res.items});
        vm.setState({ profileImage: res.items[0].user.profileImage });
        const fullusername =
          res.items[0].user.firstName + " " + res.items[0].user.lastName;
        vm.setState({ userName: fullusername });
        vm.setState({ userCity: res.items[0].city.name });
        vm.setState({ sponsorID: res.items[0].user.sponsorID });
        vm.setState({ companyName: res.items[0].user.companyName });

        vm.setState({ items: res.items });

        // console.log(vm.props.userId);
        // console.log(vm.props.authUserId);
      })
      .catch(function (res) {
        console.log("the otheruser posts error response ");
        console.log(res);
      });

    // getUser(this.state.userId).then(function(res) {
    //   // console.log("***** otheruser-profpage *****")
    //   this.setState({ userId: res.id });
    //   this.setState({ profileImage: res.profileImage });
    //   const fullusername = res.firstName + " " + res.lastName;
    //   this.setState({ userName: fullusername });
    //   this.setState({ userCity: res.city });

    //   // console.log(vm.props.userId);
    //   // console.log(vm.props.authUserId);
    // });

    getloggedinUser().then(function (res) {
      vm.setState({ authUserId: res.id });
      vm.setState({ authUserpfImage: res.profileImage });
      vm.setState({ authorName: res.id });
      const fullusername = res.firstName + " " + res.lastName;
      vm.setState({ autheruserName: fullusername });
      vm.setState({ authuserLocation: res.city });

      // console.log(vm.props.userId);
      // console.log(vm.props.authUserId);
    });

    // const AuthUserId = parseInt(localStorage.getItem('userId'))
    // this.setState({ authUserId: AuthUserId})
  }

  toggle = (type) => {
    if (type == "editprofilemodal") {
      this.setState((prevState) => ({
        EditProfileModalOpen: !prevState.EditProfileModalOpen,
      }));
    }
  };

  createRoom = () => {
    const vm = this;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.haythere.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/chat/rooms/user/" + this.state.userId,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      console.log(res.data);

      vm.setState({
        userDisplayName: res.data.userDisplayName,
        roomId: res.data.roomId,
      });

      connection.start().then(() => {
        connection
          .invoke("SubscribeChatRoom", res.data.roomId)
          .then((response) => {
            console.log(response);
            const chatMessages = response.data.latestMessages
              .reverse()
              .map((e) => {
                let from = "";
                if (e.author.userId == localStorage.getItem("userId")) {
                  from = "me";
                } else {
                  from = "sender";
                }
                return {
                  from: from,
                  message: e.body,
                  created: e.createdAt,
                };
              });
            vm.setState({
              chatHistory: chatMessages,
              isOpen: true,
            });
          });
        connection
          .invoke("MarkRoomRead", res.data.roomId)
          .then((response) => {})
          .catch(function (err) {
            return console.error(err.toString());
          });
      });
    });
  };

  // get chat history

  getChatHistory() {
    const vm = this;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.haythere.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    connection.start().then(() => {
      connection
        .invoke("SubscribeChatRoom", this.state.roomId)
        .then((response) => {
          console.log(response);
          const chatMessages = response.data.latestMessages
            .reverse()
            .map((e) => {
              let from = "";
              if (e.author.userId == localStorage.getItem("userId")) {
                from = "me";
              } else {
                from = "sender";
              }
              return {
                from: from,
                message: e.body,
                created: e.createdAt,
              };
            });
          vm.setState({
            chatHistory: chatMessages,
          });
        });
    });
  }
  _sendMessage(message) {
    const vm = this;
    console.log(message);
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.haythere.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    connection.start().then(() => {
      connection
        .invoke("SendMessageText", this.state.roomId, message.data.text)
        .catch(function (err) {
          console.log("sent message");
          return console.error(err.toString());
        })
        .then(function () {
          vm.getChatHistory();
        });
    });
    if (message.data.text.length > 0) {
      let index = this.state.chatSelect;
      console.log("after message");
      console.log(message);
    }
  }

  closeClick() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  //
  render() {
    const isOpen = this.props.hasOwnProperty("isOpen")
      ? this.props.isOpen
      : this.state.isOpen;

    return (
      <React.Fragment>
        <EditProfileModal
          toggle={this.toggle}
          modalOpen={this.state.EditProfileModalOpen}
        />

        <div
          className="other-user-prof-container"
          style={{ marginTop: "120px" }}
        >
          <div className="container post-info-container main-one">
            <div className="onee">
              <div className="left-side" style={{ width: "80px" }}>
                <img className="left-side-pf" src={this.state.profileImage} />
              </div>

              <div className="right-side" style={{ marginLeft: "12px" }}>
                <div className="right-side-one">
                  <h4 style={{ marginBottom: 0 }} className="post-title">
                    {this.state.userName}{" "}
                  </h4>
                  {this.state.sponsorID ? (
                    <p
                      className="post-title-username"
                      style={{ marginBottom: "0px" }}
                    >
                      My Sponsor Code: {this.state.sponsorID}{" "}
                    </p>
                  ) : null}
                  {this.state.companyName ? (
                    <p
                      className="post-title-username"
                      style={{ marginBottom: "0px" }}
                    >
                      Company: {this.state.companyName}{" "}
                    </p>
                  ) : null}
                  <p className="post-title-username">{this.state.userCity}</p>
                </div>
              </div>
            </div>

            <div className="twoo" style={{ width: "49%", height: "120px" }}>
              {/* {this.state.messagable ? ( */}
              <Button
                size="lg"
                className="message-user-btn"
                onClick={() => this.createRoom()}
              >
                Send Message
              </Button>
              {/* ) : null} */}
            </div>
          </div>
          <div className="posts-in-dash py-4">
            {this.state.items.length > 0 ? (
              this.state.items.map((item, index) => {
                return (
                  <div
                    className="posts-in-dash"
                    style={{
                      marginTop: "20px",
                      display: "grid",
                      gridTemplateColumns: "repeat(1, minmax(350px, 1fr))",
                    }}
                    key={item.id}
                  >
                    <Post
                      postName={item.name}
                      postUser={`${item.user.firstName} ${item.user.lastName}`}
                      postRating={item.score}
                      postId={item.id}
                      postUserId={item.user.id}
                      // postImageUrl={item.equipments[0].equipmentMedias[0].filePath}
                      amount={item.dailyRate}
                    />
                  </div>
                );
              })
            ) : (
              <div
                className="no-results-div"
                style={{ textAlign: "center", paddingTop: "150px" }}
              >
                <span className="no-results-span">
                  this user does not have any items{" "}
                </span>{" "}
              </div>
            )}
          </div>
        </div>
        {/* Same thing for both just display all posts related to user */}
        {
          <ChatWindow
            messageList={this.state.chatHistory}
            onUserInputSubmit={this._sendMessage.bind(this)}
            userProfile={this.state.userDisplayName}
            isOpen={isOpen}
            onClose={this.closeClick.bind(this)}
          />
        }
      </React.Fragment>
    );
  }
}

Profile.propTypes = {
  userId: PropTypes.number,
  autheruserName: PropTypes.string,
};
export default Profile;
