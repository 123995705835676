import React from "react";
import { InputGroup, InputGroupAddon, Input, Col } from "reactstrap";
import { resetPassword } from "../../App/Api/resetPassword";
import { Redirect } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import * as qs from "query-string";
import "./style.css";

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      password: null,
      confirmPassword: null,
      userName: null,
      resetToken: null,
      success: false,
      redirect: false,
    };
  }

  componentDidMount() {
    console.log("this.props", this.props);
    // TODO IF params are set continue oher wise redirect to home page
    if (this.props.location.search) {
      const parse = qs.parse(this.props.location.search);
      console.log("parse", parse);
      this.setState({
        resetToken: parse.token,
        userName: parse.email,
      });
    } else {
      this.setState({ redirect: true });
    }
  }

  myAlert = (message) => {
    try {
      window.alert(message);
    } catch (e) {
      console.log("alert error!");
    }
  };

  submit = (e) => {
    e.preventDefault();
    const vm = this;
    let { password, confirmPassword, userName, resetToken } = this.state;

    if (!password || !confirmPassword) {
      alert(
        "ERROR, Need to provide both password and confirmation of password"
      );
    } else {
      if (password == confirmPassword) {
        let data = {
          username: userName,
          resetToken: resetToken,
          password: password,
          passwordConfirmation: confirmPassword,
        };
        resetPassword(data).then(function (res) {
          console.log(res);
          if (res.code === "SUCCESS") {
            vm.myAlert("Password changed successfully");
            vm.setState({ success: true });
          } else {
            alert(res.message);
          }
        });
      } else {
        alert("ERROR, Passwords do not match");
      }
    }
  };

  redirectToHome = () => {
    setTimeout(() => {
      this.props.history.push("/dashboard");
      window.location.reload();
    }, [600]);
    return;
  };

  render() {
    console.log("this.state.success", this.state.success);
    console.log("this.state.redirect, ", this.state.redirect);
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <React.Fragment>
          <ReactTooltip type="success" />
          {this.state.success && this.redirectToHome()}
          <div style={{ margin: "200px -15px" }}>
            <Col sm={12} style={{ margin: "0 auto" }}>
              <form
                className="forgot-pass"
                style={{ width: "35%", margin: "0 auto" }}
                onSubmit={(e) => this.submit(e)}
              >
                <InputGroup className="forgot-pass-input">
                  <label style={{ display: "block" }}>New Password</label>
                </InputGroup>

                <InputGroup className="forgot-pass-input">
                  <Input
                    id="paddingPlaceholder"
                    style={{ width: 300, paddingLeft: "10px" }}
                    type="password"
                    data-tip="Needs to be at least  1 uppercase letter,1 lowercase letter, 1 symbol, 1 digit and between 7-10 characters"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    placeholder="New password"
                  />
                </InputGroup>

                <InputGroup
                  className="forgot-pass-input "
                  style={{ marginTop: 20 }}
                >
                  <label style={{ display: "block" }}>
                    Confirm New Password
                  </label>
                </InputGroup>

                <InputGroup className="forgot-pass-input">
                  <Input
                    id="paddingPlaceholder"
                    style={{ width: 300 }}
                    type="password"
                    data-tip="Needs to be at least  1 uppercase letter,1 lowercase letter, 1 symbol, 1 digit and between 7-10 characters"
                    onChange={(e) =>
                      this.setState({ confirmPassword: e.target.value })
                    }
                    placeholder="Confirm password"
                  />
                </InputGroup>

                <InputGroup className="forgot-pass-submit">
                  <button className="main-searchbutton">Submit</button>
                </InputGroup>
              </form>
            </Col>
          </div>
        </React.Fragment>
      );
    }
  }
}
export default ForgotPassword;
