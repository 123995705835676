import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLock,
  faEyeSlash,
  faEye,
  faUser,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";

import "./style.css";

class SuccessAccountModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("success")}>
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="signup-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("success")}
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#FFCD46", paddingLeft: "50px" }}
          >
            Success
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Col style={{ textAlign: "center" }} sm={12}>
                  <p>Redirecting you to the login screen</p>
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

SuccessAccountModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string,
};

export default SuccessAccountModal;
