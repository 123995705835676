import React, { useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  OverlayView,
  MarkerClusterer,
} from "@react-google-maps/api";
// import { MarkerClusterer } from "@googlemaps/markerclusterer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ButtonBase from "./ButtonBase.png";
import ButtonBaseWhite from "./ButtonBaseWhite.png";
import ButtonBaseHay from "./ButtonBaseHay.png";
import ButtonBaseCart from "./ButtonBaseCart.png";
import ButtonBaseWhite2 from "./ButtonBaseWhite2.png";
import ButtonBase2 from "./ButtonBase2.png";

import "./CustomerMarker.css";
const Rating = (rating) => {
  let stars = [];
  if (rating === 0) {
    return <span style={{ color: "rgba(0,0,0,0.4)" }}>Not yet rated</span>;
  } else {
    for (let i = 0; i < rating; i++) {
      stars.push(<FontAwesomeIcon icon={faStar} className="checked fa-star" />);
    }
    return stars;
  }
};
const labelContent = ({ text }) => {
  return (
    <div style={labelStyle}>
      <img src="./ButtonBase.png" /> {text}
    </div>
  );
};

let iconCart = {
  path: "M17.6878 16.4126C16.6753 16.4126 15.8889 17.2334 15.8889 18.2459C15.8889 19.2584 16.7097 20.0792 17.6878 20.0792C18.666 20.0792 19.4868 19.2584 19.4868 18.2459C19.4868 17.2334 18.7344 16.4126 17.6878 16.4126ZM6.68785 16.4126C5.67531 16.4126 4.88889 17.2334 4.88889 18.2459C4.88889 19.2584 5.71007 20.0792 6.68785 20.0792C7.66562 20.0792 8.4868 19.2584 8.4868 18.2459C8.4868 17.2334 7.73437 16.4126 6.68785 16.4126ZM21.7517 2.23212C21.5184 1.92297 21.1628 1.7459 20.7755 1.7459H4.62535L4.56806 1.26886C4.48403 0.836494 4.1059 0.523682 3.66667 0.523682H0.882292C0.41059 0.523682 0 0.934272 0 1.40597C0 1.87767 0.41059 2.35702 0.882292 2.35702H2.87375L5.17611 14.4456C5.29375 14.8772 5.67187 15.1903 6.11111 15.1903H18.6389C19.145 15.1903 19.5556 14.7798 19.5556 14.3081C19.5556 13.7657 19.1469 13.357 18.6389 13.357H6.87118L6.51979 11.5237H18.6809C19.2267 11.5237 19.706 11.162 19.8561 10.6372L21.9515 3.33823C22.0573 2.93184 21.9847 2.54149 21.7517 2.23212Z",
  // fillColor: "#FFCC1D",
  fillColor: "#313131",
  fillOpacity: 1,
  strokeWeight: 0,
  scale: 1.25,
  position: "relative",
};
let iconSecond = "https://maps.google.com/mapfiles/kml/shapes/star.png";

const labelStyle = {
  backgroundColor: "red !important",
  color: "white !important",
  fontSize: "16px !important",
  padding: "8px !important",
  borderRadius: "5px !important",
};
const containerStyle = {
  width: "100%",
  height: "100%",
};

function MyMap(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDpLI1D7Mc3yq_7sc6l6dkgFPPYpmiLVLM",
  });

  const center = {
    lat: props.places[0].latitude,
    lng: props.places[0].longitude,
  };

  const [map, setMap] = React.useState(null);
  const [selectedMarker, setSelectedMarker] = React.useState(null);
  const [mapCenter, setMapCenter] = React.useState(center);
  const [hoveredCluster, setHoveredCluster] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.setZoom(props.zoom);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
    return {
      x: offsetWidth + labelAnchor.x,
      y: offsetHeight + labelAnchor.y,
    };
  };
  useEffect(() => {
    if (selectedMarker) {
      setMapCenter({
        lat: selectedMarker.latitude,
        lng: selectedMarker.longitude,
      });
    } else setMapCenter(center);
  }, [selectedMarker]);
  const currencyFormatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  });
  console.log("places", props.places);
  console.log("selectedMarker", selectedMarker);

  const findPlacesInCluster = (cluster) => {
    let clusterPlaces = [];
    for (let i = 0; i < cluster.getMarkers().length; i++) {
      let marker = cluster.getMarkers()[i];
      clusterPlaces.push(marker.title);
    }
    let filteredPlaces = props.places.filter((place) => {
      if (clusterPlaces.includes(place.id)) {
        return true;
      }
      return false;
    });
    console.log({ filteredPlaces });
    props.setClusteredPlaces(filteredPlaces);
    props.setIsShowingClusteredPlaces(true);
    props.changeViewStatus(2);
    // setCluseteredPlaces(filteredPlaces);
    // setHoveredCluster(cluster);
  };

  return (
    isLoaded && (
      <>
        <GoogleMap
          mapContainerStyle={containerStyle}
          // center={center}
          center={mapCenter}
          zoom={8}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            navigationControl: false,
            minZoom: 4,
            maxZoom: 16,
          }}
        >
          <MarkerClusterer
            onClick={(cluster) => {
              findPlacesInCluster(cluster);
            }}
            zoomOnClick={false}
            imagePath={ButtonBaseHay}
            gridSize={50}
            //maxZoom={6}
            minimumClusterSize={2}
            averageCenter
            enableRetinaIcons
            styles={[
              {
                url: ButtonBase2,
                height: 40,
                width: 70,
                textColor: "#000",
                textSize: 16,
                fontWeight: "bold",
              },
            ]}
          >
            {(clusterer) =>
              props.places.map((place, i) => {
                let lat = place.latitude;
                let lng = place.longitude;
                return (
                  <Marker
                    title={place.id}
                    clusterer={clusterer}
                    key={place.id}
                    position={{
                      lat: lat,
                      lng: lng,
                    }}
                    label={{
                      text: place.lookingFor? String(place.quantity):currencyFormatter.format(place.amount),
                      className: "map-marker-label",
                      color: "#313131",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    ima
                    options={{
                      icon: place.lookingFor ? ButtonBaseCart : ButtonBaseHay,
                    }}
                    onClick={() => {
                      setSelectedMarker(place);
                    }}
                  ></Marker>
                );
              })
            }
          </MarkerClusterer>
          {/* {props.places.map((place, i) => {
            let lat = place.latitude;
            let lng = place.longitude;
            return (
              <div key={place.id}>
                <OverlayView
                  position={{
                    lat: lat,
                    lng: lng,
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  // mapPaneName={OverlayView.MARKER_LAYER}
                  getPixelPositionOffset={(x, y) =>
                    // getPixelPositionOffset(x, y, { x: -30, y: -15 })
                    getPixelPositionOffset(x, y, { x: -105, y: -81 })
                  }
                >
                  <div
                    onClick={(e) => {
                      // e.stopPropagation();
                      // console.log("clicked", place.id);
                      setSelectedMarker(place);
                    }}
                    style={{
                      background:
                        place.mapMarkerCategory === "Sale" ? "#FFCC1D" : `#fff`,
                      padding: `8px 12px 8px 12px`,
                      fontSize: "20px",
                      color: `#313131`,
                      borderRadius: "0px 100px 100px 0px",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormatter.format(place.amount)}
                  </div>
                </OverlayView>
                <Marker
                  position={{
                    lat: lat,
                    lng: lng,
                  }}
                  options={{
                    icon:
                      place.mapMarkerCategory === "Sale"
                        ? ButtonBaseHay
                        : ButtonBaseCart,
                  }}
                  onClick={() => {
                    setSelectedMarker(place);
                  }}
                ></Marker>
              </div>
            );
          })} */}
          {selectedMarker && (
            <>
              <Link
                to={{
                  pathname: `/post/${selectedMarker.id}`,
                  state: {
                    postUserId: selectedMarker.markerUserId,
                    postId: selectedMarker.id,
                  },
                }}
              >
                <InfoWindow
                  position={{
                    lat: selectedMarker.latitude,
                    lng: selectedMarker.longitude,
                  }}
                  options={{
                    pixelOffset: new window.google.maps.Size(100, -50),
                  }}
                  onCloseClick={() => {
                    setSelectedMarker("");
                  }}
                >
                  <div
                    className="map-hover-popup "
                    style={{ padding: "15px 10px 10px", overflow: "hidden" }}
                  >
                    <img
                      width="250px"
                      src={
                        selectedMarker.clusterImg ||
                        require("./Video/field-bale.jpg")
                      }
                      alt="Post image"
                    />
                    <p
                      className="map-marker-title"
                      style={{
                        textAlign: "left",
                        marginTop: 10,
                        fontWeight: "600",
                        marginBottom: 5,
                        fontSize: 18,
                      }}
                    >
                      {" "}
                      {selectedMarker.name}
                    </p>
                    <div
                      className="d-flex mb-0"
                      style={{ textTransform: "capitalize", fontWeight: "500" }}
                    >
                      <p
                        style={{
                          textAlign: "left",
                          fontWeight: "400",
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                      >
                        {" "}
                        <span className="dash-username mb-0">{`${selectedMarker.mapFname} ${selectedMarker.mapLname}`}</span>
                      </p>
                    </div>
                    <div className="green-txt">
                      {Rating(selectedMarker.markerRating)}
                    </div>
                    <div
                      style={{
                        width: 40,
                        padding: 1,
                        backgroundColor: "#E5E7EB",
                      }}
                      className="mb-2"
                    ></div>
                    <p className="mb-0">
                      <span
                        style={{ fontWeight: 600, fontSize: 18 }}
                        className=" text-dark text-bold mb-0"
                      >
                        {currencyFormatter.format(selectedMarker.amount)}{" "}
                      </span>
                      / bale
                    </p>
                  </div>
                </InfoWindow>
              </Link>
            </>
          )}

          {/* {hoveredCluster && !selectedMarker && (
            <>
              <InfoWindow
                position={{
                  lat: hoveredCluster.center.lat(),
                  lng: hoveredCluster.center.lng(),
                }}
                options={{
                  pixelOffset: new window.google.maps.Size(100, -50),
                }}
                onCloseClick={() => {
                  setHoveredCluster(null);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "6px",
                  }}
                >
                  {clusteredPlaces.map((place) => {
                    return (
                      <Link
                        to={{
                          pathname: `/post/${place.id}`,
                          state: {
                            postUserId: place.markerUserId,
                            postId: place.id,
                          },
                        }}
                      >
                        <div
                          style={{
                            padding: "4px",
                            display: "flex",
                            borderRadius: "16px",
                            border: "1px solid gainsboro",
                            marginBottom: "8px",
                          }}
                        >
                          <img
                            style={{
                              marginTop: "8px",
                              borderRadius: "16px",
                              width: "60px",
                              height: "60px",
                              marginRight: "12px",
                            }}
                            src={place.clusterImg}
                            alt=""
                          />
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <p
                              className="map-marker-title"
                              style={{
                                textAlign: "left",
                                marginTop: 10,
                                fontWeight: "600",
                                marginBottom: 5,
                                fontSize: 18,
                              }}
                            >
                              {" "}
                              {place.name}
                            </p>
                            <div
                              className="d-flex mb-0"
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "500",
                              }}
                            >
                              <p
                                style={{
                                  textAlign: "left",
                                  fontWeight: "400",
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                {" "}
                                <span className="dash-username mb-0">{`${place.mapFname} ${place.mapLname}`}</span>
                              </p>
                            </div>
                            <div className="green-txt">
                              {Rating(place.markerRating)}
                            </div>
                            <div
                              style={{
                                width: 40,
                                padding: 1,
                                backgroundColor: "#E5E7EB",
                              }}
                              className="mb-2"
                            ></div>
                            <p className="mb-0">
                              <span
                                style={{ fontWeight: 600, fontSize: 18 }}
                                className=" text-dark text-bold mb-0"
                              >
                                {currencyFormatter.format(place.amount)}{" "}
                              </span>
                              / bale
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </InfoWindow>
            </>
          )} */}
        </GoogleMap>
      </>
    )
  );
}

export default React.memo(MyMap);
