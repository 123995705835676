import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { getProvinces } from "../../../App/Api/location";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLock,
  faEyeSlash,
  faEye,
  faUser,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

import "./style.css";

class EditProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      confirmErrorMsg: "",
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
      firstName: "",
      lastName: "",
      email: "",
      cityCode: 0,
      file: "",
      imagePreviewUrl: "",
      uploadedImage: "",
      phone: "",
      initialImage: "",
      isUploadButtonDisabled: false,
      passErrorMsg: "",
      sponsorID: "",
      companyName: "",
      passwordChangeSuccess: "",
      currentPasswordError: "",
      hiddenNew: true,
      hiddenConfirm: true,
      errorMessage: "",
      provinces: [],
      provinceId: 0
        };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    // this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  toggleShowNew = () => {
    this.setState({ hiddenNew: !this.state.hiddenNew });
  };

  toggleShowConfirm = () => {
    this.setState({ hiddenConfirm: !this.state.hiddenConfirm });
  };

  fetchProvinces = () => {
    getProvinces()
      .then((res) => {
        //console.log({provinces:res});
        this.setState({ provinces: res.items });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }

    this.getExistingInfo();
    this.fetchProvinces();
  }

  changetoLogin = () => {
    this.props.toggle("signin");
    this.props.toggle("login");
  };

  changeToSignupProcess = () => {
    this.props.toggle("signin");
    this.props.toggle("tosmodal");
  };

  getExistingInfo() {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/users/me",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        let cityId;
        if (res.data.city != null) {
          cityId = res.data.city.id;
        } else {
          cityId = 0;
        }

        let provinceId;
        if (res.data.province != null) {
          provinceId = res.data.province.id;
        } else {
          provinceId = 0;
        }

        vm.setState({
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email,
          cityCode: cityId,
          phone: res.data.phone,
          initialImage: res.data.profileImage,
          sponsorID: res.data.sponsorID,
          companyName: res.data.companyName,
          provinceCode: res.data.provinceCode,
          provinceId: provinceId

        });
      })
      .catch(function (res) {
        console.log(res);
      });
  }

  handleUpload = () => {
    let vm = this;
    $.when(vm.uploadImages()).done(function () {});
  };

  uploadImages = () => {
    const fd = new FormData();
    fd.append("file", this.state.file);
    fd.append("public", true);
    let data = {
      File: this.state.file,
      Public: true,
    };

    const vm = this;
    $.ajax({
      method: "POST",
      //dataType: 'json',
      processData: false,
      //traditional: true,
      contentType: false,
      data: fd,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/uploads/uploadimage",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    }).then(function (res) {
      vm.setState({
        uploadedImage: res.data.filePath,
        imagePreviewUrl: "",
      });
      vm.onSaveChanges();
    });
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    if (file.size < 6000000) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select image size less than 10 mb!");
    }
  }

  handleChange = ({ target }) => {
    let name = target.name;
    this.setState({ [name]: target.value });
  };
  ////////////////////////////////////////////////////////////////////////////////
  saveChanges = (e) => {
    e.preventDefault();
    let data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      profileImage: this.state.uploadedImage,
      cityId: parseInt(this.state.cityCode),
      sponsorID: this.state.sponsorID,
      companyName: this.state.companyName,
      provinceId: parseInt(this.state.provinceId)
    };

    const vm = this;
    $.ajax({
      method: "PUT",
      dataType: "json",
      processData: false,
      traditional: true,
      contentType: "application/json",
      data: JSON.stringify(data),
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/users/me",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    })
      .then(function () {
        // vm.props.toggle("editprofilemodal");
        window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ errorMessage: error.responseJSON.message });
      });
  };

  changePassword = () => {
    console.log("******** before password change data ************");
    const vm = this;
    let data = {
      currentPassword: this.state.currentPassword,
      password: this.state.password,
      passwordConfirmation: this.state.passwordConfirmation,
    };
    $.ajax({
      method: "POST",
      dataType: "json",
      traditional: true,
      contentType: "application/json",
      data: JSON.stringify(data),
      xhrFields: {
        withCredentials: false,
      },
      url: process.env.REACT_APP_API + "/account/changepassword",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    })
      .then(function (res) {
        console.log("******** get password change data ************");
        console.log(res.code);

        vm.setState({
          passwordChangeSuccess: res.code,
        });
        if (res.code === "SUCCESS") {
          vm.props.toggle("editprofilemodal");
        }
      })
      .catch(function (res) {
        console.log("ERROR");
        console.log(res);
        if (res.responseJSON.code === "VALIDATION_ERROR") {
          vm.setState({
            currentPasswordError: "Incorrect current Password",
          });
        }
      });
  };

  handlePasswordChange(e) {
    this.setState({ password: e.target.value }, () => {
      if (this.state.password !== this.state.passwordConfirmation) {
        this.setState({ confirmErrorMsg: "The 2 Passwords dont match" });
      } else {
        this.setState({ confirmErrorMsg: "" });
      }
    });
  }

  handleConfirmPassword(e) {
    // console.log(e.target.value);
    this.setState({ passwordConfirmation: e.target.value }, () => {
      if (this.state.password !== this.state.passwordConfirmation) {
        this.setState({ confirmErrorMsg: "The 2 Passwords dont match" });
      } else {
        this.setState({ confirmErrorMsg: "" });
      }
    });
  }

  regexCheck = () => {
    const vm = this;
    const regEx =
      /(?=^.{7,15}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/;
    if (regEx.test(this.state.password)) {
      console.log("True");
    } else {
      console.log("FAIL");
      vm.setState({
        passErrorMsg:
          "Password needs: 1 uppercase letter,1 lowercase letter, 1 special character, 1 digit and between 7-10 characters",
      });
    }
  };

  onSaveChanges = (e) => {
    e.preventDefault();
    if (this.state.imagePreviewUrl.length > 0) {
      this.uploadImages();
    } else {
      if (this.state.password === "") {
        console.log("Password didtn change");
        this.saveChanges(e);
        // this.props.toggle("editprofilemodal");
      } else {
        this.regexCheck();
        this.changePassword();
        this.saveChanges(e);

        //this.props.toggle("editprofilemodal");
      }
    }
  };

  passwordChangeConfirm = () => {
    if (this.state.passwordChangeSuccess === "SUCCESS") {
    }
  };

  render() {
    console.log("firstName", this.state.firstName);
    console.log("lastName", this.state.lastName);
    console.log("phone", this.state.phone);

    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (
        <img
          className="profile-pic-container edit-pf"
          style={{ width: 150 }}
          src={imagePreviewUrl}
        />
      );
    } else {
      if (this.state.initialImage) {
        $imagePreview = (
          <img
            className="profile-pic-container edit-pf"
            style={{ width: 150 }}
            src={this.state.initialImage}
          />
        );
      } else {
        $imagePreview = (
          <img
            className="profile-pic-container edit-pf"
            style={{ width: 150 }}
            src="https://s3.ca-central-1.amazonaws.com/vog-project-common/profile.png"
          />
        );
      }
    }
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("editprofilemodal")}
      >
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="signup-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("editprofilemodal")}
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#FFCD46" }}
          >
            Edit Profile
          </ModalHeader>
          <ModalBody className="pb-4">
            <Form className="edit-post">
              <FormGroup row>
                <div className="profile-pic-container p-4">
                  {/* <img className="edit-pf" src={$imagePreviewUrl} /> */}
                  <div className="edit-pf">{$imagePreview}</div>
                  <input
                    ref={(fileInput) => (this.fileInput = fileInput)}
                    style={{ display: "none" }}
                    className="upload"
                    type="file"
                    accept="image/*"
                    onChange={(e) => this._handleImageChange(e)}
                  />
                  <p
                    className="change-photo-text"
                    style={{
                      fontWeight: 500,
                      marginTop: 10,
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => this.fileInput.click()}
                      className="btn-hay-yellow"
                      // style={{
                      //   backgroundColor: "#FFCD46",
                      //   color: "white",
                      //   border: "none",
                      //   padding: "10px",
                      // }}
                    >
                      Change Image
                    </button>
                    <br />
                    {/* <button
                      style={{
                        backgroundColor: "#FFCD46",
                        color: "white",
                        border: "none",
                        marginTop: "10px",
                        padding: "10px"
                      }}
                      type="button"
                      onClick={this.uploadImages}
                    >
                      Upload Image
                    </button> */}
                  </p>
                </div>
              </FormGroup>

              <label className="py-2" style={{ width: "100%" }}>
                <span className="post-label">First Name:</span>
                <input
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  className="input-style-post"
                  required
                />
              </label>
              <label className="py-2" style={{ width: "100%" }}>
                <span className="post-label">Last Name:</span>
                <input
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  className="input-style-post"
                  required
                />
              </label>
              <label className="py-2" style={{ width: "100%" }}>
                <span className="post-label">Email:</span>
                <input
                  type="email"
                  value={this.state.email}
                  name="email"
                  id="email"
                  onChange={this.handleChange}
                  placeholder="Email"
                  className="input-style-post"
                  required
                />
              </label>
              <label className="py-2" style={{ width: "100%" }}>
                <span className="post-label">Phone:</span>
                <input
                  type="text"
                  value={this.state.phone}
                  name="phone"
                  id="phone"
                  onChange={this.handleChange}
                  placeholder="Phone"
                  className="input-style-post"
                  required
                />
              </label>
              <label className="py-2 mb-2" style={{ width: "100%" }}>
                <span className="post-label">Province:</span>
                <select
                  defaultValue={this.state.provinceId}
                  onChange={this.handleChange}
                  className=" reportreason-select"
                  name="provinceId"
                  id="provinceId"
                  style={{
                    fontSize: "1.25rem",
                  }}
                  required
                >
                  <option hidden value="0">
                    {" "}
                    Select a province
                  </option>
                  {this.state.provinces.map((province) => (
                    <option value={province.id}>{province.name}</option>
                  ))}
                </select>
              </label>

              <label className="py-2 mb-2" style={{ width: "100%" }}>
                <span className="post-label">Company Name:</span>
                <input
                  type="text"
                  name="companyName"
                  id="companyName"
                  value={this.state.companyName}
                  onChange={this.handleChange}
                  placeholder="Company Name"
                  className="input-style-post"
                />
              </label>
              <div className="mb-2">
                <label
                  for="currentPassword"
                  sm={12}
                  size="lg"
                  style={{ position: "relative" }}
                >
                  <span className="post-label">Current Password:</span>
                  <input
                    className="input-style-post"
                    type={this.state.hidden ? "password" : "text"}
                    value={this.state.currentPassword}
                    onChange={this.handleChange}
                    name="currentPassword"
                    id="currentPassword"
                    placeholder="Current password"
                    bsSize="lg"
                  />{" "}
                  <FontAwesomeIcon
                    icon={this.state.hidden ? faEye : faEyeSlash}
                    onClick={this.toggleShow}
                    size="lg"
                    className="eye-icon-modal"
                    style={{ top: 42 }}
                  />
                </label>
                <div
                  style={{
                    fontWeight: "400",
                    marginBottom: 15,
                    fontSize: 12,
                  }}
                >
                  Required only if changing password
                </div>
              </div>

              <div
                // data-tip="Needs to be at least  1 uppercase letter,1 lowercase letter, 1 symbol, 1 digit and between 7-10 characters"
                className="mb-2"
              >
                <label
                  for="createpassword"
                  sm={12}
                  size="lg"
                  style={{ position: "relative" }}
                >
                  <span className="post-label">Change password to:</span>
                  <input
                    className="input-style-post"
                    type={this.state.hiddenNew ? "password" : "text"}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    name="password"
                    id="password"
                    placeholder="New Password"
                    bsSize="lg"
                  />{" "}
                  <FontAwesomeIcon
                    icon={this.state.hiddenNew ? faEye : faEyeSlash}
                    onClick={this.toggleShowNew}
                    size="lg"
                    className="eye-icon-modal"
                    style={{ top: 42 }}
                  />
                </label>
              </div>

              <div className="mb-2">
                <label
                  for="createpassword-confirm"
                  sm={12}
                  size="lg"
                  style={{ position: "relative" }}
                >
                  <span className="post-label">Confirm password:</span>
                  <input
                    className="input-style-post"
                    type={this.state.hiddenConfirm ? "password" : "text"}
                    value={this.state.passwordConfirmation}
                    onChange={this.handleConfirmPassword}
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    placeholder="Confirm Password"
                    bsSize="lg"
                  />{" "}
                  <FontAwesomeIcon
                    icon={this.state.hiddenConfirm ? faEye : faEyeSlash}
                    onClick={this.toggleShowConfirm}
                    size="lg"
                    className="eye-icon-modal"
                    style={{ top: 42 }}
                  />
                </label>
                <div
                  style={{
                    fontWeight: "400",
                    marginBottom: 15,
                    fontSize: 12,
                  }}
                >
                  Password requires 1 uppercase letter, 1 lowercase letter, 1
                  special character and 1 digit and between 7-10 characters
                </div>
              </div>
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "block",
                  color: "red",
                  marginBottom: 20,
                }}
              >
                {this.state.confirmErrorMsg}{" "}
              </span>
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "block",
                  color: "red",
                  marginBottom: 20,
                }}
              >
                {this.state.passErrorMsg}{" "}
              </span>
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "block",
                  color: "red",
                  marginBottom: 20,
                }}
              >
                {this.state.currentPasswordError}{" "}
              </span>
              {this.state.errorMessage && (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {this.state.errorMessage}
                </p>
              )}
              <div sm={12} className="d-flex justify-content-between pb-4">
                <button
                  className="btn-white"
                  onClick={() => {
                    this.props.toggle("editprofilemodal");
                    this.setState({ errorMessage: null });
                  }}
                  size="lg"
                >
                  Cancel
                </button>
                <button
                  className="btn-hay-yellow"
                  onClick={this.onSaveChanges}
                  size="lg"
                  type="submit"
                  disabled={
                    this.state.firstName === "" ||
                    this.state.lastName === "" ||
                    this.state.email === "" ||
                    this.state.phone === ""
                  }
                >
                  Save Changes
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

EditProfileModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default EditProfileModal;
