import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "./post.css";
import moment from "moment";

const optionsTrucking = [
  { id: 0, label: "Pick up only, loading included" },
  { id: 1, label: "Pick up only, loading not included" },
  { id: 2, label: "Delivery to your location, unloading included" },
  { id: 3, label: "Delivery to your location, unloading not included" },
];

const optionsHay = [
  { id: 1, label: "Grass" },
  { id: 2, label: "Grass/Alfalfa" },
  { id: 3, label: "Alfalfa" },
  { id: 4, label: "Second Cut" },
  { id: 5, label: "Third Cut" },
  { id: 6, label: "Haylage" },
  { id: 7, label: "Greenfeed" },
  { id: 8, label: "Other Hay" },
];

const optionsStraw = [
  { id: 9, label: "Barley" },
  { id: 10, label: "Wheat" },
  { id: 11, label: "Oats" },
  { id: 12, label: "Peas" },
  { id: 13, label: "Other Straw" },
];

const optionsGrain = [
  { id: 14, label: "Barley" },
  { id: 15, label: "Wheat" },
  { id: 16, label: "Oats" },
  { id: 17, label: "Peas" },
  { id: 18, label: "Other Feed" },
];

class RentingPost extends React.Component {
  constructor() {
    super();
  }

  isDelivery = (freightOpt) => {
    if (freightOpt == 2 || freightOpt == 3) {
      return true;
    }
    return false;
  };

  getHayType = () => {
    let vm = this;
    let foundHay = optionsHay.find((item) => {
      return item.label === this.props.category;
    });
    let foundStraw = optionsStraw.find((item) => {
      return item.label === this.props.category;
    });
    let foundFeed = optionsGrain.find((item) => {
      return item.label === this.props.category;
    });

    if (foundHay) {
      return "Hay Bale";
    }
    if (foundStraw) {
      return "Straw Bale";
    }
    if (foundFeed) {
      return "Feed Grain";
    }
    return "";
  };

  render() {
    // let Rating = () => {
    //   let stars = []
    //   for(let i = 0; i < this.props.postRating; i++){
    //     stars.push(<FontAwesomeIcon icon={faStar} className="checked fa-star" />)
    //   }
    //   return stars;
    // }
    const currencyFormatter = new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
    });
    return (
      <>
        <Link
          to={{
            pathname: `/rentals/${this.props.RentingpostId}`,
            state: { postUserId: this.props.RentingpostUserId },
          }}
        >
          <Card sm="12" className="dashboard-card" style={{ padding: "10px" }}>
            <CardHeader className="card-img-header p-0">
              <div className="card-img">
                {/* <img
                width="100%"
                src={this.props.RentingpostImageUrl}
                alt="item img"
              /> */}
                <img
                  width="100%"
                  height="220px"
                  src={
                    this.props.RentingpostImageUrl ||
                    require("../Post/field-bale.jpg")
                  }
                  alt="item img"
                  style={{ objectFit: "cover", borderRadius: "30px" }}
                />
              </div>
            </CardHeader>
            <CardBody className="text-muted dashboard-card-body">
              <CardTitle className="renting-card-title d-flex align-items-center justify-content-between">
                <span
                  className="title-rentingout"
                  style={{ maxWidth: "250px" }}
                >
                  {this.props.RentingpostName}{" "}
                </span>
                {/* <span style={{color:'red',float:'right'}}>Renting</span> */}
                {this.props.confirmedAt && (
                  <span
                    style={{
                      // position: "absolute",
                      // top: 60,
                      // right: 10,

                      backgroundColor: "#55575F",
                    }}
                    class="badge  text-white"
                  >
                    Inactive
                  </span>
                )}
                {!this.props.confirmedAt && !this.props.refundedAt ? (
                  <span
                    class="badge"
                    style={{
                      // position: "absolute",
                      // top: 60,
                      // right: 10,
                      backgroundColor: "#FFCD46",
                    }}
                  >
                    Active
                  </span>
                ) : null}
                {this.props.refundedAt && (
                  <span
                    className="renting-status"
                    style={{ color: "rgba(0,0,0,0.6)", float: "right" }}
                  >
                    Canceled
                  </span>
                )}
              </CardTitle>

              <CardText className="dashboard-card-text">
                <div className="rentingout-pf-section-container">
                  {/* <div className="first">
                  <img
                    width="100%"
                    src={this.props.RentingpostUserProfileImageUrl}
                    alt="item img"
                  />
                </div> */}
                  <span className="dash-username">
                    {this.props.RentingpostUser}
                  </span>
                </div>

                <div
                  className=""
                  style={{
                    maxWidth: "250px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div style={{ display: "flex", gap: "16px" }}>
                    <p>
                      <span
                        style={{ fontWeight: 600, fontSize: 18 }}
                        className=" text-dark text-bold"
                      >
                        {this.isDelivery(this.props.freightOptions)
                          ? "Delivery: "
                          : "Pickup: "}
                      </span>
                    </p>
                    <span
                      style={{
                        color: "#FFCD46",
                        fontWeight: 500,
                        marginLeft: "auto",
                        textAlign: "right",
                      }}
                    >
                      {/* {this.props.startDate} */}
                      {this.props.address}
                    </span>
                  </div>

                  {/* {this.props.openEndedRentalStatus === 0 ||
                this.props.confirmedAt !== null ? (
                  <p>
                    End Date:{" "}
                    <span style={{ color: "#000", fontWeight: 500 }}>
                      {this.props.endDate}
                    </span>
                  </p>
                ) : (
                  <p>Open ended</p>
                )} */}
                  {/* <p>
                  {this.props.type}:{" "}
                  <span style={{ color: "#FFCD46", fontWeight: 500 }}>
                    {this.props.pickUp
                      ? this.props.pickUp.substring(
                          0,
                          this.props.pickUp.lastIndexOf(",")
                        )
                      : ""}
                  </span>
                </p> */}
                  {/* <p>Delivary:  <span  style={{color:'#FFCD46',fontWeight:500}}>4119 Center street, NW Calgary, AB</span></p> */}
                  {/* <p>
                  Price:{" "}
                  <span style={{ color: "#FFCD46", fontWeight: 500 }}>
                    $ {this.props.total}
                  </span>{" "}


                  {/* <p>
                    <span
                      style={{ fontWeight: 600, fontSize: 18 }}
                      className=" text-dark text-bold"
                    >
                      Quantity:{" "}
                    </span>
                    {this.props.quantity}
                  </p> */}
                  <div style={{ display: "flex", gap: "16px" }}>
                    <p>
                      <span
                        style={{ fontWeight: 600, fontSize: 18 }}
                        className=" text-dark text-bold"
                      >
                        Type:
                      </span>
                    </p>
                    <span
                      style={{
                        color: "#FFCD46",
                        fontWeight: 500,
                        marginLeft: "auto",
                        textAlign: "right",
                      }}
                    >
                      {/* {this.props.startDate} */}
                      {this.getHayType()}
                    </span>
                  </div>

                  <div style={{ display: "flex", gap: "16px" }}>
                    <p>
                      <span
                        style={{ fontWeight: 600, fontSize: 18 }}
                        className=" text-dark text-bold"
                      >
                        Category:{" "}
                      </span>
                    </p>
                    <span
                      style={{
                        color: "#FFCD46",
                        fontWeight: 500,
                        marginLeft: "auto",
                      }}
                    >
                      {/* {this.props.startDate} */}
                      {this.props.category}
                    </span>
                  </div>

                  <div style={{ display: "flex", gap: "16px" }}>
                    <p>
                      <span
                        style={{ fontWeight: 600, fontSize: 18 }}
                        className=" text-dark text-bold"
                      >
                        Quantity:{" "}
                      </span>
                    </p>
                    <span
                      style={{
                        color: "#FFCD46",
                        fontWeight: 500,
                        marginLeft: "auto",
                      }}
                    >
                      {/* {this.props.startDate} */}
                      {this.props.quantity}
                    </span>
                  </div>


                  <div style={{ display: "flex", gap: "16px" }}>
                    <p>
                      <span
                        style={{ fontWeight: 600, fontSize: 18 }}
                        className=" text-dark text-bold"
                      >
                        Total:{" "}
                      </span>
                    </p>
                    <span
                      style={{
                        color: "#FFCD46",
                        fontWeight: 500,
                        marginLeft: "auto",
                      }}
                    >
                      {currencyFormatter.format(this.props.total)}{" "}

                    </span>
                  </div>

        
                </div>
              </CardText>
            </CardBody>
          </Card>
        </Link>
        {/* </Col> */}
      </>
    );
  }
}

RentingPost.propTypes = {
  RentingpostImageUrl: PropTypes.string,
  RentingpostName: PropTypes.string,
  RentingpostUser: PropTypes.string,
  RentingpostUserProfileImageUrl: PropTypes.string,
  RentingpostId: PropTypes.number,
  RentingpostUserId: PropTypes.number,
};

export default RentingPost;
