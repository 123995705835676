import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Form,
  FormGroup
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "./style.css";

class PaymentSuccessModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Modal className="about-modal" isOpen={this.props.modalOpen}>
          <ModalHeader toggle={this.toggle} style={{ background: "#ADB5BD" }}>
            Order Confirmed
          </ModalHeader>
          <ModalBody style={{ padding: "3rem 3rem 0 3rem" }}>
            <Form>
              <FormGroup row>
                <Col sm={12}>
                  <p>
                  Thank you for your order. You can view details of this purchase in your Orders.
                  </p>
                </Col>
                <Link
                  className="loginbutton btn btn-secondary btn-lg btn-block"
                  to="/posts"
                  size="md"
                  block
                >
                  Continue
                </Link>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

PaymentSuccessModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string
};

export default PaymentSuccessModal;
