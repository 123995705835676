import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLock,
  faEyeSlash,
  faEye,
  faUser,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";

import "./style.css";

class PostEditSuccessModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // changetoLogin() {
  //   this.props.toggle('signin');
  //   this.props.toggle('login');
  // }

  // changetoLogin = () => {
  //   this.props.toggle('resetpass');
  //   this.props.toggle('login');
  // }

  render() {
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("about")}>
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="about-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("about")}
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#ADB5BD" }}
          >
            Edited
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Col sm={12} style={{ textAlign: "center" }}>
                  <h5>Congrats! You edited your post succesfully</h5>
                </Col>
              </FormGroup>

              <FormGroup row style={{ margin: 0 }}>
                <Col sm={12}>
                  <Link
                    to={{ pathname: "/myprofile" }}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      onClick={this.changetoAddExisting}
                      className="loginbutton"
                      size="lg"
                      block
                    >
                      See all my posts{" "}
                    </Button>
                  </Link>
                  <Link
                    to={{ pathname: "/dashboard" }}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      onClick={this.changetoAddExisting}
                      className="loginbutton"
                      size="lg"
                      block
                    >
                      Home{" "}
                    </Button>
                  </Link>
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

PostEditSuccessModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string,
};

export default PostEditSuccessModal;
