import $ from "jquery";

export function getCities() {
  return $.ajax({
    method: "GET",
    dataType: "json",
    traditional: true,
    xhrFields: {
      withCredentials: true,
    },
    url: process.env.REACT_APP_API + "/cities",
  }).then((res) => res.data);
}

export function getProvinces() {
  return $.ajax({
    method: "GET",
    dataType: "json",
    traditional: true,
    xhrFields: {
      withCredentials: true,
    },
    url: process.env.REACT_APP_API + "/provinces?PerPage=10000",
  }).then((res) => res.data);
}
