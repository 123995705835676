import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Form,
  FormGroup,
} from "reactstrap";
import PropTypes from "prop-types";

class OnBoardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("checkstripeonboard")}
      >
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="newpost-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("login")}
        >
          <ModalHeader
            toggle={this.props.toggle}
            close={closeBtn}
            style={{ background: "#ADB5BD" }}
          >
            New Stripe User
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Col sm={12}>
                  <p>
                    You must be an onboarded stripe user to create a new
                    equipment/addon
                  </p>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <a
                    href={this.props.url}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    {" "}
                    <Button
                      type="button"
                      onClick={this.props.startCheck}
                      className="loginbutton"
                      size="lg"
                      block
                    >
                      Sign up as stripe user
                    </Button>
                  </a>
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

OnBoardModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
  url: PropTypes.string,
};

export default OnBoardModal;
