import React from "react";
import Header from "../Header";
import Footer from "../Footer";

class MainLayout extends React.Component {
  render() {
    if (this.props.onUpdate) {
      this.props.onUpdate();
    }
    console.log("this.props.authenticated main", this.props.authenticated);

    return (
      <div>
        {!window.location.pathname.includes("dashboard") && (
          <Header
            authenticated={this.props.authenticated}
            bannerDisplay={this.props.bannerDisplay}
            userName={this.props.userName}
            userLocation={this.props.userLocation}
            userId={this.props.userId}
            profileImage={this.props.profileImage}
            outsideChatUserId={this.props.outsideChatUserId}
            handler={this.props.handler}
          />
        )}

        <div
          className="page-content col-sm-12"
          style={{ padding: 0 }}
          authenticated={this.props.authenticated}
        >
          {this.props.children}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default MainLayout;
