import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  // ModalFooter,
  // Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  // FormText
} from "reactstrap";
import PropTypes from "prop-types";

import $ from "jquery";
import "./style.css";

class FeedbackModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackCategories: [],
      feedbackCategoryId: null,
      feedbackMessage: null,
      submitError: null,
    };
  }

  toggle(type) {
    if (type === "FeedbackSuccessModal") {
      this.setState((prevState) => ({
        FeedbackSuccessModalOpen: !prevState.FeedbackSuccessModalOpen,
      }));
    }
  }

  componentDidMount() {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/platformfeedback/categories",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      console.log("******** Feedback ************");
      // console.log(res.data);
      vm.setState({ feedbackCategories: res.data.items });
      console.log(vm.state.feedbackCategories);
    });
  }

  selectChangeid(e) {
    this.setState({
      feedbackCategoryId: e.target.value,
    });
    console.log("test category", this.state.feedbackCategoryId);
  }

  sendFeedback = (e) => {
    e.preventDefault();

    // const vm = this;

    // let myFeedback = {
    //   title: "From haythere App",
    //   platformFeedbackCategoryId: 1,
    //   body: "this is my feedback"
    // }

    let myFeedback = {
      title: "From haythere Web Site",
      platformFeedbackCategoryId: parseInt(this.state.feedbackCategoryId),
      body: this.state.feedbackMessage,
    };

    console.log("Post Category Id", this.state.feedbackCategoryId);
    console.log("Post my feedback", myFeedback);

    const vm = this;
    $.ajax({
      method: "POST",
      dataType: "json",
      traditional: true,
      data: JSON.stringify(myFeedback),
      xhrFields: {
        withCredentials: true,
      },

      url: process.env.REACT_APP_API + "/platformfeedback",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        console.log("******** POST FEEDBACK *******");
        console.log(res.data);
        // vm.setState
        if (res.code == "SUCCESS") {
          vm.props.toggle("feedback");
        } else {
          vm.setState({
            submitError: " Submit not successful, please try again later.",
          });
        }
        // this.props.toggle("success");
        alert("Thank you for submiting your feed back");
      })
      .catch((res) => {
        console.log();
      });
  };

  render() {
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("feedback")}>
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="signup-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("feedback")}
          size="lg"
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#FFCD46" }}
          >
            Feedback
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="reportreason" sm={2} size="lg" hidden>
                  Email
                </Label>
                <Col sm={12}>
                  <select
                    type="select"
                    id="reportreason-select"
                    name="reportReason-select"
                    onClick={(e) => this.selectChangeid(e)}
                    className="reportreason-select mt-5"
                  >
                    <option value="">Select reason for report</option>
                    {this.state.feedbackCategories.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                </Col>
              </FormGroup>

              <FormGroup row>
                <label for="feedback-message" sm={2} size="lg" hidden>
                  feedback
                </label>
                <Col sm={12}>
                  <textarea
                    onChange={(e) =>
                      this.setState({ feedbackMessage: e.target.value })
                    }
                    className="input-style"
                    type="text"
                    name="feedbackMessage"
                    id="feedback-message"
                    placeholder="Enter message"
                    bsSize="lg"
                    rows={6}
                  />
                </Col>
              </FormGroup>
              <div style={{ color: "red" }}>
                {this.state.submitError ? this.state.submitError : null}
              </div>

              <button
                className="btn-hay-yellow mb-4 ml-auto"
                style={{
                  marginTop: 20,
                  borderColor: "transparent",
                  width: "100%",
                }}
                size="lg"
                onClick={this.sendFeedback}
                block
              >
                Send Feedback
              </button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

FeedbackModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string,
};

export default FeedbackModal;
