import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  UncontrolledCarousel,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Video,
  Button,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLock,
  faEyeSlash,
  faEye,
  faFlag,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import $ from "jquery";

import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  InstapaperShareButton,
  InstapaperIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import ReportUserModal from "../../../Common/Modals/ReportUserModal";

import "./post-view.css";
import UserRating from "./UserRating";
import ViewPostTabs from "./ViewPostTabs";
import Navbar from "../../../Common/HayThereNavigation";
import { Data } from "./data.js";
import Header from "../../../Common/Header";

class ViewPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderImages: [],
      // Carousel
      activeIndex: 0,
      items: 0,
      ReportUserModalOpen: false,

      postUserId: null,
      postId: null,
      postInfo: null,
      minStartDate: null,
      maxEndDate: null,
      isDeliveryAvailable: null,
      isPickupAvailable: null,
      reviewsCount: [],
      companyName: null,
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.goBack = this.goBack.bind(this);

    this.toggle = this.toggle.bind(this);
  }

  toggle(type) {
    if (type == "reportusermodal") {
      this.setState((prevState) => ({
        ReportUserModalOpen: !prevState.ReportUserModalOpen,
      }));
    }
  }

  Rating = (itemScore) => {
    let stars = [];
    for (let i = 0; i < itemScore; i++) {
      stars.push(<FontAwesomeIcon icon={faStar} className="checked fa-star" />);
    }
    return stars;
  };

  timeSince = (date) => {
    let seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  };

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.sliderImages.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.sliderImages.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  goBack() {
    window.history.back();
  }

  instagramRedirect = () => {
    navigator.clipboard.writeText(window.location.href);
    Swal.fire("", "Post copied, please paste it on instagram.", "").then(
      function () {
        window.open(
          "https://www.instagram.com",
          "_blank",
          "height=600,width=400"
        );
      }
    );
  };

  componentWillMount() {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") != null
    ) {
    } else {
    }
    this.setState({
      postId: this.props.postId,
      postUserId: this.props.postUserId,
      postInfo: this.props.data,
    });
  }

  componentDidMount() {
    const vm = this;
    let myImages = this.props.data.equipmentMedias.map((element) => {
      return {
        src: element.filePath,
        type: element.type,
        // altText: 'Slide 1',
        // caption: 'Slide 1'
      };
    });

    vm.props.availability(this.props.data.isActive);
    vm.setState({
      sliderImages: myImages,
      postInfo: this.props.data,
      minStartDate: this.props.data.startDate,
      maxEndDate: this.props.data.endDate,
      isDeliveryAvailable: this.props.data.isDeliveryAvailable,
      isPickupAvailable: this.props.data.isPickupAvailable,
      reviewsCount: this.props.data.ratings,
    });
    // });
  }

  render() {
    // console.log(this.props);
    console.log("state", this.state.postInfo);

    const { activeIndex } = this.state;
    return (
      <>
        {/* <Header
          authenticated={true}
          bannerDisplay={false}
          userName={"this.props.userName"}
          userLocation={"this.props.userLocation"}
          userId={"this.props.userId"}
          profileImage={"this.props.profileImage"}
        /> */}
        <nav
          className="bg-grey-light rounded-md w-full text-sm"
          style={{ marginTop: 120 }}
        >
          <ol className=" d-flex align-items-center">
            <Link
              to={`/dashboard`}
              style={{ color: "#000000", fontSize: "26px" }}
              className=""
            >
              Home screen
            </Link>
            <div>
              <span className="text-gray-500 mx-2 font-semibold">{">"}</span>
            </div>
            <Link
              to={`#`}
              style={{ color: "#E7B10A", fontSize: "26px" }}
              className="font-semibold"
            >
              Hay Bale
            </Link>
          </ol>
        </nav>
        <Col>
          {localStorage.getItem("accessToken") && (
            <ReportUserModal
              equipmentId={this.state.postId}
              toggle={this.toggle}
              modalOpen={this.state.ReportUserModalOpen}
            />
          )}

          <Row style={{ padding: "0 20px" }}>
            <Col sm="7" style={{ position: "relative" }}>
              {/* {localStorage.getItem("accessToken") && (
                <div
                  onClick={() => this.toggle("reportusermodal")}
                  className="reportbutton"
                >
                  <FontAwesomeIcon
                    icon={faFlag}
                    size="lg"
                    className="report-icon"
                  />
                </div>
              )} */}
              <Row>
                <Col sm="2">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                  >
                    {this.state.sliderImages.map((item) => {
                      return (
                        <img
                          src={item.src}
                          style={{
                            width: "100px",
                            marginBottom: "10px",
                            borderRadius: 10,
                          }}
                        />
                      );
                    })}
                  </div>
                </Col>
                <Col>
                  <Carousel
                    sm="12"
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                    autoPlay={false}
                    interval={false}
                    ride={1000}
                  >
                    <CarouselIndicators
                      className="view-carousel-indicators mx-auto"
                      items={this.state.sliderImages}
                      activeIndex={activeIndex}
                      onClickHandler={this.goToIndex}
                    />
                    {this.state.sliderImages.map((item) => {
                      if (item.type === 2) {
                        return (
                          <CarouselItem
                            height="500px"
                            onExiting={this.onExiting}
                            onExited={this.onExited}
                            key={item.src}
                          >
                            <video
                              width="100%"
                              controls
                              src={item.src}
                              alt={item.altText}
                              type="video/mp4"
                            />
                          </CarouselItem>
                        );
                      } else {
                        return (
                          <CarouselItem
                            onExiting={this.onExiting}
                            onExited={this.onExited}
                            key={item.src}
                          >
                            <img
                              interval={false}
                              src={item.src}
                              alt={item.altText}
                              // className="rounded-xl"
                              style={{ borderRadius: 30 }}
                            />
                          </CarouselItem>
                        );
                      }
                    })}
                    <CarouselControl
                      direction="prev"
                      directionText="Previous"
                      onClickHandler={this.previous}
                    />
                    <CarouselControl
                      direction="next"
                      directionText="Next"
                      onClickHandler={this.next}
                    />
                  </Carousel>
                  <div
                    className="container post-info-container mt-5 "
                    style={{ flex: 1, flexDirection: "column" }}
                  >
                    {/* <div className="left-side newicons">
                    <img
                      className="left-side-icon"
                      src={require("../../../Common/images/newicons/comments-icon.svg")}
                      alt="info"
                      color="#E7B10A"
                    />
                  </div> */}
                    {this.state.postInfo.ratings.length > 0 &&
                    this.state.postInfo.selling ? (
                      this.state.postInfo.ratings.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className=""
                            style={{ fontSize: 18, width: "100%" }}
                          >
                            <h4 className="post-subtitle">Seller Rating </h4>

                            <div
                              className=" post-info-block"
                              style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                            >
                              {/* <Row className="mx-0 p-5"> */}
                              <div className="">
                                <div className="d-flex ">
                                  {item.ratedBy.profileImage ? (
                                    <img
                                      className="profile-img mr-4"
                                      src={item.ratedBy.profileImage}
                                    />
                                  ) : (
                                    <div className="profile-img mr-4 d-flex justify-content-center align-items-center p-8">
                                      {item.ratedBy.firstName.charAt(0)}
                                    </div>
                                  )}
                                  <div>
                                    {/* {this.state.postInfo.user ? ( */}
                                    <p
                                      className="post-title-username "
                                      style={{ marginBottom: "0px" }}
                                    >
                                      {item.ratedBy.firstName}{" "}
                                      {item.ratedBy.lastName}
                                      {/* {`${
                                    this.state.postInfo.user.firstName
                                  } ${this.state.postInfo.user.lastName.charAt(
                                    0
                                  )}`} */}
                                    </p>
                                    {/* ) : null} */}
                                    <div className="d-flex align-items-center">
                                      <div className="green-txt">
                                        {/* {this.state.postInfo.score !== 0 ? (
                                    this.Rating()
                                  ) : (
                                    <p> No rating </p>
                                  )} */}
                                        {this.Rating(item.score)}
                                      </div>
                                      <span className="card-review-user">
                                        {this.timeSince(
                                          new Date(item.createdAt)
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div>{item.description}</div>
                              </div>
                              {/* </Row> */}
                              {/* <Row className="mx-0 p-5">
                            <UserRating
                            equipmentId={this.state.postId}
                            score={
                              this.props.data.postInfo
                                ? this.props.data.score
                                : null
                            }
                            />
                          </Row> */}
                            </div>
                          </div>
                        );
                      })
                    ) : !this.state.postInfo.lookingFor ? (
                      <div className="" style={{ fontSize: 18, width: "100%" }}>
                        <h4 className="post-subtitle">Seller Rating </h4>
                        <p>Not rated yet</p>
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <Row className="mx-0 p-5">
                {/* <div className="col-sm-11 d-flex justify-content-between">
                  <div>
                    <div className="green-txt">
                      {Data.score !== 0 ? (
                        this.Rating()
                      ) : (
                        <p> No rating yet </p>
                      )}
                    </div>
                    <div
                      className="card-review-user"
                      style={{ color: "#FFCD46", paddingRight: 8 }}
                    >
                      {" "}
                      {this.state.reviewsCount.length} Reviews{" "}
                    </div>
                  </div>
                </div> */}
              </Row>
              {/* <Row className="mx-0 p-5">
                <UserRating
                  equipmentId={this.state.postId}
                  score={
                    this.props.data.postInfo ? this.props.data.score : null
                  }
                />
              </Row> */}
            </Col>

            <Col sm="5">
              {this.state.postInfo && (
                <ViewPostTabs
                  postInfo={this.state.postInfo}
                  postId={this.state.postId}
                  postUserId={this.state.postUserId}
                  minStartDate={this.state.minStartDate}
                  maxEndDate={this.state.maxEndDate}
                  isDeliveryAvailable={this.state.isDeliveryAvailable}
                  isPickupAvailable={this.state.isPickupAvailable}
                  isRentingOut={this.props.data.isRentedOut}
                  isOpenEndedRental={this.props.data.isOpenEndedRental}
                  handler={this.props.handler}
                />
              )}
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

ViewPost.propTypes = {
  postImageUrl: PropTypes.string,
  postName: PropTypes.string,
  postUser: PropTypes.string,
  postRating: PropTypes.number,
  postId: PropTypes.string,
  postUserId: PropTypes.string,
  interval: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default ViewPost;
