import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import PropTypes from 'prop-types';

class MenuOptionsPopup extends React.Component {
  constructor(){
    super();
    this.state ={
      
    }

  }
  componentDidMount(){

  }

  //TODO: if user is not logged in only display login/signup else show all route icons and filter/date/location and new post

  //TODO: filter popup, Date pop up, location pop up (setstate: search params)

  //TODO: notifications popup, display all notifications relative to user, on click flag notification as read, and bring user to relative notification(if message pop up relative message, if contract changed bring to contract, etc.)

  //TODO: messages popup,Display all Messages, on click pop up realtive message
  render() {
    return(
      <Modal  isOpen={this.props.modalOpen}>
        <div style={{padding:100}}>LOADING ...</div> 
      </Modal>
    );
  }
}

MenuOptionsPopup.propTypes = {
  authenticated : PropTypes.bool,
  userName: PropTypes.string,
  userLocation: PropTypes.string,
  notifications: PropTypes.object,


}

export default MenuOptionsPopup;