import React from "react";
import {
  Navbar,
  Button,
  Dropdown,
  DropdownMenu,
  Row,
  Col,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";
import PropTypes from "prop-types";
import { NavLink, Link, withRouter, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "./DatePicker";
import moment from "moment";

import LoginModal from "../Modals/Login";
import SignUpModal from "../Modals/Signup";
import ResetPassModal from "../Modals/ResetPassword";
import TosModal from "../Modals/TosModal";
import PhoneVerificationNumberModal from "../Modals/PhoneVerificationNumberModal";
import PhoneVerificationCodeModal from "../Modals/PhoneVerificationCodeModal";
import NewPostModal from "../Modals/NewPostModal";

import SuccessAccountModal from "../../Common/Modals/SuccessAccountModal";
import SuccessEmailReset from "../Modals/ResetPassword/SuccessEmailReset";

import { getCities, getProvinces } from "../../App/Api/location.js";
import { getFilters } from "../../App/Api/filter.js";
import { getSorts } from "../../App/Api/sort.js";

// import MenuLocationsPopup from './MenuLocationsPopup'
import "./header.css";
import $ from "jquery";
import * as signalR from "@aspnet/signalr";
import ChatWindow from "../Chats/components/ChatWindow";
import incomingMessageSound from "../Chats/assets/sounds/notification.mp3";
import "../Chats/styles";
import FormattedDateTime from "../Chats/FormattedDateTime";
import { BallBeat } from "react-pure-loaders";
import NotificationPopup from "./NotificationPopup";
import ChatPopup from "./ChatPopup";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forceLogin: false,
      location: null,
      locations: [],
      lat: null,
      long: null,
      cSelected: [],
      filters: [],
      sorts: [],
      startDate: null,
      endDate: null,
      searchKeyWord: "",
      displayChatPopup: false,
      displayMessagePopup: false,
      displayNotificationPopup: false,
      displayMenuOptionsPopup: false,
      displayMenuLocationsPopup: false,
      displayMenuFiltersPopup: false,
      displayMenuSortsPopup: false,
      displayMenuDatePickerPopup: false,
      LoginModalOpen: false,
      SignUpModalOpen: false,
      TosModalOpen: false,
      PhoneVerificationNumberModalOpen: false,
      PhoneVerificationCodeModalOpen: false,
      NewPostModalOpen: false,
      SuccessAccountModalOpen: false,
      SuccessEmailResetOpen: false,
      CheckoutFormOpen: false,
      loading: true,
      fullname: null,
      lastName: null,
      chatHistory: [],
      activeChatRooms: [],
      chatUser: "",
      accessToken: localStorage.getItem("accessToken"),
      lastMessage: "",
      activeChatFilter: "",
      userProfile: {
        userName: "Manish",
        imageUrl:
          "https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png",
      },
      registerData: {},
      isOpen: false,
      chatSelect: null,
      count: 0,
      notifications: [],
      selectedChatId: 0,
      readColor: "#8AC53F",
      //unReadMessageCount: 0,
      unReadMessage: null,
      redirect: false,

      userDisplayName: null,
      roomId: null,
    };

    this.showPopup = this.showPopup.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onCheckboxBtnClick = this.onCheckboxBtnClick.bind(this);
    this.setRegisterData = this.setRegisterData.bind(this);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    this.setRegisterData = this.setRegisterData.bind(this);
    this._onMessageWasSent = this._onMessageWasSent.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const vm = this;
    // if (typeof window !== "undefined") {
    //   this.setState({
    //     outsideChatUserId: localStorage.getItem("outsideChatUserId")
    //       ? localStorage.getItem("outsideChatUserId")
    //       : null,
    //   });

    //   window.addEventListener("storage", (e) => {
    //     console.log("listening", e);
    //   });
    // }

    const handleStorage = () => {
      console.log("testing!!!");
      // Place for a function responsible for
      // pulling and displaying local storage data
    };

    window.addEventListener("storage", handleStorage());

    //If not logged in post page then open login modal
    if (
      window.location.pathname.includes("post") &&
      !this.props.authenticated
    ) {
      vm.setState({
        LoginModalOpen: true,
        forceLogin: true,
      });
    }
    //TODO: get all notifications related to user, and all messages related to user
    // let city;
    // if (localStorage.getItem("searchCity")) {
    //   city = localStorage.getItem("searchCity");
    //   this.showPosition(null, city);
    // } else {
    //   vm.getLocation();
    //   city = this.props.userLocation;
    // }

    let province;
    if (localStorage.getItem("searchProvince")) {
      province = localStorage.getItem("searchProvince");
      this.showProvince(null, province);
    } else {
      //vm.getLocation();
      // province = this.props.userLocation;
    }

    vm.setState({
      // location: city,
      filter: this.props.filterOptions,
      sort: this.props.sortOptions,
    });

    // getCities().then(function (res) {
    //   vm.setState({ locations: res.items });
    // });
    getProvinces().then(function (res) {
      vm.setState({ locations: res.items });
    });
    getFilters().then(function (res) {
      // console.log("**** Filters ****");
      // console.log("**** Filters ****", res);
      vm.setState({ filters: res });
    });
    getSorts().then(function (res) {
      // console.log("**** Sort ****");
      // console.log("**** Sort ****", res);
      vm.setState({ sorts: res });
    });

    if (this.props.authenticated) {
      window.onload = function start() {
        vm.getUnreadNotificationCount();
        // vm.getActiveChatRooms();
        // setInterval(vm.getActiveChatRooms, 5000);
        // setInterval(vm.getUnreadNotificationCount, 5000);
      };

      if (this.props.authenticated) {
        vm.getUnreadNotificationCount();

        vm.getActiveRooms();
      }
    }
  }

  //get Location

  getLocation = () => {
    if (navigator.geolocation) {
      // navigator.geolocation.getCurrentPosition(this.showPosition);
      navigator.geolocation.getCurrentPosition(this.showProvince);
    } else {
      alert("Geolocation is not supported by this browser.");
      this.setState({
        location: "Alberta",
      });
      localStorage.setItem("searchProvince", 1);
    }
  };

  test = () => {
    sessionStorage.setItem("hideVideo", true);
  };

  showPosition = (position = null, id = null) => {
    const vm = this;
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (localStorage.getItem("accessToken")) {
      header.Authorization = "Bearer " + localStorage.getItem("accessToken");
    }
    if (position) {
      let latitude = position.coords.latitude;
      let longitude = position.coords.longitude;

      $.ajax({
        method: "GET",
        dataType: "json",
        traditional: true,
        data: {},
        xhrFields: {
          withCredentials: true,
        },
        url: `${process.env.REACT_APP_API}/cities?Latitude=${latitude}&Longitude=${longitude}`,

        headers: header,
      })
        .then((response) => {
          vm.setState({
            location: response.data.items[0].name,
          });
          localStorage.setItem("searchCity", response.data.items[0].id);
        })
        .catch(function (res) {
          console.log(res);
          vm.setState({
            loading: false,
          });
        });
    } else {
      $.ajax({
        method: "GET",
        dataType: "json",
        traditional: true,
        data: {},
        xhrFields: {
          withCredentials: true,
        },
        url: `${process.env.REACT_APP_API}/cities/${id}`,

        headers: header,
      })
        .then((response) => {
          vm.setState({
            location: response.data.name,
          });
          localStorage.setItem("searchCity", response.data.id);
        })
        .catch(function (res) {
          console.log(res);
          vm.setState({
            loading: false,
          });
        });
    }
  };

  showProvince = (position = null) => {
    const vm = this;
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (position) {
      let latitude = position.coords.latitude;
      let longitude = position.coords.longitude;

      $.ajax({
        method: "GET",
        dataType: "json",
        traditional: true,
        data: {},
        xhrFields: {
          withCredentials: true,
        },
        url: `${process.env.REACT_APP_API}/provinces?Latitude=${latitude}&Longitude=${longitude}`,

        headers: header,
      })
        .then((response) => {
          vm.setState({
            location: response.data.items[0].name,
          });
          localStorage.setItem("searchProvince", response.data.items[0].id);
        })
        .catch(function (res) {
          console.log(res);
          vm.setState({
            loading: false,
          });
        });
    } else {
      localStorage.setItem("searchProvince", 1);
      vm.setState({
        location: "Alberta",
      });
    }
  };

  // get chat history

  getChatHistory(index) {
    const vm = this;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.haythere.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    connection.start().then(() => {
      connection
        .invoke("SubscribeChatRoom", this.state.activeChatRooms[index].roomId)
        .then((response) => {
          const chatMessages = response.data.latestMessages
            .reverse()
            .map((e) => {
              let from = "";
              if (e.author.userId === localStorage.getItem("userId")) {
                from = "me";
              } else {
                from = "sender";
              }
              return {
                from: from,
                message: e.body,
                created: e.createdAt,
              };
            });
          console.log({ chatMessages });
          vm.setState({
            chatHistory: chatMessages,
            selectedChatId: vm.state.activeChatRooms[index].roomId,
          });
        });
      connection.on("ReceiveMessageText", (res) => {
        let text = {
          from: "sender",
          message: res.body,
          created: res.createdAt,
        };

        connection
          .invoke("SubscribeChatRoom", this.state.activeChatRooms[index].roomId)
          .then((response) => {
            const chatMessages = response.data.latestMessages
              .reverse()
              .map((e) => {
                let from = "";
                if (e.author.userId === localStorage.getItem("userId")) {
                  from = "me";
                } else {
                  from = "sender";
                }
                return {
                  from: from,
                  message: e.body,
                  created: e.createdAt,
                };
              });
            vm.setState({
              chatHistory: chatMessages,
              selectedChatId: vm.state.activeChatRooms[index].roomId,
            });
          });
      });

      connection
        .invoke("MarkRoomRead", this.state.activeChatRooms[index].roomId)
        .then((response) => {})
        .then(function (res) {
          console.log(res);
          vm.getActiveRooms();
        });
    });
  }

  getFirstChatHistory() {
    const vm = this;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.haythere.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    connection.start().then(() => {
      connection
        .invoke("SubscribeChatRoom", this.state.roomId)
        .then((response) => {
          console.log(response);
          const chatMessages = response.data.latestMessages
            .reverse()
            .map((e) => {
              let from = "";
              if (e.author.userId == localStorage.getItem("userId")) {
                from = "me";
              } else {
                from = "sender";
              }
              return {
                from: from,
                message: e.body,
                created: e.createdAt,
              };
            });
          vm.setState({
            chatHistory: chatMessages,
          });
        });
    });
  }

  getActiveRooms = () => {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/chat/rooms/active",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log({ response });
        vm.setState({ loading: false, activeChatRooms: response.data.items });

        var notificationDot = vm.state.activeChatRooms;

        notificationDot.map((chats) => {
          if (chats.hasRead === false) {
            vm.setState({
              unReadMessage: true,
            });
          }
        });
      })
      .catch(function (res) {
        console.log(res);
        vm.setState({
          loading: false,
        });
      });
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.haythere.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    connection.start();
    connection.on("UpdateRoomListEntry", function (updateRoom) {
      var updatelastMessage = vm.state.activeChatRooms;

      updatelastMessage.map((chats) => {
        if (chats.roomId === updateRoom.roomId) {
          chats.lastMessage = updateRoom.lastMessage;
          if (chats.lastMessage !== null) {
            if (
              chats.lastMessage.author.userId !== localStorage.getItem("userId")
            ) {
              chats.hasRead = false;
            }
          }
        }
      });

      vm.setState({
        activeChatRooms: updatelastMessage,
      });

      console.log({ updatelastMessage });

      if (updateRoom.hasUnreadMessages) {
        vm.setState({
          //unReadMessageCount: vm.state.unReadMessageCount + updateRoom.unreadMessageCountDelta,
          unReadMessage: true,
          lastMessage: updateRoom.lastMessage,
        });
      }
    });
  };

  redirectToDashboard = () => {
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
  };
  deleteChatRoom = (index) => {
    const vm = this;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.haythere.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    connection.start().then(() => {
      connection
        .invoke("DeleteRoom", this.state.activeChatRooms[index].roomId)
        .then((response) => {
          vm.setState({ chatSelect: null });
          vm.getActiveRooms();
        });
    });
  };

  showPopup(type) {
    if (type === "notification") {
      this.setState({
        displayNotificationPopup: !this.state.displayNotificationPopup,
      });
    } else if (type === "message") {
      this.setState({ displayMessagePopup: !this.state.displayMessagePopup });
    } else if (type === "menu") {
      this.setState({
        displayMenuOptionsPopup: !this.state.displayMenuOptionsPopup,
      });
    } else if (type === "locations") {
      this.setState({
        displayMenuLocationsPopup: !this.state.displayMenuLocationsPopup,
      });
    } else if (type === "filters") {
      this.setState({
        displayMenuFiltersPopup: !this.state.displayMenuFiltersPopup,
      });
    } else if (type === "sorts") {
      this.setState({
        displayMenuSortsPopup: !this.state.displayMenuSortsPopup,
      });
    } else if (type === "date") {
      this.setState({
        displayMenuDatePickerPopup: !this.state.displayMenuDatePickerPopup,
      });
    }
  }

  toggle(type) {
    if (type === "login") {
      this.setState((prevState) => ({
        LoginModalOpen: !prevState.LoginModalOpen,
      }));
    }
    if (type === "signin") {
      this.setState((prevState) => ({
        SignUpModalOpen: !prevState.SignUpModalOpen,
      }));
    }

    if (type === "resetpass") {
      this.setState((prevState) => ({
        ResetModalOpen: !prevState.ResetModalOpen,
      }));
    }

    if (type === "tosmodal") {
      this.setState((prevState) => ({
        TosModalOpen: !prevState.TosModalOpen,
      }));
    }

    if (type === "newpost") {
      this.setState((prevState) => ({
        NewPostModalOpen: !prevState.NewPostModalOpen,
      }));
    }

    if (type === "verificationPhoneModal") {
      this.setState((prevState) => ({
        PhoneVerificationNumberModalOpen:
          !prevState.PhoneVerificationNumberModalOpen,
      }));
    }

    if (type == "verificationCodeModal") {
      this.setState((prevState) => ({
        PhoneVerificationCodeModalOpen:
          !prevState.PhoneVerificationCodeModalOpen,
      }));
    }

    if (type === "success") {
      this.setState((prevState) => ({
        SuccessAccountModalOPen: !prevState.SuccessAccountModalOPen,
      }));
    }

    if (type === "CheckoutForm") {
      this.setState((prevState) => ({
        CheckoutFormOpen: !prevState.CheckoutFormOpen,
      }));
    }
    if (type === "SuccessEmailReset") {
      this.setState((prevState) => ({
        SuccessEmailResetOpen: !prevState.SuccessEmailResetOpen,
      }));
    }
  }

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
  }

  onCheckboxBtnClick(selected) {
    const index = this.state.cSelected.indexOf(selected);
    if (index < 0) {
      this.state.cSelected.push(selected);
    } else {
      this.state.cSelected.splice(index, 1);
    }
    this.setState({ cSelected: [...this.state.cSelected] });
  }
  setRegisterData(data) {
    this.setState({ registerData: data });
    // console.log(data);
  }

  logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    //this.props.history.push('/dashboard')
    window.location.replace("/");
  };

  addSOrttolocalstorage = () => {
    localStorage.setItem("sortId", this.state.rSelected);
    this.showPopup("sorts");
    this.setState({ redirect: true });
  };

  addFiltertolocalstorage = () => {
    localStorage.setItem("filterId", JSON.stringify(this.state.cSelected));
    this.showPopup("filters");
    this.setState({ redirect: true });
  };

  getDateValues = (start, end) => {
    this.setState(
      {
        startDate: moment.utc(start._d).format(),
        endDate: moment.utc(end._d).format(),
      },
      () => {
        localStorage.setItem("searchStartDate", this.state.startDate);
        localStorage.setItem("searchEndDate", this.state.endDate);
      }
    );
    this.showPopup("date");
    this.setState({ redirect: true });
  };

  setlocation = (item) => {
    // localStorage.setItem("searchCity", item.id);
    localStorage.setItem("searchProvince", item.id);
    this.showPopup("locations");
    this.setState({ redirect: true, location: item.name });
  };

  _onMessageWasSent = (message) => {
    this.setState({
      messageList: [...this.state.messageList, message],
    });
  };

  _sendMessage = (message) => {
    const vm = this;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.haythere.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    connection.start().then(() => {
      connection
        .invoke("SendMessageText", this.state.selectedChatId, message.data.text)
        .catch(function (err) {
          return console.error(err.toString());
        })
        .then(function () {
          vm.getChatHistory(vm.state.chatSelect);
        });
    });
    if (message.data.text.length > 0) {
      let index = this.state.chatSelect;
    }
  };

  _sendFirstMessage(message) {
    const vm = this;
    console.log(message);
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.haythere.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    connection.start().then(() => {
      connection
        .invoke("SendMessageText", this.state.roomId, message.data.text)
        .catch(function (err) {
          console.log("sent message");
          return console.error(err.toString());
        })
        .then(function () {
          vm.getFirstChatHistory();
        });
    });
    if (message.data.text.length > 0) {
      let index = this.state.chatSelect;
      console.log("after message");
      console.log(message);
    }
  }

  playIncomingMessageSound = () => {
    var audio = new Audio(incomingMessageSound);
    audio.play();
  };

  onResetBtnClick = (type) => {
    if (type === "filter") {
      this.setState({ cSelected: [], redirect: true });
      localStorage.removeItem("filterId");
    } else if (type === "sort") {
      this.setState({ rSelected: [], redirect: true });
      localStorage.removeItem("sortId");
    } else {
      localStorage.removeItem("sortId");
      localStorage.removeItem("filterId");
      localStorage.removeItem("searchStartDate");
      localStorage.removeItem("searchEndDate");
      this.setState({ redirect: true });
      this.setState(
        {
          cSelected: [],
          rSelected: [],
          endDate: null,
          startDate: null,
        },
        () => {
          this.setState({ redirect: true });
        }
      );
    }
  };
  handleClick(index) {
    let vm = this;

    vm.setState({
      userDisplayName: null,
      roomId: null,
    });
    var updatelastMessage = vm.state.activeChatRooms;
    updatelastMessage[index].hasRead = true;

    console.log("updatelastMessage", updatelastMessage);
    $.when(this.getChatHistory(index)).done(function () {
      if (vm.props.handleClick !== undefined) {
        vm.props.handleClick();
      } else {
        var updatelastMessage = vm.state.activeChatRooms;
        updatelastMessage[index].hasRead = true;
        vm.setState({
          activeChatRooms: updatelastMessage,
          isOpen: true,
          chatSelect: index,
          unReadMessage: false,
        });
      }
    });
  }

  closeClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  getUnreadNotificationCount = () => {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/notifications/getunread",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      vm.setState({
        count: res.data.totalItems,
      });
    });
  };

  onConfirm = (e, index) => {
    e.stopPropagation();

    confirmAlert({
      title: "Are you sure to delete this conversation?",
      //message: 'Are you sure to do this.',
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteChatRoom(index),
        },
        {
          label: "No",
          //onClick: () => window.location.reload()
        },
      ],
    });
  };

  // handleStateChange = () => {
  //   // const value = e.target.value;
  //   // this.setState({ searchKeyWord: value });
  //   this.props.handleSearch(this.state.searchKeyWord); // Call the callback function in the parent
  //   console.log("this.props handleStateChange", this.props);
  // };

  handleStateChange = (e) => {
    const value = e.target.value;
    this.setState({ searchKeyWord: value });
    this.props.handleSearch(value); // Call the callback function in the parent
  };

  // create a chat
  createRoom = (userIdFromOutside) => {
    const vm = this;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.haythere.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => this.state.accessToken,
      })
      .build();
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/chat/rooms/user/" + userIdFromOutside,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      console.log("create result!!!", res.data);
      function findingIndex(each) {
        return each.roomId === res.data.roomId;
      }
      console.log("this.state.activeChatRooms", vm.state.activeChatRooms);

      var foundIndex = vm.state.activeChatRooms.findIndex(findingIndex);

      console.log("foundIndexfoundIndex", foundIndex);
      if (foundIndex >= 0) {
        vm.handleClick(foundIndex);
        vm.props.handler(null);

        return;
      }
      vm.props.handler(null);
      // need to work from here!
      vm.setState({
        userDisplayName: res.data.userDisplayName,
        roomId: res.data.roomId,
        isOpen: true,
      });

      connection.start().then(() => {
        connection
          .invoke("SubscribeChatRoom", res.data.roomId)
          .then((response) => {
            console.log(response);
            const chatMessages = response.data.latestMessages
              .reverse()
              .map((e) => {
                let from = "";
                if (e.author.userId == localStorage.getItem("userId")) {
                  from = "me";
                } else {
                  from = "sender";
                }
                return {
                  from: from,
                  message: e.body,
                  created: e.createdAt,
                };
              });
            vm.setState({
              chatHistory: chatMessages,
              isOpen: true,
            });
          });
        connection
          .invoke("MarkRoomRead", res.data.roomId)
          .then((response) => {})
          .catch(function (err) {
            return console.error(err.toString());
          });
      });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    console.log("prevPropsprevProps come", prevProps);
    console.log("prevStateprevState", prevState);
    console.log("now!!!!", this.props.outsideChatUserId);
    // outsideChatUserId;
    if (
      this.props.outsideChatUserId !== null &&
      prevProps.outsideChatUserId !== this.props.outsideChatUserId
    ) {
      this.createRoom(this.props.outsideChatUserId);
      return;
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log("coming?");
  //   console.log("prevState", prevState);
  //   if (
  //     this.state.outsideChatUserId !== null &&
  //     prevState.outsideChatUserId !== this.state.outsideChatUserId
  //   ) {
  //     this.createRoom(this.state.outsideChatUserId);
  //     return;
  //   }
  // }

  //TODO: filter popup, Date pop up, location pop up (setstate: search params)

  //TODO: notifications popup, display all notifications relative to user, on click flag notification as read, and bring user to relative notification(if message pop up relative message, if contract changed bring to contract, etc.)

  //TODO: messages popup,Display all Messages, on click pop up realtive message
  render() {
    console.log("outsideChatUserId", this.state.outsideChatUserId);

    const isOpen = this.props.hasOwnProperty("isOpen")
      ? this.props.isOpen
      : this.state.isOpen;
    // console.log("this.props.authenticated", this.props.authenticated);
    // console.log("authenticated header", this.props.authenticated);
    // console.log("registerData", this.state.registerData);
    // console.log("searchKeyWord header", this.state.searchKeyWord);
    // console.log("this.props render", this.props);

    console.log("this.state.roomId", this.state.roomId);
    console.log("this.state.userDisplayName", this.state.userDisplayName);
    return (
      <div>
        {this.redirectToDashboard}

        <LoginModal
          forceLogin={this.state.forceLogin}
          loginData={this.state.loginData}
          setLoginData={this.setLoginData}
          toggle={this.toggle}
          modalOpen={this.state.LoginModalOpen}
        />
        <SignUpModal
          setRegisterData={this.setRegisterData}
          toggle={this.toggle}
          modalOpen={this.state.SignUpModalOpen}
        />
        <ResetPassModal
          toggle={this.toggle}
          modalOpen={this.state.ResetModalOpen}
        />
        <TosModal toggle={this.toggle} modalOpen={this.state.TosModalOpen} />
        <PhoneVerificationNumberModal
          registerData={this.state.registerData}
          setRegisterData={this.setRegisterData}
          toggle={this.toggle}
          modalOpen={this.state.PhoneVerificationNumberModalOpen}
        />
        <PhoneVerificationCodeModal
          registerData={this.state.registerData}
          setRegisterData={this.setRegisterData}
          toggle={this.toggle}
          modalOpen={this.state.PhoneVerificationCodeModalOpen}
        />
        <NewPostModal
          toggle={this.toggle}
          modalOpen={this.state.NewPostModalOpen}
        />
        <SuccessAccountModal
          toggle={this.toggle}
          modalOpen={this.state.SuccessAccountModalOPen}
        />
        <SuccessEmailReset
          toggle={this.toggle}
          modalOpen={this.state.SuccessEmailResetOpen}
        />

        {/* <div className=""> */}
        <Navbar
          className="navbar my-navbar-fixed p-0"
          fixed={!window.location.pathname.includes("dashboard") ? "top" : ""}
          // fixed="top"
        >
          {/* Upper Nav */}

          <div
            className="upper-nav d-flex flex-row justify-content-between align-items-end"
            style={{ padding: "1em 3em" }}
          >
            <div className="d-flex flex-row align-items-end">
              {/* LOGO */}
              <NavLink className="mr-4 " to="/dashboard" onClick={this.test}>
                <img
                  src={require("../images/HAYThere-logo.svg")}
                  alt="Hay There"
                  width={220}
                />
              </NavLink>

              {window.location.pathname.includes("dashboard") && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    document.getElementById("TestSubmit").click();
                  }}
                  className="banner-search-bar pl-4"
                  style={{ borderLeft: "1px solid #E2E2E2" }}
                >
                  <InputGroup className="form-search has-search">
                    <InputGroupAddon addonType="prepend">
                      <div className="form-control-feedback ">
                        <img
                          src={require("../images/newicons/search-icon.svg")}
                          alt="search"
                          width={20}
                        />
                      </div>
                    </InputGroupAddon>
                    <input
                      // onChange={(e) =>
                      //   this.setState({ searchKeyWord: e.target.value })
                      // }
                      onChange={this.handleStateChange}
                      style={{
                        border: "1px solid #efefef",
                        boxShadow: "none",
                        borderRadius: "8px",
                        fontSize: 25,
                        marginRight: 20,
                      }}
                      type="text"
                      className="form-control mainsearchinput"
                      value={this.state.searchKeyWord}
                      placeholder="Quick search for product title"
                    />
                    <Link
                      id="TestSubmit"
                      className="search-link"
                      onClick={() => this.setState({ searchKeyWord: "" })}
                      to={{
                        pathname: "/dashboard",
                        state: {
                          searchWord: this.state.searchKeyWord,
                        },
                      }}
                    >
                      {/* <button
                      style={{ height: "100%" }}
                      type="button"
                      className="btn-hay-yellow"
                      onClick={this.handleStateChange}
                    >
                      Search
                    </button> */}
                    </Link>
                  </InputGroup>
                </form>
              )}
            </div>
            {/* Nav Icons/User */}
            {this.props.authenticated ? (
              // If Authenticated show nav icons for user
              <div
                style={{
                  float: "right",
                  borderLeft: "1px solid #D9D9D9",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-evenly",
                }}
              >
                <Link
                  to={{ pathname: "/posts" }}
                  className="btn-white mx-4"
                  style={{ fontSize: 18 }}
                  // onClick={() => this.setState({ filtersModalOpen: true })}
                >
                  <img
                    src={require("../../Common/images/newicons/icon-list-dark.svg")}
                    alt="icon filter"
                    color="black"
                  />
                  Orders
                </Link>
                {/* <button
                  className="btn-white mx-4"
                  style={{ fontSize: 18 }}
                  // onClick={() => this.setState({ filtersModalOpen: true })}
                >
                  <img
                    src={require("../../Common/images/newicons/icon-list-dark.svg")}
                    alt="icon filter"
                    color="black"
                  />
                  Orders
                </button> */}
                <Link to={{ pathname: "/postcreate" }}>
                  <button className="btn-hay-yellow">
                    {" "}
                    <img
                      className="left-side-icon mr-2"
                      src={require("../../Common/images/newicons/plus.svg")}
                      alt="info"
                      color="#E7B10A"
                    />{" "}
                    New Post
                  </button>
                </Link>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ position: "relative" }}
                >
                  {/* <img
                    src={require("../../Common/images/newicons/chat-icon.svg")}
                    onClick={() =>
                      this.setState({
                        displayChatPopup: !this.state.displayChatPopup,
                      })
                    }
                    alt="icon chat"
                    className="mx-4"
                    width={33}
                  /> */}
                  {/* {this.state.displayChatPopup && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        zIndex: 1000,
                        position: "absolute",
                        right: "20px",
                        top: "80px",
                        backgroundColor: "white",
                        padding: "16px",
                        borderRadius: "10px",
                        overflow: "hidden",
                        width: "400px",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          color: "#ffcd46",
                        }}
                      >
                        Chats
                      </div>
                      <hr />
                      {this.chatHistory !== undefined && (
                        <ChatPopup
                          chatHistory={this.state.chatHistory}
                          handleClick={(index) => this.handleClick(index)}
                        >
                          {this.chatHistory.length}
                        </ChatPopup>
                      )}
                    </div>
                  )} */}
                  <div style={{ float: "right" }}>
                    <div
                      className="navLink"
                      style={{ position: "relative", display: "contents" }}
                    >
                      <Dropdown
                        className="displayContents"
                        style={{ position: "relative" }}
                        isOpen={this.state.displayMessagePopup}
                        toggle={() => this.showPopup("message")}
                      >
                        <DropdownToggle
                          onClick={() => this.showPopup("message")}
                          data-toggle="dropdown"
                          aria-expanded={this.state.displayMessagePopup}
                        >
                          <img
                            src={require("../../Common/images/newicons/chat-icon.svg")}
                            alt="message"
                            className="mx-4"
                            width={33}
                            onClick={() => this.getActiveRooms()}
                          />
                          {this.state.unReadMessage === true ? (
                            <span
                              style={{ width: "25px", height: "25px" }}
                              className="notification-circle"
                            >
                              !{/* {this.state.unReadMessageCount} */}
                            </span>
                          ) : null}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{
                            width: "400px",
                            posotion: "absolute",
                            right: "50px",
                          }}
                          className="nav-dropdown"
                        >
                          <p
                            style={{ textAlign: "center" }}
                            className="dropdown-title"
                          >
                            Messages
                          </p>
                          <hr />
                          <div
                            style={{ textAlign: "center" }}
                            className="sweet-loading"
                          >
                            <BallBeat
                              color={"#ffcf4e"}
                              loading={this.state.loading}
                            />
                          </div>
                          {this.state.activeChatRooms.map((message, i) => {
                            return (
                              <div key={i}>
                                {/* onClick={this.handleChatPopUp.bind(this)} */}
                                <Row
                                  className="notification-row"
                                  onClick={() => this.handleClick(i)}
                                  //onClick={() => this.showPopup("message")}
                                >
                                  <Col sm="2">
                                    <div className="profile-img">
                                      <img
                                        className="img-circle"
                                        src={message.profileImagePath}
                                        alt="user-profile"
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    style={{
                                      paddingLeft: "35px",
                                    }}
                                    sm="6"
                                  >
                                    <Row
                                      style={
                                        message.hasRead
                                          ? { color: "grey" }
                                          : { color: "#8AC53F" }
                                      }
                                    >
                                      {message.userDisplayName}
                                    </Row>
                                    <Row
                                      style={{
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverlow: "ellipsis",
                                      }}
                                    >
                                      {message.lastMessage
                                        ? message.lastMessage.body
                                        : "Thanks for booking!"}
                                    </Row>
                                  </Col>
                                  <Col className="row-flex" sm="4">
                                    <Row>
                                      <img
                                        style={{ zIndex: 10 }}
                                        onClick={(e) => this.onConfirm(e, i)}
                                        src={require("./Images/trash.svg")}
                                        alt="Trash Can"
                                      />
                                    </Row>
                                    <Row
                                      style={{
                                        color: "#AAAAAA",
                                        textAlign: "center",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <FormattedDateTime
                                        datetime={
                                          message.lastMessage
                                            ? message.lastMessage.createdAt
                                            : null
                                        }
                                        ago
                                      />
                                    </Row>
                                  </Col>
                                </Row>
                                <hr />
                              </div>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                  {/* <div
                    onClick={() => this.showPopup("notification")}
                    style={{ position: "relative", cursor: "pointer" }}
                  >
                    <img
                      src={require("../../Common/images/newicons/bell-icon.svg")}
                      alt="icon notifications"
                      className="mx-4 "
                      width={33}
                    />

                    <span
                      style={{ width: "25px", height: "25px" }}
                      className="notification-circle"
                    >
                      {this.state.count}
                    </span>
                  </div> */}
                  {/* {this.state.displayNotificationPopup && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        zIndex: 1000,
                        position: "absolute",
                        right: "20px",
                        top: "80px",
                        backgroundColor: "white",
                        padding: "16px",
                        borderRadius: "10px",
                        overflow: "hidden",
                        width: "400px",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          color: "#ffcd46",
                        }}
                      >
                        Notifications
                      </div>
                      <hr />
                      <NotificationPopup
                        getUnreadNotification={() =>
                          this.getUnreadNotificationCount()
                        }
                      >
                        {this.state.count}
                      </NotificationPopup>
                    </div>
                  )} */}
                  <div style={{ float: "right" }}>
                    <div
                      className="navLink"
                      style={{ position: "relative", display: "contents" }}
                    >
                      <Dropdown
                        className="displayContents"
                        style={{ position: "relative" }}
                        isOpen={this.state.displayNotificationPopup}
                        toggle={() => this.showPopup("notification")}
                      >
                        <DropdownToggle
                          onClick={() => this.showPopup("notification")}
                          data-toggle="dropdown"
                          aria-expanded={this.state.displayNotificationPopup}
                        >
                          <img
                            src={require("../../Common/images/newicons/bell-icon.svg")}
                            alt="notification"
                            width={33}
                          />
                          <span
                            style={{
                              width: "25px",
                              height: "25px",
                              marginLeft: "-8px",
                            }}
                            className="notification-circle"
                          >
                            {this.state.count}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu
                          style={{
                            position: "absolute",
                            width: "400px",
                            right: "50px",
                            top: "100px",
                          }}
                          className="nav-dropdown"
                          positionFixed={true}
                        >
                          <p
                            style={{ textAlign: "center" }}
                            className="dropdown-title"
                          >
                            Notifications
                          </p>

                          <hr />
                          <NotificationPopup
                            getUnreadNotification={() =>
                              this.getUnreadNotificationCount()
                            }
                          >
                            {this.state.count}
                          </NotificationPopup>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                  {/* <img
                    src={require("../../Common/images/Avatar.png")}
                    alt="icon notifications"
                    className="ml-4"
                    style={{ objectFit: "cover", width: 50, height: 50 }}
                  /> */}
                  <Dropdown
                    className="displayContents"
                    style={{ position: "relative", padding: 0 }}
                    isOpen={this.state.displayMenuOptionsPopup}
                    toggle={() => this.showPopup("menu")}
                  >
                    <DropdownToggle
                      className=""
                      onClick={() => this.showPopup("menu")}
                      data-toggle="dropdown"
                      aria-expanded={this.state.displayMenuOptionsPopup}
                      style={{ padding: 0 }}
                    >
                      <div>
                        <div className="">
                          {/* <img
                            className="img-circle"
                            src={
                              localStorage.getItem("profileImage")
                                ? localStorage.getItem("profileImage")
                                : this.props.profileImage
                            }
                            alt="user_icon"
                          /> */}
                          <img
                            src={
                              this.props.profileImage ||
                              require("../../Common/images/Avatar.png")
                            }
                            alt="Avatar"
                            className="ml-4"
                            style={{
                              objectFit: "cover",
                              width: 50,
                              height: 50,
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      </div>
                      {/* <span className="green-txt">{this.props.userName} </span> */}
                      {/* <FontAwesomeIcon
                        className="green-txt pl-1"
                        icon={faChevronDown}
                      /> */}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-container">
                      <NavLink
                        onClick={() => this.showPopup("menu")}
                        className="dropdown-option"
                        to={`/myprofile`}
                      >
                        Profile
                      </NavLink>
                      <hr className="" />
                      <NavLink
                        className="dropdown-option"
                        onClick={() => this.showPopup("menu")}
                        to="/settings"
                      >
                        Settings
                      </NavLink>
                      <hr className="" />
                      <NavLink
                        to={`/dashboard`}
                        style={{ cursor: "pointer" }}
                        className="dropdown-option"
                        onClick={(e) => this.logout(e)}
                      >
                        Logout
                      </NavLink>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            ) : (
              <div className="d-flex ml-auto">
                <button
                  size="lg"
                  onClick={() => this.toggle("login")}
                  style={{ background: "none" }}
                  // className="green-txt btn-no-bg login-button"
                  className="btn-white mx-4"
                >
                  Login
                </button>
                <button
                  onClick={() => this.toggle("signin")}
                  className="btn-hay-yellow"
                  size="lg"
                >
                  Sign Up
                </button>
              </div>
            )}
          </div>

          {/* {window.location.pathname.includes("dashboard") ||
          window.location.pathname.includes("profile") ||
          window.location.pathname.includes("rentals") ? (
            <div className="" style={{ width: "100%" }}>
              <hr className="hr-nav" />
            </div>
          ) : null} */}
        </Navbar>
        {/* </div> */}
        {this.state.chatSelect !== null && (
          <ChatWindow
            // messageList={this.state.chatHistory[this.state.chatSelect].chatHistory}
            messageList={this.state.chatHistory}
            onUserInputSubmit={this._sendMessage.bind(this)}
            userProfile={
              this.state.activeChatRooms[this.state.chatSelect].userDisplayName
            }
            isOpen={isOpen}
            onClose={this.closeClick.bind(this)}
            imageUrl={
              this.state.activeChatRooms[this.state.chatSelect].profileImagePath
            }
          />
        )}

        {this.state.roomId !== null && this.state.userDisplayName && (
          <ChatWindow
            messageList={this.state.chatHistory}
            onUserInputSubmit={this._sendFirstMessage.bind(this)}
            userProfile={this.state.userDisplayName}
            isOpen={isOpen}
            onClose={this.closeClick.bind(this)}
          />
        )}

        {/* <NotificationPopup></NotificationPopup> */}
        {/* {this.chatHistory} */}
        {/* <Stripe></Stripe> */}
      </div>
    );
  }
}

Header.propTypes = {
  authenticated: PropTypes.bool,
  userName: PropTypes.string,
  userLocation: PropTypes.string,
  userId: PropTypes.string,
};

export default Header;
