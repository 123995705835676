import React from 'react';
import { getCurrentDate } from '../TimeStamp/index'
import FormattedDateTime from '../../FormattedDateTime'

const TextMessage = (props) => {
  return (
    <div>
      <div className="sc-message--text">{
        <div>{props.message}</div>
      }
      </div>
      <div className="timeStamp"><FormattedDateTime datetime={props.time} ago /></div>
    </div>

  )
};

export default TextMessage;
