import React from "react";
import DateTime from 'react-datetime';
import moment from "moment";



import './dateTime.css';

var yesterday = DateTime.moment().subtract(1, 'day');
var valid = function( current ){
   return current.isAfter( yesterday );
};


export default class DateTimePicker extends React.Component {
  render() {
      return (
        <div>
          <DateTime dateFormat="YYYY-MM-DD"
            inputProps={{ placeholder: (' Pick Date and Time.')}}
            value={this.props.value}
            // viewMode='months'
            // dateFormat='MMMM'
            // isValidDate={current => current.isBefore(DateTime.moment().startOf('month'))}
            input={ true } 
            isValidDate={ valid }
            onChange={(e) => this.props.onChange(e)}
          />
        </div>
      )
    
  }
}


// export default class DateTimePicker extends React.Component {
//   render() {
//     if(this.props.type == 'date'){
//       return (
//         <div>
//           <DateTime utc="true" timeFormat={false} dateFormat="YYYY-MM-DD"
//             inputProps={{ placeholder: ('Please select date.')}}
//             value={this.props.value}
//             // viewMode='months'
//             // dateFormat='MMMM'
//             // isValidDate={current => current.isBefore(DateTime.moment().startOf('month'))}
//             input={ true } 
//             isValidDate={ valid }
//             onChange={(e) => this.props.onChange(e)}
//           />
//         </div>
//       )
//     }else{
//       return (
//         <div>
//           <DateTime dateFormat={false}
//             inputProps={{ placeholder: ('Please select Time.')}}
//             defaultValue={this.props.value}
//             // viewMode='months'
//             // dateFormat='MMMM'
//             // isValidDate={current => current.isBefore(DateTime.moment().startOf('month'))}
//             onChange={(e) => this.props.onChange(e)}
//           />
//         </div>
//       )
//     }
//   }
// }

