import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  CardHeader,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import "./user-rating.css";

class UserRating extends React.Component {
  constructor() {
    super();

    this.state = {
      // items: [],
      userRatingDetails: [
        {
          userRatingScore: 0,
        },
      ],

      // items: [{
      //   imageUrl: require('./Images/michelle.jpg'),
      //   userRating: 5,
      //   userName: 'Roger Christians',
      //   userId: 4,
      //   description: 'My first impressions of the 457 loader were of a nice shape, a good balance in wheelbase, counterweight and bucket configuration, plenty of glass … in the very crowded JCB yard it stood out like a lighthouse beacon on a dark and stormy night. And I hadn’t even climbed into the cabin yet!',
      // }, {
      //   imageUrl: 'https://cdn.pixabay.com/photo/2017/10/17/05/31/cheetah-2859581__340.jpg',
      //   userRating: 4,
      //   userName: 'Sarah Li',
      //   userId: 7,
      //   description: 'My first impressions of the 457 loader were of a nice shape, a good balance in wheelbase, counterweight and bucket configuration, plenty of glass … in the very crowded JCB yard it stood out like a lighthouse beacon on a dark and stormy night. And I hadn’t even climbed into the cabin yet!',
      // }]
    };
  }

  componentDidMount() {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },

      url: `${process.env.REACT_APP_API}/equipments/${this.props.equipmentId}`,

      headers: {
        // Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      let userRatingObj = res.data.ratings.map((userRatingElement) => {
        return {
          userRatingImg: userRatingElement.ratedBy.profileImage,
          userRatingScore: userRatingElement.score,
          // userRatingScore: 3,
          userRatingName:
            userRatingElement.ratedBy.firstName +
            " " +
            userRatingElement.ratedBy.lastName,
          userRatingDescriprion: userRatingElement.description,
          // userRatingDescriprion: "userRatingElement.description",
        };
      });

      vm.setState({ userRatingDetails: userRatingObj });
    });
  }

  render() {
    if (this.state.userRatingDetails.length === 0) {
      return <div>There is no reviews for this item yet.</div>;
    } else
      return (
        <Row className="user-cards-ratingss">
          <Col sm="12">
            {this.state.userRatingDetails.map((userRatingElement, i) => {
              let Rating = () => {
                let stars = [];
                for (let i = 0; i < userRatingElement.userRatingScore; i++) {
                  stars.push(
                    <FontAwesomeIcon
                      icon={faStar}
                      className="checked fa-star"
                    />
                  );
                }
                return stars;
              };

              return (
                <Card sm="12" className="user-review-card py-3 px-0">
                  <CardHeader
                    sm="12"
                    className="card-review-header d-flex flex-row align-items-center">
                    <div className="card-review-img col-sm-1 d-flex justify-content-center align-items-center">
                      <img
                        width="100%"
                        src={userRatingElement.userRatingImg}
                        alt="item img"
                      />
                    </div>
                    <div className="col-sm-11">
                      <div className="green-txt">
                        <Rating />
                      </div>
                      <div className="card-review-user">
                        {userRatingElement.userRatingName}
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="text-muted dashboard-card-body">
                    <CardText className="dashboard-card-text">
                      <div>{userRatingElement.userRatingDescriprion}</div>
                    </CardText>
                  </CardBody>
                </Card>
              );
            })}
          </Col>
        </Row>
      );
  }
}

UserRating.propTypes = {
  userRatingImageUrl: PropTypes.string,
  userRatingName: PropTypes.string,
  userRatingRating: PropTypes.number,
  userRatingUserId: PropTypes.number,
  userRatingDescription: PropTypes.number,
};

export default UserRating;
