export const Data = {
  data: {
    id: "1",

    category: {
      id: 1,
      name: "Search",
      description: "Category description goes here.",
      imagePath: "./Bale.png",
    },
    equipmentMedias: [
      {
        filePath: "../../Common/images/field-bale.jpg",
        type: 0,
        default: true,
      },
    ],
    ratings: [
      {
        createdAt: "2021-01-12T20:47:35.39781",
        description: "This is the comment content",
        id: "a765bd50-648f-42e8-a10e-9a94f260b473",
        ratedBy: {
          id: "663729dc-f125-4d09-90c4-c2ec0d2f046c",
          firstName: "M",
          lastName: "S",
        },
        city: null,
        companyName: null,
        firstName: "M",
        id: "663729dc-f125-4d09-90c4-c2ec0d2f046c",
        lastName: "S",
        profileImage:
          "https://haythere-storage-staging.s3.amazonaws.com/photos/c33476dd-f964-41bb-8a41-837998d054973d4de953-160c-4a24-a6f9-ee6a7d8d78b0.jpg",
        // score: 0,
        score: 5,
        updatedAt: "2021-01-12T20:47:35.397833",
      },
    ],
    score: 5,
    user: {
      agreement: null,
      id: "71a39bc6-479c-4eb9-80c9-baa9b14288d2",
      firstName: "Andrew",
      lastName: "Smith",
      profileImage: "",
      companyName: "Schmidt Assets Ltd",
      score: 0.0,
      city: {
        id: 50,
        name: "Edson",
        provinceCode: "AB",
        latitude: 53.585078,
        longitude: -116.433688,
        createdAt: "2019-12-12T22:21:01.011818",
        updatedAt: "2020-03-27T16:51:57.21881",
      },
    },
    city: {
      id: 73,
      name: "Niton Junction",
      provinceCode: "AB",
      latitude: 53.605671,
      longitude: -115.574078,
      createdAt: "2021-01-23T16:10:31.504264",
      updatedAt: "2021-01-23T16:10:31.50431",
    },
    createdAt: "2021-02-14T13:27:23.406949",
    updatedAt: "2021-02-14T13:27:23.406954",
    deletedAt: null,
    deactivedAt: null,
    allowsCancelling: true,
    isAddOn: true,
    canBeRentedAsStandAlone: true,
    name: "Hay Bale",
    make: "Hay Bale",
    model: "Alfalfa",
    type: "Hay",
    description:
      "The description goes here in this line and others if necessary.",
    specifications: null,
    axles: 0,
    weight: 1.2,
    length: 0.0,
    year: 2023,
    sleeps: 0,
    dailyRate: 100.0,
    weeklyRate: 65.0,
    comments: "",
    startDate: "2021-02-14T00:00:00",
    startTime: "01:00:00",
    endDate: "2024-01-31T00:00:00",
    endTime: "10:00:00",
    isOpenEndedRental: true,
    isPickupAvailable: true,
    pickupLocationAddress:
      "Yellowhead Hwy, Niton Junction, AB T0E 1S0, Canada\n",
    pickupLocationLatitude: 53.612845704260145,
    pickupLocationLongitude: -115.57157322764397,
    isDeliveryAvailable: true,
    deliveryLocationAddress:
      "Yellowhead Hwy, Niton Junction, AB T0E 1S0, Canada\n",
    deliveryLocationLatitude: 53.613250063336167,
    deliveryLocationLongitude: -115.57191152125597,
    deliveryRateFirst10Km: 50.0,
    deliveryRateExtraPerKm: 1.0,
  },
};
