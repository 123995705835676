import React from 'react';
import PropTypes from 'prop-types';
import {  } from '@fortawesome/free-brands-svg-icons'
import $ from 'jquery';
import './style.css';

class TermsOfService  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      termsOfService: '',
     
    };
   
  }

  componentDidMount(){
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: 'json',
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true
     },
       url: process.env.REACT_APP_API+"/settings/termsofservice",

    })
    .then(function(res){
      console.log("******** Terms of service ************");
      console.log(res.data);
      let finishedTXT = res.data.content.replace(/\n/g, "<br>");
      vm.setState({ termsOfService: finishedTXT })
       })
  }


  render() {
    
    return (
      
          <div  dangerouslySetInnerHTML={{__html: this.state.termsOfService}}></div>
      
    );
  }
}


export default TermsOfService;