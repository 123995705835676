import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
} from "reactstrap";
import PropTypes from "prop-types";
import TermsOfService from "./TermsOfService.js";

import {} from "@fortawesome/free-brands-svg-icons";
import $ from "jquery";

import "./style.css";

class TosModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      termsOfService: "",
      privacyPolicy: "",
    };
  }

  // changetoLogin() {
  //   this.props.toggle('signin');
  //   this.props.toggle('login');
  // }

  changeToVerificationPhone = () => {
    this.props.toggle("tosmodal");
    this.props.toggle("verificationPhoneModal");
  };

  componentDidMount() {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/settings/privacypolicy",
    }).then(function (res) {
      let finishedTXT = res.data.content.replace(/\n/g, "<br>");
      vm.setState({ privacyPolicy: finishedTXT });
    });
  }

  render() {
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("tosmodal")}>
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="signup-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("tosmodal")}
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#FFCD46", paddingLeft: "40px" }}
          >
            Terms Of Service & Privacy Policy
          </ModalHeader>
          <ModalBody style={{ maxHeight: 600, overflowY: "auto" }}>
            <Form>
              <FormGroup style={{ padding: 15 }} row>
                <TermsOfService />

                <div
                  dangerouslySetInnerHTML={{ __html: this.state.privacyPolicy }}
                ></div>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter className="py=4">
            <div row className="text-center pt-2 pb-4">
              <button
                className="btn-hay-yellow ml-auto"
                style={{ width: "100%" }}
                onClick={this.changeToVerificationPhone}
                size="lg"
                block
              >
                Accept
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

TosModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default TosModal;
