import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import {
  Button,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

const FilterType = ({ category, isOpen, toggle, children }) => {
  return (
    <div className="filter-type">
      <div
        onClick={toggle}
        style={{ marginBottom: "1rem" }}
        className="filter-toggle"
      >
        <span className="filter-category">{category}</span>
        <div style={{ marginLeft: "auto" }} className="toggle-btn">
          {isOpen ? (
            <FontAwesomeIcon className="p-1" icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon className="p-1" icon={faChevronDown} />
          )}
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="filter-card">{children}</div>
      </Collapse>
    </div>
  );
};

const optionsHay = [
  { id: 1, label: "Grass" },
  { id: 2, label: "Grass/Alfalfa" },
  { id: 3, label: "Alfalfa" },
  { id: 4, label: "Second Cut" },
  { id: 5, label: "Third Cut" },
  { id: 6, label: "Haylage" },
  { id: 7, label: "Greenfeed" },
  { id: 8, label: "Other Hay" },
];

const optionsStraw = [
  { id: 9, label: "Barley" },
  { id: 10, label: "Wheat" },
  { id: 11, label: "Oats" },
  { id: 12, label: "Peas" },
  { id: 13, label: "Other Straw" },
];

const optionsGrain = [
  { id: 14, label: "Barley" },
  { id: 15, label: "Wheat" },
  { id: 16, label: "Oats" },
  { id: 17, label: "Peas" },
  { id: 18, label: "Other Feed" },
];

// const optionsGrain = [
//   { id: "barley", label: "Barley" },
//   { id: "wheat", label: "Wheat" },
//   { id: "oats", label: "Oats" },
//   { id: "peas", label: "Peas" },
//   { id: "other", label: "Other Feed" },
// ];

const optionsTrucking = [
  { id: "checkedOne", label: "Pick up only, loading included" },
  { id: "checkedTwo", label: "Pick up only, loading not included" },
  {
    id: "checkedThree",
    label: "Delivery to your location, unloading included",
  },
  {
    id: "checkedFour",
    label: "Delivery to your location, unloading not included",
  },
];

const Filters = ({
  modalOpen,
  toggle,
  onChildStateChange,
  onQuantityStateChange,
  onPricetateChange,
  onTruckingStateChange,
  onSellingStateChange,
  sellingChecked,
  lookingForChecked,
  onLookingForStateChange,
  quantity,
  submitFilters,
  parentFilterOption,
  parentQuantity,
  parentPrice,
  parentTrucking,
}) => {
  const [isOpenType, setIsOpenType] = useState(false);
  const [isOpenQuantity, setIsOpenQuantity] = useState(false);
  const [isOpenPrice, setIsOpenPrice] = useState(false);
  const [isOpenTrucking, setIsOpenTrucking] = useState(false);

  const [selectedOption, setSelectedOption] = useState();
  const [selectedGrain, setSelectedGrain] = useState();
  const [selectedTrucking, setSelectedTrucking] = useState("");
  const [bales, setBales] = useState(0);
  const [bushels, setBushels] = useState(0);
  const [tonnes, setTonnes] = useState(0);
  const [price, setPrice] = useState(0);

  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);
  const [checkedFour, setCheckedFour] = useState(false);

  const [filterId, setFilterId] = useState([0]);
  const [checkedOptions, setCheckedOptions] = useState([]);

  // const redirectToDashboard = () => {
  //   if (redirect) {
  //     return <Redirect to="/dashboard" />;
  //   }
  // };
  const grainConversionFactors = {
    14: 45.93, // barley
    15: 36.744, // wheat
    16: 64.842, // oats
    17: 36.744, // peas
    // barley: 0.0218,
    // wheat: 0.0272,
    // oats: 0.015,
    // peas: 0.027,
  };

  const calculateWeightInMetricTons = () => {
    const conversionFactor = grainConversionFactors[selectedGrain];
    // const weightInMetricTons = bushels * conversionFactor;
    const capacityInBushels = tonnes * conversionFactor;
    // setTonnes(weightInMetricTons.toFixed(2)); // Limiting the decimal places to 2
    setBushels(capacityInBushels.toFixed(1));
  };

  const toggleType = () => setIsOpenType(!isOpenType);
  const toggleQuantity = () => setIsOpenQuantity(!isOpenQuantity);
  const togglePrice = () => setIsOpenPrice(!isOpenPrice);
  const toggleTrucking = () => setIsOpenTrucking(!isOpenTrucking);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedGrain("");
    setBushels(0);
    setTonnes(0);
    console.log("handleOptionChange", event.target.value);
    setFilterId([+event.target.value]);
  };

  const handleOptionGrainChange = (event) => {
    setSelectedOption("");
    setSelectedGrain(event.target.value);
    setBales(0);
    setTonnes(0);
    setBushels(0);
    setFilterId([+event.target.value]);
  };

  const handleTruckingChange = (event) => {
    setSelectedTrucking(event.target.value);
  };

  useEffect(() => {
    if (parentFilterOption == 0) {
      setFilterId([0]);
      setSelectedOption("");
      setSelectedGrain("");
      setBales(0);
      setTonnes(0);
      setBushels(0);
    }
    if (parentQuantity == 0) {
      setBales(0);
      setTonnes(0);
      setBushels(0);
    }
    if (parentPrice == 0) {
      setPrice(0);
    }

    setCheckedOptions(parentTrucking);

    console.log("parentFilterOption", parentTrucking);
  }, [parentFilterOption, parentQuantity, parentPrice, parentTrucking]);

  // useEffect(() => {
  //   if (selectedGrain && selectedGrain === "other") {
  //     setBales(0)
  //     setBushels(0)
  //   }
  //   if (selectedOption) {
  //     setTonnes(0)
  //   }
  //   if (bushels > 0) {
  //     setBales(0)
  //     calculateWeightInMetricTons()
  //   }
  //   // if (bushels == 0 && selectedGrain !== "other") {
  //   //   setTonnes(0)
  //   // }
  // }, [
  //   selectedOption,
  //   selectedGrain,
  //   bales,
  //   bushels,
  //   tonnes,
  //   selectedTrucking,
  // ]);

  useEffect(() => {
    if (selectedGrain && selectedGrain === "other") {
      setBales(0);
      setBushels(0);
    }
    if (selectedOption) {
      setTonnes(0);
    }
    if (tonnes > 0) {
      setBales(0);
      calculateWeightInMetricTons();
    }
    // if (bushels == 0 && selectedGrain !== "other") {
    //   setTonnes(0)
    // }
  }, [selectedOption, selectedGrain, bales, bushels, tonnes, selectedTrucking]);

  const currencyFormatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  });
  const min = 0;
  const max = 1000000;

  const handleChange = (event, setFunction) => {
    const value = event.target.value;
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      setFunction((prevValue) => {
        const newValue = Math.max(min, Math.min(max, Number(value)));
        return newValue !== prevValue ? newValue : prevValue;
      });
    }
  };
  const handleTonnesChange = (event) => {
    console.log("event", event);
    const value = Math.max(min, Math.min(max, Number(event)));
    // const regex = /^\d+(\.\d{1,2})?$/;
    // const regex = /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/;
    const regex = /^(\d+(\.\d{1,2})?)$/;

    if (event === "" || regex.test(event)) {
      setTonnes(value);
    }
  };
  const handleFiltersChange = () => {
    onChildStateChange(filterId); // Call the callback function in the parent
    if (bales > 0) {
      onQuantityStateChange(bales);
    }
    if (tonnes > 0) {
      onQuantityStateChange(tonnes);
    }
    if (price > 0) {
      onPricetateChange(price);
    }

    handleTuckingChange();

    setTimeout(() => {
      submitFilters();
      toggle();
    }, 500);
  };

  const toggleTruckingOptions = (id) => {
    const newCheckedOptions = [...checkedOptions];
    const index = newCheckedOptions.findIndex((x) => x.id === id);
    if (index > -1) {
      newCheckedOptions.splice(index, 1);
    } else {
      newCheckedOptions.push({
        id,
        label: optionsTrucking.find((x) => x.id === id).label,
      });
    }
    setCheckedOptions(newCheckedOptions);
    console.log("newCheckedOptions", newCheckedOptions);
  };

  const handleTuckingChange = () => {
    onTruckingStateChange(checkedOptions);
  };

  console.log("selectedOption", selectedOption);
  console.log("selectedGrain", selectedGrain);
  // console.log("filterId", typeof filterId, filterId);
  // console.log("selectedTrucking", selectedTrucking);
  // console.log("tonnes", tonnes);
  // console.log("bales", bales);
  // console.log("bushels", bushels);
  // console.log("price", price);
  // console.log("checkedOne", checkedOne);
  // console.log("checkedTwo", checkedTwo);
  // console.log("checkedThree", checkedThree);
  // console.log("checkedFour", checkedFour);
  // console.log("looking For Checked***", lookingForChecked);
  // console.log("selling Checked***", sellingChecked);

  return (
    <div>
      <Modal isOpen={modalOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} className="filter-card">
          Filters
        </ModalHeader>
        <ModalBody>
          <Row className="d-flex option-row">
            <div className="form-check " style={{ marginRight: 50 }}>
              <label className="filter-radio-container">
                <input
                  type="checkbox"
                  name="options"
                  value={sellingChecked || ""}
                  onChange={(e) => {
                    onSellingStateChange(!sellingChecked);
                  }}
                  className="form-check-input radio-custom"
                  checked={sellingChecked}
                />
                <span className="text-lg px-2 mb-4"> Selling </span>
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="form-check">
              <label className="filter-radio-container">
                <input
                  type="checkbox"
                  name="options"
                  value={lookingForChecked || ""}
                  onChange={(e) => onLookingForStateChange(!lookingForChecked)}
                  className="form-check-input radio-custom"
                  checked={lookingForChecked}
                />
                <span className="text-lg px-2 mb-4">Looking for </span>
                <span className="checkmark"></span>
              </label>
            </div>
          </Row>
          <FilterType category="Types" isOpen={isOpenType} toggle={toggleType}>
            <Row className="d-flex option-row">
              <Col sm={12} md={4} className="">
                <h4 className="text-lg px-2 mb-4">
                  <img
                    src={require("../../images/newicons/black-bale.png")}
                    className="px-2"
                    width={45}
                  />{" "}
                  Hay Bales
                </h4>
                {optionsHay.map((option) => (
                  <div key={option.id} className="form-check">
                    <label className="container-custom-radio">
                      <input
                        type="radio"
                        name="options"
                        value={option.id || ""}
                        checked={filterId.includes(option.id)}
                        onChange={handleOptionChange}
                        className="form-check-input radio-custom"
                      />
                      <span className="option-label">{option.label}</span>
                      <span className="custom-checkmark"></span>
                    </label>
                  </div>
                ))}
              </Col>
              <Col sm={12} md={4}>
                <h4 className="text-lg px-2 mb-4">
                  <img
                    src={require("../../images/newicons/black-bale.png")}
                    className="px-2"
                    width={45}
                  />{" "}
                  Straw Bales
                </h4>
                {optionsStraw.map((option) => (
                  <div key={option.id} className="form-check">
                    <label className="container-custom-radio">
                      <input
                        type="radio"
                        name="options"
                        value={option.id || ""}
                        checked={filterId.includes(option.id)}
                        onChange={handleOptionChange}
                        className="form-check-input radio-custom"
                      />
                      <span className="option-label">{option.label}</span>
                      <span className="custom-checkmark"></span>
                    </label>
                  </div>
                ))}
              </Col>
              <Col sm={12} md={4} className="">
                <h4 className="text-lg px-2 mb-4">
                  <img
                    src={require("../../images/newicons/grain.svg")}
                    className="px-2"
                  />{" "}
                  Feed Grain
                </h4>
                {optionsGrain.map((option) => (
                  <div key={option.id} className="form-check">
                    <label className="container-custom-radio">
                      <input
                        type="radio"
                        name="options"
                        value={option.id || ""}
                        checked={filterId.includes(option.id)}
                        onChange={handleOptionGrainChange}
                        className="form-check-input radio-custom"
                      />
                      <span className="option-label">{option.label}</span>
                      <span className="custom-checkmark"></span>
                    </label>
                  </div>
                ))}
              </Col>
            </Row>
            {/* <Row className="d-flex option-row">
            </Row> */}
          </FilterType>
          <FilterType
            category="Quantity Available"
            isOpen={isOpenQuantity}
            toggle={toggleQuantity}
          >
            {filterId.includes(0) ? (
              "Please select a type before filtering by quantity"
            ) : (
              <Row className="d-flex option-row">
                <Col
                  className=""
                  style={{ display: selectedGrain ? "none" : "block" }}
                >
                  <h4 className="text-lg px-2 mb-4">Bales</h4>
                  <input
                    value={bales || ""}
                    // onChange={(e) => setBales(e.target.value)}
                    // onChange={(e) => handleChangeBales(e)}
                    onChange={(e) => handleChange(e, setBales)}
                    type="number"
                    step="1"
                    name="bales"
                    id="itemType"
                    className="input-style"
                    // placeholder="Bales"
                    disabled={selectedGrain}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    min={0}
                  />
                </Col>
                {/* <Col className="" style={{ display:  selectedOption || selectedGrain === "other" ? "none" : "block" }}>
                <h4 className="text-lg px-2 mb-4">Bushels</h4>
                <input
                  value={bushels || ""}
                  // onChange={(e) => handleChangeBushels(e)}
                  onChange={(e) => handleChange(e, setBushels)}
                  type="number"
                  step="1"
                  name="bushels"
                  id="itemType"
                  className="input-style"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                 
                />
              </Col>
              <Col className=""  style={{ display:  selectedOption ? "none" : "block" }} >
                <h4 className="text-lg px-2 mb-4">Metric Tonnes</h4>                
                <input                  
                  // onChange={(e) => handleTonnesChange(e.target.value)}
                  onChange={(e) => setTonnes(e.target.value)}
                  type="number"
                  step="0.1"
                  name="tonnes"
                  className="input-style"
                  disabled={selectedOption || selectedGrain !== "other"}
                  value={tonnes || ""}                  
                  onKeyPress={(event) => {
                  const value = event.target.value + event.key;
                  if (!/^\d+(\.\d{0,2})?$/.test(value)) {
                    event.preventDefault();
                  }
                }}
                />
                
              </Col> */}
                <Col
                  className=""
                  style={{ display: selectedOption ? "none" : "block" }}
                >
                  <h4 className="text-lg px-2 mb-4">Metric Tonnes</h4>
                  <input
                    // onChange={(e) => handleTonnesChange(e.target.value)}
                    onChange={(e) => setTonnes(e.target.value)}
                    type="number"
                    step="0.1"
                    name="tonnes"
                    className="input-style"
                    // disabled={selectedOption || selectedGrain !== "other"}
                    disabled={selectedOption}
                    value={tonnes || ""}
                    onKeyPress={(event) => {
                      const value = event.target.value + event.key;
                      if (!/^\d+(\.\d{0,2})?$/.test(value)) {
                        event.preventDefault();
                      }
                    }}
                    min={0}
                  />
                </Col>
                <Col
                  className=""
                  style={{
                    display:
                      selectedOption || selectedGrain == 18 ? "none" : "block",
                  }}
                >
                  <h4 className="text-lg px-2 mb-4">Bushels</h4>
                  <input
                    value={bushels || ""}
                    // onChange={(e) => handleChangeBushels(e)}
                    onChange={(e) => handleChange(e, setBushels)}
                    type="number"
                    step="1"
                    name="bushels"
                    id="itemType"
                    className="input-style"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    disabled
                  />
                </Col>
              </Row>
            )}
          </FilterType>
          <FilterType
            category="Price"
            isOpen={isOpenPrice}
            toggle={togglePrice}
          >
            <Row className="d-flex option-row">
              <Col sm={12}>
                {/* <h4 className="text-lg px-2 mb-4">
                  {selectedOption ? " Per Bale" : selectedGrain === "other" ? "Per Metric Tonne" : "Per Bushel"}
                </h4> */}
                <h4 className="text-lg px-2 mb-4">
                  {selectedOption ? " Per Bale" : "Per Metric Tonne"}
                </h4>
                <input
                  value={price || ""}
                  onChange={(e) => handleChange(e, setPrice)}
                  // onChange={(e) => handleChange("price", e)}
                  type="number"
                  step="1"
                  name="price"
                  id="itemType"
                  className="input-style"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  min={0}
                />
                {/* <input
                    value={price || ""}
                    onChange={(e) => handleChange(e, setPrice)}
                    type="text"
                    step="1"
                    name="price"
                    id="itemType"
                    className="input-style"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={(e) => {
                      const formattedPrice = parseFloat(e.target.value).toLocaleString("en-CA", {
                        style: "currency",
                        currency: "CAD",
                        minimumFractionDigits: 2,
                      });
                      setPrice(formattedPrice);
                    }}
                  /> */}
                <Row className="d-flex justify-content-between p-4 w-full">
                  <Col>{currencyFormatter.format(1)}</Col>
                  <Col className="text-right">
                    {currencyFormatter.format(1000000)}
                  </Col>
                </Row>
              </Col>
            </Row>
          </FilterType>
          <FilterType
            category="Trucking/Freight Options"
            isOpen={isOpenTrucking}
            toggle={toggleTrucking}
          >
            {/* <Row className="d-flex option-row">
              <form>
                <Col className="">
                  {optionsTrucking.map((option) => (
                    <div key={option.id} className="form-check">
                      <label className="filter-radio-container">
                        <input
                          type="radio"
                          name="options"
                          value={option.label || ""}
                          checked={selectedTrucking === option.label}
                          // onChange={(e) => {setSelectedTrucking(e.target.value)}}
                          onChange={handleTruckingChange}
                          className="form-check-input radio-custom"
                        />
                        <span className="option-label">{option.label}</span>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  ))}
                </Col>
              </form>
            </Row> */}
            {/* <Row className="d-flex option-row">
              <form>
                <Col className="">
                  {optionsTrucking.map((option) => (
                    <div key={option.id} className="form-check">
                      <label className="filter-radio-container">
                        <input
                          type="checkbox"
                          name="options"
                          value={option.label || ""}
                          // checked={selectedTrucking === option.label}
                          checked={selectedTrucking === option.label}
                          // onChange={(e) => {setSelectedTrucking(e.target.value)}}
                          onChange={handleTruckingChange}
                          className="form-check-input radio-custom"
                        />
                        <span className="option-label">{option.label}</span>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  ))}
                </Col>
              </form>
            </Row> */}
            <Row className="d-flex option-row">
              <form>
                <Col className="">
                  {optionsTrucking.map((option) => (
                    <div key={option.id} className="form-check">
                      <label className="filter-radio-container">
                        <input
                          type="checkbox"
                          name="options"
                          value={option.id}
                          checked={checkedOptions.find(
                            (x) => x.id === option.id
                          )}
                          onChange={() => {
                            toggleTruckingOptions(option.id);
                          }}
                          className="form-check-input radio-custom"
                        />
                        <span className="option-label">{option.label}</span>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  ))}
                </Col>
              </form>
            </Row>
          </FilterType>
          <ModalFooter>
            {/* <button className="btn-hay-yellow" onClick={toggle}> */}
            <button className="btn-hay-yellow" onClick={handleFiltersChange}>
              Apply
            </button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Filters;
