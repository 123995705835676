import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Row,
  // Col,
  // Form,
  FormGroup,
  // Label,
  // Input,
  // FormText
} from "reactstrap";
import PropTypes from "prop-types";
// import $ from "jquery";
import PDFViewer from "mgr-pdf-viewer-react";

// import thepdf from "../../../Common/images/Recipe.pdf";
import "./style.css";

class RentalPdfModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultPdf: "",
      numPages: null,
      pageNumber: 1,
      makePaymentButtonEnabled: this.props.isPaymentButtonDisabled,
    };
  }
  // onDocumentLoadSuccess = ({ numPages }) => {
  //   this.setState({ numPages });
  // };
  componentDidMount() {}

  // goToPrevPage = () =>
  //   this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  // goToNextPage = () =>
  //   this.setState(state => ({ pageNumber: state.pageNumber + 1 }));

  onAgree = () => {
    // this.setState({ makePaymentButtonEnabled: false });
    this.props.toggle("rentalpdf");
    this.props.acceptTerms(false);
  };
  render() {
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("rentalpdf")}>
        &times;
      </button>
    );
    // const { pageNumber, numPages } = this.state;

    console.log(this.props.agreement);
    let agreement = this.props.agreement;
    return (
      <div>
        <Modal
          className="pdf-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("rentalpdf")}
          size="lg"
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#ADB5BD" }}
          >
            Rental Agreement
          </ModalHeader>
          <ModalBody>
            <p style={{ textAlign: "center" }}>
              You must agree to the Rental Agreement to continue.
            </p>
            {/* <iframe src={agreement} style={{ width: "100%", height: 700 }} /> */}
            <PDFViewer
              document={{
                url: `https://cors-anywhere.herokuapp.com/${agreement}`,
              }}
            />
          </ModalBody>
          <ModalFooter>
            <FormGroup row>
              <Button
                block
                className="agree-button-modal-pdf blockbutton"
                onClick={this.onAgree}
              >
                I Agree
              </Button>
            </FormGroup>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

RentalPdfModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string,
};

export default RentalPdfModal;
