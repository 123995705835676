import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import spacetime from 'spacetime';

/**
 * Displays a date/time stamp as a date or time or a combination
 * Also supports relative times and timezone adjustment
 */
class FormattedDateTime extends React.PureComponent {
    render() {
        if (!this.props.datetime) return '';

        const momentDateTime =
            typeof this.props.datetime === 'string'
                ? moment.utc(this.props.datetime)
                : this.props.datetime;

        let s = null;
        if (!this.props.dontAdjustTimezone) {
            s = spacetime(momentDateTime.unix() * 1000, 'UTC');
            s.goto('America/Edmonton');
        } else {
            s = spacetime(momentDateTime.unix() * 1000);
        }

        if (this.props.ago) {
            const secondsDiff = momentDateTime.unix() - moment.utc().unix();
            const duration = moment.duration(secondsDiff, 'seconds');
            return duration.humanize(true);
        }
        let formatted = null;

        if (this.props.format) {
            formatted = s.format(this.props.format);
        } else if (
            (this.props.showDate === undefined &&
                this.props.showTime === undefined) ||
            (this.props.showDate && this.props.showTime)
        ) {
            formatted = s.format('MMMM d, yyyy h:mm a');
        } else {
            if (this.props.showDate) formatted = s.format('MMMM d, yyyy');
            if (this.props.showTime) formatted = s.format('h:mm a');
        }

        return <span>{formatted}</span>;
    }
}

FormattedDateTime.propTypes = {
    datetime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Moment object
    showDate: PropTypes.bool, // Show date portion?
    showTime: PropTypes.bool, // Show time portion?
    ago: PropTypes.bool, // Show relative (eg 5 mins ago)
    format: PropTypes.string, // Custom format to use
    dontAdjustTimezone: PropTypes.string, // Custom format to use
};

export default FormattedDateTime;
