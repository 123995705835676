import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  CardHeader
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "./category.css";
import $ from "jquery";

class Category extends React.Component {
  constructor() {
    super();

    this.state = {
      // items: [{
      //   categoryImg: require('./Images/all.png'),
      //   categoryName: 'All',
      // }, {
      //   categoryImg: require('./Images/equipment.jpg'),
      //   categoryName: 'Equipment',
      // }, {
      //   categoryImg: require('./Images/trailers.jpg'),
      //   categoryName: 'Trailers',
      // }, {
      //   categoryImg: require('./Images/bicycles.jpg'),
      //   categoryName: 'Bicycles',
      // }, {
      //   categoryImg: require('./Images/equipment.jpg'),
      //   categoryName: 'Something Else',
      // } ]
      categoryItems: []
    };
  }
  componentDidMount() {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      data: { Page: 0, PerPage: 15 },
      traditional: true,
      xhrFields: {
        withCredentials: true
      },
      url: process.env.REACT_APP_API+"/categories"
    }).then(function(res) {
      console.log("***** Categories *****");
      console.log(res);

      let obj = res.data.items.map(item => {
        return {
          categoryName: item.name,
          categoryImg: item.imagePath,
          categoryId: item.id
        };
        // obj.categoryName = item.name;
        // obj.categoryImg = item.imagePath;
      });

      vm.setState({
        // items : vm.state.items.concat(obj)
        categoryItems: obj
      });
    });
  }

  render() {
    return (
      <Row className="categories-in-dash py-3">
        {this.state.categoryItems.map((item, i) => {
          return (
            <Col
              sm="6"
              md="4"
              lg="2"
              style={{ cursor: "pointer" }}
              className="pl-0 pr-4"
            >
              <Card
                onClick={() => this.props.sortFilter(item)}
                sm="6"
                className="category-card  px-0 "
              >
                <div className="card-category-img d-flex justify-content-center align-items-center px-0">
                  <img src={item.categoryImg} alt="item img" />
                </div>
                <CardTitle className="category-card-title my-0">
                  {item.categoryName}
                </CardTitle>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  }
}

Category.propTypes = {};

export default Category;
