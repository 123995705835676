import React, { Component } from 'react';
import TextMessage from './TextMessage';


class Message extends Component {

  render() {
    let contentClassList = [
      'sc-message--content',
      (this.props.message.from === 'me' ? 'sent' : 'received')
    ];
    return (
      <div className="sc-message">
        <div className={contentClassList.join(' ')}>
          <TextMessage class='messageText' message={this.props.message.message} time={this.props.message.created} />
        </div>
      </div>);
  }
}

export default Message;
