import React from "react";
import {
  Card,
  Button,
  CardTitle,
  CardImg,
  CardBody,
  Row,
  Col,
  CardText,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
// import { Map, Marker, GoogleApiWrapper} from "google-maps-react";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../Common/Loading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faArrowLeft,
  faThermometerThreeQuarters,
} from "@fortawesome/free-solid-svg-icons";
import { getRental } from "../../App/Api/getRental";
import RatingsModal from "../../Common/Modals/RatingsModal";
import ExtendRentalModal from "../../Common/Modals/ExtendRentalModal";

import UserRating from "../PostPage/ViewPost/UserRating";
import ReportUserModal from "../../Common/Modals/ReportUserModal";
import Map from "../PostPage/EditPost/GoogleAutocomplete";
import "./rentingpost.css";

import { extendRental } from "../../App/Api/extendRental";
import { cancelRental } from "../../App/Api/cancelRental";
import { activateEquipment } from "../../App/Api/activateEquipment";
import { deactivateEquipment } from "../../App/Api/deactivateEquipment";
import { returnRental } from "../../App/Api/returnRental";
import { rentalsAvailability } from "../../App/Api/rentalsAvailability";

import { extendMoment } from "moment-range";
import originalMoment from "moment";
import Swal from "sweetalert2";
import axios from "axios";

const moment = extendMoment(originalMoment);

const optionsTrucking = [
  { id: 0, label: "Pick up only, loading included" },
  { id: 1, label: "Pick up only, loading not included" },
  { id: 2, label: "Delivery to your location, unloading included" },
  { id: 3, label: "Delivery to your location, unloading not included" },
];

const optionsHay = [
  { id: 1, label: "Grass" },
  { id: 2, label: "Grass/Alfalfa" },
  { id: 3, label: "Alfalfa" },
  { id: 4, label: "Second Cut" },
  { id: 5, label: "Third Cut" },
  { id: 6, label: "Haylage" },
  { id: 7, label: "Greenfeed" },
  { id: 8, label: "Other Hay" },
];

const optionsStraw = [
  { id: 9, label: "Barley" },
  { id: 10, label: "Wheat" },
  { id: 11, label: "Oats" },
  { id: 12, label: "Peas" },
  { id: 13, label: "Other Straw" },
];

const optionsGrain = [
  { id: 14, label: "Barley" },
  { id: 15, label: "Wheat" },
  { id: 16, label: "Oats" },
  { id: 17, label: "Peas" },
  { id: 18, label: "Other Feed" },
];

const currencyFormatter = new Intl.NumberFormat("en-CA", {
  style: "currency",
  currency: "CAD",
});

class ViewRentingPost extends React.Component {
  constructor(props) {
    super(props);

    // Carousel
    this.state = {
      orderDetails: null,
      loading: true,
      type: "",
      postUserId: null,
      postUserName: null,
      rentalId: null,
      activeIndex: 0,
      score: 0,
      ReportUserModalOpen: false,
      startDate: "Thurs, Jan 17, 9:00 AM",
      endDate: "Fri, Jan 25, 5:00 PM",
      dailyRate: 150,
      weeklyRate: 400,
      equipmentId: null,
      equipmentName: "",
      make: "$",
      model: "SXL",
      year: "2012",
      description:
        "This svu does this and that and this and Deen. This svu does this and that and this and Deen This svu does this and that and this and Deen",
      specifications: ["5 Tonnes", "4 wheel Drive"],
      comments:
        "This is a very long string that will allow for the user to talk more about the equipment they are trying to rent out",
      location: {
        type: "Pickup / Delivery",
        geometry: {
          location: {
            lat: 51.05011,
            lng: -114.08529,
          },
        },
        address: "",
        returnAddress: "",
      },
      pickupDilivery: "Pickup",
      total: 300,
      numOfReviews: 0,
      profilePic: "",
      media: [],
      ratings: [],
      listOfAddOns: [],
      ratingmodal: false,
      isActive: false,
      confirmedAt: null,
      extendRentalAvailabel: false,
      extendEndDate: null,
      extendEndTime: null,
      extendEquipmentModal: false,
      extendable: false,
      endTime: null,
      rated: "",
      completed: "",
      utcStartDate: moment(),
      utcEndDate: null,
      successModal: false,
      cancelRental: false,
      openEndedRentalStatus: 0,
      sliderImages: [],
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentWillUpdate(np) {
    const { match } = this.props;

    const previousId = match.params.id;
    const nextId = np.match.params.id;
    if (nextId && previousId !== nextId) {
      window.location.reload();
    }
  }

  componentDidMount() {
    const vm = this;
    getRental(this.props.match.params.id)
      .then((res) => {
        if (
          res.comfirmedAt !== null &&
          res.rating === null &&
          res.equipmentOwner.id !== localStorage.getItem("userId")
        ) {
          vm.setState({ ratingmodal: true });
        }
        let myImages = res.equipments[0].equipmentMedias.map((item) => {
          return {
            src: item.filePath,
            type: item.type,
          };
        });

        vm.setState({
          orderDetails: res,
          loading: false,
          sliderImages: myImages,
        });
      })
      .catch((res) => {
        console.log(res);
        vm.setState({ loading: false });
        // window.location.replace("/");
        // alert(res.responseJSON.code + "\n" + res.responseJSON.message);
      });
  }

  toggle = (type) => {
    if (type === "ratings") {
      this.setState({ ratingmodal: false });
    } else if (type === "reportusermodal") {
      this.setState((prevState) => ({
        ReportUserModalOpen: !prevState.ReportUserModalOpen,
      }));
    } else if (type === "payment") {
    } else if (type === "extendRental") {
      this.setState((prevState) => ({
        extendEquipmentModal: !prevState.extendEquipmentModal,
      }));
    } else if (type == "success") {
      this.setState(
        (prevState) => ({
          successModal: !prevState.successModal,
        }),
        () => {
          if (this.state.successModal == false) {
            window.location.reload();
          }
        }
      );
    }
  };

  changeActiveRental = () => {
    // console.log('ACTIVE / DEACTIVE')
    if (this.state.isActive) {
      deactivateEquipment(this.state.equipmentId).then(function (res) {});
    } else {
      activateEquipment(this.state.equipmentId).then(function (res) {});
    }
  };

  chargeExtra = async (amount, reason) => {
    const vm = this;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    let data = {
      amountToCharge: JSON.parse(amount),
      rentalId: vm.state.rentalId,
      stripeToken: null,
      reason: reason,
    };
    console.log(data);
    axios
      .post(`${process.env.REACT_APP_API}/rentals/extracharge`, data, {
        headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === "SUCCESS") {
          Swal.fire(`$${amount} Charged Extra`).then(function () {
            if (vm.state.openEndedRentalStatus === (1 || 2)) {
              vm.openEndedCharge();
            } else {
              vm.returnCall();
            }
          });
        } else {
          Swal.fire({
            text: "Cannot charge extra, Please contact admin!",
            confirmButtonColor: "#ADB5BD",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((res) => {
        console.log(res.response.data.message);
        Swal.fire({
          text: "Cannot charge extra, Please contact admin!",
          confirmButtonColor: "#ADB5BD",
          confirmButtonText: "Ok",
        });
      });
  };

  openEndedCharge = () => {
    const vm = this;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    let data = {
      rentalId: vm.state.rentalId,
      stripeToken: null,
    };
    axios
      .post(`${process.env.REACT_APP_API}/rentals/charge`, data, {
        headers,
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === "SUCCESS") {
          this.returnCall();
        } else {
          Swal.fire({
            text: "Something wrong, Please contact admin!",
            confirmButtonColor: "#ADB5BD",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((res) => console.log(res));
  };

  returnRental = () => {
    const vm = this;
    // console.log('Return')
    const { value: formValues } = Swal.fire({
      title: "Do you want to charge extra?",
      showDenyButton: true,
      html:
        '<input placeholder="Amount" type="number" id="swal-input1" class="swal2-input" min="0"> <br/>' +
        '<select id="swal-input2" class="swal2-input"> <option value="">Please choose a reason</option> <option value="fuel-charge">Fuel charge</option> <option value="repair">Repair</option> <option value="cleaning">Cleaning</option> <option value="buy-out">Buy-out</option></select>',
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
        ];
      },
      showCancelButton: false,
      confirmButtonText: `Yes, Charge extra`,
      denyButtonText: `No extra Charge, Mark Returned`,
      denyButtonColor: "#FFCD46",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (result.value[0] > 0 && result.value[1]) {
          vm.chargeExtra(result.value[0], result.value[1]);
        } else {
          Swal.fire("", "Amount and Reason required if charging extra!").then(
            function () {
              vm.returnRental();
            }
          );
        }
      } else if (result.isDenied) {
        if (vm.state.openEndedRentalStatus === (1 || 2)) {
          vm.openEndedCharge();
        } else {
          vm.returnCall();
        }
      }
    });
  };

  getHayType = () => {
    let vm = this;
    let foundHay = optionsHay.find((item) => {
      return item.label === vm.state.orderDetails.equipments[0].category.name;
      // return item.id === vm.state.orderDetails.equipments[0].category.hayType
    });
    let foundStraw = optionsStraw.find((item) => {
      return item.label === vm.state.orderDetails.equipments[0].category.name;
      // return item.id === vm.state.orderDetails.equipments[0].category.hayType
    });
    let foundFeed = optionsGrain.find((item) => {
      return item.label === vm.state.orderDetails.equipments[0].category.name;
      // return item.id === vm.state.orderDetails.equipments[0].category.hayType
    });

    if (foundHay) {
      return "Hay Bale";
    }
    if (foundStraw) {
      return "Straw Bale";
    }
    if (foundFeed) {
      return "Feed Grain";
    }
    return "";
  };

  returnCall = () => {
    const vm = this;

    returnRental(vm.state.rentalId).then(function (res) {
      console.log(res);
      Swal.fire({
        text: "Equipment returned!",
        confirmButtonColor: "#ADB5BD",
        confirmButtonText: "Ok",
      }).then(function () {
        window.location.reload();
      });
    });
  };

  checkDeliveryAndPickup = (id) => {
    if (this.state.orderDetails.freightOptions == 0 && id == 0) {
      return true;
    } else if (this.state.orderDetails.freightOptions == 1 && id == 1) {
      return true;
    } else if (this.state.orderDetails.freightOptions == 2 && id == 2) {
      return true;
    } else if (this.state.orderDetails.freightOptions == 3 && id == 3) {
      return true;
    }
    return false;
  };

  isDelivery = () => {
    if (
      this.state.orderDetails.freightOptions == 2 ||
      this.state.orderDetails.freightOptions == 3
    ) {
      return true;
    }
    return false;
  };

  cancelRental = () => {
    // console.log('CANCEL')
    Swal.fire({
      title: "Confirm Cancellation",
      text: "Are you sure you want to cancel this booking? You will receive a full refund of the charged amount!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#FFCD46",
      confirmButtonText: "Yes",
      cancelButtonColor: "#ADB5BD",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        cancelRental(this.state.rentalId).then(function (res) {
          if (res.code == "RENTAL_CANCELED") {
            Swal.fire("Canceled!", "Rental Canceled.", "success");
            window.history.back();
          }
        });
      }
    });
  };

  saveByteArray = (reportName, byte) => {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  createPdf = () => {
    const vm = this;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    };
    vm.setState({ loading: true });
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/rentals/${vm.state.rentalId}/receipt`,
      headers,
      responseType: "arraybuffer",
    })
      .then(function (res) {
        console.log(res);
        if (res.status === 200) {
          let pdf = res.data;
          vm.saveByteArray(
            "haythere Receipt - " + moment().format("MMM D YYYY, hh:mm a"),
            pdf
          );
          vm.setState({ loading: false });
        } else {
          alert("Failed to Download, Please contact admin");
          vm.setState({ loading: false });
        }
      })
      .catch(function (err) {
        console.log(err);
        alert("Failed to Download, Please contact admin");
      });
  };

  extendRental = () => {
    // console.log('EXTEND')
    extendRental(this.state.rentalId).then(function (res) {});
  };

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.media.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.media.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  goBack() {
    window.history.back();
  }

  render() {
    let Rating = () => {
      let stars = [];
      if (this.state.score === 0) {
        return <span style={{ color: "rgba(0,0,0,0.4)" }}>Not yet rated</span>;
      } else {
        for (let i = 0; i < this.state.score; i++) {
          stars.push(
            <FontAwesomeIcon icon={faStar} className="checked fa-star" />
          );
        }
        return stars;
      }
    };

    const { activeIndex } = this.state;
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("extendRental")}
      >
        &times;
      </button>
    );
    const slides = this.state.media.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          {item.type == 0 && <img src={item.src} alt={item.altText} />}
          {item.type == 2 && (
            <video
              width="100%"
              controls
              src={item.src}
              alt={item.altText}
              type="video/mp4"
            />
          )}
          {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
        </CarouselItem>
      );
    });

    return (
      <Col>
        <Modal
          className="feedback-modal"
          isOpen={this.state.successModal}
          toggle={() => this.toggle("success")}
          size="lg"
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#ADB5BD", paddingLeft: "50px" }}
          >
            Success
          </ModalHeader>
          <ModalBody>
            <Col style={{ textAlign: "center" }} sm={12}>
              <div>You have extended your rental</div>
            </Col>
          </ModalBody>
        </Modal>

        <RatingsModal
          id={this.props.match.params.id}
          toggle={this.toggle}
          modalOpen={this.state.ratingmodal}
        />

        {this.state.equipmentId && this.state.nextStartDate && (
          <ExtendRentalModal
            toggle={this.toggle}
            modalOpen={this.state.extendEquipmentModal}
            equipmentId={this.state.rentalId}
            before={moment(this.state.endDate).toDate()}
            after={this.state.nextStartDate.toDate()}
            endTime={this.state.endTime}
            startTime={this.state.startTime}
            addonIds={this.state.listOfAddOns}
            deliveryLocation={this.state.deliveryLocationAddress}
            deliveryLocationLatitude={this.state.deliveryLocationLatitude}
            deliveryLocationLongitude={this.state.deliveryLocationLongitude}
          />
        )}
        {this.state.loading && <Loading modalOpen={this.state.loading} />}

        <ReportUserModal
          toggle={this.toggle}
          modalOpen={this.state.ReportUserModalOpen}
        />

        <Row style={{ marginTop: "180px" }}>
          <Col sm="7" style={{ position: "relative" }}>
            <Row style={{height: "50px"}}>
              <div onClick={this.goBack} className="backbutton">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="lg"
                  className="goback-icon"
                />
              </div>
              <div
                onClick={() => this.toggle("reportusermodal")}
                className="reportbutton"
              >
                <FontAwesomeIcon
                  icon={faFlag}
                  size="lg"
                  className="report-icon"
                />
              </div>
            </Row>
            <Row>
              <Col sm="2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  {this.state.sliderImages.map((item) => {
                    return (
                      <img
                        src={item.src}
                        style={{
                          width: "100px",
                          marginBottom: "10px",
                          borderRadius: 10,
                        }}
                      />
                    );
                  })}
                </div>
              </Col>
              <Col>
                <Carousel
                  sm="12"
                  activeIndex={activeIndex}
                  next={this.next}
                  previous={this.previous}
                  autoPlay={false}
                  interval={false}
                  ride={1000}
                >
                  <CarouselIndicators
                    className="view-carousel-indicators mx-auto"
                    items={this.state.sliderImages.map((element) => {
                      return {
                        src: element.src,
                        type: element.type,
                        // altText: 'Slide 1',
                        // caption: 'Slide 1'
                      };
                    })}
                    activeIndex={activeIndex}
                    onClickHandler={this.goToIndex}
                  />
                  {this.state.sliderImages.map((item) => {
                    if (item.type === 2) {
                      return (
                        <CarouselItem
                          height="500px"
                          onExiting={this.onExiting}
                          onExited={this.onExited}
                          key={item.src}
                        >
                          <video
                            width="100%"
                            controls
                            src={item.src}
                            // alt={item.altText}
                            type="video/mp4"
                          />
                        </CarouselItem>
                      );
                    } else {
                      return (
                        <CarouselItem
                          onExiting={this.onExiting}
                          onExited={this.onExited}
                          key={item.src}
                        >
                          <img
                            interval={false}
                            src={item.src}
                            // alt={item.altText}
                            // className="rounded-xl"
                            style={{ borderRadius: 30 }}
                          />
                        </CarouselItem>
                      );
                    }
                  })}
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={this.previous}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={this.next}
                  />
                </Carousel>
              </Col>
            </Row>

            <Row className="mx-0 p-5">
              <Col sm="12">
                {this.state.ratings.length > 0 ? (
                  this.state.ratings.map((item, i) => {
                    let Rating = () => {
                      let stars = [];
                      for (let i = 0; i < item.score; i++) {
                        stars.push(
                          <FontAwesomeIcon
                            icon={faStar}
                            className="checked fa-star"
                          />
                        );
                      }
                      return stars;
                    };

                    return (
                      <Card sm="12" className="user-review-card py-3 px-0">
                        <CardHeader
                          sm="12"
                          className="card-review-header d-flex flex-row align-items-center"
                        >
                          <div className="card-review-img col-sm-1 d-flex justify-content-center align-items-center">
                            <img
                              width="100%"
                              src={item.profileImage}
                              alt="item img"
                            />
                          </div>
                          <div className="col-sm-11">
                            <div className="green-txt">
                              <Rating />
                            </div>
                            <div className="card-review-user">
                              {item.firstName} {item.lastName}
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody className="text-muted dashboard-card-body">
                          <CardText className="dashboard-card-text">
                            <div>{item.description}</div>
                          </CardText>
                        </CardBody>
                      </Card>
                    );
                  })
                ) : (
                  <Card sm="12" className="user-review-card py-3 px-0">
                    <CardHeader
                      sm="12"
                      className="card-review-header d-flex flex-row align-items-center"
                    >
                      <div className="col-sm-11">
                        <div className="green-txt" />
                        <div className="card-review-user">No Ratings</div>
                      </div>
                    </CardHeader>
                  </Card>
                )}
              </Col>
            </Row>
          </Col>

          {this.state.orderDetails && (
            <Col sm="5" style={{ backgroundColor: "#FCFCFC" }}>
              <Row>
                <Col sm="12" className="post-info-main-container">
                  <Row style={{ padding: "0 10px", marginBottom: 30 }}>
                    <div className=" p-0">
                      <h2 className="post-title mb-4">
                        {this.state.orderDetails.equipments[0].name}
                      </h2>
                      <h4
                        className="post-price mb-12"
                        style={{ marginBottom: "1.5rem" }}
                      >
                        {currencyFormatter.format(
                          this.state.orderDetails.equipments[0].dailyRate
                        )}{" "}
                        <span style={{ fontWeight: 400, fontSize: 18 }}>
                          / Bale
                        </span>
                      </h4>
                      <div className="d-flex align-items-center">
                        {this.state.orderDetails.equipmentOwner.profileImage ? (
                          <img
                            className="profile-img mr-4"
                            src={
                              this.state.orderDetails.equipmentOwner
                                .profileImage
                            }
                            alt="search"
                          />
                        ) : (
                          <div className="profile-img mr-4 d-flex justify-content-center align-items-center p-8">
                            {this.state.orderDetails.equipmentOwner.firstName.charAt(
                              0
                            )}
                          </div>
                        )}
                        {this.state.orderDetails.equipmentOwner ? (
                          <p
                            className="post-title-username "
                            style={{ marginBottom: "0px" }}
                          >
                            {`${this.state.orderDetails.equipmentOwner.firstName} ${this.state.orderDetails.equipmentOwner.lastName}`}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </Row>
                  <hr />

                  <Row style={{ padding: 10 }}>
                    <div className="container post-info-container m-0 p-0">
                      <div className="left-side newicons">
                        <img
                          className="left-side-icon"
                          src={require("../../Common/images/newicons/info_icon.svg")}
                          alt="info"
                          color="#E7B10A"
                        />
                      </div>
                      <div className="" style={{ fontSize: 18 }}>
                        <h4 className="post-subtitle">Description </h4>
                        <p className="">
                          {" "}
                          {
                            this.state.orderDetails.equipments[0].description
                          }{" "}
                        </p>
                      </div>
                    </div>
                  </Row>

                  <Row style={{ padding: 10 }}>
                    <div className="container post-info-container m-0 p-0">
                      <div className="left-side newicons">
                        <img
                          className="left-side-icon"
                          src={require("../../Common/images/newicons/quantity-ellipsis-stroke.svg")}
                          alt="info"
                          color="#E7B10A"
                        />
                      </div>
                      <div className="" style={{ fontSize: 18, width: "100%" }}>
                        <h4 className="post-subtitle">Quantity </h4>

                        <div
                          className=" post-info-block"
                          style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                        >
                          <div
                            className="rates-container"
                            style={{ width: "100%" }}
                          >
                            <p className="rateFreq">Quantity</p>
                            <p className="ratePrice ml-auto">
                              {" "}
                              {this.state.orderDetails.quantity || 0}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>

                  <Row style={{ padding: 10 }}>
                    <div className="container post-info-container m-0 p-0">
                      <div className="left-side newicons">
                        <img
                          className="left-side-icon"
                          src={require("../../Common/images/newicons/ruler-icon.svg")}
                          alt="info"
                          color="#E7B10A"
                        />
                      </div>
                      <div className="" style={{ fontSize: 18, width: "100%" }}>
                        <h4 className="post-subtitle">Specifications </h4>

                        <div
                          className=" post-info-block"
                          style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                        >
                          <div
                            className="rates-container"
                            style={{ width: "100%" }}
                          >
                            <p className="rateFreq">Crop Year</p>
                            <p className="ratePrice ml-auto">
                              {" "}
                              {this.state.orderDetails.equipments[0].year}{" "}
                            </p>
                          </div>
                          <div className="rates-container">
                            <p className="rateFreq">Type</p>
                            <p className="ratePrice"> {this.getHayType()}</p>
                          </div>
                          <div className="rates-container">
                            <p className="rateFreq">Category</p>
                            <p className="ratePrice">
                              {" "}
                              {this.state.orderDetails.equipments[0].category
                                ? this.state.orderDetails.equipments[0].category
                                    .name
                                : ""}{" "}
                            </p>
                          </div>
                          <div className="rates-container">
                            <p className="rateFreq">Weight per bale</p>
                            <p className="ratePrice">
                              {" "}
                              {this.state.orderDetails.equipments[0].weight} lbs
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>

                  <Row style={{ padding: 10 }}>
                    <div className="container post-info-container m-0 p-0">
                      <div className="left-side newicons">
                        <img
                          className="left-side-icon"
                          src={require("../../Common/images/newicons/comments-icon.svg")}
                          alt="info"
                          color="#E7B10A"
                        />
                      </div>
                      <div className="" style={{ fontSize: 18, width: "100%" }}>
                        <h4 className="post-subtitle">Comments </h4>

                        <div
                          className=" post-info-block"
                          style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                        >
                          <p className="ratePrice ml-auto">
                            {this.state.orderDetails.equipments[0].comments
                              ? this.state.orderDetails.equipments[0].comments
                              : "No comments"}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Row>

                  <Row style={{ padding: 10 }}>
                    <div className="container post-info-container m-0 p-0">
                      <div className="left-side newicons">
                        <img
                          className="left-side-icon"
                          src={require("../../Common/images/newicons/truck-icon.svg")}
                          alt="info"
                          color="#E7B10A"
                          height={30}
                        />
                      </div>
                      <div className="" style={{ fontSize: 18, width: "100%" }}>
                        <h4 className="post-subtitle">
                          Trucking/Freight Options{" "}
                        </h4>

                        <div
                          className=" post-info-block"
                          style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                        >
                          <form>
                            <Col className="p-0">
                              {optionsTrucking.map((option) => (
                                <div key={option.id} className="form-check p-0">
                                  <label className="filter-radio-container">
                                    <input
                                      // On view disable this missing
                                      type="radio"
                                      name="options"
                                      value={option.id || ""}
                                      checked={this.checkDeliveryAndPickup(
                                        option.id
                                      )}
                                      // checked={this.state.checked === option.id}
                                      // onChange={(e) => {setSelectedTrucking(e.target.value)}}
                                      // onChange={() =>
                                      //   this.handleChange(option.id)
                                      // }
                                      className="form-check-input radio-custom"
                                    />
                                    <span className="option-label">
                                      {option.label}
                                    </span>
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              ))}
                            </Col>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Row>

                  <Row style={{ padding: 10 }}>
                    <div className="container post-info-container m-0 p-0">
                      {/* <div className="left-side newicons">
                        <img
                          className="left-side-icon"
                          src={require("../../Common/images/newicons/icon_location.svg")}
                          alt="info"
                          color="#E7B10A"
                          height={30}
                        />
                      </div> */}
                      <div className="" style={{ fontSize: 18, width: "100%" }}>
                        <h4 className="post-subtitle">
                          {this.isDelivery() ? "Delivery" : "Pickup"} Location{" "}
                        </h4>
                        <div
                          className="post-title-username post-info-block"
                          style={{ padding: "0 20px 10px 0" }}
                        >
                          <div className="rates-container">
                            <p className="ratePrice">
                              {this.isDelivery()
                                ? this.state.orderDetails
                                    .deliveryLocationAddress
                                : this.state.orderDetails.equipments[0]
                                    .pickupLocationAddress}
                            </p>
                          </div>
                        </div>

                        <div
                          className="my-map-container"
                          style={{ position: "relative" }}
                        >
                          {!this.state.loading && !this.isDelivery() ? (
                            <Map
                              places={[
                                {
                                  type: "Pickup / Delivery",
                                  geometry: {
                                    location: {
                                      lat: this.state.orderDetails.equipments[0]
                                        .pickupLocationLatitude,
                                      lng: this.state.orderDetails.equipments[0]
                                        .pickupLocationLongitude,
                                    },
                                  },
                                  address:
                                    this.state.orderDetails.equipments[0]
                                      .pickupLocationAddress,
                                  returnAddress: "",
                                },
                              ]}
                              renting={true}
                              theaddress={
                                this.state.orderDetails.equipments[0]
                                  .pickupLocationAddress
                              }
                              center={{
                                lat: this.state.orderDetails.equipments[0]
                                  .pickupLocationLatitude,
                                lng: this.state.orderDetails.equipments[0]
                                  .pickupLocationLongitude,
                              }}
                            />
                          ) : (
                            <Map
                              places={[
                                {
                                  type: "Pickup / Delivery",
                                  geometry: {
                                    location: {
                                      lat: this.state.orderDetails
                                        .deliveryLocationLatitude,
                                      lng: this.state.orderDetails
                                        .deliveryLocationLongitude,
                                    },
                                  },
                                  address:
                                    this.state.orderDetails
                                      .deliveryLocationAddress,
                                  returnAddress: "",
                                },
                              ]}
                              renting={true}
                              theaddress={
                                this.state.orderDetails.deliveryLocationAddress
                              }
                              center={{
                                lat: this.state.orderDetails
                                  .deliveryLocationLatitude,
                                lng: this.state.orderDetails
                                  .deliveryLocationLongitude,
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Row>

                  <Row style={{ padding: 10 }}>
                    <div className="container post-info-container m-0 p-0">
                      <div className="left-side newicons">
                        <img
                          className="left-side-icon"
                          src={require("../../Common/images/newicons/payment-icon.svg")}
                          alt="info"
                          color="#E7B10A"
                          height={25}
                        />
                      </div>
                      <div className="" style={{ fontSize: 18, width: "100%" }}>
                        <h4 className="post-subtitle">Total </h4>
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p className="rateFreq">Items</p>
                          <p className=" ml-auto">
                            {" "}
                            {currencyFormatter.format(
                              this.state.orderDetails.rentalAmount
                            )}{" "}
                          </p>
                        </div>
                        <hr />
                        <div
                          className=" post-info-block"
                          style={{ padding: "0 20px 10px 0", fontSize: 18 }}
                        >
                          <p className="ratePrice ml-auto">Delivery </p>
                        </div>
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p className="rateFreq">Delivery</p>
                          <p className=" ml-auto">
                            {" "}
                            {currencyFormatter.format(
                              this.state.orderDetails.deliveryAmount
                            )}{" "}
                          </p>
                        </div>
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p className="rateFreq">GST</p>
                          <p className=" ml-auto">
                            {" "}
                            {currencyFormatter.format(
                              this.state.orderDetails.gst
                            )}{" "}
                          </p>
                        </div>
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p className="rateFreq">PST</p>
                          <p className=" ml-auto">
                            {" "}
                            {currencyFormatter.format(
                              this.state.orderDetails.pst
                            )}{" "}
                          </p>
                        </div>
                        <hr />
                        <div
                          className="rates-container"
                          style={{ width: "100%" }}
                        >
                          <p
                            className="rateFreq"
                            style={{ fontWeight: "bold" }}
                          >
                            Total
                          </p>
                          <p
                            className=" ml-auto"
                            style={{ fontWeight: "bold" }}
                          >
                            {" "}
                            {currencyFormatter.format(
                              this.state.orderDetails.total
                            )}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row style={{ padding: 10, margin: "90px 0 20px 0" }}>
                    {this.state.orderDetails.equipments[0].selling ? (
                      <div style={{ width: "100%" }}>
                        {/* {!this.state.orderDetails.confirmedAt &&
                        !this.state.orderDetails.refundedAt &&
                        this.state.orderDetails.isActive ? (
                          <Button
                            onClick={() => this.toggle("extendRental")}
                            block
                            className="post-page-continue postview-submitbutton"
                          >
                            Extend Rental
                          </Button>
                        ) : null} */}
                        {!this.state.orderDetails.confirmedAt &&
                        !this.state.orderDetails.refundedAt ? (
                          <Button
                            onClick={() => this.cancelRental()}
                            block
                            className="post-page-cancel postview-submitbutton"
                          >
                            Cancel
                          </Button>
                        ) : null}
                        {this.state.confirmedAt && (
                          <Button
                            onClick={() => this.createPdf()}
                            block
                            className="post-page-continue postview-submitbutton"
                          >
                            View Receipt
                          </Button>
                        )}
                      </div>
                    ) : this.state.orderDetails.equipments[0].lookingFor ? (
                      <div style={{ width: "100%" }}>
                        {!this.state.orderDetails.confirmedAt &&
                        !this.state.orderDetails.refundedAt ? (
                          <Button
                            onClick={() => this.returnRental()}
                            block
                            className="post-page-continue postview-submitbutton"
                          >
                            Returned
                          </Button>
                        ) : null}
                        {this.state.orderDetails.isActive ? (
                          <Button
                            onClick={() => this.changeActiveRental()}
                            block
                            className="post-page-cancel postview-submitbutton"
                          >
                            Cancelled
                          </Button>
                        ) : null}
                      </div>
                    ) : null}
                  </Row>
                  {/* <Row style={{ padding: 10, margin: "90px 0 20px 0" }}>
                    {this.state.type == "renting" ? (
                      <div style={{ width: "100%" }}>
                        {!this.state.confirmedAt &&
                        !this.state.refundedAt &&
                        this.state.isActive ? (
                          <Button
                            onClick={() => this.toggle("extendRental")}
                            block
                            className="post-page-continue postview-submitbutton"
                          >
                            Extend Rental
                          </Button>
                        ) : null}
                        {!this.state.confirmedAt &&
                        !this.state.refundedAt &&
                        this.state.allowCancel ? (
                          <Button
                            onClick={() => this.cancelRental()}
                            block
                            className="post-page-cancel postview-submitbutton"
                          >
                            Cancel
                          </Button>
                        ) : null}
                        {this.state.confirmedAt && (
                          <Button
                            onClick={() => this.createPdf()}
                            block
                            className="post-page-continue postview-submitbutton"
                          >
                            View Receipt
                          </Button>
                        )}
                      </div>
                    ) : (
                      <div style={{ width: "100%" }}>
                        {!this.state.orderDetails.confirmedAt && !this.state.orderDetails.refundedAt ? (
                          <Button
                            onClick={() => this.returnRental()}
                            block
                            className="post-page-continue postview-submitbutton"
                          >
                            Completed
                          </Button>
                        ) : null}
                        {this.state.orderDetails.isActive ? (
                          <Button
                            onClick={() => this.changeActiveRental()}
                            block
                            className="post-page-cancel postview-submitbutton"
                          >
                            Cancelled
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </Row> */}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    );
  }
}

ViewRentingPost.propTypes = {
  postImageUrl: PropTypes.string,
  postName: PropTypes.string,
  postUser: PropTypes.string,
  postRating: PropTypes.number,
  postId: PropTypes.number,
  postUserId: PropTypes.number,
};

export default ViewRentingPost;
