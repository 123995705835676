import $ from 'jquery';
export function getEquipmentsByUserId(page, perPage, searchKeyWord, SortOptionId, FilterOptionIds, cityIds, UserId,  StartDate, EndDate){
  let data = {};
  if(page != null) {
    data.Page = page 
  } 
  if(perPage != null) {
    data.PerPage = perPage
  } 
  if(searchKeyWord != null) {
    data.SearchKeyWord = searchKeyWord 
  } 
  if(SortOptionId != null) {
    data.SortOptionId = SortOptionId
  } 
  if(FilterOptionIds != null) {
    data.FilterOptionIds = FilterOptionIds
  } 
  if(cityIds != null) {
    data.cityIds = cityIds
  } 
  if(UserId !=null){
    data.UserId= UserId
  }
  if(StartDate !=null){
    data.StartDate= StartDate
  }
  if(EndDate !=null){
    data.EndDate= EndDate
  }
 return $.ajax({
    method: "GET",
    dataType: 'json',
    data: data,
    traditional: true,
    xhrFields: {
      withCredentials: true
   },
    url: process.env.REACT_APP_API+"/equipments",
  }).then(res => res.data)
}