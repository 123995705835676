import React, { useRef, PropTypes, Component } from "react";
import { Card, InputGroup, InputGroupAddon, Input } from "reactstrap";
import $ from "jquery";
import { getEquipments } from "../../App/Api/equipment.js";
import { getFilters } from "../../App/Api/filter.js";
import { getSorts } from "../../App/Api/sort.js";
import Post from "../../Common/Post";
import Category from "../../Common/Category";
import "./dashboard.css";
import Map from "./Map";
import classnames from "classnames";
import CustomMarker from "./CustomMarker.js";
import Video from "./Video/Video";
import Navbar from "../../Common/HayThereNavigation";
import Filters from "../../Common/Modals/FiltersModal";
import { Data } from "./data.js";
import Header from "../../Common/Header";

import InfiniteScroll from "react-infinite-scroller";
import { getloggedinUser } from "../../App/Api/loggedinuser.js";

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      clusteredPlaces: [],
      isShowingClusteredPlaces: false,
      userName: "",
      userId: "",
      userLocation: "",
      profileImage: null,
      infiniteScrollPage: 0,
      isLoadingInfiniteScroll: false,
      page: 0,
      perPage: 99999,
      searchKeyWord: null,
      sortOption: 4,
      filterOption: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
      ],
      allFiterIds: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
      ],
      city: 1,
      StartDate: null,
      EndDate: null,
      items: [],
      numOfPages: 0,
      activePage: 0,
      promoText: "",
      places: [],
      mapView: true,
      cityLat: null,
      cityLong: null,
      filtersModalOpen: false,
      isVisible: true,
      height: 0,
      scrollToMap: false,
      filters: [],
      sorts: [],
      selectedFilter: {},
      searchValue: "",
      quantity: 0,
      price: 0,
      trucking: [],
      selling: false,
      lookingFor: false,
    };
    this.searchRef = React.createRef();
    this.changeViewStatus = this.changeViewStatus.bind(this);
    this.timeoutId = null;
  }

  // componentWillReceiveProps() {
  //   if (this.props.location.state) {
  //     this.setState({ filterOption: this.props.location.state.filters });
  //     this.setState({ sortOption: this.props.location.state.sorts });
  //   }
  // }

  changeViewStatus(type) {
    if (type === 1) {
      this.setState({
        mapView: true,
      });
    } else {
      this.setState({
        mapView: false,
      });
    }
  }

  // componentDidMount() {
  //   // console.log("CHECK POST");
  //   // console.log(this.props.location.state);
  //   const vm = this;
  //   let searckkeywords;
  //   if (this.props.location.state) {
  //     searckkeywords = this.props.location.state.searchWord;
  //   }
  //   let filterOptions;
  //   if (localStorage.getItem("filterId")) {
  //     filterOptions = JSON.parse(localStorage.getItem("filterId"));
  //     // console.log(filterOptions);
  //   } else {
  //     filterOptions = this.state.filterOption;
  //   }
  //   let sortOption;
  //   if (localStorage.getItem("sortId")) {
  //     sortOption = localStorage.getItem("sortId");
  //   } else {
  //     sortOption = 4;
  //   }
  //   let StartDate;
  //   let EndDate;
  //   if (
  //     localStorage.getItem("searchStartDate") &&
  //     localStorage.getItem("searchEndDate")
  //   ) {
  //     StartDate = localStorage.getItem("searchStartDate");
  //     EndDate = localStorage.getItem("searchEndDate");
  //   } else {
  //     StartDate = null;
  //     EndDate = null;
  //   }
  //   // let city;
  //   // if (localStorage.getItem("searchCity")) {
  //   //   city = localStorage.getItem("searchCity");
  //   // } else {
  //   //   city = "1";
  //   // }

  //   let province;
  //   if (localStorage.getItem("searchProvince")) {
  //     province = localStorage.getItem("searchProvince");
  //   } else {
  //     province = "1";
  //   }

  //   getEquipments(
  //     vm.state.page,
  //     vm.state.perPage,
  //     vm.state.searchKeyWord,
  //     sortOption,
  //     filterOptions,
  //     province,
  //     StartDate,
  //     EndDate
  //   ).then(function (res) {
  //     // console.log("***** Equipment *****");
  //     // console.log(res);

  //     // vm.setState({
  //     //   numOfPages: res.totalPages,
  //     //   places: res.items.map((mark) => {
  //     //     // console.log(mark);
  //     //     return {
  //     //       id: mark.id,
  //     //       name: mark.name,
  //     //       latitude: mark.pickupLocationLatitude,
  //     //       longitude: mark.pickupLocationLongitude,
  //     //       clusterImg: mark.equipmentMedias[0].filePath,
  //     //       mapFname: mark.user.firstName,
  //     //       mapLname: mark.user.lastName,
  //     //       mapMarkerCategory: mark.category.name,
  //     //       markerRating: mark.score,
  //     //       markerUserId: mark.user.id,
  //     //     };
  //     //   }),
  //     // });
  //     vm.setState({
  //       numOfPages: res.totalPages,
  //       places: Data.data.items.map((mark) => {
  //         // console.log(mark);
  //         return {
  //           id: mark.id,
  //           name: mark.name,
  //           latitude: mark.pickupLocationLatitude,
  //           longitude: mark.pickupLocationLongitude,
  //           clusterImg: mark.equipmentMedias[0].filePath,
  //           mapFname: mark.user.firstName,
  //           mapLname: mark.user.lastName,
  //           mapMarkerCategory: mark.category.name,
  //           markerRating: mark.score,
  //           markerUserId: mark.user.id,
  //           amount: mark.dailyRate,
  //         };
  //       }),
  //     });
  //     // console.log("AFTER NEW ADDITION", vm.state.items);

  //     Data.data.items.forEach((item) => {
  //       let obj = {};

  //       // console.log(item.pickupLocationLatitude, item.pickupLocationLongitude);
  //       obj.pickupLocationLatitude = item.pickupLocationLatitude;
  //       obj.pickupLocationLongitude = item.pickupLocationLongitude;

  //       // obj.name = item.name + " " + item.make + " " + item.model;
  //       obj.name = item.name;
  //       obj.user = item.user.firstName + " " + item.user.lastName;
  //       obj.rating = item.score;
  //       obj.userId = item.user.id;
  //       obj.id = item.id;
  //       obj.imageUrl = item.equipmentMedias[0].filePath;
  //       obj.dailyRate = item.dailyRate;

  //       vm.setState(
  //         {
  //           items: vm.state.items.concat(obj),
  //         },
  //         () => {
  //           // console.log("AFTER SET STATE ADDITION", vm.state.items);
  //         }
  //       );
  //     });
  //   });
  //   if (this.searchRef.current) {
  //     this.searchRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       top: "true",
  //     });
  //   }
  // }

  getPosts = () => {
    const vm = this;
    let searckkeywords;
    if (this.props.location.state) {
      searckkeywords = this.props.location.state.searchWord;
    }
    let filterOptions;
    // if (localStorage.getItem("filterId")) {
    //   filterOptions = JSON.parse(localStorage.getItem("filterId"));
    //   console.log("filterOptions", filterOptions);
    // } else {
    filterOptions = this.state.filterOption;
    // }
    // let sortOption;
    // if (localStorage.getItem("sortId")) {
    //   sortOption = localStorage.getItem("sortId");
    // } else {
    //   sortOption = 4;
    // }
    let StartDate;
    let EndDate;
    if (
      localStorage.getItem("searchStartDate") &&
      localStorage.getItem("searchEndDate")
    ) {
      StartDate = localStorage.getItem("searchStartDate");
      EndDate = localStorage.getItem("searchEndDate");
    } else {
      StartDate = null;
      EndDate = null;
    }
    // let city;
    // if (localStorage.getItem("searchCity")) {
    //   city = localStorage.getItem("searchCity");
    // } else {
    //   city = "1";
    // }

    let province;
    if (localStorage.getItem("searchProvince")) {
      province = localStorage.getItem("searchProvince");
    } else {
      province = "1";
    }

    let pickupLoadingIncluded = false;
    let pickupLoadingNotIncluded = false;
    let deliveryUnloadingIncluded = false;
    let deliveryUnloadingNotIncluded = false;

    if (vm.state.trucking.find((item) => item.id == "checkedOne")) {
      pickupLoadingIncluded = true;
    }
    if (vm.state.trucking.find((item) => item.id == "checkedTwo")) {
      pickupLoadingNotIncluded = true;
    }
    if (vm.state.trucking.find((item) => item.id == "checkedThree")) {
      deliveryUnloadingIncluded = true;
    }
    if (vm.state.trucking.find((item) => item.id == "checkedFour")) {
      deliveryUnloadingNotIncluded = true;
    }

    getEquipments(
      0,
      vm.state.perPage,
      vm.state.searchValue,
      vm.state.sortOption,
      filterOptions,
      province,
      null,
      null,
      vm.state.selling,
      vm.state.lookingFor,
      vm.state.quantity,
      vm.state.price,
      pickupLoadingIncluded,
      pickupLoadingNotIncluded,
      deliveryUnloadingIncluded,
      deliveryUnloadingNotIncluded
    )
      .then(function (res) {
        // console.log("***** Equipment *****");
        console.log({ res });

        vm.setState({
          infiniteScrollPage: 0,
          numOfPages: res.totalPages,
          places: res.items.map((mark) => {
            // console.log(mark);
            let fileExists = mark.equipmentMedias.length > 0;

            return {
              id: mark.id,
              name: mark.name,
              latitude: mark.city.latitude,
              longitude: mark.city.longitude,
              clusterImg: fileExists ? mark.equipmentMedias[0].filePath : null,
              imageUrl: fileExists ? mark.equipmentMedias[0].filePath : null,
              mapFname: mark.user.firstName,
              mapLname: mark.user.lastName,
              selling: mark.selling,
              lookingFor: mark.lookingFor,
              markerRating: mark.score,
              markerUserId: mark.user.id,
              amount: mark.dailyRate,
              quantity: mark.quantity,
            };
          }),
        });
        //CF: Commented because is hardcoded data
        // vm.setState({
        //   // numOfPages: res.totalPages,
        //   places: Data.data.items.map((mark) => {
        //     // console.log(mark);
        //     return {
        //       id: mark.id,
        //       name: mark.name,
        //       latitude: mark.pickupLocationLatitude,
        //       longitude: mark.pickupLocationLongitude,
        //       clusterImg: mark.equipmentMedias[0].filePath,
        //       mapFname: mark.user.firstName,
        //       mapLname: mark.user.lastName,
        //       mapMarkerCategory: mark.category.name,
        //       markerRating: mark.score,
        //       markerUserId: mark.user.id,
        //       amount: mark.dailyRate,
        //       rating: mark.score,
        //     };
        //   }),
        // });
        // console.log("AFTER NEW ADDITION", vm.state.items);

        // Data.data.items.forEach((item) => {
        //   let obj = {};

        //   // console.log(item.pickupLocationLatitude, item.pickupLocationLongitude);
        //   obj.pickupLocationLatitude = item.pickupLocationLatitude;
        //   obj.pickupLocationLongitude = item.pickupLocationLongitude;

        //   // obj.name = item.name + " " + item.make + " " + item.model;
        //   obj.name = item.name;
        //   obj.user = item.user.firstName + " " + item.user.lastName;
        //   obj.rating = item.score;
        //   obj.userId = item.user.id;
        //   obj.id = item.id;
        //   obj.imageUrl = item.equipmentMedias[0].filePath;
        //   obj.dailyRate = item.dailyRate;

        //   vm.setState(
        //     {
        //       items: vm.state.items.concat(obj),
        //     },
        //     () => {
        //       // console.log("AFTER SET STATE ADDITION", vm.state.items);
        //     }
        //   );
        // });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  loadMore = (nextPage) => {
    if (this.state.isLoadingInfiniteScroll) {
      return;
    }
    if (nextPage >= this.state.numOfPages) {
      return;
    }
    this.setState({
      isLoadingInfiniteScroll: true,
      infiniteScrollPage: nextPage,
    });
    const vm = this;

    let province;
    if (localStorage.getItem("searchProvince")) {
      province = localStorage.getItem("searchProvince");
    } else {
      province = "1";
    }

    let pickupLoadingIncluded = false;
    let pickupLoadingNotIncluded = false;
    let deliveryUnloadingIncluded = false;
    let deliveryUnloadingNotIncluded = false;

    if (vm.state.trucking.find((item) => item.id == "checkedOne")) {
      pickupLoadingIncluded = true;
    }
    if (vm.state.trucking.find((item) => item.id == "checkedTwo")) {
      pickupLoadingNotIncluded = true;
    }
    if (vm.state.trucking.find((item) => item.id == "checkedThree")) {
      deliveryUnloadingIncluded = true;
    }
    if (vm.state.trucking.find((item) => item.id == "checkedFour")) {
      deliveryUnloadingNotIncluded = true;
    }

    getEquipments(
      nextPage,
      vm.state.perPage,
      vm.state.searchValue,
      vm.state.sortOption,
      this.state.filterOption,
      province,
      null,
      null,
      vm.state.selling,
      vm.state.lookingFor,
      vm.state.quantity,
      vm.state.price,
      pickupLoadingIncluded,
      pickupLoadingNotIncluded,
      deliveryUnloadingIncluded,
      deliveryUnloadingNotIncluded
    ).then(function (res) {
      // console.log("***** Equipment *****");
      // console.log(res);

      vm.setState({
        isLoadingInfiniteScroll: false,
        numOfPages: res.totalPages,
        places: [
          ...vm.state.places,
          ...res.items.map((mark) => {
            // console.log(mark);
            let fileExists = mark.equipmentMedias.length > 0;

            return {
              id: mark.id,
              name: mark.name,
              latitude: mark.city.latitude,
              longitude: mark.city.longitude,
              clusterImg: fileExists ? mark.equipmentMedias[0].filePath : null,
              imageUrl: fileExists ? mark.equipmentMedias[0].filePath : null,
              mapFname: mark.user.firstName,
              mapLname: mark.user.lastName,
              selling: mark.selling,
              lookingFor: mark.lookingFor,
              markerRating: mark.score,
              markerUserId: mark.user.id,
              amount: mark.dailyRate,
              quantity: mark.quantity,
            };
          }),
        ],
      });
    });
  };

  componentDidMount() {
    // console.log("CHECK POST");
    // console.log(this.props.location.state);
    const vm = this;

    getloggedinUser()
      .then(function (res) {
        // let city = "Calgary";
        // if (res.city !== null) {
        //   city = res.city;
        // }
        // if (localStorage.getItem("searchCity")) {
        //   city = localStorage.getItem("searchCity");
        // }
        let province = "Alberta";
        if (res.province !== null) {
          province = res.provinceCode;
        }
        if (localStorage.getItem("searchProvince")) {
          province = localStorage.getItem("searchProvince");
        }
        const fullusername = res.firstName + " " + res.lastName;
        vm.setState({
          userName: fullusername,
          userId: res.id,
          userLocation: province,
          profileImage: res.profileImage,
        });

        // console.log("*******************expdate***********");

        // console.log(localStorage.getItem('expiaryDate'));

        // res.items.forEach(item => {
        //   let obj = {}
        //   obj.name = item.name + " " + item.make + " " + item.model;
        //   obj.user = item.user.firstName + " " + item.user.lastName;
        //   obj.rating = Math.floor(Math.random() * 5) + 1;
        //   obj.userId = item.user.id;
        //   obj.id = item.id;
        //   obj.imageUrl = item.equipmentMedias[0].filePath
        //   vm.setState({
        //     items : vm.state.items.concat(obj)
        //   })
        // });
      })
      .catch(function (res) {
        console.log("***** ownuserinfo *****");
        console.log(res);
      });

    getFilters().then(function (res) {
      vm.setState({ filters: res });
    });
    getSorts().then(function (res) {
      vm.setState({ sorts: res });
    });

    this.getPosts();

    if (this.searchRef.current) {
      this.searchRef.current.scrollIntoView({
        behavior: "smooth",
        top: "true",
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterOption !== this.state.filterOption) {
      console.log("pokemons state has changed.");
      this.getPosts();
      return;
    }
    if (prevState.sortOption !== this.state.sortOption) {
      console.log("sortOption state has changed.");
      this.getPosts();
      return;
    }

    if (prevState.quantity !== this.state.quantity) {
      this.getPosts();
      return;
    }

    if (prevState.price !== this.state.price) {
      this.getPosts();
      return;
    }

    if (prevState.trucking !== this.state.trucking) {
      this.getPosts();
      return;
    }
    // if(prevState.pickupLoadingIncluded !== this.state.pickupLoadingIncluded){
    //   this.getPosts();
    //   return
    // }

    // if(prevState.pickupLoadingNotIncluded !== this.state.pickupLoadingNotIncluded){
    //   this.getPosts();
    //   return
    // }

    // if(prevState.deliveryUnloadingIncluded !== this.state.deliveryUnloadingIncluded){
    //   this.getPosts();
    //   return
    // }

    // if(prevState.deliveryUnloadingNotIncluded !== this.state.deliveryUnloadingNotIncluded){
    //   this.getPosts();
    //   return
    // }

    if (prevState.searchValue !== this.state.searchValue) {
      console.log("searchValue state has changed.");
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      if (this.state.searchValue) {
        this.timeoutId = setTimeout(() => {
          this.handleSearch();
        }, 700);
      } else {
        this.getPosts();
        return;
      }
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
  // componentWillReceiveProps(nextProp) {
  //   this.setState({ mapView: false }, () => {
  //     this.handleSearch();
  //     if (nextProp.location.state) {
  //       this.setState({
  //         searchKeyWord: nextProp.location.state.searchWord,
  //         filterOption: nextProp.location.state.filters,
  //       });
  //     } else {
  //       this.setState({ searchKeyWord: "" });
  //     }
  //   });
  // }
  refreshPage() {
    window.location.reload();
    this.setState({ filterOption: 0 });
  }
  // changeActivePage = (index) => {
  //   // console.log(index);
  //   this.setState({ activePage: index }, () => {
  //     this.handleSearch();
  //   });
  // };

  // backPage = () => {
  //   if (this.state.activePage >= 1) {
  //     this.setState({ activePage: this.state.activePage - 1 }, () => {
  //       this.handleSearch();
  //     });
  //   }
  // };

  // forwardPage = () => {
  //   if (this.state.activePage + 1 < this.state.numOfPages) {
  //     this.setState({ activePage: this.state.activePage + 1 }, () => {
  //       this.handleSearch();
  //     });
  //   }
  // };

  submitFilters = () => {
    this.getPosts();
  };
  handleSearch(e = null) {
    if (e) {
      e.preventDefault();
    }
    let filterOptions;
    // if (localStorage.getItem("filterId")) {
    //   filterOptions = JSON.parse(localStorage.getItem("filterId"));
    // } else {
    //   filterOptions = JSON.stringify(this.state.filterOption);
    // }
    filterOptions = this.state.filterOption;

    // let sortOption;
    // if (localStorage.getItem("sortId")) {
    //   sortOption = localStorage.getItem("sortId");
    // } else {
    //   sortOption = 4;
    // }
    let StartDate;
    let EndDate;
    if (
      localStorage.getItem("searchStartDate") &&
      localStorage.getItem("searchEndDate")
    ) {
      StartDate = localStorage.getItem("searchStartDate");
      EndDate = localStorage.getItem("searchEndDate");
    } else {
      StartDate = null;
      EndDate = null;
    }
    // let city;
    // if (localStorage.getItem("searchCity")) {
    //   city = localStorage.getItem("searchCity");
    // } else {
    //   city = "1";
    // }

    let province;
    if (localStorage.getItem("searchProvince")) {
      province = localStorage.getItem("searchProvince");
    } else {
      province = "1";
    }

    const vm = this;

    let pickupLoadingIncluded = false;
    let pickupLoadingNotIncluded = false;
    let deliveryUnloadingIncluded = false;
    let deliveryUnloadingNotIncluded = false;

    if (vm.state.trucking.find((item) => item.id == "checkedOne")) {
      pickupLoadingIncluded = true;
    }
    if (vm.state.trucking.find((item) => item.id == "checkedTwo")) {
      pickupLoadingNotIncluded = true;
    }
    if (vm.state.trucking.find((item) => item.id == "checkedThree")) {
      deliveryUnloadingIncluded = true;
    }
    if (vm.state.trucking.find((item) => item.id == "checkedFour")) {
      deliveryUnloadingNotIncluded = true;
    }

    this.setState({ infiniteScrollPage: 0, items: [] }, () => {
      getEquipments(
        0, //On search active page is always 0
        vm.state.perPage,
        vm.state.searchValue,
        vm.state.sortOption,
        filterOptions,
        province,
        null,
        null,
        vm.state.selling,
        !vm.state.selling,
        vm.state.quantity,
        vm.state.price,
        pickupLoadingIncluded,
        pickupLoadingNotIncluded,
        deliveryUnloadingIncluded,
        deliveryUnloadingNotIncluded
      )
        .then(function (res) {
          // console.log("***** Equipment *****");
          // console.log(res);
          vm.setState({
            infiniteScrollPage: 0,
            numOfPages: res.totalPages,
            places: res.items.map((mark) => {
              // console.log(mark);
              let fileExists = mark.equipmentMedias.length > 0;

              return {
                id: mark.id,
                name: mark.name,
                latitude: mark.city.latitude,
                longitude: mark.city.longitude,
                clusterImg: fileExists
                  ? mark.equipmentMedias[0].filePath
                  : null,
                imageUrl: fileExists ? mark.equipmentMedias[0].filePath : null,
                mapFname: mark.user.firstName,
                mapLname: mark.user.lastName,
                selling: mark.selling,
                lookingFor: mark.lookingFor,
                markerRating: mark.score,
                markerUserId: mark.user.id,
                amount: mark.dailyRate,
                quantity: mark.quantity,
              };
            }),
          });
          // res.items.forEach((item) => {
          //   let obj = {};
          //   // obj.name = item.name + " " + item.make + " " + item.model;
          //   obj.name = item.name;
          //   obj.user = item.user.firstName + " " + item.user.lastName;
          //   obj.rating = item.score;
          //   obj.userId = item.user.id;
          //   obj.id = item.id;
          //   obj.dailyRate = item.dailyRate;
          //   obj.pickupLocationLatitude = item.pickupLocationLatitude;
          //   obj.pickupLocationLongitude = item.pickupLocationLongitude;

          //   obj.imageUrl = item.equipmentMedias[0].filePath;
          //   vm.setState({
          //     items: vm.state.items.concat(obj),
          //     filterOption: 0,
          //   });
          // });
        })
        .catch(function (rez) {
          localStorage.removeItem("filterId");
          localStorage.removeItem("sortId");
          localStorage.removeItem("searchStartDate");
          localStorage.removeItem("searchEndDate");
          localStorage.removeItem("searchCity");
        });
    });
    if (this.searchRef.current) {
      this.searchRef.current.scrollIntoView({
        behavior: "smooth",
        top: "true",
      });
    }
  }

  //end of handle search

  toggle = () => {
    this.setState({ filtersModalOpen: !this.state.filtersModalOpen });
  };
  // setFilterOption = () => {
  //   this.setState({ filterOption: !this.state.filtersModalOpen });
  // };

  handleFiltersState = (childStateValue) => {
    if (childStateValue.length == 0) {
      return;
    }
    this.setState({ filterOption: childStateValue });
    const selectedItem = this.state.filters.find(
      (filter) => filter.id == childStateValue
    );
    this.setState({ selectedFilter: selectedItem });
  };

  handleQuantityFiltersState = (childStateValue) => {
    this.setState({ quantity: childStateValue });
  };

  handlePriceFiltersState = (childStateValue) => {
    this.setState({ price: childStateValue });
  };

  handleTruckingFiltersState = (childStateValue) => {
    this.setState({ trucking: childStateValue });
  };
  handleSellingState = (childStateValue) => {
    this.setState({ selling: childStateValue });
  };
  handleLookingForState = (childStateValue) => {
    this.setState({ lookingFor: childStateValue });
  };

  removeTruckingOption = (id) => {
    const updatedTrucking = this.state.trucking.filter(
      (item) => item.id !== id
    );
    this.setState({ trucking: updatedTrucking });
  };

  handleSearchState = (childStateValue) => {
    this.setState({ searchValue: childStateValue });
  };

  categoryChange = (item) => {
    this.setState({ filterOption: item.categoryId }, () => {
      this.handleSearch();
    });
  };

  setClusteredPlaces = (clusteredPlaces) => {
    this.setState({ clusteredPlaces });
  };

  setIsShowingClusteredPlaces = (value) => {
    this.setState({ isShowingClusteredPlaces: value });
  };

  render() {
    //console.log("items", this.state.items);

    const currencyFormatter = new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
    });

    // const Pagination = () => {
    //   let list = [];
    //   if (this.state.numOfPages > 0) {
    //     list.push(
    //       <a
    //         className={this.state.activePage <= 0 ? "disabled-button" : null}
    //         onClick={() => this.backPage()}
    //       >
    //         &laquo;
    //       </a>
    //     );
    //     for (let i = 0; i < this.state.numOfPages; i++) {
    //       list.push(
    //         <a
    //           style={
    //             this.state.activePage == i
    //               ? { backgroundColor: "#FFCD46", color: "white" }
    //               : null
    //           }
    //           onClick={() => this.changeActivePage(i)}
    //         >
    //           {i + 1}
    //         </a>
    //       );
    //     }
    //     list.push(
    //       <a
    //         className={
    //           this.state.activePage + 1 >= this.state.numOfPages
    //             ? "disabled-button"
    //             : null
    //         }
    //         onClick={() => this.forwardPage()}
    //       >
    //         &raquo;
    //       </a>
    //     );
    //   }
    //   return list;
    // };

    return (
      <React.Fragment>
        <Filters
          modalOpen={this.state.filtersModalOpen}
          toggle={this.toggle}
          submitFilters={this.submitFilters}
          onChildStateChange={this.handleFiltersState}
          onQuantityStateChange={this.handleQuantityFiltersState}
          onPricetateChange={this.handlePriceFiltersState}
          onTruckingStateChange={this.handleTruckingFiltersState}
          onSellingStateChange={this.handleSellingState}
          sellingChecked={this.state.selling}
          lookingForChecked={this.state.lookingFor}
          onLookingForStateChange={this.handleLookingForState}
          quantity={this.state.quantity}
          parentFilterOption={this.state.filterOption}
          parentQuantity={this.state.quantity}
          parentPrice={this.state.price}
          parentTrucking={this.state.trucking}
        ></Filters>
        <Video />
        <div
          id="test"
          style={{
            height: "100vh",
            // position: "relative"
          }}
        >
          <div
            style={{
              // height: "18vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <Header
              // authenticated={this.props.authenticated}
              authenticated={
                localStorage.getItem("accessToken") &&
                localStorage.getItem("accessToken") != null
              }
              bannerDisplay={this.props.bannerDisplay}
              userName={this.state.userName}
              userLocation={this.state.userLocation}
              userId={this.state.userId}
              profileImage={this.state.profileImage}
              handleSearch={this.handleSearchState}
              searchValue={this.state.searchValue}
            />
            {!this.state.isShowingClusteredPlaces && (
              <Navbar className="d-flex">
                <button
                  className="btn-dark"
                  onClick={() => this.setState({ filtersModalOpen: true })}
                >
                  <img
                    src={require("../../Common/images/newicons/icon-filter.svg")}
                    alt="icon filter"
                  />
                  Filters
                </button>
                {this.state.filterOption !== 0 &&
                  this.state.selectedFilter.name && (
                    <div className="filter-badge ml-4">
                      <span className="mr-3">
                        {`${this.state.selectedFilter.name} - 
                      ${
                        this.state.selectedFilter.hayType == 0
                          ? "Hay Bales"
                          : ""
                      }
                      ${
                        this.state.selectedFilter.hayType == 1
                          ? "Straw Bales"
                          : ""
                      }
                      ${
                        this.state.selectedFilter.hayType == 2
                          ? "Feed Grain"
                          : ""
                      }`}
                      </span>{" "}
                      <img
                        src={require("../../Common/images/newicons/close-btn.svg")}
                        alt="close icon"
                        style={{ width: "25px", cursor: "pointer" }}
                        onClick={() => {
                          this.setState({ filterOption: 0 });
                          this.setState({ selectedFilter: {} });
                        }}
                      />
                    </div>
                  )}
                {this.state.quantity > 0 && (
                  <div className="filter-badge ml-4">
                    <span className="mr-3">
                      <span
                        style={{
                          borderRadius: "8px",
                          padding: "4px 4px 4px 8px",
                        }}
                        className="text-white mr-2 bg-dark text-center"
                      >{`${this.state.quantity} `}</span>{" "}
                      {this.state.selectedFilter.hayType == 2
                        ? "Tonnes"
                        : "Bales"}{" "}
                      Available
                    </span>{" "}
                    <img
                      src={require("../../Common/images/newicons/close-btn.svg")}
                      alt="close icon"
                      style={{ width: "25px", cursor: "pointer" }}
                      onClick={() => this.setState({ quantity: 0 })}
                    />
                  </div>
                )}
                {this.state.price > 0 && (
                  <div className="filter-badge ml-4">
                    <span className="mr-3">
                      $ 0 - {currencyFormatter.format(this.state.price)}{" "}
                    </span>
                    <img
                      src={require("../../Common/images/newicons/close-btn.svg")}
                      alt="close icon"
                      style={{ width: "25px", cursor: "pointer" }}
                      onClick={() => this.setState({ price: 0 })}
                    />
                  </div>
                )}
                {this.state.trucking.length > 0 &&
                  this.state.trucking.map((item) => {
                    return (
                      <div className="filter-badge ml-4">
                        <span className="mr-3">{item.label}</span>
                        <img
                          src={require("../../Common/images/newicons/close-btn.svg")}
                          alt="close icon"
                          style={{ width: "25px", cursor: "pointer" }}
                          onClick={() => this.removeTruckingOption(item.id)}
                        />
                      </div>
                    );
                  })}

                <div
                  className="mx-4 d-flex align-items-center px-3"
                  style={{
                    borderLeft: "1px solid #E2E2E2",
                    fontSize: 18,
                  }}
                >
                  <div
                    className="px-2 d-flex "
                    // onClick={() => this.setState({ filtersModalOpen: true })}
                  >
                    Price
                    <div className="mx-2 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src={require("../../Common/images/newicons/sort-up-icon.svg")}
                        alt="icon filter"
                        style={{
                          cursor: "pointer",
                          opacity: this.state.sortOption == 0 ? "1" : "0.5",
                        }}
                        className={"mb-1"}
                        onClick={() => this.setState({ sortOption: 0 })}
                        title="Price - High to Low"
                      />
                      <img
                        src={require("../../Common/images/newicons/sort-up-icon.svg")}
                        alt="icon filter"
                        style={{
                          cursor: "pointer",
                          transform: "rotate(180deg)",
                          opacity: this.state.sortOption == 1 ? "1" : "0.5",
                        }}
                        className={"mb-1"}
                        onClick={() => this.setState({ sortOption: 1 })}
                        title="Price - Low to High"
                      />
                    </div>
                  </div>
                  <div
                    className="px-2 d-flex "
                    // onClick={() => this.setState({ filtersModalOpen: true })}
                  >
                    Rating
                    <div className="mx-2 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src={require("../../Common/images/newicons/sort-up-icon.svg")}
                        alt="icon filter"
                        style={{
                          cursor: "pointer",
                          opacity: this.state.sortOption == 2 ? "1" : "0.5",
                        }}
                        className="mb-1"
                        onClick={() => this.setState({ sortOption: 2 })}
                        title="Rating - Highest to Lowest"
                      />
                      <img
                        src={require("../../Common/images/newicons/sort-up-icon.svg")}
                        alt="icon filter"
                        style={{
                          cursor: "pointer",
                          transform: "rotate(180deg)",
                          opacity: this.state.sortOption == 3 ? "1" : "0.5",
                        }}
                        className="mb-1"
                        onClick={() => this.setState({ sortOption: 3 })}
                        title="Rating - Lowest to Highest"
                      />
                    </div>
                  </div>
                </div>
              </Navbar>
            )}
          </div>

          {this.state.mapView ? (
            <div
              // ref={this.scrollToComponentRef}
              // ref={this.state.scrollToMap ? this.scrollToComponentRef : null}
              ref={this.searchRef}
              className="mapcluster-container"
              style={{ padding: 0 }}
            >
              {this.state.places.length > 0 ? (
                <CustomMarker
                  zoom={8}
                  cityLat={this.state.cityLat}
                  cityLong={this.state.cityLong}
                  places={this.state.places}
                  setClusteredPlaces={this.setClusteredPlaces}
                  setIsShowingClusteredPlaces={this.setIsShowingClusteredPlaces}
                  changeViewStatus={this.changeViewStatus}
                />
              ) : (
                <div className="no-results-div">
                  <span className="no-results-span">
                    No Results found, please try another city or filter.
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div
              className="py-4"
              style={{
                overflowY: "auto",
                height: "500px",
                minHeight: "82vh",
              }}
            >
              <InfiniteScroll //new
                key={this.state.infiniteScrollPage}
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  gridGap: "10px",
                }}
                pageStart={this.state.infiniteScrollPage}
                loadMore={this.loadMore}
                hasMore={
                  this.state.infiniteScrollPage < this.state.numOfPages - 1
                }
                useWindow={false}
              >
                {!this.state.isShowingClusteredPlaces &&
                  this.state.places.map((item, index) => {
                    return (
                      <div
                        className="test"
                        style={{ width: "100%", gridColumn: "span 1" }}
                        key={item.id}
                      >
                        <Post
                          postName={item.name}
                          postUser={`${item.mapFname} ${item.mapLname}`}
                          postRating={item.markerRating}
                          postId={item.id}
                          postUserId={item.markerUserId}
                          postImageUrl={item.imageUrl}
                          postType={item.selling ? "Selling" : "Looking For"}
                          amount={item.amount}
                          postQuantity={item.quantity}
                        />
                      </div>
                    );
                  })}
                {this.state.isShowingClusteredPlaces &&
                  this.state.clusteredPlaces.map((item, index) => {
                    return (
                      <div
                        className="test"
                        style={{ width: "100%", gridColumn: "span 1" }}
                        key={item.id}
                      >
                        <Post
                          postName={item.name}
                          postUser={`${item.mapFname} ${item.mapLname}`}
                          postRating={item.markerRating}
                          postId={item.id}
                          postUserId={item.markerUserId}
                          postImageUrl={item.imageUrl}
                          postType={item.selling ? "Selling" : "Looking For"}
                          amount={item.amount}
                          postQuantity={item.quantity}
                        />
                      </div>
                    );
                  })}
              </InfiniteScroll>
            </div>
          )}

          {/* <div className="paginate">
            <Pagination />
          </div> */}
          {/* <div className="absolute-Center"> */}
          <button
            style={{
              position: "absolute",
              left: "45%",
              bottom: "30px",
            }}
            className="btn-dark "
            onClick={() => {
              this.setIsShowingClusteredPlaces(false);
              this.setState({
                mapView: !this.state.mapView,
              });
            }}
          >
            <img
              src={
                this.state.mapView
                  ? require("../../Common/images/newicons/icon-list.svg")
                  : require("../../Common/images/newicons/icon-map.svg")
              }
              alt="icon filter"
            />
            {this.state.mapView ? "View List" : "View Map"}
          </button>
          {/* </div> */}
        </div>
      </React.Fragment>
    );
  }
}
export default Dashboard;
