import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { PropTypes } from "react";

/*global google*/
class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
    this.placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address, placeId) => {
    geocodeByAddress(address)
      .then((results) => 
        getLatLng(results[0])
      )
      // .then(latLng => console.log('Success', latLng))
      .then((latLng) =>  {
        console.log("Success", latLng);
        this.props.getlatlong(latLng);
      })
      // .catch(error => console.error('Error', error));
      .catch((error) => alert("we couldnt find the address, try again"));
    this.setState({ address });
    this.props.theaddress(address);
    const request = {
      placeId: placeId,
      fields: ["name", "geometry"],
    };
    this.placesService.getDetails(request, (place, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        this.props.onPlaceChanged(place);
      }
    });
  };

  render() {
    console.log("the address", this.state.address);
    const searchOptions = {
      componentRestrictions: { country: "ca" },
    };
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="autocomplete-container">
            <input
              {...getInputProps({
                placeholder: "Enter Address here ...",
                className: "location-search-input",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { places: [] };
    this.showPlace = this.showPlace.bind(this);
    this.mapRef = React.createRef();
  }
  componentDidMount() {
    if (this.props.places) {
      this.setState({ places: this.props.places });
    }
  }

  showPlace(place) {
    this.setState((prevState) => ({
      places: [...prevState.places, place],
    }));
    this.mapRef.current.map.setCenter(place.geometry.location);
  }

  render() {
    // console.log(this.props)
    //console.log('the address', this.state.address)
    //getLatLng(this.state.address);

    //console.log(this.state.places[0].geometry)
    //   console.log(this.state.showPlace)
    //  console.log(this.state.mapRef)
    //  console.log(this.state.address)

    return (
      <div className="map-container">
        {!this.props.renting ? (
          <LocationSearchInput
            theaddress={this.props.theaddress}
            getlatlong={this.props.getlatlong}
            onPlaceChanged={this.showPlace}
          />
        ) : null}
        <Map
          ref={this.mapRef}
          google={this.props.google}
          className={"map"}
          zoom={7}
          initialCenter={this.props.center}
        >
          {this.state.places.map((place, i) => {
            // console.log(place)
            return <Marker key={i} position={place.geometry.location} />;
          })}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDpLI1D7Mc3yq_7sc6l6dkgFPPYpmiLVLM",
  libraries: ["places"],
})(MapContainer);
