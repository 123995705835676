import React from "react";
import {
  Card,
  Button,
  CardTitle,
  CardHeader,
  CardImg,
  CardBody,
  CardSubtitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import "../../ViewPostTabs/style.css";
import "../../ViewPostTabs/viewPostTabs.css";
import moment from "moment";

export default class RentalHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rentalHistory: [],
      historyItemDetails: [],
      userId: localStorage.getItem("userId"),
      myOwnerId: "",
    };
  }
  componentDidMount() {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },

      url: `${process.env.REACT_APP_API}/equipments/${this.props.equipmentId}/rentalhistory`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      console.log("**** Rental History ****", res.data.items);

      let rentalHistoryObj = res.data.items.map((rentalHistoryElement) => {
        console.log("THIS IS HERE", rentalHistoryElement);
        let rentedOn = null;
        let completedOn = null;

        if (rentalHistoryElement.createdAt != null) {
          rentedOn = moment(rentalHistoryElement.createdAt).format("LLL");
        }

        if (rentalHistoryElement.refundedAt != null) {
          completedOn = moment(rentalHistoryElement.refundedAt).format("LLL");
        }
        return {
          historyId: rentalHistoryElement.id,
          equipmentImage:
            rentalHistoryElement.equipments[0].equipmentMedias[0].filePath,
          equipmentName: rentalHistoryElement.equipments[0].name,
          renterImage: rentalHistoryElement.renter.profileImage,
          renterName:
            rentalHistoryElement.renter.firstName + " " +
            rentalHistoryElement.renter.lastName,
          renterId: rentalHistoryElement.renter.id,
          rentedOn: rentedOn,
          returnedOn: completedOn,
          equipmentOwnerId: rentalHistoryElement.equipmentOwner.id,
          equipmentOwnerName:
            rentalHistoryElement.equipmentOwner.firstName +
            " " +
            rentalHistoryElement.equipmentOwner.lastName,
        };
      });

      console.log("**** rentalHistoryElement ****", rentalHistoryObj);
      vm.setState({
        rentalHistory: rentalHistoryObj,
        myOwnerId: rentalHistoryObj[0].equipmentOwnerId,
      });
      console.log("**** myOwnerId ****", vm.state.myOwnerId);
    });
  }

  render() {
    console.log("***** user ID ******", this.state.rentalHistory);
    return (
      <div>
        {this.state.userId === this.state.myOwnerId ? (
          <div>
            {this.state.rentalHistory.map((rentalHistoryElement) => {
              return (
                <div
                  key={rentalHistoryElement.id}
                  className="history-item-container"
                >
                  <div className="leftside-pic">
                    <CardImg
                      top
                      width="100%"
                      src={rentalHistoryElement.equipmentImage}
                      alt="Card image cap"
                    />
                  </div>
                  <div className="rightside-info">
                    <div className="info1">
                      <h4 class="post-title">
                        {" "}
                        {rentalHistoryElement.equipmentName}{" "}
                      </h4>
                    </div>
                    <div>
                      <div className="info2">
                        <Link
                          to={{
                            pathname: `/profile/${rentalHistoryElement.renterId}`,
                            state: { userId: this.state.myOwnerId },
                          }}
                        >
                          <div className="rentingout-pf-section-container">
                            {/* <div className="first">
                              <img
                                width="100%"
                                src={rentalHistoryElement.renterImage}
                                alt="item img"
                              />
                            </div> */}
                            <span className="dash-username">{rentalHistoryElement.renterName}</span>
                          </div>
                        </Link>
{/* 
                        <div className="info-prof-pic">
                          <img
                            className="left-side-pf"
                            src={rentalHistoryElement.renterImage}
                            alt="search"
                          />
                        </div>

                        <div className="right-side">
                          <div className="right-side-one">
                            <p className="username-of-renter">
                              {" "}
                              <Link
                                to={{
                                  pathname: `/profile/${rentalHistoryElement.renterId}`,
                                  state: { userId: this.state.myOwnerId },
                                }}
                              >
                                {" "}
                                {rentalHistoryElement.renterName}{" "}
                              </Link>{" "}
                            </p>
                          </div>
                        </div> */}
                      </div>
                      <div className="info3">
                        <p class="rented-date" style={{ fontWeight: 500 }}>
                          Ordered: <span> {rentalHistoryElement.rentedOn}</span>{" "}
                        </p>
                      </div>
                      <div className="info4">
                        <p class="returned-date" style={{ fontWeight: 500 }}>
                          Completed:{" "}
                          <span> {rentalHistoryElement.returnedOn}</span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="" style={{ fontSize: 18, width: "100%" }}>
            <p>No registers where found</p>
          </div>
        )}
      </div>
    );
  }
}
RentalHistory.propTypes = {
  postImageUrl: PropTypes.string,
  postName: PropTypes.string,
  postUser: PropTypes.string,
  postRating: PropTypes.number,
  postId: PropTypes.string,
  postUserId: PropTypes.string,
};
