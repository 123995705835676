import React, { useState, useEffect } from "react";
import videoBg from "./HayThereVideoBg.mp4";
import "./video.css";

const Video = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0);

  const hide = sessionStorage.getItem("hideVideo");

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  useEffect(() => {
    if (hide) {
      setIsVisible(false);
    }
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 200;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);

    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };
  // console.log("video", props);
  return (
    <div id="video-container">
      {isVisible && (
        <div id="hide" className="video-main">
          <div className="overlay"></div>
          <video src={videoBg} autoPlay loop muted />
          <div className="video-content">
            <div className="mb-10">
              <img
                src={require("../../../Common/images/HAYThere-logo-white.svg")}
                alt="Hay There Logo"
                width={450}
                style={{ marginBottom: 30, marginTop: "-50px" }}
              />
            </div>
            <h1 className="video-title mb-4 text-center">
              Mapping the feed marketplace
            </h1>
            <h2 className="video-subtitle">Join our trusted network today</h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default Video;
