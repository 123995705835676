import $ from "jquery";

export function getEquipments(
  page,
  perPage,
  searchKeyWord,
  sortOption,
  filterOption,
  province,
  StartDate,
  EndDate,
  Selling,
  LookingFor,
  Quantity,
  Price,
  PickUpLoadingIncluded,
  PickUpLoadingNotIncluded,
  DeliveryUnloadingIncluded,
  DeliveryUnloadingNotIncluded
) {
  let data = {};
  if (page != null) {
    data.Page = page;
  }
  if (perPage != null) {
    data.PerPage = perPage;
  }
  if (searchKeyWord != null && searchKeyWord != "") {
    data.SearchKeyWord = searchKeyWord;
  }
  if (sortOption != null) {
    data.SortOptionId = sortOption;
  }
  if (filterOption != null) {
    data.FilterOptionIds = filterOption;
  }
  if (province != null) {
    data.ProvinceIds = province;
  }
  // if (StartDate != null) {
  //   data.StartDate = StartDate;
  // }

  // if (EndDate != null) {
  //   data.EndDate = EndDate;
  // }

  //Endpoint returning empty if selling and looking for both true
  if(Selling & LookingFor){
    Selling = false
    LookingFor = false
  }

  if (Selling != null) {
    data.Selling = Selling;
  }

  if (LookingFor != null) {
    data.LookingFor = LookingFor;
  }

  if (Quantity != null || Quantity != 0) {
    data.Quantity = Quantity;
  }

  if (Price != null || Price != 0) {
    data.Price = Price;
  }

  if (PickUpLoadingIncluded != null) {
    data.PickUpLoadingIncluded = PickUpLoadingIncluded;
  }

  if (PickUpLoadingNotIncluded != null) {
    data.PickUpLoadingNotIncluded = PickUpLoadingNotIncluded;
  }

  if (DeliveryUnloadingIncluded != null) {
    data.DeliveryUnloadingIncluded = DeliveryUnloadingIncluded;
  }

  if (DeliveryUnloadingNotIncluded != null) {
    data.DeliveryUnloadingNotIncluded = DeliveryUnloadingNotIncluded;
  }
  let header = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (localStorage.getItem("accessToken")) {
    header.Authorization = "Bearer " + localStorage.getItem("accessToken");
  }
  return $.ajax({
    method: "GET",
    dataType: "json",
    data: data,
    traditional: true,
    xhrFields: {
      withCredentials: true,
    },
    url: process.env.REACT_APP_API + "/equipments",
    headers: header,
  }).then((res) => res.data);
}
