import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";

//import NumberFormat from 'react-number-format';

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-brands-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
// import { postRegister } from '../../../App/Api/register/register.js'
import { postRegister } from "../../../App/Api/register/register.js";
import { postVerify } from "../../../App/Api/register/verify.js";

import "./style.css";

class PhoneVerificationCodeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
  }

  // changetoLogin() {
  //   this.props.toggle('signin');
  //   this.props.toggle('login');
  // }

  changeToSignUpSuccess = (e) => {
    e.preventDefault();
    const vm = this;
    //if signup all good with no errors then redirect to homepage and change to authenticaed=true
    let data = Object.assign({}, this.props.registerData, {
      smsVerificationCode: this.state.code,
    });
    postRegister(data).then(function (res) {
      // console.log(res);
      if (res.code === "SUCCESS") {
        vm.props.toggle("verificationCodeModal");

        vm.props.toggle("success");

        setTimeout(() => {
          vm.props.toggle("success");
          vm.props.toggle("login");
        }, 5000);
      }
    });
  };

  resendCode = () => {
    postVerify().then(function (res) {
      // console.log(res)
      this.props.registerData.smsVerificationToken =
        res.data.smsVerificationToken;
      this.props.setRegisterData(this.props.registerData);
    });
  };

  render() {
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("verificationCodeModal")}
      >
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="signup-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("tosmodal")}
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#FFCD46" }}
          >
            Verification
          </ModalHeader>
          <ModalBody style={{ paddingTop: 20 }}>
            <Form>
              <FormGroup row>
                <Col sm={12}>
                  <p>
                    Please enter the code you received on your phone. If you
                    haven't received it in a few minutes, hit the Re-send link{" "}
                  </p>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="phoneverificationcode" sm={2} size="lg" hidden>
                  Enter phone verification Code
                </Label>
                <Col sm={12}>
                  <input
                    onChange={(e) => this.setState({ code: e.target.value })}
                    type="text"
                    name="phoneverificationcode"
                    id="phoneverificationcode"
                    placeholder="Enter Code"
                    bsSize="lg"
                    className="input-style"
                  />
                </Col>
              </FormGroup>

              <button
                className="btn-hay-yellow my-4"
                style={{ width: "100%" }}
                onClick={this.changeToSignUpSuccess}
                size="lg"
                block
              >
                Submit
              </button>
            </Form>
          </ModalBody>
          <ModalFooter style={{ padding: "0 0 30px" }}>
            <Row style={{ width: "90%" }}>
              <Col xs="12" style={{ textAlign: "center", marginBottom: 15 }}>
                <a className="forgot-pass-login">Re-Send Code</a>
              </Col>
              <Col xs="12" style={{ textAlign: "center" }}>
                <a
                  className="forgot-pass-login mt-4"
                  onClick={() => {
                    this.props.toggle("verificationCodeModal");
                    this.props.toggle("verificationPhoneModal");
                  }}
                >
                  Back
                </a>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

PhoneVerificationCodeModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default PhoneVerificationCodeModal;
