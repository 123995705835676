import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import $ from "jquery";
import { Link } from "react-router-dom";
import FormattedDateTime from "../../Chats/FormattedDateTime";
import { BallBeat } from "react-pure-loaders";

class ChatPopup extends React.Component {
  constructor() {
    super();
    this.state = {
      items: [],
      count: 0,
      loading: true,
    };
  }

  render() {
    return (
      <div>
        <div style={{ textAlign: "center" }} className="sweet-loading">
          <BallBeat color={"#FFCD46"} loading={this.state.loading} />{" "}
        </div>
        {/* {this.sendData} */}
        {this.props.chatHistory.map((chat, i) => {
          let img;
          if (chat.icon == null) {
            img =
              "https://s3.ca-central-1.amazonaws.com/vog-project-common/profile.png";
          } else {
            img = chat.icon;
          }
          return (
            <Link
              className="chatLink"
              onClick={() => this.handleClick(chat.id)}
              to={chat.url}
              key={i}
            >
              <div className="noti-container">
                <Row className="chat-row">
                  <Col sm="2">
                    <div className="profile-img">
                      <img
                        className="img-circle"
                        src={img}
                        alt="user-profile"
                      />
                    </div>
                  </Col>
                  <Col className="col-flex " sm="6">
                    <Row
                      className="pr-2"
                      style={
                        chat.readAt !== null
                          ? { color: "grey" }
                          : { color: "#FFCD46" }
                      }
                    >
                      {chat.message}
                    </Row>
                  </Col>
                  <Col sm="2" className="read-date">
                    <Row
                      style={{
                        color: "#AAAAAA",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <FormattedDateTime
                        datetime={chat.createdAt}
                        ago
                      />
                    </Row>
                  </Col>
                </Row>
                <hr />
              </div>
            </Link>
          );
        })}
      </div>
    );
  }
}

ChatPopup.propTypes = {
  authenticated: PropTypes.bool,
  userName: PropTypes.string,
  userLocation: PropTypes.string,
  chats: PropTypes.object,
  positionFixed: PropTypes.bool,
};

export default ChatPopup;
