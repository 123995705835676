import $ from 'jquery';

export function activateEquipment(id){

  // return $.ajax({
  //   method: "PUT",
  //   dataType: 'json',
  //   traditional: true,
  //   data: {},
  //   xhrFields: {
  //     withCredentials: true
  //  },
  //   url: `${process.env.REACT_APP_API}/equipments/${id}/activate`,
    
  //   headers: {
  //       "Authorization": "Bearer " + localStorage.getItem('accessToken'),
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //   },
  // }).then(res => res.data).catch(res => res)
  return $.ajax({
    method: "PUT",
    dataType: 'json',
    traditional: true,
    data: {},
    xhrFields: {
      withCredentials: true
   },
    url: `${process.env.REACT_APP_API}/equipments/${id}/updateactivestatus`,
    
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('accessToken'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
  }).then(res => res.data).catch(res => res)
}