import React from "react";
import { Redirect, Route, Switch, Router } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import Dashboard from "../Pages/Dashboard";
import Settings from "../Pages/Settings";
import PostPage from "../Pages/PostPage";
import Profile from "../Pages/Profile";
import MyProfile from "../Pages/MyProfile";
import Renting from "../Pages/Renting";
import EditPost from "../Pages/PostPage/EditPost";
import ViewRentingPost from "../Pages/RentingPost";
import ForgotPassword from "../Pages/ForgotPassword";
import googleImage from "./Google_Play-black.png";
import appleImage from "./app_store-black.png";

import "./App.css";

import Header from "../Common/Header";
import Footer from "../Common/Footer";

import MainLayout from "../Common/MainLayout";

import { getloggedinUser } from "../App/Api/loggedinuser.js";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      userName: null,
      userId: 1,
      userLocation: "Alberta",
      profileImage: null,
      bannerDisplay: "flex",
      outsideChatUserId: null,
    };
    this.handler = this.handler.bind(this);
  }

  handler(value) {
    this.setState({
      outsideChatUserId: value,
    });
  }

  resize() {
    //CF: commented to work on smaller screens and avoid overlay of download app modal
    // this.setState({ mobileScreen: window.innerWidth <= 1200 });
    this.setState({ mobileScreen: false });
  }
  componentWillMount() {
    // console.log(localStorage.getItem('accessToken'))
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") != null
    ) {
      this.setState({ authenticated: true });
      const vm = this;
      getloggedinUser()
        .then(function (res) {
          // let city = "Calgary";
          // if (res.city !== null) {
          //   city = res.city;
          // }
          // if (localStorage.getItem("searchCity")) {
          //   city = localStorage.getItem("searchCity");
          // }
          let province = "Alberta";
          if (res.province !== null) {
            province = res.provinceCode;
          }
          if (localStorage.getItem("searchProvince")) {
            province = localStorage.getItem("searchProvince");
          }
          const fullusername = res.firstName + " " + res.lastName;
          vm.setState({ userName: fullusername });
          vm.setState({ userId: res.id });
          vm.setState({ userLocation: province });
          vm.setState({ profileImage: res.profileImage });

          // console.log("*******************expdate***********");

          // console.log(localStorage.getItem('expiaryDate'));

          // res.items.forEach(item => {
          //   let obj = {}
          //   obj.name = item.name + " " + item.make + " " + item.model;
          //   obj.user = item.user.firstName + " " + item.user.lastName;
          //   obj.rating = Math.floor(Math.random() * 5) + 1;
          //   obj.userId = item.user.id;
          //   obj.id = item.id;
          //   obj.imageUrl = item.equipmentMedias[0].filePath
          //   vm.setState({
          //     items : vm.state.items.concat(obj)
          //   })
          // });
        })
        .catch(function (res) {
          console.log("***** ownuserinfo *****");
          console.log(res);
        });
    }

    // setTimeout(() => {
    //   localStorage.clear();
    //   this.setState({ authenticated: false });
    // }, 3000000); //logout after 50 mins
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // console.log('Changing')
      window.scrollTo(0, 0);
    }
  }
  componentDidMount() {
    //CF: commented to work on smaller screens and avoid overlay of download app modal
    // if (window.innerWidth <= 1200) {
    //   this.setState({ mobileScreen: true });
    // }
  }

  render() {
    console.log("mobileScreen", this.state.mobileScreen);
    console.log("outsideChatUserId top", this.state.outsideChatUserId);
    if (this.state.authenticated) {
      return (
        <React.Fragment>
          <Switch>
            <MainLayout
              onUpdate={() => window.scrollTo(0, 0)}
              authenticated={this.state.authenticated}
              bannerDisplay={this.state.bannerDisplay}
              userName={this.state.userName}
              userLocation={this.state.userLocation}
              userId={this.state.userId}
              profileImage={this.state.profileImage}
              outsideChatUserId={this.state.outsideChatUserId}
              handler={this.handler}
            >
              <Route
                onUpdate={() => window.scrollTo(0, 0)}
                exact
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />

              <Route path="/dashboard" component={Dashboard} />
              {/* <Route path="/forgotpassword/reset/" component={ForgotPassword} /> */}

              <Route path="/settings" component={Settings} />

              <Route exact path="/posts" component={Renting} />

              <Route path="/myprofile" component={MyProfile} />

              <Route path="/postcreate" component={EditPost} />

              {/* ADDING RENTING POST PAGE */}
              <Route exact path="/posts/:id" component={ViewRentingPost} />

              <Route
                handler={this.handler}
                exact
                path="/post/:id"
                // component={PostPage}
                render={(props) => (
                  <PostPage {...props} handler={this.handler} />
                )}

                // component={() => <PostPage handler={this.handler} />}
              />

              <Route exact path="/rentals/:id" component={ViewRentingPost} />

              {/* <Route exact path="/editpost/:id" component={EditPost} /> */}

              <Route exact path="/profile/:id" component={Profile} />
            </MainLayout>
          </Switch>
        </React.Fragment>
      );
    } else if (window.location.pathname.includes("/forgotpassword/reset/")) {
      return (
        <React.Fragment>
          <Switch>
            <MainLayout
              onUpdate={() => window.scrollTo(0, 0)}
              authenticated={this.state.authenticated}
            >
              <Route path="/forgotpassword/reset/" component={ForgotPassword} />
            </MainLayout>
          </Switch>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Modal
            className="about-modal warning-modal"
            style={{ borderRadius: 20 }}
            isOpen={this.state.mobileScreen}
          >
            <ModalHeader
              style={{
                background: "#FFCD46",
                paddingLeft: "40px",
                color: "#55575F",
                fontSize: 28,
              }}
            >
              <h4>Download The App</h4>
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup row>
                  <Col sm={12} style={{ textAlign: "center" }}>
                    <h5>
                      This website is not optimized for mobile devices, please
                      download the app.
                    </h5>
                  </Col>
                </FormGroup>

                <FormGroup row style={{ margin: 0 }}>
                  <Col style={{ textAlign: "center" }} sm={12}>
                    <a href="#" target="_blank">
                      <img src={googleImage} />{" "}
                    </a>
                  </Col>
                  <Col style={{ textAlign: "center" }} sm={12}>
                    <a href="#" target="_blank">
                      <img src={appleImage} />{" "}
                    </a>
                  </Col>
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
          <Switch>
            <MainLayout
              onUpdate={() => window.scrollTo(0, 0)}
              authenticated={this.state.authenticated}
              bannerDisplay={this.state.bannerDisplay}
              userName={this.state.userName}
              userLocation={this.state.userLocation}
              userId={this.state.userId}
              profileImage={this.state.profileImage}
            >
              <Route
                onUpdate={() => window.scrollTo(0, 0)}
                exact
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/forgotpassword/reset/" component={ForgotPassword} />

              <Route path="/post/:id" component={PostPage} />
              {window.location.pathname.includes("/dashboard") ||
              window.location.pathname.includes("/forgotpassword/reset/") ||
              window.location.pathname.includes("/post") ? null : (
                <Redirect to="/dashboard" />
              )}
            </MainLayout>
          </Switch>
        </React.Fragment>
      );
    }
  }
}

export default App;
