import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
const Navbar = ({ children }) => {
  return (
    <nav style={{overflowY: "scroll"}}>
      <ul>
        <li className="d-flex">
          {/* <Link to="/">Home</Link> */}
          {children}
        </li>
        {/* <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
