import React from "react";
import "./renting.css";
import classnames from "classnames";

// import {getEquipments} from '../../App/Api/equipment.js'

import { getRentals } from "../../App/Api/rentals.js";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";

import RentingPost from "../../Common/RentingPost";
import { get } from "http";
import { NavLink, Link, withRouter, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCircle } from "@fortawesome/free-solid-svg-icons";
import { extendMoment } from "moment-range";
import originalMoment from "moment";
import { Data } from "./data";

import {
  Navbar,
  Button,
  Dropdown,
  DropdownMenu,
  Row,
  Col,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
const moment = extendMoment(originalMoment);

class Renting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      type: localStorage.getItem("activeTab")
        ? JSON.parse(localStorage.getItem("activeTab"))
        : 1,
      Page: 0,
      PerPage: 2000,
      RentalType: 2,
      city: null,
      items: [],
      mockupitems: [],
      cSelected: [],
      NO_DATA: false,
    };
  }

  isDelivery = (freightOpt) => {
    if (freightOpt == 2 || freightOpt == 3) {
      return true;
    }
    return false;
  };

  changeRentingStatus = (type, filter) => {
    const vm = this;
    getRentals(type, vm.state.Page, vm.state.PerPage)
      .then(function (res) {
        console.log({ res });
        if (res.items.length <= 0) {
          vm.setState({
            NO_DATA: true,
            loading: false,
          });
        }

        vm.setState({ items: res.items, loading: false });
      })
      .catch(function (err) {
        console.log({ err });

        // console.log('isse', res)
      });
  };

  componentDidMount() {
    const vm = this;
    getRentals(vm.state.type, vm.state.Page, vm.state.PerPage)
      .then(function (res) {
        console.log({ res });
        if (res.items.length <= 0) {
          vm.setState({
            NO_DATA: true,
            loading: false,
          });
        }

        vm.setState({ items: res.items, loading: false });
      })
      .catch(function (err) {
        console.log({ err });

        // console.log('isse', res)
      });
  }

  showPopup = (type) => {
    if (type === "notification") {
      this.setState({
        displayNotificationPopup: !this.state.displayNotificationPopup,
      });
    } else if (type === "message") {
      this.setState({ displayMessagePopup: !this.state.displayMessagePopup });
    } else if (type === "menu") {
      this.setState({
        displayMenuOptionsPopup: !this.state.displayMenuOptionsPopup,
      });
    } else if (type === "locations") {
      this.setState({
        displayMenuLocationsPopup: !this.state.displayMenuLocationsPopup,
      });
    } else if (type === "filters") {
      this.setState({
        displayMenuFiltersPopup: !this.state.displayMenuFiltersPopup,
      });
    } else if (type === "sorts") {
      this.setState({
        displayMenuSortsPopup: !this.state.displayMenuSortsPopup,
      });
    } else if (type === "date") {
      this.setState({
        displayMenuDatePickerPopup: !this.state.displayMenuDatePickerPopup,
      });
    }
  };

  // onResetBtnClick = (type) => {
  //   // if (type === "filter") {
  //   //   this.setState({ cSelected: [], redirect: true });
  //   //   localStorage.removeItem("filterId");
  //   // } else if (type === "sort") {
  //   //   this.setState({ rSelected: [], redirect: true });
  //   //   localStorage.removeItem("sortId");
  //   // } else {
  //   //   localStorage.removeItem("sortId");
  //   //   localStorage.removeItem("filterId");
  //   //   localStorage.removeItem("searchStartDate");
  //   //   localStorage.removeItem("searchEndDate");
  //   //   this.setState({ redirect: true });
  //   //   this.setState(
  //   //     {
  //   //       cSelected: [],
  //   //       rSelected: [],
  //   //       endDate: null,
  //   //       startDate: null,
  //   //     },
  //   //     () => {
  //   //       this.setState({ redirect: true });
  //   //     }
  //   //   );
  //   // }
  //   this.setState({ rSelected: [], cSelected: [] });
  //   this.changeRentingStatus(2);
  // };

  // onRadioBtnClick(rSelected) {
  //   this.setState({ rSelected }, () => {
  //     if (rSelected === "startDate") {
  //       this.changeRentingStatus(2, rSelected);
  //     } else if (rSelected === "endDate") {
  //       this.changeRentingStatus(2, "endDate");
  //     } else if (rSelected === "upcoming") {
  //       this.changeRentingStatus(2, rSelected);
  //     } else if (rSelected === "inProgress") {
  //       this.changeRentingStatus(2, "inProgress");
  //     } else if (rSelected === "canceled") {
  //       this.changeRentingStatus(2, "canceled");
  //     }
  //   });
  // }

  render() {
    // console.log("mockupitems", this.state.mockupitems);

    return (
      <React.Fragment>
        <div className="renting-nav" style={{ marginTop: 135 }}>
          <span
            className={classnames({ active: this.state.type === 1 })}
            onClick={() => {
              this.changeRentingStatus(1);
              this.setState({ type: 1 });
              localStorage.setItem("activeTab", 1);
            }}
          >
            Buying
          </span>
          <span
            onClick={() => {
              this.changeRentingStatus(2);
              this.setState({ type: 2 });
              localStorage.setItem("activeTab", 2);
            }}
            className={classnames({ active: this.state.type === 2 })}
          >
            Selling
          </span>
          {/* {this.state.type !== 1 && (
            <div>
              <div style={{ display: "inline-block" }}>
                <Dropdown
                  className="displayContents  mx-auto"
                  style={{ position: "relative" }}
                  isOpen={this.state.displayMenuSortsPopup}
                  toggle={() => this.showPopup("sorts")}
                >
                  <DropdownToggle
                    className="user-profile-dropdown mx-auto"
                    onClick={() => this.showPopup("sorts")}
                    data-toggle="dropdown"
                    aria-expanded={this.state.displayMenuSortsPopup}
                  >
                    <a>
                      <img
                        src={require("../../Common/Header/Images/icon_filter.svg")}
                        alt="filter"
                      />
                    </a>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-container location filter pt-0 px-0">
                    <DropdownItem
                      header
                      className="filter-dropd-header  text-align-center d-flex flex-row justify-content-between align-items-center py-0"
                    >
                      <div />
                      <div className="align-self-center ml-5">
                        <img
                          src={require("../../Common/Header/Images/icon_filter.svg")}
                          alt="filter"
                        />
                      </div>
                      <Button
                        className="btn-reset text-align-right"
                        onClick={() => this.onResetBtnClick("sort")}
                      >
                        Reset
                      </Button>
                    </DropdownItem>
                    <div>
                      <strong style={{ margin: 14 }}>Sort</strong>
                      <Row
                        className="filter-checkbox-box d-flex justify-content-between"
                        sm="12"
                      >
                        <Col className="filter-col" sm="12">
                          <div className="d-flex flex-wrap">
                            <Row className="filter-row mr-0 ml-0 pr-4" sm="6">
                              <Col sm="10">Start Date</Col>
                              <Col sm="2">
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.onRadioBtnClick("startDate")
                                  }
                                  active={this.state.rSelected === "startDate"}
                                >
                                  <FontAwesomeIcon
                                    className="text-white"
                                    icon={faCircle}
                                  />
                                </Button>
                              </Col>
                            </Row>
                            <Row className="filter-row mr-0 ml-0 pr-4" sm="6">
                              <Col sm="10">End Date</Col>
                              <Col sm="2">
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.onRadioBtnClick("endDate")
                                  }
                                  active={this.state.rSelected === "endDate"}
                                >
                                  <FontAwesomeIcon
                                    className="text-white"
                                    icon={faCircle}
                                  />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <strong style={{ margin: 14 }}>Filter</strong>
                      <Row
                        className="filter-checkbox-box d-flex justify-content-between"
                        sm="12"
                      >
                        <Col className="filter-col" sm="12">
                          <div className="d-flex flex-wrap">
                            <Row className="filter-row mr-0 ml-0 pr-4" sm="6">
                              <Col sm="10"> In Progress</Col>
                              <Col sm="2">
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.onRadioBtnClick("inProgress")
                                  }
                                  active={this.state.rSelected === "inProgress"}
                                >
                                  <FontAwesomeIcon
                                    className="text-white"
                                    icon={faCircle}
                                  />
                                </Button>
                              </Col>
                            </Row>
                            <Row className="filter-row mr-0 ml-0 pr-4" sm="6">
                              <Col sm="10">Upcoming</Col>
                              <Col sm="2">
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.onRadioBtnClick("upcoming")
                                  }
                                  active={this.state.rSelected === "upcoming"}
                                >
                                  <FontAwesomeIcon
                                    className="text-white"
                                    icon={faCircle}
                                  />
                                </Button>
                              </Col>
                            </Row>
                            <Row className="filter-row mr-0 ml-0 pr-4" sm="6">
                              <Col sm="10">Canceled</Col>
                              <Col sm="2">
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.onRadioBtnClick("canceled")
                                  }
                                  active={this.state.rSelected === "canceled"}
                                >
                                  <FontAwesomeIcon
                                    className="text-white"
                                    icon={faCircle}
                                  />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          )} */}
        </div>

        {this.state.type === 1 ? (
          <div className="renting-list-content">
            {this.state.loading && (
              <div className="no-results-span">LOADING ...</div>
            )}
            {this.state.items.length<= 0 && (
              <div className="no-results-span">
                You are not buying anything.{" "}
              </div>
            )}

            <div
              className="posts-in-dash py-4"
            >
              {this.state.items.map((item, index) => {
                return (
                  <RentingPost
                    key={item.id}
                    RentingpostName={item.equipments[0].name}
                    RentingpostUser={`${item.equipmentOwner.firstName} ${item.equipmentOwner.lastName}`}
                    RentingpostUserProfileImageUrl={
                      item.equipmentOwner.profileImage
                    }
                    RentingpostRating={item.score}
                    RentingpostId={item.id}
                    RentingpostUserId={item.equipmentOwner.id}
                    RentingpostImageUrl={
                      item.equipments[0].equipmentMedias.length > 0
                        ? item.equipments[0].equipmentMedias[0].filePath
                        : null
                    }
                    total={item.total}
                    startDate={item.createdAt}
                    endDate={item.endDate}
                    startTime={item.startTime}
                    endTime={item.endTime}
                    confirmedAt={item.confirmedAt}
                    refundedAt={item.refundedAt}
                    type={item.type}
                    openEndedRentalStatus={item.openEndedRentalStatus}
                    amount={item.equipments[0].dailyRate}
                    freightOptions={item.freightOptions}
                    address={
                      this.isDelivery(item.freightOptions)
                        ? item.deliveryLocationAddress
                        : item.equipments[0].pickupLocationAddress
                    }
                    category={item.equipments[0].category.name}
                    quantity={item.quantity}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div className="rentingout-list-content">
            {this.state.loading && (
              <div className="no-results-span">LOADING ...</div>
            )}
            {this.state.items.length<= 0 && (
              <div className="no-results-span">
                You are not selling anything.{" "}
              </div>
            )}
            <div className="posts-in-dash">
              {this.state.items.map((item, index) => {
                return (
                  <RentingPost
                    key={item.id}
                    RentingpostName={item.equipments[0].name}
                    RentingpostUser={`${item.renter.firstName} ${item.renter.lastName}`}
                    RentingpostUserProfileImageUrl={item.renter.profileImage}
                    RentingpostRating={item.score}
                    RentingpostId={item.id}
                    RentingpostUserId={item.renter.id}
                    RentingpostImageUrl={
                      item.equipments[0].equipmentMedias.length > 0
                        ? item.equipments[0].equipmentMedias[0].filePath
                        : null
                    }
                    total={item.total}
                    startDate={item.createdAt}
                    endDate={item.endDate}
                    startTime={item.startTime}
                    endTime={item.endTime}
                    confirmedAt={item.confirmedAt}
                    refundedAt={item.refundedAt}
                    type={item.type}
                    openEndedRentalStatus={item.openEndedRentalStatus}
                    amount={item.equipments[0].dailyRate}
                    freightOptions={item.freightOptions}
                    address={
                      this.isDelivery(item.freightOptions)
                        ? item.deliveryLocationAddress
                        : item.equipments[0].pickupLocationAddress
                    }
                    category={item.equipments[0].category.name}
                    quantity={item.quantity}
                  />
                );
              })}
            </div>

            {this.state.items.length === 0 && (
              <div className="no-results-span">No Results</div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

Renting.propTypes = {};
export default Renting;
