import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  // FormText
} from "reactstrap";
import PropTypes from "prop-types";

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  // faEnvelope,
  // faLock,
  // faEyeSlash,
  // faEye,
  faUser,
  // faIdBadge
} from "@fortawesome/free-solid-svg-icons";

import "./style.css";
import $ from "jquery";

class ResetPassModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      inValidEmail: false,
    };
  }

  // changetoLogin() {
  //   this.props.toggle('signin');
  //   this.props.toggle('login');
  // }

  changetoLogin = () => {
    this.props.toggle("resetpass");
    this.props.toggle("login");
  };

  sendEmail = (e) => {
    e.preventDefault();
    console.log("GOING INTO CALL");
    const vm = this;
    $.ajax({
      method: "POST",
      dataType: "json",
      traditional: true,
      data: JSON.stringify({ username: this.state.email }),
      xhrFields: {
        withCredentials: true,
      },
      url: `${process.env.REACT_APP_API}/account/forgotpassword`,

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        vm.props.toggle("resetpass");
        vm.props.toggle("SuccessEmailReset");
      })
      .catch(function (res) {
        console.log(res);
        vm.setState({
          inValidEmail: true,
        });
      });
  };

  render() {
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("resetpass")}>
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="signup-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("resetpass")}
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#FFCD46" }}
          >
            Reset Password
          </ModalHeader>
          <ModalBody style={{ padding: 20 }}>
            <Form onSubmit={(e) => this.sendEmail(e)}>
              <FormGroup row>
                <Col sm={12}>
                  <p className="pt-4">
                    Enter your email, you will receive a message with steps to
                    change your password.
                  </p>
                </Col>
              </FormGroup>
              <FormGroup row className="py-3">
                <label for="restemail" sm={2} size="lg" hidden>
                  Enter Email
                </label>
                <Col sm={12}>
                  <input
                    type="email"
                    name="restemail"
                    id="restemail"
                    placeholder="Enter Email "
                    bsSize="lg"
                    className="input-style"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </Col>
              </FormGroup>
              {this.state.inValidEmail ? (
                <span style={{ color: "red" }}>Invalid Email</span>
              ) : null}
              <button
                className="btn-hay-yellow "
                size="lg"
                block
                style={{ width: "100%" }}
              >
                Submit
              </button>
            </Form>
          </ModalBody>
          <ModalFooter>
            <FormGroup row>
              <a
                className="forgot-pass-login pb-3"
                onClick={this.changetoLogin}
              >
                Back to Login
              </a>
            </FormGroup>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

ResetPassModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ResetPassModal;
