import $ from 'jquery';

export function getSorts(){
 return $.ajax({
    method: "GET",
    dataType: 'json',
    traditional: true,
    xhrFields: {
      withCredentials: true
   },
    url: process.env.REACT_APP_API+"/equipments/sortoptions",
  }).then(res => res.data)
}