import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// get our fontawesome imports
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGoogle, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faLock,
  faEyeSlash,
  faEye,
  faUser,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";

import "./style.css";

class NewPostModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // changetoLogin() {
  //   this.props.toggle('signin');
  //   this.props.toggle('login');
  // }

  changetoNewPost = () => {
    this.props.toggle("newpost");
  };

  changetoAddExisting = () => {
    this.props.toggle("newpost");
  };

  render() {
    const closeBtn = (
      <button className="close" onClick={() => this.props.toggle("newpost")}>
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="newpost-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("newpost")}
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#ADB5BD" }}
          >
            New Post
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Col sm={12}>
                  <Link
                    to={{ pathname: "/postcreate", state: { isAddOn: false } }}
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <Button
                      onClick={this.changetoNewPost}
                      className="loginbutton"
                      size="lg"
                      block
                    >
                      New Equipment
                    </Button>
                  </Link>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col sm={12}>
                  <Link
                    to={{ pathname: "/postcreate", state: { isAddOn: true } }}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      onClick={this.changetoAddExisting}
                      className="loginbutton"
                      size="lg"
                      block
                    >
                      New Attachment for Existing Equipment
                    </Button>
                  </Link>
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

NewPostModal.propTypes = {
  modalOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default NewPostModal;
