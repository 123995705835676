import React from "react";
import ReactDOM from "react-dom";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./style.css";
import $ from "jquery";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

class Timeslider extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedTime: 0, minValue: 0, maxValue: 24 };
    this.formatTimeShow = this.formatTimeShow.bind(this);
  }

  formatTimeShow(h_24) {
    var h = h_24 % 12;
    if (h === 0) h = 12;
    return (h < 10 ? "0" : "") + h + ":00" + (h_24 < 12 ? "am" : "pm");
  }

  componentWillMount() {
    console.log("PROPS TO SLIDER",this.props.postInfo)
    console.log('UTC MOMENT', moment(this.props.startDate).format("h"))
    console.log('UTC MOMENT', moment(this.props.endDate).format("h"))
    let startTime = this.props.postInfo.startTime.split(":");
    let startHour = parseInt(startTime[0]);
    let endTime = this.props.postInfo.endTime.split(":");
    let endHour = parseInt(endTime[0]);
    this.setState({
      selectedTime: parseInt(moment(this.props.startDate).format("h")),
      minValue: parseInt(moment(this.props.startDate).format("h")),
      maxValue: parseInt(moment(this.props.endDate).format("h"))
    });

    console.log("Initial time selectedTime", this.state.selectedTime);
  }

  sendData = selectedTime => {
    console.log("selectedTime")
    this.setState({ selectedTime: selectedTime });
    this.props.parentCallback(selectedTime, this.props.type);
  };

  render() {
    // console.log("TIME SLIDER",this.state.selectedTime.split(":"))
    console.log("TIME SLIDER", this.state.selectedTime);
    return (
      <InputRange
        maxValue={this.state.maxValue}
        minValue={this.state.minValue}
        formatLabel={selectedTime => `${this.formatTimeShow(selectedTime)}`}
        value={this.state.selectedTime}
        onChange={selectedTime => this.sendData(selectedTime)}
      />
    );
  }
}

Timeslider.propTypes = {};

export default Timeslider;
