import $ from "jquery";

export function resetPassword(data) {
  return $.ajax({
    method: "POST",
    dataType: "json",
    traditional: true,
    data: JSON.stringify(data),
    xhrFields: {
      withCredentials: true,
    },
    url: `${process.env.REACT_APP_API}/account/resetpassword`,

    headers: {
      // "Authorization": "Bearer " + data.resetToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res)
    .catch((res) => {
      if (res.responseJSON.code === "VALIDATION_ERROR") {
        if(res.responseJSON.data != null){
          alert(Object.values(res.responseJSON.data)[0]);
        }
        else{
          alert(
            "Must be between 8 and 255 characters and Password must contain at least one digit, one non-alphanumeric character, one lowercase and one uppercase letter"
          );
        }
      } else {
        alert(res.responseJSON.message);
      }
    });
}
