import $ from "jquery";

export function getloggedinUser() {
  let data = {};
  //   if(page != null) {
  //     data.Page = page
  //   }
  //   if(perPage != null) {
  //     data.PerPage = perPage
  //   }
  //   if(searchKeyWord != null) {
  //     data.SearchKeyWord = searchKeyWord
  //   }
  //   if(sortOption != null) {
  //     data.SortOptionId = sortOption
  //   }
  //   if(filterOption != null) {
  //     data.FilterOptionIds = filterOption
  //   }
  //   if(city != null) {
  //     data.cityIds = city
  //   }
  return $.ajax({
    method: "GET",
    dataType: "json",
    data: data,
    traditional: true,
    xhrFields: {
      withCredentials: true,
    },
    url: process.env.REACT_APP_API + "/users/me",

    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.data)
    .catch((res) => {
      if (localStorage.getItem("refreshToken")) {
        $.ajax({
          method: "post",
          dataType: "json",
          data: JSON.stringify({
            refreshToken: localStorage.getItem("refreshToken"),
            clientId: localStorage.getItem("userId"),
          }),
          traditional: true,
          xhrFields: {
            withCredentials: true,
          },
          url: process.env.REACT_APP_API + "/account/refreshtoken",

          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            console.log(res);
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("expiaryDate", res.data.expires);
            localStorage.setItem("userId", res.data.user.id);
          })
          .catch((res) => {
            localStorage.clear();
            window.location.reload();
          });
      }
    });
}
