import $ from "jquery";

export function postLogin(username, password) {
  let data = {
    username: username,
    password: password,
    clientId: "mobile",
    twoFactorCode: ""
  };


  return $.ajax({
    method: "POST",
    dataType: "json",
    data: JSON.stringify(data),
    traditional: true,
    xhrFields: {
      withCredentials: false
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    url: process.env.REACT_APP_API+"/account/login"
  }).then(res => res);
}
