import $ from 'jquery';

export function getRentals(RentalType,Page, PerPage){
  let data = {};

  if(RentalType != null) {
    data.RentalType = RentalType 
  } 

  if(Page != null) {
    data.Page = Page 
  } 
  if(PerPage != null) {
    data.PerPage = PerPage
  } 
  

 return $.ajax({
    method: "GET",
    dataType: 'json',
    data: data,
    traditional: true,
    xhrFields: {
      withCredentials: true
   },
   headers: {
    "Authorization": "Bearer " + localStorage.getItem('accessToken'),
     'Accept': 'application/json',
     'Content-Type': 'application/json'
 },
    url: process.env.REACT_APP_API+"/rentals",
  }).then(res => res.data)
}
