import React from "react";
import { FormGroup, Label, Input, Col, Button } from "reactstrap";

import AboutModal from "../../Common/Modals/AboutModal";
import FeedbackModal from "../../Common/Modals/FeedbackModal";

import SettingsTosModal from "../../Common/Modals/SettingsTosModal";
import SettingsPpModal from "../../Common/Modals/SettingsPpModal";
import "./settings-style.css";

import * as signalR from "@aspnet/signalr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-brands-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Switch from "react-switch";
import { getloggedinUser } from "../../App/Api/loggedinuser.js";
import $ from "jquery";
import ChatWindow from "../../Common/Chats/components/ChatWindow";
import moment from "moment";

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      pushNotification: false,
      messageNotification: false,
      AboutModalOpen: false,
      FeedbackModalOpen: false,
      SettingsTosModalOpen: false,
      SettingsPpModalOpen: false,
      uploadedPdf: "",
      uploadedSuccess: null,
      saveSuccess: false,
      fileName: "",
      userId: localStorage.getItem("userId"),
      notificationsFrequency: "",
      useruploadedpdf: false,
      istherefilesuploaded: false,
      admin: null,
      chatHistory: [],
      isOpen: false,
      userDisplayName: "",
      roomId: "",
      currentUploadedPdfName: null,
      theDefaultPdf: null,
    };

    this.toggle = this.toggle.bind(this);
  }

  // handleChange(checked) {
  //   this.setState({ checked, isPickupAvailable: true });
  // }

  handleChange(checked) {
    if (checked == "switch1") {
      this.setState({ pushNotification: !this.state.pushNotification });
    } else if (checked == "switch2") {
      this.setState({ messageNotification: !this.state.messageNotification });
    }
  }

  //TODO: get a list of all items and set state to them

  //
  selectFrequency(e) {
    this.setState(
      {
        notificationsFrequency: e.target.value,
      },
      () => {
        console.log("Notif freq", this.state.notificationsFrequency);
      }
    );
  }

  toggle(type) {
    if (type == "about") {
      this.setState((prevState) => ({
        AboutModalOpen: !prevState.AboutModalOpen,
      }));
    }

    if (type == "feedback") {
      this.setState((prevState) => ({
        FeedbackModalOpen: !prevState.FeedbackModalOpen,
      }));
    }

    if (type == "settingstosmodal") {
      this.setState((prevState) => ({
        SettingsTosModalOpen: !prevState.SettingsTosModalOpen,
      }));
    }

    if (type == "settingsppmodal") {
      this.setState((prevState) => ({
        SettingsPpModalOpen: !prevState.SettingsPpModalOpen,
      }));
    }
  }

  componentDidMount() {
    const vm = this;
    vm.getAdmin();
    getloggedinUser().then((res) => {
      console.log("GETING USER ME", res);
      vm.setState({ notificationsFrequency: res.frequency.toString() });
    });
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url:
        process.env.REACT_APP_API + "/notifications/getmynotificationsettings",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      console.log("******** Get my notification settings ************");
      console.log("Get my notification settings data", res.data);
      vm.setState(
        {
          pushNotification: res.data.pushNotificationsEnabled,
          emailNotifications: res.data.emailNotificationsEnabled,
          voiceNotifications: res.data.voiceNotificationsEnabled,
          messageNotification: res.data.smsNotificationsEnabled,
        },
        () => {
          console.log("Push notifications state", vm.state.pushNotification);
        }
      );
    });
    //get default pdf
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/agreements/default",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      console.log("******** default pdf ************");
      console.log("current res", res.data);
      vm.setState(
        {
          theDefaultPdf: res.data.filePath,
        },
        () => {}
      );
    });
    //get current uploaded pdf
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: `${process.env.REACT_APP_API}/users/me`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      console.log("******** already uploaded pdf ************");
      console.log("current res", res.data);
      vm.setState(
        {
          currentUploadedPdfName: res.data.agreement.name,
        },
        () => {}
      );
    });
  }

  saveFrequencySettings = () => {
    // let myFrequency = {
    //   frequency: parseInt(this.state.notificationsFrequency)
    // };
    // console.log("Notif update", myFrequency);

    // this.setState({  })
    let frequency = parseInt(this.state.notificationsFrequency);

    $.ajax({
      method: "PUT",
      dataType: "json",
      traditional: true,
      // data: myFrequency,
      xhrFields: {
        withCredentials: true,
      },

      url: `${process.env.REACT_APP_API}/notifications/frequency?frequency=${frequency}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      console.log("******** POST SAVED NOTIFICATION SETTINGS *******");
      console.log(res);
    });
  };

  saveNotificationSettings = () => {
    const vm = this;

    let notificationUpdate = {
      emailNotificationsEnabled: true,
      pushNotificationsEnabled: this.state.pushNotification,
      smsNotificationsEnabled: this.state.messageNotification,
      voiceNotificationsEnabled: true,
    };
    console.log("Notif update", notificationUpdate);

    // this.setState({  })

    $.ajax({
      method: "POST",
      dataType: "json",
      traditional: true,
      data: JSON.stringify(notificationUpdate),
      // data: notificationUpdate,
      xhrFields: {
        withCredentials: true,
      },

      url:
        process.env.REACT_APP_API +
        "/notifications/updatemynotificationsettings",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      console.log("******** POST SAVED NOTIFICATION SETTINGS *******");
      console.log(res);
    });
  };

  fileInput = () => {};

  uploadPdf = (e) => {
    e.stopPropagation();
    let vm = this;
    const fd = new FormData();
    fd.append("file", this.state.file);
    fd.append("public", true);
    let data = {
      File: this.state.file,
      Public: true,
    };
    let dataFileName = {
      File: this.state.file.name,
    };
    console.log("upload PDF", data);
    console.log("upload PDF Name", data.File.name);
    console.log("file name dataFileName", dataFileName);

    $.ajax({
      method: "POST",
      processData: false,
      contentType: false,
      data: fd,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/uploads/uploadpdf",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    })
      .then(function (res) {
        console.log("file path", res.data.filePath);
        vm.setState({
          uploadedPdf: res.data.filePath,
          fileName: dataFileName.File,
          uploadedSuccess: "uploaded",
        });
        console.log("state file path", vm.state.uploadedPdf);
        console.log("state file nam", vm.state.fileName);
      })
      .catch(function (res) {
        console.log("file failed");
        vm.setState({
          uploadedSuccess: "Failed to upload",
        });
      });
  };

  _handlePdfChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState(
        {
          file: file,
          istherefilesuploaded: true,
          // imagePreviewUrl: reader.result
        },
        () => this.uploadPdf(e)
      );
    };

    reader.readAsDataURL(file);
  }

  saveSettings = () => {
    // let newname= this.state.fileName;
    // newname = newname.substring(0, newname.length - 4);

    let vm = this;
    let savedData = {
      name: this.state.fileName,
      filePath: this.state.uploadedPdf,
    };
    console.log("***** Saved data ******", savedData);

    $.ajax({
      method: "POST",
      // dataType: "json",
      processData: false,
      // traditional: true,
      contentType: "application/json",
      data: JSON.stringify(savedData),
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/agreements",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
      },
    })
      .then(function (res) {
        console.log("POST SAVED SETTINGS", res.data);
        vm.setState(
          {
            saveSuccess: true,
          },
          () => {
            alert("Upload Successful");
            window.location.reload();
          }
        );
        // console.log('POST SAVED SETTINGS STATE', vm.state);
      })
      .catch((res) => {});
  };

  onSaveChanges = () => {
    this.saveNotificationSettings();
    this.saveFrequencySettings();
    if (this.state.uploadedSuccess === "uploaded") {
      this.saveSettings();
    }
  };

  deleteuser = () => {
    let confirm = window.confirm("Do you really want to delete your account?");

    if (confirm === true) {
      console.log("DELETE MEEEEE");
      $.ajax({
        method: "DELETE",
        dataType: "json",
        processData: false,
        traditional: true,
        contentType: "application/json",
        data: JSON.stringify({}),
        xhrFields: {
          withCredentials: true,
        },
        url: process.env.REACT_APP_API + "/users/me",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          Accept: "application/json",
        },
      }).then(function (res) {
        console.log("DELETE ACCOUNT", res.data);
        localStorage.clear();
        window.location.reload();
      });
    } else {
    }
  };

  getAdmin = () => {
    const vm = this;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: {},
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/chat/rooms/user/admin",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(function (res) {
      console.log("********GET ADMIN DTS ************");
      console.log(res.data.messageHistory);
      if (res.data.onlineStatus === true) {
        vm.setState({
          admin: "Online",
        });
      } else {
        vm.setState({
          admin: "Offline",
        });
      }
      vm.setState({
        userDisplayName: res.data.userDisplayName,
        roomId: res.data.roomId,
      });
      if (res.data.messageHistory.length > 0) {
        const chatMessages = res.data.messageHistory.reverse().map((e) => {
          let from = "";
          if (e.userId == localStorage.getItem("userId")) {
            from = "me";
          } else {
            from = "sender";
          }
          let obj = {
            from: from,
            message: e.body,
            created: e.createdAt,
          };
          console.log(obj);
          return obj;
        });
        vm.setState({
          chatHistory: chatMessages,
        });
        const connection = new signalR.HubConnectionBuilder()
          .withUrl(
            "https://api.staging.haythere.vogdevelopment.com/hubs/chat",
            {
              accessTokenFactory: () => localStorage.getItem("accessToken"),
            }
          )
          .build();
        connection.start().then(() => {
          connection
            .invoke("SubscribeChatRoom", vm.state.roomId)
            .then((response) => {
              console.log(response);
              const chatMessages = response.data.latestMessages
                .reverse()
                .map((e) => {
                  let from = "";
                  if (e.author.userId == localStorage.getItem("userId")) {
                    from = "me";
                  } else {
                    from = "sender";
                  }
                  return {
                    from: from,
                    message: e.body,
                    created: e.createdAt,
                  };
                });
              vm.setState({
                chatHistory: chatMessages,
              });
            });
          connection
            .invoke("MarkRoomRead", vm.state.roomId)
            .then((response) => {})
            .catch(function (err) {
              return console.error(err.toString());
            });
        });
      } else {
        // vm.setState({
        //   chatHistory: [
        //     {
        //       from: "sender",
        //       message: "How can I help?",
        //       created: moment()
        //     }
        //   ]
        // });
        // let text = {
        //   author: res.data.userDisplayName,
        //   type: "text",
        //   data: {
        //     text:
        //       "How can we help you today? Send your question, I will reply ASAP."
        //   }
        // };
        // vm._sendMessage(text);
      }
    });
    console.log(vm.state.chatHistory);
  };

  _sendMessage(message) {
    const vm = this;
    console.log(message);
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://api.staging.haythere.vogdevelopment.com/hubs/chat", {
        accessTokenFactory: () => localStorage.getItem("accessToken"),
      })
      .build();
    connection.start().then(() => {
      connection
        .invoke("SendMessageText", this.state.roomId, message.data.text)
        .catch(function (err) {
          console.log("sent message");
          return console.error(err.toString());
        })
        .then(function () {
          vm.getAdmin();
        });
    });
  }

  openChatWindow = () => {
    this.setState({
      isOpen: true,
    });
  };

  closeClick() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const isOpen = this.props.hasOwnProperty("isOpen")
      ? this.props.isOpen
      : this.state.isOpen;

    return (
      <React.Fragment>
        <AboutModal
          toggle={this.toggle}
          modalOpen={this.state.AboutModalOpen}
        />
        <FeedbackModal
          toggle={this.toggle}
          modalOpen={this.state.FeedbackModalOpen}
        />
        <SettingsTosModal
          toggle={this.toggle}
          modalOpen={this.state.SettingsTosModalOpen}
        />
        <SettingsPpModal
          toggle={this.toggle}
          modalOpen={this.state.SettingsPpModalOpen}
        />

        <div
          className="settings-container"
          style={{ padding: 10, paddingTop: 100 }}
        >
          <h1 className="title">Settings</h1>

          <div className="settings-items">
            <div className="settings-item-row" style={{ color: "#000" }}>
              <span className="post-subtitle">Notifications</span>
            </div>

            <div className="settings-item-row ">
              {/* <div> */}
              <a>
                {" "}
                <span className="post-label">Push Notifications</span>
              </a>
              {/* </div> */}
              <div>
                <Switch
                  checked={this.state.pushNotification}
                  onChange={() => this.handleChange("switch1")}
                  offColor="#E6E6E6"
                  onColor="#dabc69"
                  onHandleColor="#FFCD46"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
            </div>

            <div className="settings-item-row">
              <a>
                <span className="post-label"> Message Notifications</span>{" "}
              </a>
              <div>
                <Switch
                  checked={this.state.messageNotification}
                  onChange={() => this.handleChange("switch2")}
                  offColor="#E6E6E6"
                  onColor="#dabc69"
                  onHandleColor="#FFCD46"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch2"
                />
              </div>
            </div>

            <div className="settings-item-row">
              <a>
                {" "}
                <span className="post-label"> Notifications Frequency </span>
              </a>
              <div>
                <Input
                  type="select"
                  name="frequency"
                  id="freq-select"
                  onChange={(e) => this.selectFrequency(e)}
                >
                  <option
                    selected={
                      this.state.notificationsFrequency === "3" ? true : false
                    }
                    value="3"
                  >
                    Once a Month
                  </option>
                  <option
                    selected={
                      this.state.notificationsFrequency === "1" ? true : false
                    }
                    value="1"
                  >
                    Once a day
                  </option>
                  <option
                    selected={
                      this.state.notificationsFrequency === "4" ? true : false
                    }
                    value="4"
                  >
                    Twice per day
                  </option>
                  <option
                    selected={
                      this.state.notificationsFrequency === "2" ? true : false
                    }
                    value="2"
                  >
                    Once a Week
                  </option>
                </Input>
              </div>
            </div>

            <div className="settings-item-row" style={{ color: "#000" }}>
              <span className="post-subtitle">General</span>
            </div>

            <div
              className="settings-item-row about"
              onClick={() => this.toggle("about")}
            >
              <a>
                {" "}
                <span className="post-label"> About </span>
              </a>
              <div>
                <FontAwesomeIcon icon={faChevronRight} className="" />
              </div>
            </div>

            <div
              className="settings-item-row feedback"
              onClick={() => this.toggle("feedback")}
            >
              <a>
                {" "}
                <span className="post-label">Feedback </span>
              </a>
              <div>
                <FontAwesomeIcon icon={faChevronRight} className="" />
              </div>
            </div>

            <div
              onClick={this.openChatWindow}
              className="settings-item-row contact"
            >
              <a>
                <span className="post-label"> Contact</span>
              </a>
              <div className="contact-text">
                {" "}
                Admin is{" "}
                <span style={{ color: "#FFCD46" }}>
                  {this.state.admin}
                </span>{" "}
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="chev-right-contact"
                />
              </div>
            </div>

            <div
              className="settings-item-row tos"
              onClick={() => this.toggle("settingstosmodal")}
            >
              <a>
                {" "}
                <span className="post-label">Terms of Service</span>
              </a>
              <div>
                <FontAwesomeIcon icon={faChevronRight} className="" />
              </div>
            </div>

            <div
              className="settings-item-row pp"
              onClick={() => this.toggle("settingsppmodal")}
            >
              <a>
                <span className="post-label"> Privacy Policy</span>
              </a>
              <div>
                <FontAwesomeIcon icon={faChevronRight} className="" />
              </div>
            </div>
            {/* <a
              href={this.state.theDefaultPdf}
              className="settings-item-row pdf"
              // onClick={() => this.toggle("settingstosmodal")}
            >
              <p>Download default haythere Rental Agreement</p>
              <div>
                <FontAwesomeIcon icon={faChevronRight} className="" />
              </div>
            </a> */}
            <div onClick={this.deleteuser} className="settings-item-row delete">
              <a className="deleteaccount" style={{ color: "red" }}>
                {" "}
                Delete Account
              </a>
            </div>
            {/* <div
              onClick={() => this.fileInput.click()}
              className="settings-item-row pp"
            >
              <FormGroup className="upload-pdf d-flex flex-row  pl-0 mb-0">
                <Col sm-6 className="px-0 mx-0">
                  <Label className=" pl-0" for="pdfFile">
                    Upload my own Rental agreement
                  </Label>
                  <input
                    style={{ display: "none" }}
                    onChange={(e) => this._handlePdfChange(e)}
                    ref={(fileInput) => (this.fileInput = fileInput)}
                    className="edit-post-btn"
                    type="file"
                    accept=".pdf"
                    name="file"
                    id="pdfFile"
                  />
                  <p style={{ color: "rgba(0,0,0,0.3)", fontSize: 13 }}>
                    Current Agreement:
                    <span> {this.state.currentUploadedPdfName}</span>
                  </p>
                </Col>
                <Col className="upload-button d-flex px-0 mx-0" sm-6>
                  {this.state.istherefilesuploaded ? (
                    <Button
                      className="new-post-button align-self-right"
                      type="button"
                      onClick={(e) => this.uploadPdf(e)}
                      disabled
                    >
                      {" "}
                      {this.state.uploadedSuccess === "uploaded"
                        ? `${this.state.fileName} Uploaded`
                        : this.state.uploadedSuccess === "Failed to upload"
                        ? "Failed to upload"
                        : "Uploading"}{" "}
                    </Button>
                  ) : null}
                </Col>
              </FormGroup>
            </div> */}

            <div className="d-flex flex-row flex-end pr-0">
              <button
                className="btn-hay-yellow my-5 ml-auto"
                type="button"
                onClick={this.onSaveChanges}
              >
                {" "}
                Save Changes{" "}
              </button>
            </div>
          </div>
          <ChatWindow
            messageList={this.state.chatHistory}
            onUserInputSubmit={this._sendMessage.bind(this)}
            userProfile={this.state.userDisplayName}
            isOpen={isOpen}
            onClose={this.closeClick.bind(this)}
          />
        </div>
      </React.Fragment>
    );
  }
}
Settings.propTypes = {};
export default Settings;
