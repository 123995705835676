import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
// import Renting from '../Renting'
import EditProfileModal from "../../Common/Modals/EditProfileModal";
import { getloggedinUser } from "../../App/Api/loggedinuser.js";
import { getUser } from "../../App/Api/singleuser.js";
import $, { error } from "jquery";
import Post from "../../Common/Post";
import Switch from "react-switch";
import "./style.css";
import { Data } from "./data";

import InfiniteScroll from "react-infinite-scroller";


class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      infiniteScrollPage: 0,
      isLoadingInfiniteScroll: false,
      page: 0,
      perPage: 10,
      numOfPages: 0,
      EditProfileModalOpen: false,
      userId: 1,

      profileImage: null,
      userName: null,
      userCity: null,
      authUserId: 1,
      authUserpfImage: null,
      autheruserName: null,
      authuserLocation: null,
      items: [],
      mockupitems: [],
      allowsCancelling: false,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      profileImage: null,
      cityId: 0,
      socialMediaUser: false,
      cityName: null,
      sponsorID: null,
      companyName: null,
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
  }
  componentWillMount() {
    this.setState({ userId: this.props.match.params.id });
    if (localStorage.getItem("socialMediaUser")) {
      this.setState({ socialMediaUser: true });
    }
  }
  componentWillReceiveProps(nextProps) {
    //console.log("HERE")
    // if(nextProps.locaton.state === "MyProfile"){
    this.setState({ userId: this.props.match.params.id });
    // }
  }
  //TODO: get a list of all items and set state to them
  componentDidMount() {
    //   getUser(this.state.userId).then(function(res){
    //     console.log("***** otheruser-profpage *****")
    //     console.log(res)
    //   this.setState({userId: res.id});
    //   this.setState({profileImage: res.profileImage});
    //   const fullusername = res.firstName + " " + res.lastName;
    //   this.setState({userName: fullusername});
    //     this.setState({ userCity: res.city});

    //   // //console.log(vm.props.userId);
    //   // //console.log(vm.props.authUserId);
    // });

    const vm = this;

    getloggedinUser().then(function (res) {
      const fullusername = res.firstName + " " + res.lastName;
      vm.setState({
        authUserId: res.id,
        authorName: res.id,
        autheruserName: fullusername,
        authuserLocation: res.city,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        phone: res.phone,
        authUserpfImage: res.profileImage,
        allowsCancelling: res.allowsCancelling,
        sponsorID: res.sponsorID,
        companyName: res.companyName,
      });
      if (res.city) {
        vm.setState({ cityName: res.city.name, cityId: res.city.id });
      }

      //console.log(vm.props.userId);
      //console.log(vm.props.authUserId);
    })
    // const AuthUserId = parseInt(localStorage.getItem('userId'))
    // this.setState({ authUserId: AuthUserId})
    let data = {};

    data.EquipmentType = 0;
    data.page = 0;
    data.perPage = this.state.perPage;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: data,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/equipments/user",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        vm.setState({
          numOfPages: res.data.totalPages,
          infiniteScrollPage: 0,
          items: res.data.items.map((item) => {
            let obj = {};
            // obj.name = item.name + " " + item.make + " " + item.model;
            obj.name = item.name;
            obj.user = item.user.firstName + " " + item.user.lastName;
            obj.rating = item.score;
            obj.userId = item.user.id;
            obj.id = item.id;
            obj.isActive = item.isActive;
            obj.amount = item.dailyRate;

            if(item.equipmentMedias.length > 0){
              obj.imageUrl = item.equipmentMedias[0].filePath;
            } else {
              obj.imageUrl = null;
            }
            return obj;
            // vm.setState({
            //   items: vm.state.items.concat(obj),
            // });
          }),
        });
      })
      .catch(err=>console.log(err))

    // Used for mockup
    //this.setState({ mockupitems: Data.data.items });
  }

  loadMore = (nextPage) => {
    console.log({ nextPage });
    if (this.state.isLoadingInfiniteScroll) {
      return;
    }
    if (nextPage >= this.state.numOfPages) {
      return;
    }
    this.setState({
      isLoadingInfiniteScroll: true,
      infiniteScrollPage: nextPage,
    });
    const vm = this;

    let data = {};

    data.EquipmentType = 0;
    data.page = nextPage;
    data.perPage = this.state.perPage;
    $.ajax({
      method: "GET",
      dataType: "json",
      traditional: true,
      data: data,
      xhrFields: {
        withCredentials: true,
      },
      url: process.env.REACT_APP_API + "/equipments/user",

      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        vm.setState({
          isLoadingInfiniteScroll: false,
          numOfPages: res.data.totalPages,
          items: [
            ...vm.state.items,
            ...res.data.items.map((item) => {
              let obj = {};
              // obj.name = item.name + " " + item.make + " " + item.model;
              obj.name = item.name;
              obj.user = item.user.firstName + " " + item.user.lastName;
              obj.rating = item.score;
              obj.userId = item.user.id;
              obj.id = item.id;
              obj.isActive = item.isActive;
              obj.imageUrl = item.equipmentMedias[0].filePath;
              obj.amount = item.dailyRate;
              return obj;
              // vm.setState({
              //   items: vm.state.items.concat(obj),
              // });
            }),
          ],
        });
      })
      .catch(err=>console.log(err))
  };

  toggle(type) {
    if (type == "editprofilemodal") {
      this.setState((prevState) => ({
        EditProfileModalOpen: !prevState.EditProfileModalOpen,
      }));
    }
  }

  handleChange3(allowsCancelling) {
    const vm = this;
    vm.setState(
      {
        allowsCancelling: !this.state.allowsCancelling,
      },
      () => {
        let data = {
          allowsCancelling: vm.state.allowsCancelling,
          firstName: vm.state.firstName,
          lastName: vm.state.lastName,
          email: vm.state.email,
          phone: vm.state.phone,
          cityId: vm.state.cityId,
        };

        $.ajax({
          method: "PUT",
          dataType: "json",
          processData: false,
          traditional: true,
          contentType: "application/json",
          data: JSON.stringify(data),
          xhrFields: {
            withCredentials: true,
          },
          url: process.env.REACT_APP_API + "/users/me",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            Accept: "application/json",
          },
        })
          .then(function (res) {
            //console.log("was it all good")
            console.log("res me", res);
          })
          .catch((error) => {
            console.log("error", error);
            console.log("error r", error.response);
          });
      }
    );
  }

  //
  render() {
    console.log("mockupitems", this.state.mockupitems);
    return (
      <React.Fragment>
        <EditProfileModal
          toggle={this.toggle}
          modalOpen={this.state.EditProfileModalOpen}
        />
        <div id="test" style={{
            height: "100vh",
            // position: "relative"
          }}>
          <div
            className="myownstuff-container"
            style={{ padding: 10, marginTop: 100 }}
          >
            <div className="container w-full d-flex align-items-center">
              <div className="onee d-flex">
                {this.state.authUserpfImage ? (
                  <img
                    className="left-side-pf"
                    src={this.state.authUserpfImage}
                    alt="Avatar"
                    style={{
                      objectFit: "cover",
                      width: 50,
                      height: 50,
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <img
                    src={require("../../Common/images/no-profile-img.png")}
                    alt="Avatar"
                    className="left-side-pf"
                    style={{
                      objectFit: "cover",
                      width: 50,
                      height: 50,
                      borderRadius: "50%",
                    }}
                  />
                )}

                <div className="px-4">
                  <h4
                    className="post-label"
                    style={{ textTransform: "capitalize", marginBottom: 0 }}
                  >
                    {this.state.autheruserName}
                    <span className="user-id" style={{ color: "#A0A0A0" }}>
                      {" "}
                    </span>
                  </h4>
                  <p className="post-label">{this.state.cityName} </p>
                </div>
              </div>
              <div className="twoo" style={{ width: "49%" }}>
                {this.state.socialMediaUser ? (
                  <button
                    disabled
                    onClick={() => this.toggle("editprofilemodal")}
                    size="lg"
                    className="btn-hay-yellow"
                  >
                    Profile cannot be edited
                  </button>
                ) : (
                  <button
                    onClick={() => this.toggle("editprofilemodal")}
                    size="lg"
                    className="btn-hay-yellow"
                  >
                    Edit Profile
                  </button>
                )}
              </div>
              {/* <div className="cancel-stuff">
                <h5 style={{ paddingRight: "50px" }}>Allow Cancellations</h5>
                <Switch
                  onChange={this.handleChange3}
                  checked={this.state.allowsCancelling}
                  offColor="#E6E6E6"
                  onColor="#D0E7B2"
                  onHandleColor="#FFCD46"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                />{" "}
              </div> */}
            </div>

            <div
              // to={`/dashboard`}
              style={{
                color: "#000000",
                fontSize: "26px",
                fontWeight: 500,
                textAlign: "left",
                paddingTop: 20,
              }}
              className="container text-left justify-content-start"
            >
              <h2 className="mb-0">My posts</h2>
            </div>
            <div
              className="py-4"
              style={{
                overflowY: "auto",
                height: "500px",
                minHeight: "50vh",
              }}
            >
              <InfiniteScroll //new
                key={this.state.infiniteScrollPage}
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  gridGap: "10px",
                }}
                pageStart={this.state.infiniteScrollPage}
                loadMore={this.loadMore}
                hasMore={
                  this.state.infiniteScrollPage < this.state.numOfPages - 1
                }
                useWindow={false}
              >
                {this.state.items.map((item, index) => {
                  return (
                    <div
                      className="test"
                      style={{ width: "100%", gridColumn: "span 1" }}
                      key={item.id}
                    >
                      <Post
                        postName={item.name}
                        postUser={item.user}
                        postRating={item.rating}
                        postId={item.id}
                        postUserId={item.userId}
                        postImageUrl={item.imageUrl}
                        postIsActive={item.isActive}
                        postCategory={item.category}
                        amount={item.amount}
                      />
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
Profile.propTypes = {
  userId: PropTypes.number,
  autheruserName: PropTypes.string,
};
export default Profile;
